// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import moment,
{ Moment } from 'moment';

export interface ICountdownProps {
    to: Moment;
    /**
     * Called when countdown is finished.
     */
    onFinish?: () => void;
}

interface ITimeLeft {
    hours: number;
    minutes: number;
    seconds: number;
}

const Countdown: React.FC<ICountdownProps> = ({
    to,
    onFinish,
}) => {
    const [timeLeft, setTimeLeft] = React.useState(calculateTimeLeft(to));
    React.useEffect(() => {
        const interval = setInterval(() => {
            const left = calculateTimeLeft(to);
            setTimeLeft(left);
            if (isTimeExceed(left)) {
                onFinish?.();
                clearInterval(interval);
            }
        }, 1000);
        return () => clearInterval(interval);
    });

    if (isTimeExceed(timeLeft)) {
        return <span>00:00:00</span>;
    }

    return (
        <span>
            {format(timeLeft.hours)}:{format(timeLeft.minutes)}:{format(timeLeft.seconds)}
        </span>
    );
};

const calculateTimeLeft = (to: Moment): ITimeLeft => {
    const diff = to.diff(moment());
    const duration = moment.duration(Math.max(0, diff));
    return {
        hours: duration.hours(),
        minutes: duration.minutes(),
        seconds: duration.seconds(),
    };
};

const isTimeExceed = (t: ITimeLeft): boolean =>
    t.hours <= 0
    && t.minutes <= 0
    && t.seconds <= 0;

const format = (n: number): string => (n <= 9) ? `0${n}` : `${n}`;

export default Countdown;
