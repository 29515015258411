// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { IProjectTokenPricingResponse } from 'common/api/resources/Project';

export const calculatePrice = (tokens: number, tokenPricing: IProjectTokenPricingResponse): number =>
    tokens * tokenPricing.unit_cost;

export const formatPricePerMonth = (price: number, tokenPricing: IProjectTokenPricingResponse): string => {
    const calculatedPrice = calculatePrice(price, tokenPricing);
    const numberString = calculatedPrice.toFixed(tokenPricing.currency_decimals_per_month)
        .replace('.', tokenPricing.currency_decimals_separator)
        .replace(/\B(?=(\d{3})+(?!\d),.*$)/g, tokenPricing.currency_thousands_separator);

    return `${tokenPricing.currency_prefix}${numberString}${tokenPricing.currency_suffix}`;
};

export const formatPricePerHour = (price: number, tokenPricing: IProjectTokenPricingResponse): string => {
    const calculatedPrice = calculatePrice(price, tokenPricing);
    const numberString = calculatedPrice.toFixed(tokenPricing.currency_decimals_per_hour)
        .replace('.', tokenPricing.currency_decimals_separator)
        .replace(/\B(?=(\d{3})+(?!\d),.*$)/g, tokenPricing.currency_thousands_separator);

    return `${tokenPricing.currency_prefix}${numberString}${tokenPricing.currency_suffix}`;
};