// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import {
    IStorageTypeResponse,
    StorageType,
} from 'common/api/resources/StorageType';
import {
    IPaginated,
    IRequest,
    ISearchable,
} from 'common/api/resources/Request/request';
import { IShortStorageTagResponse } from 'common/api/resources/StorageTag';
import { IShortVmResponse } from 'common/api/resources/ComputeResourceVm';
import { IShortUserResponse } from 'common/api/resources/User';

export interface IStorageResponse {
    id: number;
    name: string;
    mount: string;
    type: IStorageTypeResponse;
    path: string;
    thin_pool: string | null;
    is_available_for_balancing: boolean;
    servers_count: number;
    compute_resources_count: number;
    free_space: number;
    credentials: Record<string, unknown>;
    storage_tag?: IShortStorageTagResponse;

    // virtual fields
    isDeleting: boolean;
    isLoading: boolean;
}
export interface IShortStorageResponse {
    id: number;
    name: string;
    mount: string;
    type: IStorageTypeResponse;
    path: string;
    thin_pool: string | null;
    is_available_for_balancing: boolean;
    free_space: number;
    credentials: Record<string, unknown>;
}

export interface IStorageCreateRequest {
    type: StorageType;
    path: string;
    thin_pool?: string;
    is_available_for_balancing: boolean;
    storage_tag_id?: number|null;
}

export interface IStorageUpdateRequest {
    is_available_for_balancing: boolean;
    storage_tag_id?: number|null;
}

export interface IStorageRequest {
    name: string;
    is_available_for_balancing: boolean;
    type: StorageType;
    mount: string;
    credentials: Record<string, unknown>;
    storage_tag_id?: number|null;
}

export interface IStorageMountRequest {
    compute_resource_id: number;
}

export interface IStoragePatchRequest {
    is_available_for_balancing: boolean;
}

export interface IStorageListFilters {
    type?: StorageType;
}

export interface IStorageListRequest extends IRequest<IStorageListFilters>, IPaginated, ISearchable {}

export enum BALANCE_STRATEGY {
    RANDOM = 'random',
    ROUND_ROBIN = 'round-robin',
    MOST_STORAGE_AVAILABLE = 'most-storage-available',
}

export interface IUpdateStorageResource {
    storage: IStorageResponse;
}

export interface IStorageDiskResponse {
    id: number;
    is_primary: boolean;
    name: string;
    size: number;
    actual_size: number;
    path: string;
    server: IShortVmResponse;
    user: IShortUserResponse;
}

export interface IStorageDiskListRequest extends IRequest<object>, IPaginated {}
