// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    IAdditionalDiskRequest,
    IVmDiskResponse,
} from 'common/api/resources/ComputeResourceVm';
import { IAppState as IClientState } from 'client/core/store';
import { connect } from 'react-redux';
import {
    Alert,
    Form,
    FormField,
    FormFieldText,
    Icon,
    Section,
    setIn,
    Translate,
} from '@plesk/ui-library';
import {
    ICONS,
    INTENT_TYPE,
    SIZE,
} from 'common/constants';
import { FormFieldNumber } from 'common/components/Form/FormFieldNumber/FormFieldNumber';
import { IProjectTokenPricingResponse } from 'common/api/resources/Project';
import {
    IOfferParametersAdditionalDisk,
    IOfferResponse,
    IShortOfferResponse,
    offer,
    OfferPriceMode,
    OfferType,
} from 'common/api/resources/Offer';
import AsyncSelectInput from 'common/components/Select/AsyncSelectInput';
import { createOptionsLoader } from 'common/components/Select/helpers';
import { Button } from 'admin/common/components/Button/Button';
import { ISelectOption } from 'common/components';
import { IPlanResponse } from 'common/api/resources/Plan';
import {
    offerToPricePerHour,
    offerToPricePerMonth,
} from 'common/helpers/ProjectTokenCalculator';
import { FormDescription } from 'common/modules/computeResourceVm/components/Styles';
import { ICommonState } from 'common/store';
import {
    formatPricePerHour,
    formatPricePerMonth,
} from 'common/helpers/token_pricing';
import { nestStringProperties } from 'common/modules/app/formErrors/selectors';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import {
    intMinMaxRule,
    intMinRule,
    requiredRule,
    validate,
} from 'common/validator';

export interface IAdditionalDiskFormProps {
    disk?: IVmDiskResponse;
    onSubmit: (disk: IAdditionalDiskRequest) => void;
    selectedPlan?: IPlanResponse;
    tokenPricing?: IProjectTokenPricingResponse;
    disabledOfferIds?: number[];
    fields: string[];
    confirmation?: React.ReactNode;
}

export type AdditionalDiskFormProps =
    IAdditionalDiskFormProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

interface IOfferSelectOption extends ISelectOption {
    label: string;
    value: number;
    offer: IOfferResponse|IShortOfferResponse;
}

const offerToSelectOption = (offerResponse: IOfferResponse|IShortOfferResponse): IOfferSelectOption => ({
    label: offerResponse.name,
    value: offerResponse.id,
    offer: offerResponse,
});

const offersLoader = createOptionsLoader(
    offer.list,
    offerToSelectOption,
    true,
    { type: OfferType.ADDITIONAL_DISK }
);

export const FIELDS = {
    NAME: 'name',
    OFFER: 'offer',
    SIZE: 'size',
};

export const AdditionalDiskForm: React.FC<AdditionalDiskFormProps> = ({
    disk,
    tokenPricing,
    disabledOfferIds,
    onSubmit,
    selectedPlan,
    formErrors,
    formErrorsActions: {
        setFormErrors,
        clearFormErrors,
    },
    fields,
    confirmation,
}) => {
    const [submitValues, setSubmitValues] = React.useState<IAdditionalDiskRequest>({
        name: disk ? disk.name : '',
        size: disk ? disk.size : 0,
        offer_id: disk && disk.offer ? disk.offer.id : 0,
    });
    const handleFieldChange = (field: string, value: string) => setSubmitValues(setIn(submitValues, field, value));

    const [selectedOfferOption, setSelectedOfferOption] = React.useState<IOfferSelectOption|undefined>(
        disk && disk.offer ? offerToSelectOption(disk.offer) : undefined
    );
    const handleOfferChange = (item: IOfferSelectOption) => {
        setSelectedOfferOption(item);
    };
    React.useEffect(() => {
        setSubmitValues(values => ({
            ...values,
            offer_id: selectedOfferOption?.value || 0,
        }));
    }, [selectedOfferOption, setSubmitValues]);

    const selectedOffer = selectedOfferOption?.offer;

    const maxDiskSizeLimit = (selectedOffer?.parameters as IOfferParametersAdditionalDisk)?.max_size;
    const maxDiskSizeGiB = maxDiskSizeLimit?.is_enabled ? maxDiskSizeLimit.limit : 1024;

    const diskPricePerMonth = selectedOffer ? offerToPricePerMonth(selectedOffer, selectedPlan, submitValues.size) : undefined;
    const diskPricePerHour = selectedOffer ? offerToPricePerHour(selectedOffer, selectedPlan, submitValues.size) : undefined;

    const offerSizeOverflow = submitValues.size > maxDiskSizeGiB;

    React.useEffect(() => {
        clearFormErrors();

        if (offerSizeOverflow) {
            setFormErrors({
                'size': [
                    <Translate content="servers.additionalDisk.form.errors.sizeOverflow" params={{ max_size: maxDiskSizeGiB }} />,
                ],
            });
        }
    }, [offerSizeOverflow, maxDiskSizeGiB, setFormErrors, clearFormErrors]);

    const handleSubmit = (values: IAdditionalDiskRequest) => {
        const rules = {};

        if (fields.includes(FIELDS.NAME)) {
            rules['name'] = requiredRule(<Translate content="validate.fieldRequired" />);
        }
        if (fields.includes(FIELDS.OFFER)) {
            rules['offer_id'] = [
                requiredRule(<Translate content="validate.fieldRequired" />),
                intMinRule(<Translate content="validate.fieldRequired" />, 1), // since the default state is 0
            ];
        }
        if (fields.includes(FIELDS.SIZE)) {
            rules['size'] = [
                requiredRule(<Translate content="validate.fieldRequired" />),
                intMinMaxRule(
                    <Translate content="servers.additionalDisk.form.errors.sizeOverflow" params={{
                        max_size: maxDiskSizeGiB,
                    }} />,
                    1,
                    maxDiskSizeGiB
                ),
            ];
        }

        const errors = validate<IAdditionalDiskRequest>(values, rules);

        if (Object.keys(errors).length) {
            setFormErrors(errors);
            return;
        }

        clearFormErrors();

        onSubmit(values);
    };

    const description = React.useMemo(() => {
        if (!tokenPricing || !selectedOffer) {
            return <></>;
        }

        if (selectedOffer.price_mode === OfferPriceMode.PERCENT) {
            if (selectedPlan === undefined) {
                return (
                    <>
                        <Alert intent={INTENT_TYPE.WARNING}>
                            <Icon intent={INTENT_TYPE.WARNING} name={ICONS.TRIANGLE_EXCLAMATION_MARK_FILLED} />
                            &nbsp;
                            <Translate content="servers.offer.notice.selectPlanForPrice" />
                        </Alert>
                        <Translate
                            content="servers.additionalDisk.form.description.percent"
                            params={{
                                per_month: selectedOffer.per_month,
                                per_hour: selectedOffer.per_hour,
                            }}
                        />
                    </>
                );
            }
        }

        if (selectedOffer.price_mode === OfferPriceMode.PER_GIB) {
            if (submitValues.size < 1) {
                return (
                    <>
                        <Translate
                            content="servers.additionalDisk.form.description.per_gib"
                            params={{
                                per_month: formatPricePerMonth(selectedOffer.per_month, tokenPricing),
                                per_hour: formatPricePerHour(selectedOffer.per_hour, tokenPricing),
                            }}
                        />
                    </>
                );
            }
        }

        return (
            <Translate
                content="servers.additionalDisk.form.description.per_gib"
                params={{
                    per_month: formatPricePerMonth(diskPricePerMonth!, tokenPricing),
                    per_hour: formatPricePerHour(diskPricePerHour!, tokenPricing),
                }}
            />);
    }, [tokenPricing, selectedPlan, selectedOffer, diskPricePerMonth, diskPricePerHour, submitValues.size]);

    return (
        <>
            <Form
                id="additionalDiskForm"
                footerClassName="hidden"
                onSubmit={handleSubmit}
                values={submitValues}
                onFieldChange={handleFieldChange}
                errors={formErrors}
                hideRequiredLegend={true}
                vertical={true}
                style={{ maxWidth: '420px' }}
            >
                <Section>
                    {confirmation && (
                        <Alert intent={INTENT_TYPE.WARNING}>
                            <Icon intent={INTENT_TYPE.WARNING} name={ICONS.TRIANGLE_EXCLAMATION_MARK_FILLED} />
                            &nbsp;
                            {confirmation}
                        </Alert>
                    )}
                    {fields.includes(FIELDS.NAME) && (
                        <FormFieldText
                            id="additionalDiskName"
                            name="name"
                            size={SIZE.FILL}
                            label={
                                <Translate content="servers.additionalDisk.form.name" />
                            }
                            required={true}
                        />
                    )}
                    {fields.includes(FIELDS.OFFER) && (
                        <FormField
                            name="offer_id"
                            label={<Translate content="servers.additionalDisk.form.offer" />}
                            required={true}
                        >
                            {({ getId }) => (
                                <AsyncSelectInput
                                    inputId={getId()}
                                    value={selectedOfferOption}
                                    loadOptions={offersLoader}
                                    debounceTimeout={1000}
                                    additional={{ page: 1 }}
                                    onChange={handleOfferChange}
                                    placeholder={<Translate content="servers.additionalDisk.form.offerEmpty" />}
                                    required={true}
                                    isOptionDisabled={(option: IOfferSelectOption) => disabledOfferIds?.includes(option.value)}
                                    formatOptionLabel={(option: IOfferSelectOption) => {
                                        const priceLabel = (() => {
                                            if (!tokenPricing) {
                                                return undefined;
                                            }

                                            switch (option.offer.price_mode) {
                                            case OfferPriceMode.PER_UNIT:
                                                return (
                                                    <Translate
                                                        content={'servers.additionalDisk.form.offerPrice.per_unit'}
                                                        params={{
                                                            per_month: formatPricePerMonth(option.offer.per_month, tokenPricing),
                                                            per_hour: formatPricePerHour(option.offer.per_hour, tokenPricing),
                                                        }}
                                                    />
                                                );
                                            case OfferPriceMode.PER_GIB:
                                                return (
                                                    <Translate
                                                        content={'servers.additionalDisk.form.offerPrice.per_gib'}
                                                        params={{
                                                            per_month: formatPricePerMonth(option.offer.per_month, tokenPricing),
                                                            per_hour: formatPricePerHour(option.offer.per_hour, tokenPricing),
                                                        }}
                                                    />
                                                );
                                            case OfferPriceMode.PERCENT:
                                                return (
                                                    <Translate
                                                        content={'servers.additionalDisk.form.offerPrice.percent'}
                                                        params={{
                                                            per_month: option.offer.per_month,
                                                            per_hour: option.offer.per_hour,
                                                        }}
                                                    />
                                                );
                                            default:
                                                return undefined;
                                            }
                                        })();

                                        return (
                                            <>
                                                {option.label} { priceLabel && (<>{priceLabel}</>) }
                                            </>
                                        );
                                    }}
                                />
                            )}
                        </FormField>
                    )}
                    {fields.includes(FIELDS.SIZE) && (
                        <FormFieldNumber
                            name="size"
                            size={SIZE.FILL}
                            min={0}
                            max={maxDiskSizeGiB}
                            label={
                                <Translate content="servers.additionalDisk.form.size" />
                            }
                            required={true}
                        />
                    )}
                </Section>
                {tokenPricing && (fields.includes(FIELDS.SIZE) || fields.includes(FIELDS.OFFER)) && (
                    <FormDescription>
                        {description}
                    </FormDescription>
                )}
            </Form>
            <Button
                type="submit"
                form="additionalDiskForm"
                fill={true}
                intent={INTENT_TYPE.PRIMARY}
                size={SIZE.LG}
            >
                <Translate content="servers.additionalDisk.saveBtn" />
            </Button>
        </>
    );
};

const mapStateToProps = (state: (IClientState | ICommonState)) => ({
    formErrors: nestStringProperties(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    formErrorsActions: bindActionCreators(formErrorsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AdditionalDiskForm);