// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';
import { COLORS } from 'common/theme';

interface IItemProps {
    isSelected: boolean;
}

export const UserDataButton = styled.button<IItemProps>`
    color: ${props => !props.isSelected ? props.theme.primary_color : '#fff'};
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid ${COLORS.GREY_30};
    box-sizing: border-box;
    background: ${props => !props.isSelected ? COLORS.WHITE_0 : props.theme.primary_color};
    border-radius: 2px;
    height: 66px;
    padding: 12px 20px;
    margin-right: 15px;
    width: 180px;
    cursor: pointer;
    &:hover {
        border: 1px solid ${COLORS.GREY_30};
        box-sizing: border-box;
        border-radius: 2px;
        background: ${(props) => !props.isSelected ? COLORS.GREY_20 : props.theme.primary_color};
    }
    
    div {
        font-size: 14px;
        line-height: 20px;
        font-weight: 600;
    }
    
    &:disabled {
        color: ${COLORS.GREY_40};
        background: ${COLORS.GREY_20};
    }
`;

export const UserDataContainer = styled.div`
    display: flex;
    flex: 1;
    flex-direction: column;
    height: 100%;

    .pul-form {
        flex: 1;
    }

    .CodeMirror {
        border: 1px solid #eee;
        height: 450px;
        overflow: hidden;
        position: relative;
    }
`;
