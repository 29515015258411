// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';

export const FigureTitle = styled.div`
    z-index: 999;
    color: #fff;
    line-height: 26px;
    font-size: 20px;
    font-weight: 600;
`;

interface IFigureContentProps {
    url: string;
}

export const FigureContent = styled.div<IFigureContentProps>`
    width: 32px;
    height: 32px;
    display: flex;
    align-items: center;
    justify-content: center;

    ${({ url }) => `
        background-image: url(${url});
    `}
`;
