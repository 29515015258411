// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';
import { COLORS } from 'admin/core/theme';

export const ProgressLoaderWrapper = styled.div`
    padding: 0;
    margin: 0;

    & div {
        margin: 0;
    }

    .pul-content-loader__container {
          padding: 0 0 7px 0;
          justify-content: start;
    }
    
    .pul-content-loader__loader {
        padding: 0 0 0 30px;
    }
`;

export const StatusWrapper = styled.div`
  .pul-action {
    margin-left: 15px;
    vertical-align: middle;
    font-size: 14px;
    line-height: 20px;
    font-weight: normal;
  }
  
  .pul-status {
      color: ${COLORS.GREY_80};
  }
`;

export const Table = styled.table`
    td {
        &:first-child {
            font-size: 14px;
            line-height: 20px;
            color: ${COLORS.GREY_90};
        }
    
       padding-bottom: 12px;
       padding-right: 16px;
    }
`;

export const FormContainer = styled.div`
    padding-top: 30px;
    width: 500px;
    
    .pul-form-field__error {
        max-width: 500px;
    }
`;
