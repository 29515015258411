// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as imagePresetActions from 'admin/imagePreset/actions';
import { combineReducers } from 'redux';
import {
    ActionType,
    getType,
} from 'typesafe-actions';
import {
    IPaginateApiResponse,
    paginateInitialState,
} from 'common/api/resources/Response';
import {
    addToPaginated,
    deleteFromPaginated,
    updateInPaginated,
} from 'common/reducers/list';
import {
    IImagePresetResponse,
    ImagePresetType,
} from 'common/api/resources/ImagePreset';
import { VirtualizationType } from 'common/api/resources/ComputeResource';

interface IImagePreset {
    list: IPaginateApiResponse<IImagePresetResponse[]>;
    item: IImagePresetResponse;
}

export type ImagePresetAction = ActionType<typeof imagePresetActions>;
export type ImagePresetState = IImagePreset;

export const initialImagePresetState: IImagePresetResponse = {
    id: 0,
    name: '',
    is_deleting: false,
    image_type: ImagePresetType.OS_IMAGE_VERSION,
    virtualization_type: VirtualizationType.KVM,
    os_image_versions: [],
    applications: [],
    is_default: false,
};

export default combineReducers<ImagePresetState, ImagePresetAction>({
    list: (state = paginateInitialState, action: ImagePresetAction) => {
        switch (action.type) {
        case getType(imagePresetActions.addImagePresetItem):
            return addToPaginated(state, action.payload);
        case getType(imagePresetActions.setImagePresetList):
            return action.payload;
        case getType(imagePresetActions.removeImagePresetItem):
            return deleteFromPaginated(state, action.payload);
        case getType(imagePresetActions.updateImagePresetItem):
            return updateInPaginated(state, action.payload);
        case getType(imagePresetActions.setImagePresetItemIsDeleting):
            return updateInPaginated(state, action.payload);
        default:
            return state;
        }
    },
    item: (state = initialImagePresetState, action) => {
        switch (action.type) {
        case getType(imagePresetActions.setImagePresetItem):
            return action.payload;
        case getType(imagePresetActions.unsetImagePresetItem):
            return { ...initialImagePresetState };
        default:
            return state;
        }
    },
});
