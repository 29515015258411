// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { Button } from '@plesk/ui-library';
import {
    BUTTON_INTENT_TYPE,
    INTENT_TYPE,
} from 'common/constants';
import * as responseErrorActions from 'common/modules/app/responseError/actions';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { connect } from 'react-redux';
import { RootState } from 'client/core/store';
import ConfirmationPopover from 'common/components/ConfirmationPopover';
import { StyledButton } from 'admin/common/components/Button/Styles';

export const TEST = {
    CONFIRM_POPOVER: 'confirm-popover',
};

interface IButtonWithConfirmationProps extends React.HTMLProps<HTMLDivElement> {
    onClose?: () => void;
    confirmationButtonText?: string | React.ReactNode;
    confirmationButtonGhost?: boolean;
    disabled?: boolean;
    icon?: string | React.ReactNode;
    withForce?: boolean;
    isLoading?: boolean;
    handleConfirm: () => void;
    translations: {
        button: React.ReactNode;
        text?: React.ReactNode;
        tooltip?: React.ReactNode;
        title?: React.ReactNode;
    };
    'data-cy'?: string;
    buttonColor?: string;
    withStyledButton?: boolean;
    buttonSize?: 'md' | 'lg';
    buttonIntent?: BUTTON_INTENT_TYPE;
    buttonClassName?: string;
    placement?: string;
    withConfirmation?: boolean;
}

export type ButtonWithConfirmationProps =
    IButtonWithConfirmationProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const ButtonWithConfirmation: React.FC<ButtonWithConfirmationProps> = ({
    responseErrorActions: { clearResponseError },
    confirmationButtonText,
    confirmationButtonGhost,
    onClose,
    error,
    icon,
    handleConfirm,
    isLoading,
    disabled,
    translations: {
        button,
        text,
        title,
        tooltip,
    },
    'data-cy': dataCy,
    buttonColor,
    withStyledButton,
    buttonSize,
    buttonIntent,
    buttonClassName,
    placement,
    withConfirmation = true,
}) => {
    const ghost = confirmationButtonGhost !== undefined ? confirmationButtonGhost : true;
    const [isPopoverOpened, setPopoverOpened] = React.useState(false);

    const handleClose = () => {
        setPopoverOpened(false);
        clearResponseError();

        onClose?.();
    };

    const handleOpenPopover = (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        clearResponseError();
        setPopoverOpened(true);
    };

    const handleClickConfirm = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        setPopoverOpened(false);

        await handleConfirm();

        if (!error.error) {
            setPopoverOpened(false);
        }
    };

    let Btn = Button;
    let style: Record<string, string> = { verticalAlign: 'bottom', maxHeight: '28px' };
    if (withStyledButton) {
        Btn = StyledButton;
        style = {};
    }

    return (
        <ConfirmationPopover
            opened={isPopoverOpened}
            buttonDataCy={TEST.CONFIRM_POPOVER}
            translations={{
                button,
                text,
                title,
            }}
            disabled={isLoading}
            error={error.error ? error.error : undefined}
            intent={error.error ? INTENT_TYPE.DANGER : undefined}
            isLoading={isLoading}
            onButtonClick={handleClickConfirm}
            onClose={handleClose}
            buttonColor={buttonColor}
            placement={placement}
        >
            <Btn
                intent={buttonIntent}
                ghost={ghost}
                size={buttonSize}
                style={style}
                state={isLoading ? 'loading' : undefined}
                disabled={disabled}
                tooltip={tooltip}
                onClick={withConfirmation ? handleOpenPopover : handleClickConfirm}
                icon={icon}
                data-cy={dataCy}
                className={buttonClassName}
            >
                {confirmationButtonText}
            </Btn>
        </ConfirmationPopover>
    );
};

const mapStateToProps = (state: RootState) => ({
    error: state.app.responseError,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    responseErrorActions: bindActionCreators(responseErrorActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ButtonWithConfirmation);
