// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import {
    ActionType,
    getType,
} from 'typesafe-actions';
import * as languageActions from 'common/modules/app/language/actions';
import { ILanguageResponse } from 'common/api/resources/Language';
import { initialIconState } from 'admin/icon/reducer';
import { DEFAULT_LOCALE } from 'common/components/LocaleProvider';

export type LocaleAction = ActionType<typeof languageActions>;
export type LanguageState = Readonly<ILanguageResponse>;

export const initialLanguageState = {
    id: 0,
    name: '',
    locale: DEFAULT_LOCALE,
    country: '',
    icon: initialIconState,
    is_default: false,
    is_visible: true,
    users_count: 0,
};

const reducer = (state: LanguageState = initialLanguageState, action: LocaleAction) => {
    if (action.type === getType(languageActions.setLanguage)) {
        return action.payload;
    }

    return state;
};

export default reducer;
