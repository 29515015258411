// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { EmptyView } from 'common/components/EmptyView/EmptyView';
import { NotFoundSvg } from 'common/components/ErrorPages/Styles';

export const NotFound: React.FC = () => (
    <EmptyView
        title="errorPages.notFound.title"
        description="errorPages.notFound.description"
        customIcon={<NotFoundSvg />}
    />
);

export default NotFound;