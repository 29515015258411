// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { IVmResponse } from 'common/api/resources/ComputeResourceVm';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { connect } from 'react-redux';
import { ICommonState } from 'common/store';
import * as vpcNetworkActions from 'common/modules/vpcNetwork/actions';
import { createOptionsLoader } from 'common/components/Select/helpers';
import {
    Form,
    FormField,
    Section,
    Translate,
} from '@plesk/ui-library';
import AsyncSelectInput from 'common/components/Select/AsyncSelectInput';
import { ISelectOption } from 'common/components/SelectInput';
import { nestStringProperties } from 'common/modules/app/formErrors/selectors';
import { Button } from 'admin/common/components/Button/Button';
import {
    INTENT_TYPE,
    SIZE,
} from 'common/constants';
import { IVpcNetworkAttachServersRequest } from 'common/api/resources/VpcNetwork';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import {
    requiredRule,
    validate,
} from 'common/validator';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';

export interface IAttachVpcInterfaceFormProps {
    server: IVmResponse;
    onSubmit: () => void;
    attachedVpcNetworkIds?: number[];
}

export type AttachVpcInterfaceFormProps = IAttachVpcInterfaceFormProps
    & ReturnType<typeof mapStateToProps>
    & ReturnType<typeof mapDispatchToProps>;

export const AttachVpcInterfaceForm: React.FC<AttachVpcInterfaceFormProps> = ({
    server,
    onSubmit,
    formErrors,
    isAttaching,
    vpcNetworkActions: {
        getVpcNetworks,
        attachServersToVpcNetwork,
    },
    formErrorsActions: {
        clearFormErrors,
        setFormErrors,
    },
    attachedVpcNetworkIds = [],
}) => {
    const [submitValues, setSubmitValues] = React.useState<IVpcNetworkAttachServersRequest>({
        server_ids: [server.id],
    });
    React.useEffect(() => {
        setSubmitValues({
            server_ids: [server.id],
        });
    }, [server.id]);

    React.useEffect(() => {
        clearFormErrors();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmit = async (values: IVpcNetworkAttachServersRequest) => {
        const rules = {
            vpc_network_id: [
                requiredRule(<Translate content="validate.fieldRequired" />),
            ],
        };

        const errors = validate({
            ...values,
            vpc_network_id: selectedVpcNetwork?.value,
        }, rules);

        if (Object.keys(errors).length) {
            setFormErrors(errors);
            return;
        }

        await attachServersToVpcNetwork(selectedVpcNetwork!.value as number, values);

        onSubmit();
    };

    const [selectedVpcNetwork, setSelectedVpcNetwork] = React.useState<ISelectOption>();
    const vpcNetworksLoader = createOptionsLoader(
        getVpcNetworks,
        (vpcNetwork) => ({
            value: vpcNetwork.id,
            label: vpcNetwork.name,
        }),
        true,
        {
            available_for_server: server.id,
        }
    );
    const handleVpcNetworkChange = (option: ISelectOption) => {
        setSelectedVpcNetwork(option);
    };

    return (
        <>
            <Form
                id="attachVpcInterfaceForm"
                onSubmit={handleSubmit}
                values={submitValues}
                footerClassName="hidden"
                errors={formErrors}
                hideRequiredLegend={true}
                submitButton={false}
                cancelButton={false}
                applyButton={false}
                vertical={true}
            >
                <Section>
                    <FormField
                        name="vpc_network_id"
                        label={<Translate content="servers.tabs.networking.vpcInterfaces.attachDialog.form.vpcNetwork" />}
                        required={true}
                    >
                        {({ getId }) => (
                            <AsyncSelectInput
                                inputId={getId()}
                                value={selectedVpcNetwork}
                                loadOptions={vpcNetworksLoader}
                                debounceTimeout={1000}
                                additional={{ page: 1 }}
                                onChange={handleVpcNetworkChange}
                                isClearable={true}
                                menuPosition={'fixed'}
                                filterOption={
                                    (option: ISelectOption) => !attachedVpcNetworkIds.includes(option.value as number)
                                }
                            />
                        )}
                    </FormField>
                </Section>
            </Form>
            <Button
                type="submit"
                form="attachVpcInterfaceForm"
                fill={true}
                intent={INTENT_TYPE.PRIMARY}
                isLoading={isAttaching}
                size={SIZE.LG}
            >
                <Translate content="servers.tabs.networking.vpcInterfaces.attachDialog.form.saveBtn" />
            </Button>
        </>
    );
};

const mapStateToProps = (state: ICommonState) => ({
    isAttaching: state.app.loadingFlags.has(LOADING_FLAGS.VPC_NETWORK_SERVER_ATTACH),
    formErrors: nestStringProperties(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    vpcNetworkActions: bindActionCreators(vpcNetworkActions, dispatch),
    formErrorsActions: bindActionCreators(formErrorsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(AttachVpcInterfaceForm);