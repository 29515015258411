// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { createCustomAction } from 'typesafe-actions';
import { Dispatch } from 'redux';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { get } from 'common/actions/actionsWrapper';
import * as types from 'common/modules/osImage/constants/types';
import {
    removeOsImageVersionItem,
    setOsImageVersionItemIsLoading,
    unsetOsImageVersionItemIsLoading,
    updateOsImageVersionItem,
    updateOsImageVersionItemPosition,
} from 'common/modules/osImage/actions';
import {
    setIsLoading,
    unsetIsLoading,
} from 'common/modules/app/loadingFlags/actions';
import { INTENT_TYPE } from 'common/constants';
import { bakeForegroundToast } from 'common/modules/app/toaster/actions';
import { clearFormErrors } from 'common/modules/app/formErrors/actions';
import { HTTP_CODES } from 'common/api/constants';
import {
    IOsImageVersionPatchRequest,
    IOsImageVersionRequest,
    IOsImageVersionResponse,
    osImageVersions,
} from 'common/api/resources/OsImageVersion';

export const setOsImageVersionItem = createCustomAction(
    types.SET_OS_IMAGE_VERSION_ITEM,
    (data: IOsImageVersionResponse) => ({ payload: data })
);
export const unsetOsImageVersionItem = createCustomAction(types.UNSET_OS_IMAGE_VERSION_ITEM);

export const getOsImageVersion = (id: number) => async (dispatch: Dispatch) => await get(id, {
    dispatch,
    apiCall: osImageVersions.item,
    action: setOsImageVersionItem,
    loadingFlag: LOADING_FLAGS.OS_IMAGE_VERSION_ITEM,
});

export const updateOsImageVersion = (id: number, data: IOsImageVersionRequest) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.SAVE_OS_IMAGE_VERSION_ITEM));

    try {
        const result = await osImageVersions.update(id, data);

        bakeForegroundToast(INTENT_TYPE.SUCCESS, 'osImage.toasts.osImageSaved')(dispatch);
        dispatch(clearFormErrors());
        dispatch(updateOsImageVersionItem(result.data.data.os_image_id, result.data.data));

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.SAVE_OS_IMAGE_VERSION_ITEM));
    }
};

export const updateOsImageVersionPosition = (id: number, data: IOsImageVersionResponse) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.SAVE_OS_IMAGE_VERSION_ITEM));
    dispatch(updateOsImageVersionItemPosition(data));

    try {
        const result = await osImageVersions.patch(id, data);

        bakeForegroundToast(INTENT_TYPE.SUCCESS, 'osImage.toasts.osImageSaved')(dispatch);
        dispatch(clearFormErrors());
        dispatch(updateOsImageVersionItem(result.data.data.os_image_id, result.data.data));

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.SAVE_OS_IMAGE_VERSION_ITEM));
    }
};

export const updateOsImageVersionVisibility = (id: number, osImageId: number, data: IOsImageVersionPatchRequest) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.SAVE_OS_IMAGE_VERSION_ITEM));
    dispatch(setOsImageVersionItemIsLoading(osImageId, id));

    try {
        const result = await osImageVersions.patch(id, data);

        bakeForegroundToast(INTENT_TYPE.SUCCESS, 'osImage.toasts.osImageSaved')(dispatch);
        dispatch(clearFormErrors());
        dispatch(updateOsImageVersionItem(result.data.data.os_image_id, result.data.data));

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.SAVE_OS_IMAGE_VERSION_ITEM));
        dispatch(unsetOsImageVersionItemIsLoading(osImageId, id));
    }
};

export const removeOsImageVersion = (version: IOsImageVersionResponse) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.REMOVE_OS_IMAGE_VERSION_ITEM));

    try {
        const result = await osImageVersions.remove(version.id);

        if (result.status === HTTP_CODES.NO_CONTENT) {
            bakeForegroundToast(INTENT_TYPE.SUCCESS, 'osImage.toasts.osImageDeleted')(dispatch);
            dispatch(removeOsImageVersionItem(version.os_image_id, version.id));
        }

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.REMOVE_OS_IMAGE_VERSION_ITEM));
    }
};
