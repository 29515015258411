// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { connect } from 'react-redux';
import { RootState } from 'admin/core/store';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { StyledTable } from 'common/components/styles/StyledTable';
import {
    Button,
    FormField,
    Icon,
    List,
    Translate,
} from '@plesk/ui-library';
import { PageHeader } from 'admin/common/components/PageHeader/PageHeader';
import {
    ICONS,
    SIZE,
} from 'common/constants';
import {
    ISelectOption,
    ISelectRequiredOption,
    Loader,
} from 'common/components';
import { EmptyView } from 'common/components/EmptyView/EmptyView';
import ButtonWithConfirmation from 'common/components/ButtonWithConfirmation';
import { dataCySelector } from 'common/tests/selectors';
import { TABLE_ACTIONS } from 'client/account/constants/test';
import { IApiTokenResponse } from 'common/api/resources/ApiToken';
import * as apiTokenActions from 'admin/apiToken/actions';
import * as tagActions from 'admin/tag/actions';
import ApiTokenCreateForm from 'admin/apiToken/containers/ApiTokenCreateForm';
import ApiTokenEditForm from 'admin/apiToken/containers/ApiTokenEditForm';
import InfiniteScroll from 'common/components/InfinityScroll/InfinityScroll';
import { ValueType } from 'react-select';
import { UserFilter } from 'admin/common/components/Filters/UserFilter/UserFilter';
import FilterForm from 'admin/common/components/FilterForm/FilterForm';
import { getActionColumnProps } from 'common/helpers/list';
import { Dialog } from 'common/components/Dialog/Dialog';
import { EntityType } from 'common/api/resources/Tag';
import { hasPermission } from 'common/modules/permission/selectors';
import { PERMISSION_LIST } from 'common/modules/permission/constants';

export type ApiTokensProps =
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

const columns = [{
    width: '15%',
    key: 'colName',
    title: <Translate content="apiToken.list.name" />,
}, {
    width: '15%',
    key: 'colUser',
    title: <Translate content="apiToken.list.user" />,
}, {
    width: '15%',
    key: 'colCreated',
    title: <Translate content="apiToken.list.created" />,
}, getActionColumnProps(),
];

enum DIALOG {
    NONE,
    CREATE,
    EDIT,
}

export const ApiTokens: React.FC<ApiTokensProps> = ({
    isLoadingList,
    isLoadingItem,
    list,
    canManageTags,
    apiTokenActions: {
        getApiTokens,
        getApiToken,
        loadApiTokensOnScroll,
        removeApiToken,
    },
    tagActions: {
        getTaggableEntity,
    },
}) => {
    React.useEffect(() => {
        getApiTokens();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [dialog, setDialog] = React.useState(DIALOG.NONE);

    const handleCreate = () => {
        setDialog(DIALOG.CREATE);
    };
    const handleEdit = (id: string) => () => {
        getApiToken(id);
        if (canManageTags) {
            getTaggableEntity(id, EntityType.TOKEN);
        }
        setDialog(DIALOG.EDIT);
    };
    const closeDialog = () => setDialog(DIALOG.NONE);

    const handleRemove = (apiToken: IApiTokenResponse) => () => removeApiToken(apiToken);

    const handleUserFilterChange = async (option: ValueType<ISelectRequiredOption>) => {
        const type = option as ISelectOption;
        type ? getApiTokens({
            filters: {
                user_id: option ? parseFloat((option as ISelectRequiredOption).value) : 0,
            },
        }) : getApiTokens();
    };

    const data = list.data.map(item => ({
        colName: item.name,
        colUser: item.user ? item.user.email : '',
        colCreated: item.created_at,
        colActions: (
            <>
                <Button
                    ghost={true}
                    icon={<Icon name={ICONS.PENCIL} size={18} />}
                    tooltip={<Translate content="apiToken.tooltip.edit"/>}
                    onClick={handleEdit(item.id)}
                    data-cy={dataCySelector(item.id, TABLE_ACTIONS.EDIT)}
                />
                <ButtonWithConfirmation
                    isLoading={item.isLoading}
                    translations={{
                        text: (
                            <Translate content="apiToken.confirmationRemove.text" />
                        ),
                        button: (
                            <Translate content="apiToken.confirmationRemove.button" />
                        ),
                        tooltip: (
                            <Translate content="apiToken.confirmationRemove.button" />
                        ),
                    }}
                    handleConfirm={handleRemove(item)}
                    data-cy={dataCySelector(item.id, TABLE_ACTIONS.REMOVE)}
                    icon={ICONS.RECYCLE}
                />
            </>
        ),
        key: item.id.toString(),
    }));

    return (
        <>
            <PageHeader
                isButtonShown={true}
                title={<Translate content="apiToken.title"/>}
                buttonText="apiToken.addNewToken"
                buttonIcon={ICONS.HEXAGONS}
                onButtonClick={handleCreate}
            />
            <FilterForm>
                <FormField label={<Translate content="apiToken.list.user" />}>
                    <UserFilter onChange={handleUserFilterChange} margin_left="0px" />
                </FormField>
            </FilterForm>
            <StyledTable>
                <InfiniteScroll
                    loadMore={loadApiTokensOnScroll}
                    hasMore={!!list.links.next}
                >
                    <Loader isLoading={isLoadingList}>
                        <List
                            emptyView={
                                <EmptyView
                                    title="apiToken.emptyView.title"
                                    icon="net"
                                />
                            }
                            columns={columns}
                            data={data}
                        />
                    </Loader>
                </InfiniteScroll>
            </StyledTable>
            <Dialog
                heading={<Translate content="apiToken.dialog.generateTitle"/>}
                closeHandler={closeDialog}
                isOpen={dialog === DIALOG.CREATE}
                size={SIZE.XS}
            >
                <ApiTokenCreateForm onSubmit={() => {}} />
            </Dialog>
            <Dialog
                heading={<Translate content="apiToken.dialog.editTitle"/>}
                closeHandler={closeDialog}
                isOpen={dialog === DIALOG.EDIT}
                size={SIZE.XS}
            >
                <Loader isLoading={isLoadingItem} center={false}>
                    <ApiTokenEditForm onSubmit={closeDialog} />
                </Loader>
            </Dialog>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    list: state.apiToken.list,
    isLoadingList: state.app.loadingFlags.has(LOADING_FLAGS.API_TOKEN_LIST),
    isLoadingItem: state.app.loadingFlags.has(LOADING_FLAGS.API_TOKEN_ITEM) ||
        state.app.loadingFlags.has(LOADING_FLAGS.TAGGABLE_ENTITY),
    canManageTags: hasPermission(state, PERMISSION_LIST.MANAGE_TAGS),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    apiTokenActions: bindActionCreators(apiTokenActions, dispatch),
    tagActions: bindActionCreators(tagActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApiTokens);
