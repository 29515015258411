// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import React from 'react';
import { IOsImageResponse } from 'common/api/resources/OsImage';
import { IOsImageVersionResponse } from 'common/api/resources/OsImageVersion';
import {
    List,
    Switch,
    Tooltip,
    Translate,
} from '@plesk/ui-library';
import { connect } from 'react-redux';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import * as osImageVersionActions from 'common/modules/osImage/actionsVersions';
import { OsImageRowContainer } from 'admin/osImage/containers/OsImageRow/Styles';
import { getNewPosition } from 'common/helpers/position';
import ButtonWithConfirmation from 'common/components/ButtonWithConfirmation';
import { StyledActions } from 'common/components/Actions/Styles';
import { StyledTable } from 'common/components/styles/StyledTable';
import CopyText from 'common/containers/CopyText/CopyText';
import { IListReorderedItem } from 'common/reducers/list';
import { getActionColumnProps } from 'common/helpers/list';
import { dataCySelector } from 'common/tests/selectors';
import { OS_IMAGE_VERSION } from 'common/components/serverType/constants/test';
import { ICONS } from 'common/constants';
import {
    VIRTUALIZATION_TYPE_TRANSLATION_MAP,
    VirtualizationType,
} from 'common/api/resources/ComputeResource';
import Action from 'common/components/Action/Action';

interface IOsImageRowProps {
    item: IOsImageResponse;
    onEdit: (id: number) => void;
    reorderable?: boolean;
}

export type OsImageRowProps =
    IOsImageRowProps &
    ReturnType<typeof mapDispatchToProps>;

const columns = [{
    key: 'colId',
    width: '1%',
    title: <Translate content="osImageVersion.id" />,
}, {
    key: 'colVersion',
    width: '1%',
    title: <Translate content="osImageVersion.version" />,
}, {
    key: 'colUrl',
    truncate: true,
    title: <><Translate content="osImageVersion.url" /> / <Translate content="osImageVersion.template" /></>,
}, {
    key: 'colVirtualizationType',
    width: '5%',
    title: <Translate content="osImageVersion.virtualizationType" />,
}, {
    key: 'colCloudInitVersion',
    width: '5%',
    title: <Translate content="osImageVersion.cloudInitVersion" />,
}, {
    key: 'colVisibility',
    width: '1%',
    title: <Translate content="osImageVersion.visibility" />,
}, getActionColumnProps(),
];

export const OsImageRow: React.FC<OsImageRowProps> = ({
    item,
    onEdit,
    reorderable = true,
    osImageVersionActions: {
        updateOsImageVersionPosition,
        updateOsImageVersionVisibility,
        removeOsImageVersion,
    },
}) => {
    const sortedVersions = item.versions.sort((a, b) => a.position - b.position);

    const handleDragEnd = async (reorderData: IListReorderedItem) => {
        if (sortedVersions[reorderData.oldIndex] === undefined) {
            return;
        }

        const newPos = getNewPosition(reorderData.oldIndex, reorderData.newIndex, item.versions);

        await updateOsImageVersionPosition(item.versions[reorderData.oldIndex].id, {
            ...item.versions[reorderData.oldIndex],
            position: newPos,
        });
    };

    const handleEdit = (id: number) => () => onEdit(id);
    const handleRemove = (version: IOsImageVersionResponse) => () => removeOsImageVersion(version);
    const handleToggleVisibility = (version: IOsImageVersionResponse) => () => {
        if (!version.is_loading) {
            updateOsImageVersionVisibility(version.id, version.os_image_id, {
                is_visible: !version.is_visible,
            });
        }
    };

    const data = sortedVersions.map((osImageVersion) => {
        const actionEl = (
            <StyledActions>
                <Tooltip title={<Translate content="osImageVersion.tooltip.download" />}>
                    <Action
                        icon={ICONS.ARROW_TRAY_DOWN}
                        className="action-icon"
                        href={osImageVersion.url}
                        target="_blank"
                        disabled={osImageVersion.virtualization_type === VirtualizationType.VZ}
                    />
                </Tooltip>
                <Tooltip title={<Translate content="osImageVersion.tooltip.edit" />}>
                    <Action
                        icon={ICONS.PENCIL}
                        className="action-icon"
                        onClick={handleEdit(osImageVersion.id)}
                    />
                </Tooltip>
                <ButtonWithConfirmation
                    translations={{
                        button: (
                            <Translate content='osImageVersion.buttonWithConfirmation.button' />
                        ),
                        title: (
                            <Translate content='osImageVersion.buttonWithConfirmation.title' />
                        ),
                        tooltip: (
                            <Translate content='osImageVersion.buttonWithConfirmation.tooltip' />
                        ),
                    }}
                    handleConfirm={handleRemove(osImageVersion)}
                    icon={ICONS.RECYCLE}
                    data-cy={dataCySelector(osImageVersion.id, OS_IMAGE_VERSION.DELETE)}
                />
            </StyledActions>
        );

        return {
            colId: osImageVersion.id,
            colVersion: osImageVersion.version,
            colUrl: (
                <CopyText width="450px" text={osImageVersion.url}>{osImageVersion.url}</CopyText>
            ),
            colCloudInitVersion: osImageVersion.cloud_init_version,
            colVirtualizationType: VIRTUALIZATION_TYPE_TRANSLATION_MAP[osImageVersion.virtualization_type],
            colVisibility: <Switch
                checked={osImageVersion.is_visible}
                onChange={handleToggleVisibility(osImageVersion)}
                loading={osImageVersion.is_loading}
                data-cy={dataCySelector(osImageVersion.id, OS_IMAGE_VERSION.VISIBILITY)}
            />,
            colActions: actionEl,
            key: osImageVersion.id.toString(),
        };
    });

    return (
        <OsImageRowContainer>
            <StyledTable>
                <List
                    columns={columns}
                    data={data}
                    reorderable={reorderable}
                    onReorderEnd={handleDragEnd}
                />
            </StyledTable>
        </OsImageRowContainer>
    );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    osImageVersionActions: bindActionCreators(osImageVersionActions, dispatch),
});

export default connect(null, mapDispatchToProps)(OsImageRow);
