// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import {
    api,
    IApiResponse,
} from 'common/api/resources/Response';
import {
    IAuthenticateRequest,
    IAuthLinkRequest,
    IEnableTwoFactorRequest,
    ILoginRequest,
    ILoginResponse,
    IRegisterRequest,
    IResetPasswordRequest,
    ITwoFactorAuthRecoveryCodesResponse,
    ITwoFactorAuthSecretResponse,
    ITwoFactorLoginRequest,
    IUpdatePasswordRequest,
    IVerifyEmailRequest,
} from 'common/api/resources/Auth/model';

export const auth = {
    verifyToken: () => api.get('auth'),
    login: (data: ILoginRequest) => api.post<IApiResponse<ILoginResponse>>('auth/login', data),
    logout: () => api.post('auth/logout'),
    register: (data: IRegisterRequest) => api.post<IApiResponse<ILoginResponse>>('auth/register', data),
    verify: (id: number, data: IVerifyEmailRequest) => api.post<IApiResponse<ILoginResponse>>(`auth/verify/${id}`, data),
    byLink: (data: IAuthLinkRequest) => api.post<IApiResponse<ILoginResponse>>('auth/by_link', data),
    resetPassword: (data: IResetPasswordRequest) => api.post('auth/reset_password', data),
    updatePassword: (data: IUpdatePasswordRequest) => api.put('auth/reset_password', data),
    twoFactorAuthSecret: () => api.get<IApiResponse<ITwoFactorAuthSecretResponse>>('auth/2fa/secret'),
    enableTwoFactorAuth: (data: IEnableTwoFactorRequest) => api.post<IApiResponse<ITwoFactorAuthSecretResponse>>('auth/2fa', data),
    disableTwoFactorAuth: () => api.delete('auth/2fa'),
    generateNewTwoFactorAuthRecoveryCodes: () => api.post<IApiResponse<ITwoFactorAuthRecoveryCodesResponse>>('auth/2fa/recovery_codes'),
    twoFactorLogin: (data: ITwoFactorLoginRequest) => api.post<IApiResponse<ILoginResponse>>('auth/2fa/login', data),
    authenticate: (data: IAuthenticateRequest) => api.post('auth', data),
};
