// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import { ICommonState } from 'common/store';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import {
    requiredRule,
    validate,
} from 'common/validator';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import {
    Button,
    Form,
    FormFieldText,
    Paragraph,
    Section,
    setIn,
    Translate,
} from '@plesk/ui-library';
import {
    INTENT_TYPE,
    SIZE,
} from 'common/constants';
import { nestStringProperties } from 'common/modules/app/formErrors/selectors';
import { FormContainer } from 'common/components/Form/Styles';
import CopyText from 'common/containers/CopyText/CopyText';
import * as twoFactorAuthActions from 'common/modules/auth/twoFactorAuth/actions';
import { Loader } from 'common/components';
import { IEnableTwoFactorRequest } from 'common/api/resources/Auth';
import validator from 'validator';
import { CODE_LENGTH } from 'common/modules/account/constants/constants';
import { setAndCallInsecureAction } from 'common/modules/auth/confirmIdentity/insecureActionHelpers';

export type TwoFactorAuthFormDisabledProps =
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

type FormRequest = Pick<IEnableTwoFactorRequest, 'code'>;

export const TwoFactorAuthFormDisabled: React.FC<TwoFactorAuthFormDisabledProps> = ({
    secret,
    qr,
    isSecretLoading,
    isSwitching,
    formErrors,
    twoFactorAuthActions: {
        getTwoFactorAuthSecret,
        unsetTwoFactorAuthSecret,
        enableTwoFactorAuth,
        setShouldGenerateTwoFactorAuthRecoveryCodes,
    },
    formErrorsActions: {
        setFormErrors,
        clearFormErrors,
    },
}) => {
    const [submitValues, setSubmitValues] = React.useState<FormRequest>({ code: '' });

    const handleFieldChange = (field: string, value: string) => setSubmitValues(setIn(submitValues, field, value));

    const handleSubmit = async (values: FormRequest) => {
        const errors = validate(values, {
            code: [
                requiredRule(<Translate content="validate.fieldRequired" />),
                {
                    validator: (value: string) => validator.matches(value, new RegExp(`^\\d{${CODE_LENGTH}}$`)),
                    message: <Translate content="commonAccount.twoFactorAuthForm.invalidCode" params={{
                        length: CODE_LENGTH,
                    }} />,
                    comparison: false,
                },
            ],
        });

        if (Object.keys(errors).length) {
            setFormErrors(errors);
            return;
        }

        await setAndCallInsecureAction( async () => {
            setShouldGenerateTwoFactorAuthRecoveryCodes(true);
            await enableTwoFactorAuth({
                secret,
                code: values.code,
            });
        });
    };

    React.useEffect(() => {
        getTwoFactorAuthSecret();
        return () => {
            unsetTwoFactorAuthSecret();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => () => {
        clearFormErrors();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Loader isLoading={isSecretLoading}>
            <FormContainer>
                <Form
                    id="twoFactorAuthForm"
                    onSubmit={handleSubmit}
                    values={submitValues}
                    onFieldChange={handleFieldChange}
                    footerClassName="hidden"
                    errors={formErrors}
                    hideRequiredLegend={true}
                    submitButton={false}
                    cancelButton={false}
                    applyButton={false}
                    vertical={true}
                >
                    <Section><Translate content="commonAccount.twoFactorAuthForm.info" /></Section>
                    <Section>
                        <Paragraph><Translate content="commonAccount.twoFactorAuthForm.qrCode" /></Paragraph>
                        <img src={qr} alt="qr-code" />
                    </Section>
                    <Section>
                        <Translate content="commonAccount.twoFactorAuthForm.secretKey" />
                        &nbsp;
                        <CopyText isInline={true}>{secret}</CopyText>
                    </Section>
                    <Section>
                        <FormFieldText
                            name="code"
                            size={SIZE.XL}
                            label={<Translate content="commonAccount.twoFactorAuthForm.code" />}
                            required={true}
                        />
                    </Section>
                </Form>
                <Button
                    type="submit"
                    form="twoFactorAuthForm"
                    intent={INTENT_TYPE.PRIMARY}
                    size={SIZE.LG}
                    state={isSwitching ? 'loading' : undefined}
                >
                    <Translate content="commonAccount.twoFactorAuthForm.enableBtn" />
                </Button>
            </FormContainer>
        </Loader>
    );
};

const mapStateToProps = (state: ICommonState) => ({
    secret: state.twoFactorAuth.secret,
    qr: state.twoFactorAuth.qr,
    isSecretLoading: state.app.loadingFlags.has(LOADING_FLAGS.TWO_FACTOR_AUTH_SECRET),
    isSwitching: state.app.loadingFlags.has(LOADING_FLAGS.TWO_FACTOR_AUTH_SWITCH),
    formErrors: nestStringProperties(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    twoFactorAuthActions: bindActionCreators(twoFactorAuthActions, dispatch),
    formErrorsActions: bindActionCreators(formErrorsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TwoFactorAuthFormDisabled);