// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { RootState as AdminRootState } from 'admin/core/store';
import { RootState as ClientRootState } from 'client/core/store';
import { FEATURE } from 'common/api/resources/Settings';

export const isFeatureEnabled = (feature: FEATURE, rootState: AdminRootState | ClientRootState) => {
    if (!rootState.settings) {
        throw new Error('Cannot get settings.feature from specified state');
    }

    if (!rootState.settings.features) {
        throw new Error('Cannot get settings.feature from specified state');
    }

    return rootState.settings.features[feature];
};
