// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'admin/core/store';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import {
    requiredRule,
    validate,
} from 'common/validator';
import * as tagActions from 'admin/tag/actions';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import {
    Form,
    FormFieldText,
    Link,
    Section,
    setIn,
    Translate,
} from '@plesk/ui-library';
import {
    INTENT_TYPE,
    SIZE,
} from 'common/constants';
import { Button } from 'admin/common/components/Button/Button';
import { nestStringProperties } from 'common/modules/app/formErrors/selectors';
import {
    SyntheticEvent,
    useState,
} from 'react';
import { TagValueInput } from 'admin/tag/components/TagValueInput';
import { ITagRequest } from 'common/api/resources/Tag';

interface ITagFormProps {
    onSubmit: () => void;
}

export type TagFormProps =
    ITagFormProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const TagForm: React.FC<TagFormProps> = ({
    item,
    isItemSaving,
    formErrors,
    formErrorsActions: {
        setFormErrors,
        clearFormErrors,
    },
    tagActions: {
        unsetTagItem,
        createTag,
        updateTag,
    },
    onSubmit,
}) => {
    const [submitValues, setSubmitValues] = useState<ITagRequest>((): ITagRequest => item.id
        ? {
            key: item.key,
            values: item.values,
        }
        : {
            key: '',
            values: [
                { value: '' },
            ],
        }
    );

    React.useEffect(() => () => {
        clearFormErrors();
        unsetTagItem();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleFieldChange = (field: string, value: string) => setSubmitValues(setIn(submitValues, field, value));

    const handleSubmit = async (data: ITagRequest) => {
        const errors = validate<ITagRequest>(data, {
            key: requiredRule(<Translate content="validate.fieldRequired" />),
            ...Object.fromEntries(Object.entries(data.values).map(([idx]) =>
                [`values.${idx}.value`, requiredRule(<Translate content="validate.fieldRequired" />)])),
        });

        if (Object.keys(errors).length) {
            setFormErrors(errors);
            return;
        }

        item.id
            ? await updateTag(item.id, data)
            : await createTag(data);

        onSubmit();
    };

    const handleAddNewTagValue = (event: SyntheticEvent<HTMLElement>) => {
        event.preventDefault();

        setSubmitValues((prev): ITagRequest => ({
            ...prev,
            values: [
                ...prev.values,
                { value: '' },
            ],
        }));
    };

    const handleTagValueChange = (newValue: string, idx: number) => {
        setSubmitValues((prev): ITagRequest => ({
            ...prev,
            values: prev.values.map((v, k) => k !== idx ? v : {
                ...v,
                value: newValue,
            }),
        }));
    };

    const handleTagValueDelete = (idx: number) => {
        setSubmitValues((prev): ITagRequest => ({
            ...prev,
            values: prev.values.filter((_, k) => k !== idx),
        }));
    };

    return (
        <>
            <Form
                id="tagForm"
                onSubmit={handleSubmit}
                values={submitValues}
                onFieldChange={handleFieldChange}
                footerClassName="hidden"
                errors={formErrors}
                submitButton={false}
                cancelButton={false}
                applyButton={false}
                vertical={true}
            >
                <Section>
                    <FormFieldText
                        name="key"
                        size={SIZE.FILL}
                        label={<Translate content="tag.form.key" />}
                        required
                    />

                    {submitValues.values.map((tagValue, idx) => (
                        <TagValueInput
                            key={idx}
                            label={<Translate content="tag.form.tagValue" params={{ num: idx + 1 }} />}
                            name={`values[${idx}][value]`}
                            tagValue={tagValue}
                            isDeletionDisabled={submitValues.values.length === 1}
                            onChange={(value) => handleTagValueChange(value, idx)}
                            onDelete={() => handleTagValueDelete(idx)}
                        />
                    ))}

                    <Link onClick={handleAddNewTagValue}>
                        <Translate content="tag.form.addNewTagValue" />
                    </Link>
                </Section>
            </Form>
            <Button
                type="submit"
                form="tagForm"
                fill={true}
                intent={INTENT_TYPE.PRIMARY}
                size={SIZE.LG}
                isLoading={isItemSaving}
            >
                <Translate content="tag.form.saveBtn" />
            </Button>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    item: state.tag.item,
    isItemSaving: state.app.loadingFlags.has(LOADING_FLAGS.TAG_ITEM_SAVE),
    formErrors: nestStringProperties(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    tagActions: bindActionCreators(tagActions, dispatch),
    formErrorsActions: bindActionCreators(formErrorsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TagForm);
