// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';
import { CardItem } from 'common/components/styles/CardItem';

export const LocationItem = styled(CardItem)`
    height: 66px;
    padding: 12px 20px;

    .location-description {
        opacity: ${props => props.isSelected ? 0.8 : 1};
    }
`;

export const ItemContent = styled.div`
    margin-left: 12px;
    flex: 2 1;
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

export const ContentName = styled.div`
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    color: #222;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const LogoContainer = styled.div`
    height: 34px;
    overflow: hidden;
    display: flex;
    justify-content: center;
    align-items: center;
    
    span {
        height: 24px !important;
        width: 24px !important;
    }
`;

export const DescriptionText = styled.div`
    font-size: 12px;
    line-height: 16px;
    color: #737373;
    display: flex;
    align-content: center;
`;
