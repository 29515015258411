// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import {
    IPaginated,
    IRequest,
} from 'common/api/resources/Request/request';

export interface IEventHandlerResponse<T = IWebhookParams> {
    id: number;
    name: string;
    action_type: ActionType;
    events: EventName[];
    action_params: T;
    is_enabled: boolean;
    is_deleting?: boolean;
    is_loading?: boolean;
}

export interface IEventHandlerRequest {
    name: string;
    action_type: ActionType;
    events: EventName[];
    action_params: IWebhookParams;
    is_enabled: boolean;
}

export interface IEventHandlerPatchRequest {
    is_enabled?: boolean;
}

export interface IWebhookParams {
    url: string;
    request_params: string;
}

export interface IEventHandlerListFilters {
    action_type?: ActionType;
}

export interface IEventHandlerListRequest extends IRequest<IEventHandlerListFilters>, IPaginated {}

export enum ActionType {
    WEBHOOK = 'webhook',
}

export enum EventName {
    ADD_OR_REMOVE_ADDITIONAL_IP = 'add_or_remove_additional_ip',
    SUSPEND_SERVER = 'suspend_server',
    RESUME_SERVER = 'resume_server',
    DELETE_SERVER = 'delete_server',
    CHANGE_SERVER_HOSTNAME = 'change_server_hostname',
    CHANGE_SERVER_ROOT_PASSWORD = 'change_server_root_password',
    REINSTALL_SERVER = 'reinstall_server',
}