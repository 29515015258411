// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { Translate } from '@plesk/ui-library';
import {
    ItemPlan,
    Title,
} from 'common/components/plan/Styles';
import { ComputeResourceVmCustomPlan } from 'common/api/resources/ComputeResourceVm';
import { initialCustomPlanState } from 'common/modules/computeResourceVm/reducers';
import { PlanCardDescription } from 'common/components/plan/components/PlanCardDescription';

export interface ICustomPlanCardProps extends React.HTMLProps<HTMLDivElement> {
    plan?: ComputeResourceVmCustomPlan;
    isSelected: boolean;
    onItemClicked: (plan: ComputeResourceVmCustomPlan) => void;
}

export const CustomPlanCard: React.FC<ICustomPlanCardProps> = ({
    plan,
    onItemClicked,
    isSelected,
}) => {
    const handleClick = (item: ComputeResourceVmCustomPlan) => () => onItemClicked(item);

    return plan === undefined ? (
        <ItemPlan
            onClick={handleClick(initialCustomPlanState)}
            style={{ height: '100%', textAlign: 'center', alignItems: 'center' }}
        >
            <Title>
                <Translate content="servers.create.plan.nonCustomizedCardTitle" />
            </Title>
        </ItemPlan>
    ) : (
        <ItemPlan
            isSelected={isSelected}
            onClick={handleClick(plan)}
        >
            <PlanCardDescription
                name={<Translate content="servers.create.plan.customizedCardTitle" />}
                ram={plan.params.ram}
                vcpu={plan.params.vcpu}
                disk={plan.params.disk}
                showName={true}
            />
        </ItemPlan>
    );
};
