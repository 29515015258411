// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'admin/core/store';
import {
    Header as HeaderContainer,
    HeaderToolbar,
    HeaderSection,
} from 'admin/common/containers/Header/Styles';
import { Button } from 'admin/common/components/Button/Button';
import {
    INTENT_TYPE,
    ICONS,
} from 'common/constants';
import { Badge } from '@plesk/ui-library';
import { setNotificationAlert } from 'common/modules/app/notificationAlert/actions';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import LanguageDropdown from 'common/containers/LanguageDropdown/LanguageDropdown';
import { ILanguageResponse } from 'common/api/resources/Language';
import * as authActions from 'common/modules/auth/actions';
import { HEADER } from 'admin/common/constants/tests';
import { pathTo } from 'common/helpers/core';
import SearchBar from 'common/modules/search/containers/SearchBar';
import { hasPermission } from 'common/modules/permission/selectors';
import { PERMISSION_LIST } from 'common/modules/permission/constants';

interface IHeaderProps {
    isOpen: boolean;
    onOpen: () => void;
}

export type HeaderProps =
    IHeaderProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const Header: React.FC<HeaderProps> = ({
    userEmail,
    userLanguage,
    languages,
    notificationAlert,
    setAlert,
    unreadCount,
    isOpen,
    authActions: {
        updateUserSettings,
    },
    onOpen,
    canSearch,
}) => {
    const [unreads, setUnreads] = React.useState(0);
    const [isBadgeHidden, setIsBadgeHidden] = React.useState(true);

    React.useEffect(() => {
        if (unreadCount > unreads) {
            setIsBadgeHidden(isOpen || !notificationAlert);
        }
        setUnreads(unreadCount);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [unreadCount, notificationAlert]);

    const handleClick = () => {
        setIsBadgeHidden(true);
        setAlert(false);
        onOpen();
    };

    const handleLanguageChange = (language: ILanguageResponse) => {
        updateUserSettings({ language_id: language.id });
    };

    return (
        <HeaderContainer>
            <HeaderToolbar>
                { canSearch && (<SearchBar />) }

                {/* flex spacer */}
                <div style={{ flexGrow: 1 }} />

                {languages.length > 1 && (
                    <HeaderSection>
                        <LanguageDropdown
                            data-cy={HEADER.LANGUAGE_DROPDOWN}
                            selected={userLanguage}
                            languages={languages}
                            onChange={handleLanguageChange}
                        />
                    </HeaderSection>
                )}
                <HeaderSection>
                    <Badge intent={INTENT_TYPE.DANGER} hidden={isBadgeHidden}>
                        <Button
                            ghost={true}
                            icon="bell"
                            onClick={handleClick}
                            state={isOpen ? 'active' : undefined}
                        />
                    </Badge>
                </HeaderSection>
                <Button
                    to={pathTo('account')}
                    ghost={true}
                    intent={INTENT_TYPE.PRIMARY}
                    icon={ICONS.USER}
                >
                    {userEmail}
                </Button>
            </HeaderToolbar>
        </HeaderContainer>
    );
};

const mapStateToProps = (state: RootState) => ({
    languages: state.language.list.data,
    userEmail: state.auth.user.email,
    userLanguage: state.auth.user.language,
    unreadCount: state.notification.list.meta.total,
    notificationAlert: state.app.notificationAlert,
    canSearch: hasPermission(state, PERMISSION_LIST.GET_COMPUTE_RESOURCES)
        || hasPermission(state, PERMISSION_LIST.MANAGE_COMPUTE_RESOURCES)
        || hasPermission(state, PERMISSION_LIST.GET_SERVERS)
        || hasPermission(state, PERMISSION_LIST.MANAGE_SERVERS)
        || hasPermission(state, PERMISSION_LIST.GET_USERS)
        || hasPermission(state, PERMISSION_LIST.MANAGE_USERS),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    setAlert: (value: boolean) => dispatch(setNotificationAlert(value)),
    authActions: bindActionCreators(authActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Header);
