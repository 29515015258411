// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { PageHeader } from 'admin/common/components/PageHeader/PageHeader';
import { Translate } from '@plesk/ui-library';
import { RootState } from 'admin/core/store';
import { connect } from 'react-redux';
import {
    ICONS,
    INTENT_TYPE,
    SIZE,
} from 'common/constants';
import { PERMISSION_LIST } from 'common/modules/permission/constants';
import { hasPermission } from 'common/modules/permission/selectors';
import { RouteComponentProps } from 'react-router';
import List from 'common/components/List/List';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import * as clusterImportActions from 'admin/clusterImport/actions';
import { EmptyView } from 'common/components/EmptyView/EmptyView';
import { useIsFirstLoading } from 'common/hooks/useIsFirstLoading';
import { getActionColumnProps } from 'common/helpers/list';
import { Dialog } from 'common/components/Dialog/Dialog';
import ClusterImportForm from 'admin/clusterImport/containers/ClusterImportForm';
import { StyledActions } from 'common/components/Actions/Styles';
import { dataCySelector } from 'common/tests/selectors';
import ButtonWithConfirmation from 'common/components/ButtonWithConfirmation';
import { CLUSTER_IMPORT } from 'admin/clusterImport/constants/tests';
import {
    CLUSTER_IMPORT_STATUS,
    IClusterImportResponse,
} from 'common/api/resources/ClusterImport';
import { ClusterImportStatus } from 'admin/clusterImport/components/ClusterImportStatus';
import ClusterImportStatusDialog from 'admin/clusterImport/containers/ClusterImportStatusDialog';
import { ADMIN_ROUTE_PREFIX } from 'admin/core/constants';
import { Button } from 'admin/common/components/Button/Button';
import { Status } from 'common/components/styles/Status';
import ClusterImportErrorDialog from 'admin/clusterImport/containers/ClusterImportErrorDialog';

export type ClusterImportProps =
    RouteComponentProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

const columns = [{
    key: 'colId',
    title: <Translate content="clusterImport.list.id" />,
    width: '1%',
}, {
    key: 'colName',
    title: <Translate content="clusterImport.list.name" />,
    cellProps: {
        className: 'cell-bold',
    },
}, {
    key: 'colHost',
    title: <Translate content="clusterImport.list.host" />,
}, {
    key: 'colStatus',
    title: <Translate content="clusterImport.list.status" />,
}, {
    key: 'colError',
    title: <Translate content="clusterImport.list.error.title" />,
}, getActionColumnProps({ width: '5%' }),
];

export const ClusterImports: React.FC<ClusterImportProps> = ({
    clusterImports,
    isLoading,
    canManageImports,
    clusterImportActions: {
        getClusterImports,
        deleteClusterImport,
        setClusterImport,
        unsetClusterImport,
        preCheckClusterImport,
        refreshClusterImport,
    },
    history,
}) => {
    const [isFormDialogOpened, setFormDialogOpened] = React.useState(false);
    const [isStatusDialogOpen, setIsStatusDialogOpen] = React.useState(false);
    const [isErrorDialogOpen, setIsErrorDialogOpen] = React.useState(false);

    const handleOpenStatusDialog = (item: IClusterImportResponse) => () => {
        setClusterImport(item);
        setIsStatusDialogOpen(true);
    };
    const handleOpenErrorDialog = (item: IClusterImportResponse) => () => {
        setClusterImport(item);
        setIsErrorDialogOpen(true);
    };

    const handleFormDialogClose = () => {
        setFormDialogOpened(false);
    };

    const handleCreate = () => {
        unsetClusterImport();
        setFormDialogOpened(true);
    };

    const handleEdit = (item: IClusterImportResponse) => () => {
        setClusterImport(item);
        setFormDialogOpened(true);
    };

    const handleRemove = (id: number) => () => {
        deleteClusterImport(id);
    };

    const handleRun = (item: IClusterImportResponse) => () => {
        setClusterImport(item);
        history.push(`${ADMIN_ROUTE_PREFIX}/cluster_imports/${item.id}/compute_resources`);
    };

    const handlePreCheck = (item: IClusterImportResponse) => () => {
        preCheckClusterImport(item);
    };

    const handleRefresh = (item: IClusterImportResponse) => () => {
        refreshClusterImport(item);
    };

    const isFirstLoading = useIsFirstLoading(isLoading);

    const loadPaginated = (page: number) => getClusterImports({ page });

    React.useEffect(() => {
        getClusterImports();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const data = clusterImports.data.map(item => ({
        colId: item.id,
        colName: item.name,
        colHost: item.configuration.host,
        colStatus: (
            <ClusterImportStatus item={item} onOpen={handleOpenStatusDialog(item)} />
        ),
        colError: (
            item.errors_on_import.length > 0 ? (
                <>
                    <Status
                        intent={INTENT_TYPE.DANGER}
                        compact={true}
                    >
                        <Translate content="clusterImport.list.error.details"/>
                    </Status>
                    <Button
                        data-cy={dataCySelector(item.id, CLUSTER_IMPORT.ERROR)}
                        disabled={item.errors_on_import.length === 0}
                        onClick={handleOpenErrorDialog(item)}
                        ghost={true}
                        icon={ICONS.BOX_DIAGONAL_TOP_IN}
                    />
                </>
            ) : (
                <Status
                    intent={INTENT_TYPE.SUCCESS}
                    compact={true}
                >
                    <Translate content="clusterImport.list.error.noError"/>
                </Status>
            )
        ),
        colActions: (
            <StyledActions>
                <Button
                    ghost={true}
                    disabled={!item.is_ready}
                    icon={ICONS.START}
                    onClick={handleRun(item)}
                    data-cy={dataCySelector(item.id, CLUSTER_IMPORT.RUN)}
                    tooltip={
                        <Translate content="clusterImport.tooltip.run"/>
                    }
                    isLoading={item.status === CLUSTER_IMPORT_STATUS.IMPORTING}
                />
                {(item.status === CLUSTER_IMPORT_STATUS.FAILED_PRE_CHECK || item.status === CLUSTER_IMPORT_STATUS.PRE_CHECKING) &&
                    <Button
                        ghost={true}
                        disabled={item.status === CLUSTER_IMPORT_STATUS.PRE_CHECKING}
                        icon={ICONS.REFRESH}
                        onClick={handlePreCheck(item)}
                        data-cy={dataCySelector(item.id, CLUSTER_IMPORT.PRE_CHECK)}
                        tooltip={
                            <Translate content="clusterImport.tooltip.preCheck"/>
                        }
                        isLoading={item.status === CLUSTER_IMPORT_STATUS.PRE_CHECKING}
                    />
                }
                {(item.status !== CLUSTER_IMPORT_STATUS.FAILED_PRE_CHECK && item.status !== CLUSTER_IMPORT_STATUS.PRE_CHECKING) &&
                    <Button
                        ghost={true}
                        disabled={!item.is_ready}
                        icon={ICONS.REFRESH}
                        onClick={handleRefresh(item)}
                        data-cy={dataCySelector(item.id, CLUSTER_IMPORT.REFRESH)}
                        tooltip={
                            <Translate content="clusterImport.tooltip.refresh"/>
                        }
                        isLoading={item.status === CLUSTER_IMPORT_STATUS.REFRESHING}
                    />
                }
                <Button
                    ghost={true}
                    disabled={![
                        CLUSTER_IMPORT_STATUS.FAILED_PREPARE,
                        CLUSTER_IMPORT_STATUS.FAILED_PRE_CHECK,
                    ].includes(item.status as CLUSTER_IMPORT_STATUS)}
                    icon={ICONS.PENCIL}
                    onClick={handleEdit(item)}
                    data-cy={dataCySelector(item.id, CLUSTER_IMPORT.EDIT)}
                    tooltip={
                        <Translate content="clusterImport.tooltip.edit"/>
                    }
                />
                <ButtonWithConfirmation
                    isLoading={item.is_deleting}
                    translations={{
                        button: (
                            <Translate content="clusterImport.buttonWithConfirmation.button" />
                        ),
                        title: (
                            <Translate content="clusterImport.buttonWithConfirmation.title" />
                        ),
                        tooltip: (
                            <Translate content="clusterImport.buttonWithConfirmation.tooltip" />
                        ),
                        text: (
                            <Translate content="clusterImport.buttonWithConfirmation.confirmationText" />
                        ),
                    }}
                    handleConfirm={handleRemove(item.id)}
                    data-cy={dataCySelector(item.id, CLUSTER_IMPORT.DELETE)}
                    icon={ICONS.RECYCLE}
                />
            </StyledActions>
        ),
        key: item.id.toString(),
    }));

    return (
        <>
            <PageHeader
                title={<Translate content="clusterImport.title"/>}
                buttonText="clusterImport.addBtn"
                buttonIcon={ICONS.TRANSFER}
                onButtonClick={handleCreate}
                isButtonShown={canManageImports}
            />
            <List
                isLoading={isLoading}
                isFirstLoading={isFirstLoading}
                loadItems={loadPaginated}
                emptyView={(
                    <EmptyView
                        title="clusterImport.emptyView.title"
                        description="clusterImport.emptyView.description"
                        buttonText="clusterImport.emptyView.buttonText"
                        icon={ICONS.TRANSFER}
                        onButtonClick={handleCreate}
                    />
                )}
                columns={columns}
                data={data}
                meta={clusterImports.meta}
            />
            <Dialog
                heading={<Translate content="clusterImport.form.title" />}
                closeHandler={handleFormDialogClose}
                isOpen={isFormDialogOpened}
                size={SIZE.XS}
            >
                <ClusterImportForm
                    onSubmit={handleFormDialogClose}
                />
            </Dialog>
            <ClusterImportStatusDialog
                isOpen={isStatusDialogOpen}
                handleClose={() => setIsStatusDialogOpen(false)}
            />
            <ClusterImportErrorDialog
                isOpen={isErrorDialogOpen}
                handleClose={() => setIsErrorDialogOpen(false)}
            />
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    clusterImports: state.clusterImport.list,
    isLoading: state.app.loadingFlags.has(LOADING_FLAGS.CLUSTER_IMPORT_LIST),
    canManageImports: hasPermission(state, PERMISSION_LIST.MANAGE_CLUSTER_IMPORTS),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    clusterImportActions: bindActionCreators(clusterImportActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClusterImports);