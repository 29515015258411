// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

export enum StorageType {
    FB = 'fb',
    LVM = 'lvm',
    THIN_LVM = 'thinlvm',
    NFS = 'nfs',
    VZ = 'vz',
}

export const KVMStorageTypes = [
    StorageType.FB,
    StorageType.LVM,
    StorageType.THIN_LVM,
    StorageType.NFS,
];

export const STORAGE_TYPES_TRANSLATION_MAP = {
    [StorageType.FB]: 'File Based',
    [StorageType.LVM]: 'LVM',
    [StorageType.THIN_LVM]: 'ThinLVM',
    [StorageType.NFS]: 'NFS',
    [StorageType.VZ]: 'VZ',
};

export enum ImageFormat {
  RAW = 'raw',
  QCOW2 = 'qcow2',
  PLOOP = 'ploop',
}

export interface IStorageTypeResponse {
  name: StorageType;
  formats: ImageFormat[];
}

export const isSnapshotsAvailableFor = (storageType: StorageType, imageFormat: ImageFormat): boolean => {
    if (imageFormat === ImageFormat.QCOW2) {
        return true;
    }

    return [StorageType.THIN_LVM, StorageType.LVM].includes(storageType);
};
