// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { LicenseType } from 'common/api/resources/ComputeResource';

export enum LicenseStatus {
    ACTIVE = 'active',
    INVALID = 'invalid',
}

export interface ISolusVMLicenseState {
    status: LicenseStatus;
    registered_name: string;
    company_name: string;
    registered_email: string;
    product_id: number;
    product_name: string;
    registration_date: string;
    ips: string[];
    standard_cr_limit: number;
    mini_cr_limit: number;
    micro_cr_limit: number;
    expiration_date: string;
    soluslabs_key: string;
    ka_key: string;
    activation_code: string;
}

export interface IExceededLimits {
    crs_on_management_node: string[];
    cr_limit: IComputeResourcesExceededLimit[];
    vs_per_cr_limit: IVirtualSeverPerComputeResourcesExceededLimit[];
}

export interface IComputeResourcesExceededLimit {
    license_type: LicenseType;
    count: number;
    limit: number;
}

export interface IVirtualSeverPerComputeResourcesExceededLimit {
    license_type: LicenseType;
    limit: number;
    crs: IVirtualSeverPerComputeResourcesExceededLimitItem[];
}

export interface IVirtualSeverPerComputeResourcesExceededLimitItem {
    count: number;
    name: string;
}

export interface ISolusVMLicenseResponse {
    state: ISolusVMLicenseState;
    exceeded_limits: IExceededLimits;
}

export interface IActivateSolusVMLicenseRequest {
    activation_code: string;
}

export interface ISolusVMLicenseComputeResourceItem {
    id: number;
    name: string;
}

export interface ISolusVMLicenseComputeResources {
    standard: ISolusVMLicenseComputeResourceItem[];
    mini: ISolusVMLicenseComputeResourceItem[];
    micro: ISolusVMLicenseComputeResourceItem[];
}

export interface IAssignComputeResourcesLicenseTypes {
    standard: number[];
    mini: number[];
    micro: number[];
}

export interface IAssignComputeResourcesRequest {
    license_types: IAssignComputeResourcesLicenseTypes;
}

export interface IUpdateSolusVMLicense {
    license: ISolusVMLicenseResponse;
}

export const isLicenseActive = (data: ISolusVMLicenseResponse): boolean => data.state.status === LicenseStatus.ACTIVE;
