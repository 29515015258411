// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    CopyTextContainer,
    CopyTextInlineContainer,
} from 'common/containers/CopyText/Styles';
import * as toasterActions from 'common/modules/app/toaster/actions';
import { connect } from 'react-redux';
import {
    ICONS,
    INTENT_TYPE,
} from 'common/constants';
import {
    copyToClipboard,
    Tooltip,
    Translate,
    Icon,
    Action,
} from '@plesk/ui-library';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';

export interface ICopyText extends React.HTMLProps<HTMLElement> {
    width?: string;
    /**
     * Text which is should be copied. Use innerText if not set.
     */
    text?: string;
    tooltip?: React.ReactElement;
    copy?: (text: string) => void;
    isInline?: boolean;
}

export type CopyTextProps =
    ICopyText &
    ReturnType<typeof mapDispatchToProps>;

export const CopyText: React.FC<CopyTextProps> = ({
    bakeToast,
    children,
    width,
    text,
    tooltip,
    copy = copyToClipboard,
    isInline = false,
}) => {
    const textRef = React.useRef<HTMLDivElement>(null);

    if (children === undefined || children === null) {
        return null;
    }

    const handleClick = () => {
        const textToCopy = text ? text : textRef.current?.innerText;

        if (textToCopy) {
            copy(textToCopy);
            bakeToast(INTENT_TYPE.SUCCESS, 'copyText.copied');
        }
    };

    const Container = isInline ? CopyTextInlineContainer : CopyTextContainer;

    return (
        <Tooltip
            title={
                tooltip ? tooltip : <Translate content="copyText.copy"/>
            }
        >
            {/*
                We did not use the styled Action component, because that way we cannot access the styles
                of the root tag of the Action component (.pul-action).
            */}
            <Container
                style={{ width }}
                onClick={handleClick}
            >
                <Action>
                    <div ref={textRef}>
                        {children}
                    </div>
                    <Icon
                        intent={INTENT_TYPE.INACTIVE}
                        name={ICONS.COPY}
                        size="12"
                    />
                </Action>
            </Container>
        </Tooltip>
    );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    bakeToast: bindActionCreators(toasterActions.bakeForegroundToast, dispatch),
});

export default connect(null, mapDispatchToProps)(CopyText);
