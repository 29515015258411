// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { createCustomAction } from 'typesafe-actions';
import { Dispatch } from 'redux';
import * as types from 'admin/externalIntegration/constants/types';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import {
    create,
    get,
    remove,
    update,
} from 'common/actions/actionsWrapper';
import { IPaginateApiResponse } from 'common/api/resources/Response';
import {
    IExternalIntegrationResponse,
    IExternalIntegrationCreateRequest,
    externalIntegrations,
} from 'common/api/resources/ExternalIntegration';
import {
    setIsLoading,
    unsetIsLoading,
} from 'common/modules/app/loadingFlags/actions';
import { HTTP_CODES } from 'common/api/constants';
import { IPaginatedWithSearch } from 'common/api/resources/Request/request';
import { CancelTokenSource } from 'axios';

export const setExternalIntegrationList = createCustomAction(
    types.SET_EXTERNAL_INTEGRATION_LIST,
    (data: IPaginateApiResponse<IExternalIntegrationResponse[]>) => ({ payload: data })
);

export const setExternalIntegrationItem = createCustomAction(
    types.SET_EXTERNAL_INTEGRATION_ITEM,
    (data: IExternalIntegrationResponse) => ({ payload: data })
);

export const unsetExternalIntegrationItem = createCustomAction(types.UNSET_EXTERNAL_INTEGRATION_ITEM);

export const addExternalIntegrationItem = createCustomAction(
    types.ADD_EXTERNAL_INTEGRATION_ITEM,
    (data: IExternalIntegrationResponse) => ({ payload: data })
);

export const updateExternalIntegrationItem = createCustomAction(
    types.UPDATE_EXTERNAL_INTEGRATION_ITEM,
    (data: IExternalIntegrationResponse) => ({ payload: data })
);

export const removeExternalIntegrationItem = createCustomAction(
    types.REMOVE_EXTERNAL_INTEGRATION_ITEM,
    (id: number) => ({ payload: id })
);

export const setExternalIntegrationIsDeleting = createCustomAction(
    types.SET_EXTERNAL_INTEGRATION_ITEM_IS_DELETING,
    (id: number, isDeleting: boolean) => ({ payload: { id, is_deleting: isDeleting } })
);

export const getExternalIntegrations = (request?: IPaginatedWithSearch, cancelToken?: CancelTokenSource) => async(dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.EXTERNAL_INTEGRATION_LIST));

    try {
        const result = await externalIntegrations.list(request, cancelToken);
        if (result.status === HTTP_CODES.OK) {
            dispatch(setExternalIntegrationList(result.data));
        }

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.EXTERNAL_INTEGRATION_LIST));
    }
};

export const createExternalIntegration = (data: IExternalIntegrationCreateRequest) => async (dispatch: Dispatch) => await create({
    data,
    dispatch,
    loadingFlag: LOADING_FLAGS.EXTERNAL_INTEGRATION_SAVE,
    action: addExternalIntegrationItem,
    apiCall: externalIntegrations.create,
    translations: {
        success: 'externalIntegration.toasts.externalIntegrationSaved',
    },
});

export const updateExternalIntegration = (id: number, data: IExternalIntegrationCreateRequest) => async (dispatch: Dispatch) => await update(id, {
    data,
    dispatch,
    apiCall: externalIntegrations.update,
    action: updateExternalIntegrationItem,
    loadingFlag: LOADING_FLAGS.EXTERNAL_INTEGRATION_SAVE,
    translations: {
        success: 'externalIntegration.toasts.externalIntegrationSaved',
    },
});

export const removeExternalIntegration = (id: number) => async (dispatch: Dispatch) => await remove(id, {
    dispatch,
    apiCall: externalIntegrations.remove,
    setLoadingAction: setExternalIntegrationIsDeleting,
    action: removeExternalIntegrationItem,
    loadingFlag: LOADING_FLAGS.EXTERNAL_INTEGRATION_REMOVE,
    translations: {
        success: 'externalIntegration.toasts.deleted',
    },
});

export const getExternalIntegration = (id: number) => async (dispatch: Dispatch) => await get(id, {
    dispatch,
    apiCall: externalIntegrations.item,
    action: setExternalIntegrationItem,
    loadingFlag: LOADING_FLAGS.EXTERNAL_INTEGRATION_ITEM,
});
