// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';
import { COLORS } from 'admin/core/theme';

export const Header = styled.div`
   display: flex;
   align-items: center;
   margin-bottom: 20px;
   margin-top: 20px;
`;

export const Title = styled.div`
    flex: 1;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    color: ${COLORS.GREY_100};
`;
