// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { createCustomAction } from 'typesafe-actions';
import { Dispatch } from 'redux';
import * as types from 'admin/role/constants/types';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import {
    create,
    get,
    loadOnScroll,
    paginateList,
    remove,
    update,
} from 'common/actions/actionsWrapper';
import { IAppState } from 'admin/core/store';
import { IPaginateApiResponse } from 'common/api/resources/Response';
import {
    IRoleRequest,
    IRoleResponse,
    roles,
} from 'common/api/resources/Role';

export const setRoleList = createCustomAction(
    types.SET_ROLE_LIST,
    (data: IPaginateApiResponse<IRoleResponse[]>) => ({ payload: data })
);
export const setRoleItem = createCustomAction(
    types.SET_ROLE_ITEM,
    (data: IRoleResponse) => ({ payload: data })
);
export const unsetRoleItem = createCustomAction(types.UNSET_ROLE_ITEM);
export const addRoleItem = createCustomAction(
    types.ADD_ROLE_ITEM,
    (data: IRoleResponse) => ({ payload: data })
);
export const updateRoleItem = createCustomAction(
    types.UPDATE_ROLE_ITEM,
    (data: IRoleResponse) => ({ payload: data })
);
export const removeRoleItem = createCustomAction(
    types.REMOVE_ROLE_ITEM,
    (id: number) => ({ payload: id })
);
export const appendRoles = createCustomAction(
    types.APPEND_ROLES,
    (data: IPaginateApiResponse<IRoleResponse[]>) => ({ payload: data })
);
export const setRolesItemIsDeleting = createCustomAction(
    types.SET_ROLE_ITEM_IS_DELETING,
    (id: number, isDeleting: boolean) => ({ payload: { id, isDeleting } })
);

export const createRole = (data: IRoleRequest) => async(dispatch: Dispatch) => await create({
    data,
    dispatch,
    loadingFlag: LOADING_FLAGS.SAVE_ROLE_ITEM,
    action: addRoleItem,
    apiCall: roles.create,
    translations: {
        success: 'role.toasts.roleSaved',
    },
});

export const getRoles = () => async(dispatch: Dispatch) => await paginateList({
    dispatch,
    loadingFlag: LOADING_FLAGS.ROLE_LIST,
    action: setRoleList,
    apiCall: roles.list,
});

export const getRole = (id: number) => async(dispatch: Dispatch) => await get(id, {
    dispatch,
    apiCall: roles.item,
    action: setRoleItem,
    loadingFlag: LOADING_FLAGS.ROLE_ITEM,
});

export const updateRole = (id: number, data: IRoleRequest) => async(dispatch: Dispatch) => await update(id, {
    data,
    dispatch,
    apiCall: roles.update,
    action: updateRoleItem,
    loadingFlag: LOADING_FLAGS.SAVE_ROLE_ITEM,
    translations: {
        success: 'role.toasts.roleSaved',
    },
});

export const removeRole = (id: number) => async(dispatch: Dispatch) => await remove(id, {
    dispatch,
    apiCall: roles.remove,
    setLoadingAction: setRolesItemIsDeleting,
    action: removeRoleItem,
    loadingFlag: LOADING_FLAGS.REMOVE_ROLE_ITEM,
    translations: {
        success: 'role.toasts.roleDeleted',
    },
});

export const loadRolesOnScroll = ()  => async(dispatch: Dispatch, getState: () => IAppState) => {
    const state = getState();
    const nextPage = state.role.list.links.next;
    const isLoading = state.app.loadingFlags.has(LOADING_FLAGS.ROLE_LIST);

    return await loadOnScroll({
        nextPage,
        isLoading,
        dispatch,
        action: appendRoles,
        loadingFlag: LOADING_FLAGS.ROLE_LIST,
    });
};
