// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'admin/core/store';
import {
    ProgressStep,
    Translate,
} from '@plesk/ui-library';
import { Dialog } from 'common/components/Dialog/Dialog';
import { TASK_STATUS } from 'common/api/resources/Task';
import { ICONS } from 'common/constants';

interface ITaskDialogsProps {
    isOpen: boolean;
    handleClose: () => void;
}

export type TaskDialogProps =
    ITaskDialogsProps &
    ReturnType<typeof mapStateToProps>;

const transformTaskStatusToProgressStatus = function (taskStatus: TASK_STATUS) {
    if (taskStatus === TASK_STATUS.FAILED ||
        taskStatus === TASK_STATUS.DONE_WITH_ERRORS) {
        return 'error';
    }

    if (taskStatus === TASK_STATUS.PENDING ||
        taskStatus === TASK_STATUS.STATUS_WAITING_FOR_PREVIOUS_TASKS ||
        taskStatus === TASK_STATUS.QUEUED) {
        return 'not_started';
    }

    return taskStatus;
};

const TaskDialog: React.FC<TaskDialogProps> = ({
    tasks: { item },
    isOpen,
    handleClose,
}) => {
    const renderStep = () => {
        const status = transformTaskStatusToProgressStatus(item.status as TASK_STATUS);

        return (
            <ProgressStep
                icon={ICONS.GEAR}
                status={status}
                progress={item.progress}
            >
                {item.output}
            </ProgressStep>
        );
    };

    return (
        <Dialog
            heading={<Translate
                content="task.dialogTitle"
                params={{ task : `${item.action} ${item.compute_resource ? `on ${item.compute_resource.host}` : ''}` }}
            />}
            closeHandler={handleClose}
            isOpen={isOpen}
            size="xs"
        >
            {renderStep()}
        </Dialog>
    );
};

const mapStateToProps = (state: RootState) => ({
    tasks: state.task,
});

export default connect(mapStateToProps)(TaskDialog);
