// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import { ICommonState } from 'common/store';
import TwoFactorAuthFormDisabled from 'common/modules/account/containers/TwoFactorAuthFormDisabled';
import TwoFactorAuthFormEnabled from 'common/modules/account/containers/TwoFactorAuthFormEnabled';

export type TwoFactorAuthFormPros = ReturnType<typeof mapStateToProps>;

export const TwoFactorAuthForm: React.FC<TwoFactorAuthFormPros> = ({
    isEnabled,
}) => isEnabled
    ? (<TwoFactorAuthFormEnabled />)
    : (<TwoFactorAuthFormDisabled />);

const mapStateToProps = (state: ICommonState) => ({
    isEnabled: state.auth.user.is_two_factor_auth_enabled,
});

export default connect(mapStateToProps)(TwoFactorAuthForm);
