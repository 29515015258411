// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    Button,
    Icon,
    Popover,
    Translate,
} from '@plesk/ui-library';
import { ICONS } from 'common/constants';
import { dataCySelector } from 'common/tests/selectors';
import { TABLE_ACTIONS } from 'common/modules/isoImage/constants/test';
import { IIsoImageResponse } from 'common/api/resources/IsoImage';
import IsoImageForm, { FIELDS } from 'common/containers/IsoImageForm/IsoImageForm';

export interface IIsoImageEditProps {
    item: IIsoImageResponse;
    setIsoImageItem: (item: IIsoImageResponse) => void;
    disabled?: boolean;
}

export const IsoImageEdit: React.FC<IIsoImageEditProps> = ({
    item,
    setIsoImageItem,
    disabled,
}) => {
    const [isPopoverOpened, setPopoverOpened] = React.useState(false);

    const handleOpen = () => {
        setIsoImageItem(item);
        setPopoverOpened(true);
    };

    return (
        <Popover
            onClose={() => setPopoverOpened(false)}
            visible={isPopoverOpened}
            target={(
                <Button
                    ghost={true}
                    disabled={disabled}
                    icon={<Icon name={ICONS.PENCIL} size={18}/>}
                    tooltip={disabled ? (
                        <Translate content="isoImage.tooltip.disabled"/>
                    ) : (
                        <Translate content="isoImage.tooltip.edit"/>
                    )}
                    onClick={handleOpen}
                    data-cy={dataCySelector(item.id, TABLE_ACTIONS.EDIT)}
                />
            )}
        >
            <IsoImageForm
                onSubmit={() => setPopoverOpened(false)}
                fields={[
                    FIELDS.NAME,
                    FIELDS.ICON,
                    FIELDS.OS,
                    FIELDS.USE_TLS,
                    FIELDS.URL,
                    FIELDS.CHECKSUM_METHOD,
                    FIELDS.CHECKSUM,
                ]}
            />
        </Popover>
    );
};