// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import React from 'react';
import { INotificationResponse } from 'common/api/resources/Notification';
import {
    Icon,
    Translate,
} from '@plesk/ui-library';
import NotificationItem from 'admin/notification/containers/Items/NotificationItem';
import moment from 'moment';
import ComputeResourceVmLink from 'admin/common/components/ComputeResourceVmLink';
import { VM_TABS } from 'admin/computeResourceVm/constants';

export interface IBackupItemProps {
    item: INotificationResponse;
    translations: {
        title: string;
        description: string;
    };
}

const BackupItem: React.FC<IBackupItemProps> = ({
    item,
    translations: {
        title,
        description,
    },
}) => {
    const date = moment(item.data.date);
    const vm = item.data.compute_resource_vm;

    return (
        <NotificationItem
            item={item}
            icon={<Icon name="check-mark-circle-filled" size="16" intent="success" />}
            header={(
                <Translate
                    content={title}
                    params={{ computeResourceVm: (
                        <ComputeResourceVmLink id={vm.id} tab={VM_TABS.BACKUPS_TAB}>
                            {vm.name}
                        </ComputeResourceVmLink>
                    ) }}
                />
            )}
            description={(
                <Translate
                    content={description}
                    params={{
                        date: date.format('D-M-Y'),
                        time: date.format('HH:mm:ss'),
                    }}
                />
            )}
        />
    );
};

export default BackupItem;
