// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import LimitGroupForm from 'admin/limitGroup/containers/LimitGroupForm';
import { Loader } from 'common/components';
import { connect } from 'react-redux';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { RootState } from 'admin/core/store';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import * as limitGroupActions from 'admin/limitGroup/actions';
import { History } from 'history';
import { PageHeader } from 'admin/common/components/PageHeader/PageHeader';
import InfiniteScroll from 'common/components/InfinityScroll/InfinityScroll';
import { StyledTable } from 'common/components/styles/StyledTable';
import {
    Action,
    Icon,
    List,
    Tooltip,
    Translate,
} from '@plesk/ui-library';
import {
    LIMIT_GROUPS,
    TABLE_ACTIONS,
} from 'admin/limitGroup/constants/tests';
import { EmptyView } from 'common/components/EmptyView/EmptyView';
import { Dialog } from 'common/components/Dialog/Dialog';
import { StyledActions } from 'common/components/Actions/Styles';
import ButtonWithConfirmation from 'common/components/ButtonWithConfirmation';
import { dataCySelector } from 'common/tests/selectors';
import { getActionColumnProps } from 'common/helpers/list';
import { ILimit } from 'common/api/resources/model';
import { SIZE } from 'common/constants';
import { Link } from 'react-router-dom';
import { pathTo } from 'common/helpers/core';

interface ILimitGroupProps {
    history: History;
}

export type LimitGroupProps =
    ILimitGroupProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

interface ILimitGroupState {
    isDrawerOpen: boolean;
    confirmationDialog: React.ReactNode | null;
}

const columns = [{
    key: 'colId',
    width: '1%',
    title: <Translate content="limitGroup.list.id" />,
}, {
    key: 'colName',
    title: <Translate content="limitGroup.list.name" />,
    cellProps: {
        className: 'cell-bold',
    },
}, {
    key: 'colServers',
    title: <Translate content="limitGroup.list.servers" />,
}, {
    key: 'colRunning',
    title: <Translate content="limitGroup.list.running" />,
}, {
    key: 'colAdditionalIpv4',
    title: <Translate content="limitGroup.list.additionalIpv4" />,
}, {
    key: 'colAdditionalIpv6',
    title: <Translate content="limitGroup.list.additionalIpv6" />,
}, {
    key: 'colIsoImages',
    title: <Translate content="limitGroup.list.isoImages" />,
}, {
    key: 'colIsoImagesSize',
    title: <Translate content="limitGroup.list.isoImagesSize" />,
}, {
    key: 'colUsers',
    title: <Translate content="limitGroup.list.users" />,
}, getActionColumnProps(),
];

export class LimitGroup extends React.Component<LimitGroupProps, ILimitGroupState> {
    state: ILimitGroupState = {
        isDrawerOpen: false,
        confirmationDialog: null,
    };

    async componentDidMount() {
        await this.props.limitGroupActions.getLimitGroups();
    }

    handleEdit = (id: number) => () => {
        this.props.limitGroupActions.getLimitGroup(id);
        this.setState({ isDrawerOpen: true });
    };

    handleRemove = (id: number) => async () => await this.props.limitGroupActions.removeLimitGroup(id);

    handleCreate = () => {
        this.setState({ isDrawerOpen: true });
    };

    handleClose = () => {
        this.setState({ isDrawerOpen: false });
    };

    getLimitColumn = (limit: ILimit) => {
        if (!limit.is_enabled) {
            return (<Translate content="limitGroup.limit.unlimited" />);
        }

        return limit.limit;
    };

    getData() {
        const { list } = this.props.limitGroup;

        return list.data.map((limitGroup) => {
            const actionsEl = (
                <StyledActions>
                    <Tooltip title={<Translate content="limitGroup.tooltip.edit"/>}>
                        <Action
                            icon={<Icon name="pencil" />}
                            className="action-icon"
                            onClick={this.handleEdit(limitGroup.id)}
                            data-cy={dataCySelector(limitGroup.id, TABLE_ACTIONS.EDIT)}
                        />
                    </Tooltip>
                    <ButtonWithConfirmation
                        isLoading={limitGroup.is_deleting}
                        translations={{
                            text: (
                                <Translate content="limitGroup.buttonWithConfirmation.confirmationText" />
                            ),
                            button: (
                                <Translate content="limitGroup.buttonWithConfirmation.button" />
                            ),
                            title: (
                                <Translate content="limitGroup.buttonWithConfirmation.title" />
                            ),
                            tooltip: (
                                <Translate content="limitGroup.buttonWithConfirmation.tooltip" />
                            ),
                        }}
                        handleConfirm={this.handleRemove(limitGroup.id)}
                        data-cy={dataCySelector(limitGroup.id, TABLE_ACTIONS.REMOVE)}
                        icon="recycle"
                    />
                </StyledActions>
            );

            return {
                colId: limitGroup.id,
                colName: limitGroup.name,
                colActions: actionsEl,
                colServers: this.getLimitColumn(limitGroup.vms),
                colRunning: this.getLimitColumn(limitGroup.running_vms),
                colAdditionalIpv4: this.getLimitColumn(limitGroup.additional_ips),
                colAdditionalIpv6: this.getLimitColumn(limitGroup.additional_ipv6),
                colIsoImages: this.getLimitColumn(limitGroup.iso_images),
                colIsoImagesSize: this.getLimitColumn(limitGroup.iso_images_size),
                colUsers: limitGroup.users_count > 0 && (
                    <Action
                        component={Link}
                        to={pathTo(`users/?limit_group_id=${limitGroup.id}`)}
                        data-cy={dataCySelector(limitGroup.id, TABLE_ACTIONS.FILTER)}
                    >
                        {limitGroup.users_count}
                    </Action>
                ),
                key: limitGroup.id.toString(),
            };
        });
    }

    getTitle() {
        const { limitGroup } = this.props;

        if (limitGroup.item.id) {
            return <Translate content="limitGroup.form.titleEdit" />;
        }

        return <Translate content="limitGroup.form.titleAdd" />;
    }

    render() {
        const {
            isDrawerOpen,
            confirmationDialog,
        } = this.state;

        const {
            loadingFlags: { isLoadingList, isLoadingItem },
            limitGroupActions: { loadLimitGroupsOnScroll },
            limitGroup: { list },
        } = this.props;
        const data = this.getData();

        return (
            <>
                {confirmationDialog}
                <PageHeader
                    title={<Translate content="limitGroup.title"/>}
                    buttonText="limitGroup.addBtn"
                    buttonIcon="clone"
                    onButtonClick={this.handleCreate}
                    isButtonShown={data.length > 0}
                />
                <StyledTable>
                    <InfiniteScroll
                        loadMore={loadLimitGroupsOnScroll}
                        hasMore={!!list.links.next}
                    >
                        <Loader isLoading={isLoadingList}>
                            <List
                                emptyView={
                                    <EmptyView
                                        title="limitGroup.emptyView.title"
                                        description="limitGroup.emptyView.description"
                                        buttonText="limitGroup.emptyView.buttonText"
                                        onButtonClick={this.handleCreate}
                                        icon="clone"
                                    />
                                }
                                data-cy={LIMIT_GROUPS.TABLE}
                                columns={columns}
                                data={data}
                            />
                        </Loader>
                    </InfiniteScroll>
                </StyledTable>
                <Dialog
                    heading={this.getTitle()}
                    closeHandler={this.handleClose}
                    isOpen={isDrawerOpen}
                    size={SIZE.XS}
                >
                    <Loader isLoading={isLoadingItem} center={false}>
                        <LimitGroupForm onCreated={this.handleClose}  />
                    </Loader>
                </Dialog>
            </>
        );
    }
}

const mapStateToProps = (state: RootState) => ({
    limitGroup: state.limitGroup,
    errors: state.app.formErrors,
    loadingFlags: {
        isLoadingList: !state.limitGroup.list.data.length && state.app.loadingFlags.has(LOADING_FLAGS.LIMIT_GROUP_LIST),
        isLoadingItem: state.app.loadingFlags.has(LOADING_FLAGS.LIMIT_GROUP_ITEM),
    },
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    limitGroupActions: bindActionCreators(limitGroupActions, dispatch),
    unsetLimitGroupItem: bindActionCreators(limitGroupActions.unsetLimitGroupItem, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(LimitGroup);
