// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { Middleware } from 'redux';
import { getType } from 'typesafe-actions';
import {
    setUser,
    updateUser,
} from 'common/modules/auth/actions';
import { setLanguage } from 'common/modules/app/language/actions';
import { ICommonState } from 'common/store';

/**
 * Update language when current user data is changed.
 *
 * This middleware is created as workaround for bug somewhere in our code or vendors
 * Which is leads to invalid rendering - for some reason `LocaleProvider` got old
 * language in props.
 *
 * @param state
 */
export const languageUpdateMiddleware: Middleware = state => next => action =>  {
    if ([getType(setUser), getType(updateUser)].includes(action.type)) {
        const store: ICommonState = state.getState();

        if (store.app.language.locale !== action.payload.language.locale) {
            state.dispatch(setLanguage(action.payload.language));
        }
    }

    next(action);
};
