// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';
import { FormField } from '@plesk/ui-library';

export const FilterContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
    
  > form > div {
    margin: 0;
  }
`;

export const FixedFormField = styled(FormField)<{margin_left?: string}>`
  margin-left: ${props => props.margin_left ? props.margin_left : '16px'};
  padding: 0 !important;
`;
