// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';

export const TabsHeader = styled.div`
  display: flex;
  align-content: center;
  justify-content: space-between;
`;

