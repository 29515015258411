// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'admin/core/store';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import * as settingsActions from 'common/modules/settings/actions';
import { ISettingsResponse } from 'common/api/resources/Settings';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { Loader } from 'common/components';
import { StyledSettings } from 'admin/settings/containers/Settings/Styles';
import { PageHeader } from 'admin/common/components/PageHeader/PageHeader';
import {
    Form,
    setIn,
    Translate,
} from '@plesk/ui-library';
import { Button } from 'admin/common/components/Button/Button';
import { INTENT_TYPE } from 'common/constants';
import { SETTINGS } from 'admin/settings/constants/tests';
import { nestStringProperties } from 'common/modules/app/formErrors/selectors';

interface ISettingsWrapperProps {
    title: string;
    children: React.ReactNode;
    onSubmit?: (values: ISettingsResponse) => void;
}

export type SettingsWrapperProps =
    ISettingsWrapperProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const SettingsWrapper: React.FC<SettingsWrapperProps> = ({
    title,
    children,
    onSubmit,
    settings,
    settingsActions: {
        getSettings,
        setSettings,
    },
    errors,
    loadingFlags,
}) => {
    React.useEffect(() => {
        getSettings();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onFieldChange = (key: string, value: string) => setSettings(setIn(settings, key, value));

    return (
        <>
            <PageHeader title={<Translate content={title}/>} isButtonShown={false}/>
            <StyledSettings>
                <Loader isLoading={loadingFlags.isLoading}>
                    <Form
                        data-cy={SETTINGS.FORM}
                        onSubmit={onSubmit}
                        onFieldChange={onFieldChange}
                        errors={errors}
                        values={settings}
                        submitButton={false}
                        hideRequiredLegend={true}
                        cancelButton={false}
                        applyButton={false}
                        vertical={true}
                    >
                        {children}
                        {onSubmit && (
                            <Button
                                type="submit"
                                intent={INTENT_TYPE.PRIMARY}
                                isLoading={loadingFlags.isSavingSettings}
                                size="lg"
                            >
                                <Translate content="settings.save" />
                            </Button>
                        )}
                    </Form>
                </Loader>
            </StyledSettings>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    errors: nestStringProperties(state),
    settings: state.settings,
    loadingFlags: {
        isLoading: state.app.loadingFlags.has(LOADING_FLAGS.APP_SETTINGS),
        isSavingSettings: state.app.loadingFlags.has(LOADING_FLAGS.SAVE_APP_SETTINGS),
    },
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    settingsActions: bindActionCreators(settingsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SettingsWrapper);
