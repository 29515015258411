// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { createCustomAction } from 'typesafe-actions';
import { Dispatch } from 'redux';
import * as types from 'common/modules/plan/constants/types';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { projects } from 'common/api/resources/Project';
import {
    create,
    get,
    loadOnScroll,
    paginateList,
    paginateSiblingsList,
    remove,
    removeBatch,
    update,
} from 'common/actions/actionsWrapper';
import { IPaginateApiResponse } from 'common/api/resources/Response';
import {
    IPlanListRequest,
    IPlanPatchRequest,
    IPlanCreateRequest,
    IPlanResponse,
    PlanUpdateRequest,
    plans,
} from 'common/api/resources/Plan';
import { ICommonState } from 'common/store';
import { IAppState } from 'client/core/store';
import {
    setIsLoading,
    unsetIsLoading,
} from 'common/modules/app/loadingFlags/actions';
import { INTENT_TYPE } from 'common/constants';
import { bakeForegroundToast } from 'common/modules/app/toaster/actions';
import { CancelTokenSource } from 'axios';

export const setPlanItem = createCustomAction(
    types.SET_PLAN_ITEM,
    (data: IPlanResponse) => ({ payload: data })
);
export const unsetPlanItem = createCustomAction(types.UNSET_PLAN_ITEM);
export const appendPlans = createCustomAction(
    types.APPEND_PLANS,
    (data: IPaginateApiResponse<IPlanResponse[]>) => ({ payload: data })
);
export const setPlanList = createCustomAction(
    types.SET_PLAN_LIST,
    (data: IPaginateApiResponse<IPlanResponse[]>) => ({ payload: data })
);
export const addPlanItem = createCustomAction(
    types.ADD_PLAN_ITEM,
    (data: IPlanResponse) => ({ payload: data })
);
export const removePlanItem = createCustomAction(
    types.REMOVE_PLAN_ITEM,
    (id: number) => ({ payload: id })
);
export const removePlanItems = createCustomAction(
    types.REMOVE_PLAN_ITEMS,
    (ids: number[]) => ({ payload: ids })
);
export const clearDefault = createCustomAction(types.CLEAR_DEFAULT);
export const setPlanItemIsLoading = createCustomAction(
    types.SET_PLAN_ITEM_IS_LOADING,
    (index: number) => ({ payload: index })
);
export const unsetPlanItemIsLoading = createCustomAction(
    types.UNSET_PLAN_ITEM_IS_LOADING,
    (index: number) => ({ payload: index })
);
export const setPlanItemIsDeleting = createCustomAction(
    types.SET_PLAN_ITEM_IS_DELETING,
    (id: number, isDeleting: boolean) => ({ payload: { id, isDeleting } })
);
export const updatePlanItemPosition = createCustomAction(
    types.UPDATE_PLAN_ITEM_POSITION,
    (id: number, position: number) => ({ payload: { id, position } })
);
export const updatePlanItem = createCustomAction(
    types.UPDATE_PLAN_ITEM,
    (data: IPlanResponse) => ({ payload: data })
);

export const createPlan = (data: IPlanCreateRequest) => async (dispatch: Dispatch) => await create({
    data,
    dispatch,
    loadingFlag: LOADING_FLAGS.SAVE_PLAN_ITEM,
    action: addPlanItem,
    apiCall: plans.create,
    translations: {
        success: 'plan.toasts.planSaved',
    },
});

export const getPlans = (
    request?: IPlanListRequest,
    cancelToken?: CancelTokenSource
) => async (dispatch: Dispatch) => await paginateList({
    dispatch,
    loadingFlag: LOADING_FLAGS.PLAN_LIST,
    action: setPlanList,
    apiCall: () => plans.list(request, cancelToken),
});

export const getProjectPlans = (projectId: number) => async(dispatch: Dispatch) => await paginateSiblingsList(projectId, {
    dispatch,
    loadingFlag: LOADING_FLAGS.PLAN_LIST,
    action: setPlanList,
    apiCall: (id: number) => projects.plans.list(id, { additional_ips_count: 0 }),
});

export const getProjectServerPlans = (request?: IPlanListRequest) => async(dispatch: Dispatch, getState: () => IAppState) => {
    const state = getState();

    return await paginateSiblingsList(state.project.servers.item.project.id, {
        dispatch,
        loadingFlag: LOADING_FLAGS.PLAN_LIST,
        action: setPlanList,
        apiCall: (id: number) => projects.plans.list(id, request),
    });
};

export const updatePlanPosition = (source_id: number, data: IPlanPatchRequest) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.SAVE_PLAN_ITEM));

    if (data.position) {
        dispatch(updatePlanItemPosition(source_id, data.position));
    }

    try {
        const result = await plans.patch(source_id, data);

        bakeForegroundToast(INTENT_TYPE.SUCCESS, 'plan.toasts.planSaved')(dispatch);
        dispatch(updatePlanItem(result.data.data));

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.SAVE_PLAN_ITEM));
    }
};

export const updatePlan = (id: number, data: PlanUpdateRequest) => async (dispatch: Dispatch) => await update(id, {
    data,
    dispatch,
    apiCall: plans.update,
    action: updatePlanItem,
    loadingFlag: LOADING_FLAGS.SAVE_PLAN_ITEM,
    translations: {
        success: 'plan.toasts.planSaved',
    },
});

export const updateDefaultPlan = (id: number, data: IPlanPatchRequest) => async (dispatch: Dispatch) => {
    dispatch(setPlanItemIsLoading(id));

    try {
        const result = await plans.patch(id, data);

        dispatch(clearDefault());
        dispatch(updatePlanItem(result.data.data));

        return result;
    } finally {
        dispatch(unsetPlanItemIsLoading(id));
    }
};

export const updatePlanVisibility = (id: number, data: IPlanPatchRequest) => async (dispatch: Dispatch) => {
    dispatch(setPlanItemIsLoading(id));

    try {
        const result = await plans.patch(id, data);

        dispatch(updatePlanItem(result.data.data));

        return result;
    } finally {
        dispatch(unsetPlanItemIsLoading(id));
    }
};

export const getPlan = (id: number) => (dispatch: Dispatch) => get(id, {
    dispatch,
    loadingFlag: LOADING_FLAGS.PLAN_ITEM,
    action: setPlanItem,
    apiCall: plans.item,
});

export const removePlan = (id: number) => async (dispatch: Dispatch) => await remove(id, {
    dispatch,
    apiCall: plans.remove,
    setLoadingAction: setPlanItemIsDeleting,
    action: removePlanItem,
    loadingFlag: LOADING_FLAGS.REMOVE_PLAN_ITEM,
    translations: {
        success: 'plan.toasts.planDeleted',
    },
});

export const removePlans = (ids: number[]) => async (dispatch: Dispatch) => await removeBatch(ids, {
    dispatch,
    apiCall: plans.removeBatch,
    action: removePlanItems,
    loadingFlag: LOADING_FLAGS.REMOVE_PLAN_ITEM,
    translations: {
        success: 'plan.toasts.plansBatchDeleted',
    },
});

export const loadPlansOnScroll = () => async (dispatch: Dispatch, getState: () => ICommonState) => {
    const state = getState();
    const nextPage = state.plan.list.links.next;
    const isLoading = state.app.loadingFlags.has(LOADING_FLAGS.PLAN_LIST);

    return await loadOnScroll({
        nextPage,
        isLoading,
        dispatch,
        action: appendPlans,
        loadingFlag: LOADING_FLAGS.PLAN_LIST,
    });
};
