// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';
import { Dropdown } from '@plesk/ui-library';

export const RolesContainer = styled.div`
  margin-left: -6px;
`;

export const RolesDropdown = styled(Dropdown)`
  display: inline-block;
`;
