// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    Form,
    FormFieldText,
    Section,
    Translate,
} from '@plesk/ui-library';
import { connect } from 'react-redux';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { RootState } from 'client/core/store';
import * as ProjectServerActions from 'common/modules/computeResourceVm/actions';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import {
    requiredRule,
    validate,
} from 'common/validator';
import { StyledForm } from 'client/common/styles/Form';
import {
    IVmResponse,
    IVmUpdateRequest,
    updateFqdns,
} from 'common/api/resources/ComputeResourceVm';
import { PopoverFormContent } from 'client/common/styles/PopoverForm';
import { HeaderButton } from 'client/common/components/HeaderButton/HeaderButton';
import { shouldRegisterFqdnOnServerCreate } from 'common/modules/settings/selectors';
import { getProcessedErrors } from 'common/modules/app/formErrors/selectors';
import { Tooltip } from 'common/components';
import { canChangeHostname } from 'common/services/ServerCapabilities';

interface IProjectServerFormProps {
    server: IVmResponse;
    onCreated: () => void;
}

export type ProjectServerFormProps =
    IProjectServerFormProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

const defaultValues = {
    name: '',
    description: '',
};

export const ProjectServerForm: React.FC<ProjectServerFormProps> = ({
    registerFqdn,
    server,
    onCreated,
    formErrors,
    projectServerActions: { updateComputeResourceVm },
    formErrorsActions: { setFormErrors, clearFormErrors },
}) => {
    const [submitValues, setSubmitValues] = React.useState({ ...defaultValues });
    const [isLoading, setIsLoading] = React.useState(false);

    const isHostnameChangingDisabled = React.useMemo(
        () => !canChangeHostname(server),
        [server]
    );

    React.useEffect(() => () => {
        clearFormErrors();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        if (server) {
            setSubmitValues({ name: server.name, description: server.description });
        }
    }, [server.description]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSubmit = async (values: IVmUpdateRequest) => {
        const rules = {
            name: requiredRule(<Translate content="validate.fieldRequired" />),
        };

        const errors = validate<IVmUpdateRequest>(values, rules);

        if (Object.keys(errors).length) {
            setFormErrors(errors);
            return;
        }

        try {
            if (registerFqdn && server.name !== values.name) {
                values.fqdns = updateFqdns(server, values.name!);
            }

            setIsLoading(true);
            await updateComputeResourceVm(server.id, values);
            onCreated();
        } catch (e) {
            throw e;
        } finally {
            setIsLoading(false);
        }
    };

    return (
        <PopoverFormContent>
            <Section title={<Translate content="projects.server.editServer" />}>
                <StyledForm>
                    <Form
                        id="projectServerForm"
                        values={submitValues}
                        errors={formErrors}
                        onSubmit={handleSubmit}
                        hideRequiredLegend={true}
                        submitButton={false}
                        cancelButton={false}
                        applyButton={false}
                        vertical={true}
                    >
                        <Tooltip
                            shown={isHostnameChangingDisabled}
                            title={<Translate content="servers.guestToolsMissing" />}
                        >
                            <FormFieldText
                                name="name"
                                placeholder="Hostname"
                                size="fill"
                                inputProps={{
                                    maxLength: 63,
                                }}
                                required={true}
                                label={<Translate content="projects.server.create.serverName" />}
                                description={<Translate content="projects.server.create.serverNameDescription" />}
                                disabled={isHostnameChangingDisabled}
                            />
                        </Tooltip>
                        <FormFieldText
                            name="description"
                            placeholder="Description"
                            autoFocus={true}
                            size="fill"
                            inputProps={{
                                maxLength: 48,
                            }}
                            label={<Translate content="projects.server.create.serverDescription" />}
                        />
                        <HeaderButton type="submit" isLoading={isLoading} form="projectServerForm">
                            <Translate content="projects.form.save" />
                        </HeaderButton>
                    </Form>
                </StyledForm>
            </Section>
        </PopoverFormContent>
    );
};

const mapStateToProps = (state: RootState) => ({
    registerFqdn: shouldRegisterFqdnOnServerCreate(state),
    formErrors: getProcessedErrors(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    projectServerActions: bindActionCreators(ProjectServerActions, dispatch),
    formErrorsActions: bindActionCreators(formErrorsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectServerForm);
