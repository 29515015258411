// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { createCustomAction } from 'typesafe-actions';
import * as types from 'common/modules/app/meta/constants';
import { Dispatch } from 'redux';
import { ICommonState } from 'common/store';
import { setLanguage } from 'common/modules/app/language/actions';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import {
    IMeta,
    meta,
} from 'common/api/resources/Meta';
import {
    setIsLoading,
    unsetIsLoading,
} from 'common/modules/app/loadingFlags/actions';

export const setMeta = createCustomAction(
    types.SET_META,
    (data: IMeta) => ({ payload: data })
);
export const setLicenseIsActive = createCustomAction(
    types.SET_LICENSE_IS_ACTIVE,
    (isActive: boolean) => ({ payload: isActive })
);

export const getMeta = () => async(dispatch: Dispatch, getState: () => ICommonState) =>  {
    const state = getState();

    try {
        dispatch(setIsLoading(LOADING_FLAGS.APP_META));

        const result = await meta.get();

        dispatch(setMeta(result.data));

        if (!state.auth.isAuth && !state.app.language.id && result.data.defaultLanguage) {
            dispatch(setLanguage(result.data.defaultLanguage));
        }

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.APP_META));
    }
};
