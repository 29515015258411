// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { Dispatch } from 'redux';
import * as types from 'admin/update/constants/types';
import { createCustomAction } from 'typesafe-actions';
import { list } from 'common/actions/actionsWrapper';
import { setAlertedItem } from 'common/modules/app/menu/actions';
import {
    setIsLoading,
    unsetIsLoading,
} from 'common/modules/app/loadingFlags/actions';
import {
    IUpdateResponse,
    IUpgradeResult,
    UPDATE_STATUS,
    updates,
} from 'common/api/resources/Update';
import { CancelTokenSource } from 'axios';

export const setState = createCustomAction(
    types.SET_UPDATE_STATE,
    (data: IUpdateResponse) => ({ payload: data })
);

export const addUpgradeResult = createCustomAction(
    types.ADD_UPGRADE_RESULT,
    (data: IUpgradeResult) => ({ payload: data })
);

export const getUpdateData = (cancelToken?: CancelTokenSource) => async (dispatch: Dispatch) => {
    const result = await list({
        dispatch,
        loadingFlag: LOADING_FLAGS.UPDATE_DATA,
        action: setState,
        apiCall: () => updates.data(cancelToken),
    });

    const hasNewVersion = result.data.data.status === UPDATE_STATUS.READY_FOR_UPDATE;
    dispatch(setAlertedItem('/admin/updates', hasNewVersion));

    return result;
};

export const initUpdate = () => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.UPDATE_INSTALLATION));
    try {
        const result = await updates.initUpdate();
        dispatch(setState(result.data.data));
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.UPDATE_INSTALLATION));
    }
};
