// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    Form,
    FormFieldText,
    Section,
    Translate,
    useTranslate,
} from '@plesk/ui-library';
import { connect } from 'react-redux';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import isEmpty from 'validator/lib/isEmpty';
import { RootState } from 'client/core/store';
import * as ProjectActions from 'common/modules/project/actions';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import {
    IProjectRequest,
    IProjectResponse,
} from 'common/api/resources/Project';
import { validate } from 'common/validator';
import { StyledForm } from 'client/common/styles/Form';
import { PopoverFormContent } from 'client/common/styles/PopoverForm';
import { HeaderButton } from 'client/common/components/HeaderButton/HeaderButton';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';

interface IProjectFormProps {
    project?: IProjectResponse;
    onCreated: () => void;
}

export type ProjectFormProps =
    IProjectFormProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

const defaultValues = {
    name: '',
    description: '',
};

export const ProjectForm: React.FC<ProjectFormProps> = ({
    project,
    isCreating,
    onCreated,
    formErrors,
    projectsActions: { saveProject },
    formErrorsActions: { setFormErrors, clearFormErrors },
}) => {
    const translate = useTranslate();

    const [isSubmitting, setIsSubmitting] = React.useState(false);
    const [submitValues, setSubmitValues] = React.useState({ ...defaultValues });

    React.useEffect(() => () => {
        clearFormErrors();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    React.useEffect(() => {
        if (project) {
            setSubmitValues({ name: project.name, description: project.description });
        }
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSubmit = async (values: IProjectRequest) => {
        if (isSubmitting) {
            return;
        }

        try {
            setIsSubmitting(true);

            const validationErrors = validate<IProjectRequest>(values, {
                name: {
                    validator: isEmpty,
                    message: <Translate content="validate.fieldRequired" />,
                    comparison: true,
                },
            });


            if (Object.keys(validationErrors).length) {
                setFormErrors(validationErrors);
                return;
            }

            project ? await saveProject(values, project.id) : await saveProject(values);
            onCreated();
        } catch (e) {
            throw e;
        } finally {
            setIsSubmitting(false);
        }
    };

    return (
        <PopoverFormContent>
            <Section title={<Translate content={`projects.${project ? 'editProject' : 'addProject'}`} />}>
                <StyledForm>
                    <Form
                        id="projectForm"
                        values={submitValues}
                        errors={formErrors}
                        onSubmit={handleSubmit}
                        hideRequiredLegend={true}
                        submitButton={false}
                        cancelButton={false}
                        applyButton={false}
                        vertical={true}
                    >
                        <FormFieldText
                            name="name"
                            placeholder={translate('projects.form.name') as string}
                            errors={formErrors}
                            autoFocus={true}
                            size="fill"
                            inputProps={{
                                maxLength: 32,
                            }}
                        />
                        <FormFieldText
                            name="description"
                            placeholder={translate('projects.form.description') as string}
                            errors={formErrors}
                            size="fill"
                            inputProps={{
                                maxLength: 48,
                            }}
                        />
                        <HeaderButton
                            icon="plus"
                            type="submit"
                            isLoading={(project && project.isLoading) || isCreating}
                            form="projectForm"
                        >
                            <Translate content="projects.form.save" />
                        </HeaderButton>
                    </Form>
                </StyledForm>
            </Section>
        </PopoverFormContent>
    );
};

const mapStateToProps = (state: RootState) => ({
    formErrors: state.app.formErrors,
    isCreating: state.app.loadingFlags.has(LOADING_FLAGS.PROJECT_ITEM),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    projectsActions: bindActionCreators(ProjectActions, dispatch),
    formErrorsActions: bindActionCreators(formErrorsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ProjectForm);
