// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    Pagination,
    Translate,
} from '@plesk/ui-library';
import { IMeta } from 'common/api/resources/Response';

export interface ICustomListHeaderProps {
    isLoading?: boolean;
    meta: IMeta;
    loadItems: (page: number) => void;
}

// Implemented for card list view. Header should have the same look as in UI-library List header.
const ListHeader: React.FC<ICustomListHeaderProps> = ({
    meta,
    isLoading,
    loadItems,
}) => (
    <div className="pul-list__header">
        <span>
            <span className="pul-list__header-text">
                <span className="pul-text pul-text--sm">
                    <Translate content="List.totalRows" params={{ totalRows: meta.total }} />
                </span>
            </span>
        </span>
        {meta.last_page > 1 && (
            <Pagination
                loading={isLoading}
                current={meta.current_page}
                total={meta.last_page}
                onSelect={loadItems}
            />
        )}
    </div>
);

export default ListHeader;
