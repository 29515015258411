// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import styled,
{ css } from 'styled-components';
import { Link } from 'react-router-dom';

interface IItemContainerProps {
    $isActive: boolean;
    $isNested: boolean;
    $isHighlighted: boolean;
}

const ItemContainerStyle = css<IItemContainerProps>`
    color: white;
    padding: ${({ $isNested }) => $isNested ? '8px 17px' : '12px 17px'};
    font-size: ${({ $isNested }) => $isNested ? '12px' : '14px'};
    font-weight: 600;
    opacity: ${({ $isActive }) => $isActive ? '1' : '0.87'};
    line-height: 16px;
    display: flex;
    align-items: center;
    background-color: ${({ $isHighlighted }) => $isHighlighted ? 'rgba(255, 255, 255, 0.13)' : 'inherit'};
    border: none;
    width: 100%;

    &:hover {
        color: white;
        text-decoration: none;
        opacity: 1;
        cursor: pointer;
    }
`;

export const ItemContainer = styled.button<IItemContainerProps>`
    ${ItemContainerStyle}
`;
export const ItemContainerLink = styled(Link)<React.ComponentProps<Link> & IItemContainerProps>`
    ${ItemContainerStyle}
`;

export const ItemContent = styled.div`
    margin-left: 8px;
`;

interface IItemContentNestedProps {
    isActive: boolean;
}

export const ItemContentNested = styled.div<IItemContentNestedProps>`
    ${({ isActive }) => isActive
        ? 'margin-left: 10px;'
        : 'margin-left: 25px;'
}
`;

export const NavGroupContainer = styled.div`
    position: relative;
`;

export const NavGroupChevron = styled.span`
    position: absolute;
    right: 8px
`;
