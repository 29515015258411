// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import * as imagePresetActions from 'admin/imagePreset/actions';
import { connect } from 'react-redux';
import { RootState } from 'admin/core/store';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { PageHeader } from 'admin/common/components/PageHeader/PageHeader';
import { Translate } from '@plesk/ui-library';
import { Loader } from 'common/components';
import { EmptyView } from 'common/components/EmptyView/EmptyView';
import { Dialog } from 'common/components/Dialog/Dialog';
import {
    ICONS,
    SIZE,
} from 'common/constants';
import {
    getActionColumnProps,
    reloadListData,
} from 'common/helpers/list';
import { useIsFirstLoading } from 'common/hooks/useIsFirstLoading';
import List from 'common/components/List/List';
import ImagePresetForm from 'admin/imagePreset/containers/ImagePresetForm';
import { ImagePresetActions } from 'admin/imagePreset/components/ImagePresetActions';
import { hasPermission } from 'common/modules/permission/selectors';
import { PERMISSION_LIST } from 'common/modules/permission/constants';
import { IMAGE_PRESET_TYPE_TRANSLATION_MAP } from 'common/api/resources/ImagePreset';
import { VIRTUALIZATION_TYPE_TRANSLATION_MAP } from 'common/api/resources/ComputeResource';

export type ImagePresetsProps =
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export enum ImagePresetTableColumns {
    ID = 'colId',
    NAME = 'colName',
    IMAGE_TYPE = 'colImageType',
    VIRTUALIZATION_TYPE = 'colVirtualizationType',
    DESCRIPTION = 'colDescription',
    ACTIONS = 'colActions',
}

const columns = [
    {
        width: '1%',
        key: ImagePresetTableColumns.ID,
        title: <Translate content="imagePreset.list.id"/>,
    },
    {
        width: '15%',
        key: ImagePresetTableColumns.NAME,
        title: <Translate content="imagePreset.list.name"/>,
    },
    {
        width: '15%',
        key: ImagePresetTableColumns.IMAGE_TYPE,
        title: <Translate content="imagePreset.list.imageType"/>,
    },
    {
        width: '15%',
        key: ImagePresetTableColumns.VIRTUALIZATION_TYPE,
        title: <Translate content="imagePreset.list.virtualizationType"/>,
    },
    {
        key: ImagePresetTableColumns.DESCRIPTION,
        title: <Translate content="imagePreset.list.description"/>,
    },
    getActionColumnProps(),
];

export const ImagePresets: React.FC<ImagePresetsProps> = ({
    item,
    list,
    isLoadingList,
    isLoadingItem,
    canManage,
    imagePresetActions: {
        getImagePresets,
        getImagePreset,
        removeImagePreset,
    },
}) => {
    const [isDialogOpen, setDialogOpen] = React.useState(false);

    React.useEffect(() => {
        getImagePresets();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadPaginated = (page: number) => getImagePresets({ page });

    const isFirstLoading = useIsFirstLoading(isLoadingList);

    const handleRemove = (id: number) => async () => {
        await removeImagePreset(id);
        reloadListData(list, loadPaginated);
    };

    const handleEdit = (id: number) => () => {
        getImagePreset(id);
        setDialogOpen(true);
    };

    const data = list.data.map((imagePreset) => ({
        [ImagePresetTableColumns.ID]: imagePreset.id,
        [ImagePresetTableColumns.NAME]: imagePreset.name,
        [ImagePresetTableColumns.IMAGE_TYPE]: (<Translate content={IMAGE_PRESET_TYPE_TRANSLATION_MAP[imagePreset.image_type]} />),
        [ImagePresetTableColumns.VIRTUALIZATION_TYPE]: VIRTUALIZATION_TYPE_TRANSLATION_MAP[imagePreset.virtualization_type],
        [ImagePresetTableColumns.DESCRIPTION]: imagePreset.description?.length ? imagePreset.description : (<>&mdash;</>),
        [ImagePresetTableColumns.ACTIONS]: canManage ? (
            <ImagePresetActions
                item={imagePreset}
                handleEdit={handleEdit(imagePreset.id)}
                handleRemove={handleRemove(imagePreset.id)}
                removeDisabled={imagePreset.is_default}
            />
        ) : undefined,
        key: imagePreset.id.toString(),
    }));

    return (
        <>
            <PageHeader
                isButtonShown={list.data.length > 0 && canManage}
                title={<Translate content="imagePreset.title"/>}
                buttonText="imagePreset.addBtn"
                buttonIcon={ICONS.PLUS}
                onButtonClick={() => setDialogOpen(true)}
            />
            <Loader isLoading={isFirstLoading}>
                <List
                    emptyView={
                        <EmptyView
                            title="imagePreset.emptyView.title"
                            description="imagePreset.emptyView.description"
                            buttonText="imagePreset.emptyView.buttonText"
                            onButtonClick={() => setDialogOpen(true)}
                            icon={ICONS.PLUS}
                        />
                    }
                    columns={columns}
                    data={data}
                    loadItems={loadPaginated}
                    meta={list.meta}
                    isLoading={isLoadingList}
                    isFirstLoading={isFirstLoading}
                />
            </Loader>
            <Dialog
                heading={<Translate content={item.id
                    ? 'imagePreset.form.title.edit'
                    : 'imagePreset.form.title.create'
                }/>}
                closeHandler={() => setDialogOpen(false)}
                isOpen={isDialogOpen}
                size={SIZE.XS}
            >
                <Loader isLoading={isLoadingItem} center={false}>
                    <ImagePresetForm onSubmit={() => setDialogOpen(false)}/>
                </Loader>
            </Dialog>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    item: state.imagePreset.item,
    list: state.imagePreset.list,
    isLoadingList: state.app.loadingFlags.has(LOADING_FLAGS.IMAGE_PRESET_LIST),
    isLoadingItem: state.app.loadingFlags.has(LOADING_FLAGS.IMAGE_PRESET_ITEM),
    canManage: hasPermission(state, PERMISSION_LIST.MANAGE_IMAGE_PRESETS),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    imagePresetActions: bindActionCreators(imagePresetActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ImagePresets);
