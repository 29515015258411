// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';

import { COLORS } from 'admin/core/theme';
import CopyText from 'common/containers/CopyText/CopyText';
import { Button } from 'admin/common/components/Button/Button';
import {
    Popover,
    Translate,
} from '@plesk/ui-library';

export interface IVariable {
    variable: string;
    description: React.ReactNode;
}

export interface ISystemVariablesPopoverProps {
    variables: IVariable[];
    isInBrackets?: boolean;
    button?: React.ReactNode;
    header?: React.ReactNode;
}

export const SystemVariablesPopover: React.FC<ISystemVariablesPopoverProps> = ({
    variables,
    isInBrackets = true,
    button = <Translate content="application.variables.btnInfo" />,
    header = <Translate content="application.variables.title" />,
}) => (
    <Popover
        target={(
            <Button
                ghost={true}
                icon="info-circle"
            >
                {button}
            </Button>
        )}
        placement="right"
    >
        <h3>
            {header}
        </h3>
        {variables.map((variable, key) => (
            <p key={key}>
                <b style={{ color: COLORS.PRIMARY }}>
                    <div style={{ float: 'left' }}>
                        {isInBrackets ? (
                            <CopyText>{`{{ ${variable.variable} }}`}</CopyText>
                        ) : (
                            <CopyText>{variable.variable}</CopyText>
                        )}
                    </div>
                </b>  &nbsp;— {variable.description}
            </p>
        ))}
    </Popover>
);
