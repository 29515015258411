// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as RoleActions from 'admin/role/actions';
import { combineReducers } from 'redux';
import {
    ActionType,
    getType,
} from 'typesafe-actions';
import { IRoleResponse } from 'common/api/resources/Role';
import {
    IPaginateApiResponse,
    paginateInitialState,
} from 'common/api/resources/Response';
import { addToPaginated } from 'common/reducers/list';
import { IPermissionResponse } from 'common/api/resources/Permission';

interface IRoles {
    list: IPaginateApiResponse<IRoleResponse[]>;
    item: IRoleResponse;
}

export type RoleAction = ActionType<typeof RoleActions>;
export type RoleState = Readonly<IRoles>;

export const initialRoleState: IRoleResponse = {
    id: 0,
    name: '',
    is_default: false,
    permissions: [],
    is_deleting: false,
};

export const initialPermissionState: IPermissionResponse = {
    id: 0,
    name: '',
};

export default combineReducers<RoleState, RoleAction>({
    list: (state = paginateInitialState, action: RoleAction) => {
        switch (action.type) {
        case getType(RoleActions.appendRoles):
            return {
                links: action.payload.links,
                data: [...state.data, ...action.payload.data],
                meta: action.payload.meta,
            };
        case getType(RoleActions.addRoleItem):
            return addToPaginated(state, action.payload);
        case getType(RoleActions.setRoleList):
            return action.payload;
        case getType(RoleActions.removeRoleItem):
            return {
                ...state,
                data: state.data.filter(item => item.id !== action.payload),
            };
        case getType(RoleActions.updateRoleItem):
            return {
                ...state,
                data: state.data.map(item => {
                    if (item.id === action.payload.id) {
                        return action.payload;
                    }

                    return item;
                }),
            };
        case getType(RoleActions.setRolesItemIsDeleting):
            return {
                ...state, data: [...state.data.map(item => {
                    if (item.id === action.payload.id) {
                        return { ...item, is_deleting: action.payload.isDeleting };
                    }

                    return item;
                })],
            };
        default:
            return state;
        }
    },
    item: (state = initialRoleState, action) => {
        switch (action.type) {
        case getType(RoleActions.setRoleItem):
            return action.payload;
        case getType(RoleActions.unsetRoleItem):
            return { ...initialRoleState };
        default:
            return state;
        }
    },
});
