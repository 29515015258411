// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { INTENT_TYPE } from 'common/constants';

export const STATUS_INVITED = 'invited';
export const STATUS_ACTIVE = 'active';

export const STATUS_INTENT_MAP = {
    [STATUS_INVITED]: INTENT_TYPE.WARNING,
    [STATUS_ACTIVE]: INTENT_TYPE.SUCCESS,
};

export const SNAPSHOT_STATUS_PROCESSING = 'processing';
export const SNAPSHOT_STATUS_FAILED = 'failed';
export const SNAPSHOT_STATUS_AVAILABLE = 'available';

export enum TABS {
    OVERVIEW = '#overview',
    SETTINGS = '#settings',
    GRAPHS = '#graphs',
    SNAPSHOTS = '#snapshots',
    RESCUE = '#rescue',
    BACKUP = '#backup',
    REINSTALL = '#reinstall',
    RESIZE = '#resize',
    NETWORKING = '#networking',
    DISKS = '#disks',
}
