// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import {
    api,
    IApiResponse,
    IPaginateApiResponse,
} from 'common/api/resources/Response';
import {
    ISshKeyRequest,
    ISshKeyResponse,
} from 'common/api/resources/SshKey/model';
import { QueryBuilder } from 'common/api/resources/Request/QueryBuilder';
import { IPaginatedWithSearch } from 'common/api/resources/Request/request';

export const sshKeys = {
    list: (params?: IPaginatedWithSearch) => api.get<IPaginateApiResponse<ISshKeyResponse[]>>('ssh_keys', {
        params: new QueryBuilder(params).build(),
    }),
    remove: (id: number) => api.delete(`ssh_keys/${id}`),
    create: (data: ISshKeyRequest) => api.post<IApiResponse<ISshKeyResponse>>('ssh_keys', data),
};
