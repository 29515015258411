// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';
import { COLORS } from 'admin/core/theme';

export const UsageContainer = styled.div`
  width: 100%;
`;

export const UsageLineBar = styled.div`
  height: 2px;
  width: 80px;
  background-color: ${COLORS.GREY_40};
  margin-top: 2px;
`;

export interface IUsageLineProps {
    progress: number;
    color: string;
}

export const UsageLine = styled.div<IUsageLineProps>`
  height: 100%;
  width: ${({ progress }) => progress}%;
  background-color: ${({ color }) => color};
`;
