// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

export const PLANS = {
    TOOLBAR: 'plans-toolbar',
    TABLE: 'plans-table',
    VISIBILITY: 'plans-switch-visibility',
    DEFAULT: 'plans-default',
    BATCH_DELETE_BTN: 'plans-batch-delete',
};

export const FORM = {
    VIRTUALIZATION_TYPE: 'virtualization-type',
    STORAGE_TYPE: 'storage-type',
    IMAGE_FORMAT: 'image-format',
    SNAPSHOT: 'snapshot',
    BACKUP_AVAILABILITY: 'backup_availability',
    BACKUP_PRICE: 'backup_price',
    ADDITIONAL_IP_AVAILABILITY: 'additional_ip_availability',
    DISK_LIMITS_SECTION: 'disk-limits-section',
    NETWORK_LIMITS_SECTION: 'network-limits-section',
    ADDITIONAL_DISKS: 'additional-disks',

    VZ_PARAMETERS_SECTION: 'vz-parameters-section',
    NETFILTER: 'netfilter',
    TUN_TAP: 'tun_tap',
    PPP: 'ppp',

    DISK_LIMITS: {
        IOPS: 'disk-iops',
        BANDWIDTH: 'disk-bandwidth',
    },

    NETWORK_LIMITS: {
        INCOMING_BANDWIDTH: 'incoming-bandwidth',
        OUTGOING_BANDWIDTH: 'outgoing-bandwidth',
        INCOMING_TRAFFIC: 'incoming-traffic',
        OUTGOING_TRAFFIC: 'outgoing-traffic',
        TOTAL_TRAFFIC: 'total-traffic',
        REDUCE_BANDWIDTH: 'reduce-bandwidth',
    },

    BACKUP_LIMITS: {
        NUMBER: 'backups_number',
    },

    LIMIT_INPUT_VALUE: 'plan-limit-value',

    SNAPSHOT_LIMITS: {
        COUNT: 'snapshot-count',
        SIZE: 'snapshot-size',
    },

    ADDITIONAL_DISK_LIMIT: 'additional-disk',
};
