// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';
import { COLORS } from 'admin/core/theme';

interface IStyledCodeEditorProps {
    height: number;
    width?: number;
}

export const StyledCodeEditor = styled.div<IStyledCodeEditorProps>`
    .CodeMirror {
        height: ${props => props.height}px;
        width: ${props => props.width ? `${props.width}px` : 'auto'};
        border: 1px solid ${COLORS.GREY_50};
        box-sizing: border-box;
        border-radius: 2px;
        z-index: 0;
    }
`;
