// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import { ICommonState } from 'common/store';
import { INTENT_TYPE } from 'common/constants';
import {
    Alert,
    Icon,
} from '@plesk/ui-library';
import { Dispatch } from 'redux';
import { clearResponseError } from 'common/modules/app/responseError/actions';

export type NetworkFormErrorProps =
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const NetworkFormError: React.FC<NetworkFormErrorProps> = ({ networkError, clearResponseErrors }) => {
    React.useEffect(() => () => {
        clearResponseErrors();
    }, [clearResponseErrors]);

    if (!networkError.length) {
        return null;
    }

    return (
        <Alert intent={INTENT_TYPE.DANGER}>
            <Icon name="exclamation-mark-circle" /> {networkError}
        </Alert>
    );
};

const mapStateToProps = (state: ICommonState) => ({
    networkError: state.app.responseError.error,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    clearResponseErrors: () => dispatch(clearResponseError()),
});

export default connect(mapStateToProps, mapDispatchToProps)(NetworkFormError);
