// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { Dispatch } from 'redux';
import { createCustomAction } from 'typesafe-actions';
import * as types from 'admin/notification/constants/types';
import { paginateList } from 'common/actions/actionsWrapper';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { IPaginateApiResponse } from 'common/api/resources/Response';
import { IAppState } from 'admin/core/store';
import {
    setIsLoading,
    unsetIsLoading,
} from 'common/modules/app/loadingFlags/actions';
import { HTTP_CODES } from 'common/api/constants';
import { accounts } from 'common/api/resources/Account';
import {
    INotificationResponse,
    notifications,
} from 'common/api/resources/Notification';

export const setNotificationList = createCustomAction(
    types.SET_NOTIFICATION_LIST,
    (data: IPaginateApiResponse<INotificationResponse[]>) => ({ payload: data })
);
export const appendNotifications = createCustomAction(
    types.APPEND_NOTIFICATIONS,
    (data: IPaginateApiResponse<INotificationResponse[]>) => ({ payload: data })
);
export const clearNotificationList = createCustomAction(types.CLEAR_NOTIFICATION_LIST);
export const addNotificationItem = createCustomAction(
    types.ADD_NOTIFICATION_ITEM,
    (data: INotificationResponse) => ({ payload: data })
);
export const removeNotificationItem = createCustomAction(
    types.REMOVE_NOTIFICATION_ITEM,
    (id: number) => ({ payload: id })
);

export const getNotifications = () => async (dispatch: Dispatch) => await paginateList({
    dispatch,
    loadingFlag: LOADING_FLAGS.NOTIFICATION_LIST,
    action: setNotificationList,
    apiCall: accounts.notifications.list,
});

export const removeNotification = (id: number) => async (dispatch: Dispatch) => {
    dispatch(removeNotificationItem(id));
    await notifications.remove(id);
};

export const clearAll = () => async (dispatch: Dispatch) => {
    dispatch(clearNotificationList());
    await accounts.notifications.clearAll();
};

export const loadMoreNotifications = () => async (dispatch: Dispatch, getState: () => IAppState) => {
    const state = getState();
    dispatch(setIsLoading(LOADING_FLAGS.NOTIFICATION_LIST));

    try {
        const result = await accounts.notifications.showMore(state.notification.list.data.length);

        if (result.status === HTTP_CODES.OK) {
            dispatch(appendNotifications(result.data));
        }
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.NOTIFICATION_LIST));
    }
};
