// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    FormFieldPassword as UIFormFieldPassword,
    FormFieldPasswordProps,
} from '@plesk/ui-library';

export interface IFormFieldPassword extends Omit<
    FormFieldPasswordProps,
    'hideGenerateButton' | 'autoComplete' | 'hidePasswordMeter' | 'ref'
>{}

const FormFieldPassword: React.FC<IFormFieldPassword> = (props) => (
    <UIFormFieldPassword
        {...props}
        hideGenerateButton={true}
        autoComplete="new-password"
        hidePasswordMeter={true}
    />
);

export default FormFieldPassword;
