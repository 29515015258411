// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import ComputeResourceVmTable from 'admin/computeResourceVm/containers/ComputeResourceVmTable/ComputeResourceVmTable';
import { EmptyView } from 'common/components/EmptyView/EmptyView';
import {
    Button,
    Translate,
} from '@plesk/ui-library';
import PageHeader from 'common/components/PageHeader/PageHeader';
import { PageSection } from 'common/components/PageHeader/Styles';
import {
    RouteComponentProps,
    withRouter,
} from 'react-router';
import {
    Tab,
    TabList,
    TabPanel,
    Tabs,
} from 'react-tabs';
import { VMS_TABS } from 'admin/computeResourceVm/constants';
import ServersMigrationTable from 'admin/serversMigration/containers/ServersMigrationTable';
import {
    ICONS,
    INTENT_TYPE,
    SIZE,
} from 'common/constants';
import { Link } from 'react-router-dom';
import { pathTo } from 'common/helpers/core';

export interface IComputeResourceVmsProps extends RouteComponentProps {}

export const ComputeResourceVms: React.FC<IComputeResourceVmsProps> = ({
    history,
    location: {
        hash,
    },
}) => {
    if (!hash) {
        history.replace(VMS_TABS.SERVERS_TAB);
    }

    const tabs = Object.values(VMS_TABS);
    const handleTabSelect = (index: number) => history.replace(tabs[index]);

    const renderHeaderButton = () => {
        if (hash !== VMS_TABS.SERVERS_TAB) {
            return null;
        }
        return (
            <Button
                intent={INTENT_TYPE.PRIMARY}
                size={SIZE.LG}
                icon={ICONS.SCREEN}
                component={Link}
                to={pathTo('servers/create')}
            >
                <Translate content="computeResourceVm.createBtn" />
            </Button>
        );
    };

    return (
        <>
            <PageHeader
                title={(
                    <PageSection>
                        <Translate content="computeResourceVm.header" />
                    </PageSection>
                )}
                actionButton={renderHeaderButton()}
            />
            <Tabs selectedIndex={tabs.findIndex(tab => tab === hash)} onSelect={handleTabSelect}>
                <TabList>
                    <Tab>
                        <Translate content="computeResourceVms.serversTab.title"/>
                    </Tab>
                    <Tab>
                        <Translate content="computeResourceVms.migrationsTab.title"/>
                    </Tab>
                </TabList>
                <TabPanel>
                    <ComputeResourceVmTable
                        withFilters={true}
                        emptyView={<EmptyView
                            title="computeResource.servers.emptyView.title"
                            description="computeResource.servers.emptyView.description"
                            icon={ICONS.SCREEN}
                        />}
                    />
                </TabPanel>
                <TabPanel>
                    <ServersMigrationTable/>
                </TabPanel>
            </Tabs>
        </>
    );
};

export default withRouter(ComputeResourceVms);
