// Copyright 1999-2024. WebPros International GmbH. All rights reserved.
import styled from 'styled-components';
import { Icon } from '@plesk/ui-library';

export interface ISearchBarContainerProps {
    focused: boolean;
}
export const SearchBarContainer = styled.div<ISearchBarContainerProps>`
  width: ${({ focused }) => focused ? '700px' : '270px'};
  position: relative;
  transition: width 0.1s ease-in-out;
`;

export interface ISearchBarResultsContainer {
    visible: boolean;
}
export const SearchBarResultsContainer = styled.div<ISearchBarResultsContainer>`
  position: absolute;
  display: ${({ visible }) => visible ? 'block' : 'none'};
  inset-inline-end: 0;
  z-index: 1035;
  margin-block: 4px;
  margin-inline: 0;
  inline-size: 100%;
`;

export const SearchBarResultIcon = styled(Icon)`
  margin-inline-end: 4px;
`;