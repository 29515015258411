// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import { OfferType } from 'common/api/resources/Offer';
import {
    Grid,
    GridCol,
    Icon,
    Translate,
} from '@plesk/ui-library';
import {
    ICONS,
    INTENT_TYPE,
    SIZE,
} from 'common/constants';
import { IProjectTokenPricingResponse } from 'common/api/resources/Project';
import { PageSubHeader } from 'client/common/components';
import { IAdditionalDiskRequest } from 'common/api/resources/ComputeResourceVm';
import {
    CardItem,
    LogoContainer,
} from 'common/modules/computeResourceVm/components/Styles';
import AdditionalDiskForm,
{ FIELDS } from 'common/modules/computeResourceVm/containers/AdditionalDiskForm';
import { Dialog } from 'common/components/Dialog/Dialog';
import { IAppState as IClientState } from 'client/core/store';
import { AdditionalDiskCard } from 'common/modules/computeResourceVm/components/AdditionalDiskCard';
import { IPlanResponse } from 'common/api/resources/Plan';
import { ICommonState } from 'common/store';
import { LIST } from 'common/modules/offer/constants/tests';

export interface IAdditionalDiskListProps {
    plan?: IPlanResponse;
    disks: IAdditionalDiskRequest[];
    onDiskAdded: (disk: IAdditionalDiskRequest) => void;
    onDiskRemoved: (index: number) => void;
    selectedPlan?: IPlanResponse;
    tokenPricing?: IProjectTokenPricingResponse;
    disabledOfferIds?: number[];
}

export type AdditionalDiskListProps =
    IAdditionalDiskListProps &
    ReturnType<typeof mapStateToProps>;

export const AdditionalDiskList: React.FC<AdditionalDiskListProps> = ({
    tokenPricing,
    selectedPlan,
    offers,
    disabledOfferIds,
    disks,
    onDiskAdded,
    onDiskRemoved,
}) => {
    const [isDialogOpen, setDialogIsOpen] = React.useState(false);

    const handleFormSubmit = (disk: IAdditionalDiskRequest) => {
        setDialogIsOpen(false);
        onDiskAdded(disk);
    };

    return (
        <>
            <PageSubHeader title="servers.create.additionalDiskOffers"/>
            <Grid
                style={{ marginBottom: '-24px' }}
                gap={SIZE.LG}
                xs={1}
                sm={2}
                md={3}
                lg={4}
                xl={5}
            >
                <GridCol>
                    <CardItem
                        style={{ height: '100%', textAlign: 'center', alignItems: 'center' }}
                        onClick={() => setDialogIsOpen(true)}
                        data-cy={LIST.ADD_ADDITIONAL_DISK}
                    >
                        <LogoContainer style={{ width: '100%' }}>
                            <Icon
                                intent={INTENT_TYPE.INACTIVE}
                                name={ICONS.PLUS}
                            />
                        </LogoContainer>
                    </CardItem>
                </GridCol>
                {disks.map((disk, index) => (
                    <GridCol key={index}>
                        <AdditionalDiskCard
                            plan={selectedPlan}
                            tokenPricing={tokenPricing}
                            offer={offers.find((offer) => offer.id === disk.offer_id)!}
                            diskName={disk.name}
                            sizeGib={disk.size}
                            icon={ICONS.CD}
                            isSelected={!disabledOfferIds?.includes(disk.offer_id)}
                            onRemoveClicked={() => {
                                onDiskRemoved(index);
                            }}
                        />
                    </GridCol>
                ))}
            </Grid>
            <Dialog
                heading={<Translate content="servers.additionalDisk.dialog.title"/>}
                closeHandler={() => setDialogIsOpen(false)}
                isOpen={isDialogOpen}
                size={SIZE.XS}
            >
                <AdditionalDiskForm
                    selectedPlan={selectedPlan}
                    disabledOfferIds={disabledOfferIds}
                    onSubmit={handleFormSubmit}
                    tokenPricing={tokenPricing}
                    fields={Object.values(FIELDS)}
                />
            </Dialog>
        </>
    );
};

const mapStateToProps = (state: (IClientState | ICommonState)) => ({
    offers: state.offer.list.data.filter((offer) => offer.type === OfferType.ADDITIONAL_DISK),
});

export default connect(mapStateToProps)(AdditionalDiskList);