// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';
import { CLASS_COLUMN_FIXED_RIGHT } from 'common/styles/variables';

export const StyledList = styled.div`
    .pul-list__table {
        thead {
            th {
                font-size: 12px;
                line-height: 16px;
                color: #737373;
                padding-bottom: 12px;
                border-bottom: 1px solid #e6e6e6;

                &:first-child {
                    padding-left: 22px;
                }

                &:last-child {
                    padding-right: 17px;
                }
            }
        }

        tbody {
            tr {
                td {
                    padding: 8px;
                    white-space: nowrap;
                    line-height: 24px;

                    &:first-child {
                        border-left: 1px solid #e6e6e6;
                    }

                    &:last-child {
                        border-right: 1px solid #e6e6e6;
                    }
                }

                &:hover > td {
                    background-color: #fff!important;
                }
            }
        }

        & .${CLASS_COLUMN_FIXED_RIGHT} {
            position: sticky;
            right: 0;
        }
    }

    .pul-content-loader__container {
        padding: 0;
    }

    .pul-content-loader__indicator {
        width: 16px;
        height: 16px;
    }

    .pul-status {
        font-size: 14px;
        line-height: 20px;
        text-transform: capitalize;

        &:before {
            margin-top: 6px!important;
        }
    }

    .header-default {
        padding-left: 12px
    }

    .header-status {
        padding-left: 22px
    }

    .header-role {
        padding-left: 32px;
    }

    .header-location {
        padding-left: 32px;
    }

    .cell-description {
        max-width: 200px;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    .cell-icon {
        display: flex;
        justify-content: flex-end;

        &:last-child {
            padding: 6px!important;
        }
    }

    .cell-status {
        display: flex;
        align-items: center;
    }

    .cell-owner {
        position: relative;
        padding-left: 16px!important;

        span {
            position: absolute;
            top: 11px;
        }
    }
`;
