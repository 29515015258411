// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import List from 'common/components/List/List';
import { EmptyView } from 'common/components/EmptyView/EmptyView';
import {
    Button,
    Translate,
} from '@plesk/ui-library';
import { ICONS } from 'common/constants';
import { activityLogEventMap } from 'admin/activityLog/constants/activityLogEvents';
import { formatTableDate } from 'common/date';
import { RootState } from 'admin/core/store';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import * as activityLogActions from 'admin/activityLog/actions';
import { connect } from 'react-redux';
import {
    IActivityLogFilters,
    IActivityLogResponse,
} from 'common/api/resources/ActivityLog';
import { ActivityLogDataDialog } from 'admin/activityLog/components/ActivityLogDataDialog';
import { useIsFirstLoading } from 'common/hooks/useIsFirstLoading';
import { dataCySelector } from 'common/tests/selectors';
import { TABLE_ACTIONS } from 'admin/activityLog/constants/tests';

interface IActivityLogTableProps {
    toolbar?: React.ReactElement;
    filters?: IActivityLogFilters;
}

export type ActivityLogTableProps =
    IActivityLogTableProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

const columns = [{
    width: '15%',
    key: 'colEvent',
    title: <Translate content="activityLog.list.event" />,
}, {
    width: '15%',
    key: 'colUser',
    title: <Translate content="activityLog.list.user" />,
}, {
    width: '10%',
    key: 'colIp',
    title: <Translate content="activityLog.list.ip" />,
}, {
    width: '10%',
    key: 'colCreated',
    title: <Translate content="activityLog.list.created" />,
}];

export const ActivityLogTable: React.FC<ActivityLogTableProps> = ({
    list,
    isLoadingList,
    toolbar,
    filters,
    activityLogActions: {
        getActivityLogs,
    },
}) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [selectedLogData, setSelectedLogData] = React.useState<IActivityLogResponse>();

    const handleOpen = (log: IActivityLogResponse) => () => {
        setSelectedLogData(log);
        setIsOpen(true);
    };

    const data = list.data.map(item => ({
        colEvent: (
            <>
                <Translate content={activityLogEventMap[item.event]} />
                <Button
                    onClick={handleOpen(item)}
                    ghost={true}
                    icon={ICONS.BOX_DIAGONAL_TOP_IN}
                    data-cy={dataCySelector(item.id, TABLE_ACTIONS.SHOW_LOG_DATA)}
                />
            </>
        ),
        colUser: item.user_email,
        colIp: item.user_ip,
        colCreated: formatTableDate(item.created_at),
        key: item.id.toString(),
    }));

    const handleClose = () => setIsOpen(false);

    const loadPaginated = (page: number) => getActivityLogs({
        page,
        filters,
    });

    const filtered = filters !== undefined && Object.values(filters).some(filter => !!filter);

    const isFirstLoading = useIsFirstLoading(isLoadingList);

    return (
        <>
            <List
                emptyView={
                    <EmptyView
                        title="activityLog.emptyView.title"
                        icon={ICONS.NET}
                    />
                }
                columns={columns}
                data={data}
                loadItems={loadPaginated}
                meta={list.meta}
                isLoading={isLoadingList}
                isFirstLoading={isFirstLoading}
                filtered={filtered || isLoadingList}
                toolbar={toolbar}
            />
            {selectedLogData && (
                <ActivityLogDataDialog
                    handleClose={handleClose}
                    isOpen={isOpen}
                    logData={selectedLogData}
                />
            )}
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    list: state.activityLog.list,
    isLoadingList: state.app.loadingFlags.has(LOADING_FLAGS.ACTIVITY_LOG_LIST),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    activityLogActions: bindActionCreators(activityLogActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ActivityLogTable);
