// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import React from 'react';
import { RootState } from 'admin/core/store';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import * as backupActions from 'common/modules/backup/actions';
import { connect } from 'react-redux';
import BackupsTable,
{ BackupsTableColumns } from 'common/modules/backup/containers/BackupsTable';
import { PageHeader } from 'admin/common/components/PageHeader/PageHeader';
import {
    BackupStatus,
    IBackupListFilters,
    IBackupResponse,
} from 'common/api/resources/Backup';
import { ValueType } from 'react-select';
import {
    ISelectRequiredOption,
    Loader,
} from 'common/components';
import {
    BackupOperationContainer,
    BackupsCardContainer,
} from 'admin/backup/containers/Backups/Styles';
import { VerticalFiltersContainer } from 'admin/common/styles/Styles';
import {
    Button,
    Form,
    Popover,
    Section,
    Text,
    Translate,
} from '@plesk/ui-library';
import ButtonWithConfirmation from 'common/components/ButtonWithConfirmation';
import { ACTIONS } from 'common/modules/backup/constants/tests';
import { EmptyView } from 'common/components/EmptyView/EmptyView';
import { ComputeResourceFilter } from 'admin/common/components/Filters/ComputeResourceFilter/ComputeResourceFilter';
import { BackupNodeFilter } from 'admin/common/components/Filters/BackupNodeFilter/BackupNodeFilter';
import { UserFilter } from 'admin/common/components/Filters/UserFilter/UserFilter';
import { ComputeResourceVmFilter } from 'admin/common/components/Filters/ComputeResourceVmFilter/ComputeResourceVmFilter';
import { CancelTokenSource } from 'axios';
import GlobalVsBackupSettingsCard from 'admin/backup/containers/GlobalVsBackupSettingsCard/GlobalVsBackupSettingsCard';
import { useIsFirstLoading } from 'common/hooks/useIsFirstLoading';
import {
    ICONS,
    SIZE,
} from 'common/constants';
import { ForDeletedServersFilter } from 'admin/common/components/Filters/ForDeletedServersFilter/ForDeletedServersFilter';
import { Dialog } from 'common/components/Dialog/Dialog';
import RestoreForm from 'admin/backup/containers/RestoreForm/RestoreForm';
import { DELETE_CONFIRMATION } from 'admin/common/constants/constants';
import CopyText from 'common/containers/CopyText/CopyText';
import ButtonWithInputConfirmation from 'common/components/ButtonWithInputConfirmation/ButtonWithInputConfirmation';

export const emptyFilters = {
    creator_id: 0,
    compute_resource_id: 0,
    compute_resource_vm_id: 0,
    backup_node_id: 0,
    only_for_deleted_servers: '',
};

export type BackupsProps =
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

interface IFilterOptions {
    creator_id?: ValueType<ISelectRequiredOption>;
    compute_resource_id?: ValueType<ISelectRequiredOption>;
    compute_resource_vm_id?: ValueType<ISelectRequiredOption>;
    backup_node_id?: ValueType<ISelectRequiredOption>;
    only_for_deleted_servers?: ValueType<ISelectRequiredOption>;
}

export const Backups: React.FC<BackupsProps> = ({
    backups,
    isServerProcessing,
    backupActions: {
        getBackups,
        removeBackups,
        getNextScheduledDate,
    },
    isBackupBatchRemoving,
    isLoading,
}) => {
    const [filters, setFilters] = React.useState<IBackupListFilters>(emptyFilters);
    const [options, setOptions] = React.useState<IFilterOptions>();
    const [selection, setSelection] = React.useState<string[]>([]);
    const [areFiltersOpened, toggleFilters] = React.useState(false);
    const [currentBackup, setCurrentBackup] = React.useState<IBackupResponse>();
    const [isRestoreDialogOpen, setIsRestoreDialogOpen] = React.useState(false);

    const hasLastFullBackup = React.useMemo(() => {
        if (selection.length === 0) {
            return false;
        }

        const backupMap = backups.reduce((map: IBackupResponse[], backup: IBackupResponse) => {
            map[backup.id] = backup;
            return map;
        }, []);
        let backupMapOfDeletedServers: number[] = [];

        selection.forEach(id => {
            const backup = backupMap[parseInt(id, 10)];
            if (backup === undefined
                || backup.compute_resource_vm === null
                || backup.compute_resource_vm.deleted_at === null
                || backup.status !== BackupStatus.CREATED
            ) {
                return;
            }

            const serverId = backup.compute_resource_vm.id;
            backupMapOfDeletedServers[serverId] = backupMapOfDeletedServers[serverId] === undefined
                ? 1
                : backupMapOfDeletedServers[serverId] + 1;
        });

        return backups.some(backup => backup.compute_resource_vm !== null
            && backup.compute_resource_vm.full_backup_count === backupMapOfDeletedServers[backup.compute_resource_vm.id]);
    }, [selection, backups]);

    const handleSelectionChange = (indexes: string[]) => {
        setSelection(indexes);
    };

    const handleBatchDelete = async () => {
        await removeBackups(selection.map(id => parseInt(id, 10)));
        setSelection([]);
    };

    const handleComputeResourceFilterChange = async (option: ValueType<ISelectRequiredOption>) => {
        setFilters({
            ...filters,
            compute_resource_id: option ? parseFloat((option as ISelectRequiredOption).value) : 0,
        });
        setOptions({
            ...options,
            compute_resource_id: option,
        });
    };

    const handleComputeResourceVmFilterChange = async (option: ValueType<ISelectRequiredOption>) => {
        setFilters({
            ...filters,
            compute_resource_vm_id: option ? parseFloat((option as ISelectRequiredOption).value) : 0,
        });
        setOptions({
            ...options,
            compute_resource_vm_id: option,
        });
    };

    const handleUserFilterChange = async (option: ValueType<ISelectRequiredOption>) => {
        setFilters({
            ...filters,
            creator_id: option ? parseFloat((option as ISelectRequiredOption).value) : 0,
        });
        setOptions({
            ...options,
            creator_id: option,
        });
    };

    const handleBackupNodeFilterChange = async (option: ValueType<ISelectRequiredOption>) => {
        setFilters({
            ...filters,
            backup_node_id: option ? parseFloat((option as ISelectRequiredOption).value) : 0,
        });
        setOptions({
            ...options,
            backup_node_id: option,
        });
    };

    const handleRemovedFilterChange = async (option: ValueType<ISelectRequiredOption>) => {
        setFilters({
            ...filters,
            only_for_deleted_servers: option ? (option as ISelectRequiredOption).value : '',
        });
        setOptions({
            ...options,
            only_for_deleted_servers: option,
        });
    };

    const handleFilterToggle = () => {
        toggleFilters(!areFiltersOpened);
    };

    const handleRestore = (backup: IBackupResponse) => {
        setIsRestoreDialogOpen(true);
        setCurrentBackup(backup);
    };

    const handleCloseRestoreDialog = () => {
        setIsRestoreDialogOpen(false);
        setCurrentBackup(undefined);
    };

    const loadPaginated = React.useCallback(
        (page: number, cancelToken?: CancelTokenSource) => getBackups({ filters, page }, cancelToken),
        [getBackups, filters]
    );

    const isFirstLoading = useIsFirstLoading(isLoading);

    React.useEffect(() => {
        getNextScheduledDate();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => {
        getBackups({ filters });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filters]);

    return (
        <>
            <PageHeader
                isButtonShown={false}
                title={<Translate content="backups.header"/>}
            />
            <Loader isLoading={isFirstLoading}>
                <BackupsCardContainer>
                    <GlobalVsBackupSettingsCard />
                </BackupsCardContainer>
                <BackupsTable
                    columns={{
                        [BackupsTableColumns.ID]: { width: '1%' },
                        [BackupsTableColumns.STATUS]: { width: '10%' },
                        [BackupsTableColumns.CREATED_AT]: { width: '10%' },
                        [BackupsTableColumns.CREATION_METHOD]: { width: '1%' },
                        [BackupsTableColumns.SIZE]: { width: '1%' },
                        [BackupsTableColumns.DISK]: { width: '1%' },
                        [BackupsTableColumns.COMPUTE_RESOURCE]: { width: '15%' },
                        [BackupsTableColumns.COMPUTE_RESOURCE_VM]: { width: '15%' },
                        [BackupsTableColumns.BACKUP_NODE]: { width: '15%' },
                        [BackupsTableColumns.BACKUP_TYPE]: { width: '15%' },
                        [BackupsTableColumns.CREATOR]: { width: '15%' },
                        [BackupsTableColumns.ACTIONS]: { width: '1%' },
                    }}
                    isFirstLoading={isFirstLoading}
                    loadPaginated={loadPaginated}
                    filters={filters}
                    toolbar={(
                        <BackupOperationContainer>
                            {hasLastFullBackup ? (
                                <ButtonWithInputConfirmation
                                    data-cy={ACTIONS.DELETE}
                                    disabled={selection.length === 0}
                                    isLoading={isBackupBatchRemoving}
                                    ghost={false}
                                    confirmation={DELETE_CONFIRMATION}
                                    placement="bottom"
                                    translations={{
                                        title: (
                                            <Translate content="backup.batchButtonWithConfirmation.title" />
                                        ),
                                        label: (
                                            <Translate
                                                content="backup.batchButtonWithConfirmation.forDeletedServer.label"
                                                params={{ confirmation: <CopyText isInline={true}>{DELETE_CONFIRMATION}</CopyText> }}
                                            />
                                        ),
                                        text: (
                                            <Translate
                                                content="backup.batchButtonWithConfirmation.forDeletedServer.text"
                                                params={{ count: <Text bold>{selection.length}</Text> }}
                                            />
                                        ),
                                        button: (
                                            <Translate content="backup.batchButtonWithConfirmation.button" />
                                        ),
                                        confirmationButton: (
                                            <Translate content="backup.batchButtonWithConfirmation.confirmationButton" />
                                        ),
                                        tooltip: (
                                            <Translate content="backup.batchButtonWithConfirmation.tooltip" />
                                        ),
                                    }}
                                    icon={ICONS.RECYCLE}
                                    handleConfirm={handleBatchDelete}
                                />
                            ) : (
                                <ButtonWithConfirmation
                                    data-cy={ACTIONS.DELETE}
                                    disabled={selection.length === 0}
                                    isLoading={isBackupBatchRemoving}
                                    confirmationButtonGhost={false}
                                    confirmationButtonText={<Translate content="backups.batchDelete" />}
                                    translations={{
                                        button: (
                                            <Translate content="backup.batchButtonWithConfirmation.button" />
                                        ),
                                        title: (
                                            <Translate content="backup.batchButtonWithConfirmation.title" />
                                        ),
                                        tooltip: (
                                            <Translate content="backup.batchButtonWithConfirmation.tooltip" />
                                        ),
                                    }}
                                    handleConfirm={handleBatchDelete}
                                    icon={ICONS.RECYCLE}
                                />
                            )}
                            <Popover
                                onClose={handleFilterToggle}
                                canCloseOnOutsideClick={false}
                                visible={areFiltersOpened}
                                target={(
                                    <Button
                                        icon={ICONS.FILTER}
                                        onClick={handleFilterToggle}
                                        data-cy={ACTIONS.FILTERS}
                                    >
                                        <Translate content="backups.filters" />
                                    </Button>
                                )}
                                placement="left-bottom"
                            >
                                <Section title={<Translate content="backups.filters" />}>
                                    <Form
                                        id="filterForm"
                                        footerClassName="hidden"
                                        submitButton={false}
                                        cancelButton={false}
                                        applyButton={false}
                                        vertical={true}
                                    >
                                        <VerticalFiltersContainer>
                                            <ComputeResourceFilter
                                                onChange={handleComputeResourceFilterChange}
                                                value={options?.compute_resource_id}
                                            />
                                            <ComputeResourceVmFilter
                                                onChange={handleComputeResourceVmFilterChange}
                                                value={options?.compute_resource_vm_id}
                                            />
                                            <ForDeletedServersFilter
                                                onChange={handleRemovedFilterChange}
                                                value={options?.only_for_deleted_servers}
                                            />
                                            <BackupNodeFilter
                                                onChange={handleBackupNodeFilterChange}
                                                value={options?.backup_node_id}
                                            />
                                            <UserFilter
                                                onChange={handleUserFilterChange}
                                                value={options?.creator_id}
                                            />
                                        </VerticalFiltersContainer>
                                    </Form>
                                </Section>
                            </Popover>
                        </BackupOperationContainer>
                    )}
                    isServerProcessing={isServerProcessing}
                    withSelection={true}
                    selection={selection}
                    onSelectionChange={handleSelectionChange}
                    onDeletedServerRestore={handleRestore}
                    emptyView={
                        <EmptyView
                            title="backups.emptyView.title"
                            description="backups.emptyView.description"
                            icon={ICONS.BACKUP}
                        />
                    }
                />
            </Loader>
            <Dialog
                heading={
                    <Translate content="backups.restoreForm.title" />
                }
                closeHandler={handleCloseRestoreDialog}
                isOpen={isRestoreDialogOpen}
                size={SIZE.XS}
            >
                <RestoreForm onSubmit={handleCloseRestoreDialog} backup={currentBackup!} />
            </Dialog>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    backups: state.backup.list.data,
    isServerProcessing: state.computeResourceVm.item.is_processing,
    isBackupBatchRemoving: state.app.loadingFlags.has(LOADING_FLAGS.BACKUP_BATCH_REMOVE),
    isLoading:
        state.app.loadingFlags.has(LOADING_FLAGS.BACKUP_LIST) ||
        state.app.loadingFlags.has(LOADING_FLAGS.BACKUP_NEXT_SCHEDULED_DATE) ||
        state.app.loadingFlags.has(LOADING_FLAGS.APP_SETTINGS),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    backupActions: bindActionCreators(backupActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Backups);
