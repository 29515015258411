// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import {
    ITaskResponse,
    TASK_STATUS,
} from 'common/api/resources/Task';

export enum CHANGE_TYPE {
    FEATURE = 'feature',
    IMPROVEMENT = 'improvement',
    BUGFIX = 'bugfix',
    ATTENTION = 'attention',
}

export enum UPDATE_STATUS {
    UP_TO_DATE = 'up to date',
    DOWNLOADING_UPDATE = 'downloading update',
    UPDATE_DOWNLOAD_FAILED = 'failed to download update',
    READY_FOR_UPDATE = 'ready for update',
    UPDATING = 'updating',
    UPDATE_FAILED = 'update failed',
}

export interface IChange {
    type: string;
    text: string;
}

export interface IVersion {
    version: string;
    date: string;
    changelog: IChange[];
}

export interface IComputeResourcesUpgradeState {
    total: number;
    done: number;
    upgrading: boolean;
}

export interface IUpdateResponse {
    version: string;
    current_version: string;
    versions: IVersion[];
    status: UPDATE_STATUS;
    compute_resources: IComputeResourcesUpgradeState;
    scheduled_date: string | null;
    task: ITaskResponse | null;
}

export interface IUpdateUpdateData {
    data: IUpdateResponse;
}

export interface IUpgradeResult {
    result: TASK_STATUS;
    upgrading: boolean;
}
