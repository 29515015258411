// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { createCustomAction } from 'typesafe-actions';
import { Dispatch } from 'redux';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import {
    create,
    get,
    paginateList,
    remove,
    update,
} from 'common/actions/actionsWrapper';
import { IPaginateApiResponse } from 'common/api/resources/Response';
import * as types from 'admin/imagePreset/constants/types';
import {
    IImagePresetListRequest,
    IImagePresetRequest,
    IImagePresetResponse,
} from 'common/api/resources/ImagePreset/model';
import { imagePreset } from 'common/api/resources/ImagePreset/api';

export const setImagePresetList = createCustomAction(
    types.SET_IMAGE_PRESET_LIST,
    (data: IPaginateApiResponse<IImagePresetResponse[]>) => ({ payload: data })
);
export const setImagePresetItem = createCustomAction(
    types.SET_IMAGE_PRESET_ITEM,
    (data: IImagePresetResponse) => ({ payload: data })
);
export const unsetImagePresetItem = createCustomAction(types.UNSET_IMAGE_PRESET_ITEM);
export const addImagePresetItem = createCustomAction(
    types.ADD_IMAGE_PRESET_ITEM,
    (data: IImagePresetResponse) => ({ payload: data })
);
export const updateImagePresetItem = createCustomAction(
    types.UPDATE_IMAGE_PRESET_ITEM,
    (data: IImagePresetResponse) => ({ payload: data })
);
export const removeImagePresetItem = createCustomAction(
    types.REMOVE_IMAGE_PRESET_ITEM,
    (id: number) => ({ payload: id })
);
export const setImagePresetItemIsDeleting = createCustomAction(
    types.SET_IMAGE_PRESET_ITEM_IS_DELETING,
    (id: number, isDeleting: boolean) => ({ payload: { id, is_deleting: isDeleting } })
);

export const getImagePresets = (request?: IImagePresetListRequest) => async (dispatch: Dispatch) => {
    const apiCall = () => imagePreset.list(request);

    return await paginateList({
        dispatch,
        loadingFlag: LOADING_FLAGS.IMAGE_PRESET_LIST,
        action: setImagePresetList,
        apiCall,
    });
};

export const createImagePreset = (data: IImagePresetRequest) => async (dispatch: Dispatch) => await create({
    data,
    dispatch,
    loadingFlag: LOADING_FLAGS.IMAGE_PRESET_ITEM_SAVE,
    action: addImagePresetItem,
    apiCall: imagePreset.create,
    translations: {
        success: 'imagePreset.toasts.saved',
    },
});

export const getImagePreset = (id: number) => async (dispatch: Dispatch) => await get(id, {
    dispatch,
    apiCall: imagePreset.item,
    action: setImagePresetItem,
    loadingFlag: LOADING_FLAGS.IMAGE_PRESET_ITEM,
});

export const updateImagePreset = (id: number, data: IImagePresetRequest) => async (dispatch: Dispatch) => await update(id, {
    data,
    dispatch,
    apiCall: imagePreset.update,
    action: updateImagePresetItem,
    loadingFlag: LOADING_FLAGS.IMAGE_PRESET_ITEM_SAVE,
    translations: {
        success: 'imagePreset.toasts.saved',
    },
});

export const removeImagePreset = (id: number) => async (dispatch: Dispatch) => await remove(id, {
    dispatch,
    apiCall: imagePreset.remove,
    setLoadingAction: setImagePresetItemIsDeleting,
    action: removeImagePresetItem,
    loadingFlag: LOADING_FLAGS.IMAGE_PRESET_ITEM_REMOVE,
    translations: {
        success: 'imagePreset.toasts.deleted',
    },
});
