// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { Tooltip } from '@plesk/ui-library';
import styled from 'styled-components';

const CutTitleText = styled.span`
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export interface ICutTitleProps extends React.HTMLProps<HTMLSpanElement> {
    title: string;
    withTooltip?: boolean;
}

export const CutTitle: React.FC<ICutTitleProps> = ({
    title,
    style,
    withTooltip = true,
}) => {
    const cutTitleText = (<CutTitleText style={style}>{title}</CutTitleText>);
    return withTooltip ? (<Tooltip title={title}>{cutTitleText}</Tooltip>) : cutTitleText;
};
