// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { RootState } from 'admin/core/store';
import { connect } from 'react-redux';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import List from 'common/components/List/List';
import { EmptyView } from 'common/components/EmptyView/EmptyView';
import {
    ICONS,
    SIZE,
} from 'common/constants';
import * as React from 'react';
import { useIsFirstLoading } from 'common/hooks/useIsFirstLoading';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import {
    Action,
    Switch,
    Tooltip,
    Translate,
} from '@plesk/ui-library';
import {
    getActionColumnProps,
    reloadListData,
} from 'common/helpers/list';
import { Dialog } from 'common/components/Dialog/Dialog';
import { Loader } from 'common/components';
import WebhookForm from 'admin/eventHandler/containers/WebhookForm/WebhookForm';
import {
    ActionType,
    EventName,
    IEventHandlerResponse,
    IWebhookParams,
} from 'common/api/resources/EventHandler';
import { hasPermission } from 'common/modules/permission/selectors';
import { PERMISSION_LIST } from 'common/modules/permission/constants';
import { StyledActions } from 'common/components/Actions/Styles';
import { dataCySelector } from 'common/tests/selectors';
import ButtonWithConfirmation from 'common/components/ButtonWithConfirmation';
import * as eventHandlerActions from 'admin/eventHandler/actions';
import {
    eventNameTranslations,
    TABLE_ACTIONS,
} from 'admin/eventHandler/constants';
import PopoverList from 'common/components/PopoverList/PopoverList';

interface IWebhooksTableProps {
    openWebhooksDialog: () => void;
    closeWebhooksDialog: () => void;
    isDialogOpened: boolean;
}

export type WebhooksTableProps =
    IWebhooksTableProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

const columns = [{
    key: 'colId',
    title: <Translate content="eventHandler.list.id" />,
    width: '1%',
}, {
    key: 'colName',
    width: '15%',
    title: <Translate content="eventHandler.list.name" />,
    cellProps: {
        className: 'cell-bold',
    },
}, {
    key: 'colUrl',
    truncate: true,
    title: <Translate content="eventHandler.webhooks.list.url" />,
}, {
    key: 'colEvents',
    title: <Translate content="eventHandler.list.events" />,
}, {
    width: '1%',
    key: 'colEnabled',
    title: <Translate content="eventHandler.list.enabled" />,
    cellProps: {
        style: { textAlign: 'center' },
    },
}, getActionColumnProps({ width: '5%' }),
];

export const WebhooksTable: React.FC<WebhooksTableProps> = ({
    items,
    item,
    isLoading,
    isLoadingItem,
    canManageEventHandlers,
    openWebhooksDialog,
    closeWebhooksDialog,
    isDialogOpened,
    eventHandlerActions: {
        getEventHandlers,
        getEventHandler,
        patchEventHandler,
        removeEventHandler,
    },
}) => {
    const [blockIsEnabled, setBlockIsEnabled] = React.useState(false);
    const isFirstLoading = useIsFirstLoading(isLoading);

    const loadPaginated = (page: number) => getEventHandlers({ filters: { action_type: ActionType.WEBHOOK }, page });

    const handleToggleIsEnabled = (webhook: IEventHandlerResponse<IWebhookParams>) => async () => {
        if (blockIsEnabled && webhook.is_loading) {
            return;
        }

        try {
            setBlockIsEnabled(true);
            await patchEventHandler(webhook.id, { is_enabled: !webhook.is_enabled });
        } finally {
            setBlockIsEnabled(false);
        }
    };

    const handleEdit = (id: number) => () => {
        getEventHandler(id);
        openWebhooksDialog();
    };

    const handleRemove = (id: number) => async () => {
        await removeEventHandler(id);
        reloadListData(items, loadPaginated);
    };

    const renderEvent = (eventName: EventName) => (<Translate content={eventNameTranslations[eventName]} />);

    const renderActions = (webhook: IEventHandlerResponse<IWebhookParams>) => {
        if (!canManageEventHandlers) {
            return null;
        }

        return (
            <StyledActions>
                <Tooltip title={<Translate content="eventHandler.webhooks.tooltip.edit"/>}>
                    <Action
                        icon={ICONS.PENCIL}
                        className="action-icon"
                        onClick={handleEdit(webhook.id)}
                        data-cy={dataCySelector(webhook.id, TABLE_ACTIONS.EDIT)}
                    />
                </Tooltip>
                <ButtonWithConfirmation
                    isLoading={webhook.is_deleting}
                    translations={{
                        title: (
                            <Translate content="eventHandler.webhooks.confirmationRemove.title" />
                        ),
                        button: (
                            <Translate content="eventHandler.webhooks.confirmationRemove.button" />
                        ),
                        tooltip: (
                            <Translate content="eventHandler.webhooks.confirmationRemove.tooltip" />
                        ),
                    }}
                    handleConfirm={handleRemove(webhook.id)}
                    data-cy={dataCySelector(webhook.id, TABLE_ACTIONS.REMOVE)}
                    icon={ICONS.RECYCLE}
                />
            </StyledActions>
        );
    };

    const data = items.data.map((webhook: IEventHandlerResponse<IWebhookParams>) => ({
        colId: webhook.id,
        colName: webhook.name,
        colUrl: webhook.action_params.url,
        colEvents: (
            <PopoverList
                items={webhook.events}
                render={renderEvent}
                keyExtractor={w => w.toString()}
            />
        ),
        colEnabled: (
            <Switch
                checked={webhook.is_enabled}
                onChange={handleToggleIsEnabled(webhook)}
                loading={webhook.is_loading && blockIsEnabled}
                data-cy={dataCySelector(webhook.id, TABLE_ACTIONS.ENABLE)}
            />
        ),
        colActions: renderActions(webhook),
        key: webhook.id.toString(),
    }));

    React.useEffect(() => {
        getEventHandlers({ filters: { action_type: ActionType.WEBHOOK } });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <List
                isLoading={isLoading}
                isFirstLoading={isFirstLoading}
                emptyView={(
                    <EmptyView
                        title="eventHandler.webhooks.list.emptyView.title"
                        description="eventHandler.webhooks.list.emptyView.description"
                        buttonText="eventHandler.webhooks.list.emptyView.buttonText"
                        icon={ICONS.WEB}
                        onButtonClick={openWebhooksDialog}
                    />
                )}
                columns={columns}
                data={data}
                loadItems={loadPaginated}
                meta={items.meta}
            />
            <Dialog
                heading={<Translate content={
                    item.id === 0 ? 'eventHandler.webhooks.dialog.titleAdd' : 'eventHandler.webhooks.dialog.titleEdit'
                } />}
                closeHandler={closeWebhooksDialog}
                isOpen={isDialogOpened}
                size={SIZE.XS}
            >
                <Loader isLoading={isLoadingItem} center={false}>
                    <WebhookForm onSubmit={closeWebhooksDialog}/>
                </Loader>
            </Dialog>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    items: state.eventHandler.list,
    item: state.eventHandler.item,
    isLoading: state.app.loadingFlags.has(LOADING_FLAGS.EVENT_HANDLER_LIST),
    isLoadingItem: state.app.loadingFlags.has(LOADING_FLAGS.EVENT_HANDLER_ITEM),
    canManageEventHandlers: hasPermission(state, PERMISSION_LIST.MANAGE_EVENT_HANDLERS),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    eventHandlerActions: bindActionCreators(eventHandlerActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(WebhooksTable);
