// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { Translate } from '@plesk/ui-library';
import DisableEnableSegmentedControl,
{ IDisableEnableSegmentedControlProps } from 'common/components/DisabledEnabledSegmentedControl';

export interface IVZTUNTAPSegmentedControlProps extends
    Omit<IDisableEnableSegmentedControlProps, 'confirmationPopoverTitle'>{
}

const VZTUNTAPSegmentedControl: React.FC<IVZTUNTAPSegmentedControlProps> = (props) => (
    <DisableEnableSegmentedControl
        confirmationPopoverTitle={<Translate content="components.vzTUNTAPSegmentedControl.confirmationPopoverTitle" />}
        {...props}
    />
);

export default VZTUNTAPSegmentedControl;