// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import {
    ActionType,
    getType,
} from 'typesafe-actions';
import * as updateActions from 'admin/update/actions';
import { initialTaskState } from 'admin/task/reducer';
import {
    UPDATE_STATUS,
    CHANGE_TYPE,
    IVersion,
    IUpdateResponse,
} from 'common/api/resources/Update';
import { TASK_STATUS } from 'common/api/resources/Task';

export type UpdateState = IUpdateResponse;
export type UpdateAction = ActionType<typeof updateActions>;

export const initialVersionState: IVersion = {
    version: '1.0.0-1',
    date: '01-01-2000 12.00',
    changelog: [
        {
            type: CHANGE_TYPE.FEATURE,
            text: 'extra bugs',
        },
    ],
};

export const initialUpdateState: IUpdateResponse = {
    version: '',
    current_version: '',
    versions: [],
    status: UPDATE_STATUS.UP_TO_DATE,
    scheduled_date: null,
    compute_resources: {
        total: 0,
        done: 0,
        upgrading: false,
    },
    task: initialTaskState,
};

export default (state: UpdateState = initialUpdateState, action: UpdateAction): UpdateState => {
    switch (action.type) {
    case getType(updateActions.setState): {
        return action.payload;
    }
    case getType(updateActions.addUpgradeResult): {
        const computeResources = { ...state.compute_resources };

        if (action.payload.result === TASK_STATUS.DONE) {
            computeResources.done++;
        }
        computeResources.upgrading = action.payload.upgrading;

        return { ...state, compute_resources: computeResources };
    }
    default:
        return state;
    }
};
