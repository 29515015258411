// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { createCustomAction } from 'typesafe-actions';
import * as types from 'common/modules/backup/constants/types';
import { IPaginateApiResponse } from 'common/api/resources/Response';
import {
    backups,
    BackupStatus,
    IBackupListRequest,
    IBackupResponse,
    IRestoreServerRequest,
} from 'common/api/resources/Backup';
import { Dispatch } from 'redux';
import { IAppState } from 'admin/core/store';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import {
    loadOnScroll,
    removeBatch,
} from 'common/actions/actionsWrapper';
import {
    setIsLoading,
    unsetIsLoading,
} from 'common/modules/app/loadingFlags/actions';
import { HTTP_CODES } from 'common/api/constants';
import { updateVm } from 'common/modules/computeResourceVm/actions';
import { ComputeResourceVmStatus } from 'common/api/resources/ComputeResourceVm';
import { CancelTokenSource } from 'axios';

export const setList = createCustomAction(
    types.SET_BACKUP_LIST,
    (data: IPaginateApiResponse<IBackupResponse[]>) => ({ payload: data })
);
export const addItem = createCustomAction(
    types.ADD_BACKUP_ITEM,
    (data: IBackupResponse) => ({ payload: data })
);
export const unsetList = createCustomAction(types.UNSET_BACKUPS);
export const appendList = createCustomAction(
    types.APPEND_BACKUPS,
    (data: IPaginateApiResponse<IBackupResponse[]>) => ({ payload: data })
);
export const updateItem = createCustomAction(
    types.UPDATE_BACKUP,
    (data: Partial<IBackupResponse>) => ({ payload: data })
);
export const updateItemProgress = createCustomAction(
    types.UPDATE_BACKUP_PROGRESS,
    (id: number, progress: number) => ({ payload: { id, progress } })
);
export const removeBackupItems = createCustomAction(
    types.REMOVE_BACKUP_ITEMS,
    (ids: number[]) => ({ payload: ids })
);
export const setBackupItemIsDeleting = createCustomAction(
    types.SET_BACKUP_ITEM_IS_DELETING,
    (id: number, isDeleting: boolean) => ({ payload: { id, isDeleting } })
);

export const setNextScheduledBackupDate = createCustomAction(
    types.SET_NEXT_SCHEDULED_BACKUP_DATE,
    (date: string) => ({ payload: date })
);

export const getBackups = (request?: IBackupListRequest, cancelToken?: CancelTokenSource) => async(dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.BACKUP_LIST));

    try {
        const result = await backups.list(request, cancelToken);
        if (result.status === HTTP_CODES.OK) {
            dispatch(setList(result.data));
        }

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.BACKUP_LIST));
    }
};

export const loadBackupsOnScroll = () => async(dispatch: Dispatch, getState: () => IAppState) => {
    const state = getState();
    const nextPage = state.backup.list.links.next;
    const isLoading = state.app.loadingFlags.has(LOADING_FLAGS.BACKUP_LIST_INFINITY_SCROLL);

    return await loadOnScroll({
        nextPage,
        isLoading,
        dispatch,
        action: appendList,
        loadingFlag: LOADING_FLAGS.BACKUP_LIST_INFINITY_SCROLL,
    });
};

export const deleteBackup = (id: number) => async(dispatch: Dispatch) => {
    dispatch(setBackupItemIsDeleting(id, true));
    try {
        const result = await backups.remove(id);

        dispatch(removeBackupItems(result.data));
    } finally {
        dispatch(setBackupItemIsDeleting(id, false));
    }
};

export const restoreFromBackup = (id: number) => async(dispatch: Dispatch) => {
    const result = await backups.restore(id);

    if (result.data.data.compute_resource_vm_id) {
        dispatch(updateVm({
            id: result.data.data.compute_resource_vm_id,
            is_processing: true,
            progress: 0,
            status: ComputeResourceVmStatus.BACKUP_RESTORING,
        }));
    }

    dispatch(updateItem({
        id,
        status: BackupStatus.RESTORING,
        backup_progress: 0,
    }));
};

export const restoreDeletedServerFromBackup = (id: number, data: IRestoreServerRequest) => async(dispatch: Dispatch) => {
    await backups.restoreDeletedServer(id, data);

    dispatch(updateItem({
        id,
        status: BackupStatus.RESTORING,
        backup_progress: 0,
    }));
};

export const removeBackups = (ids: number[]) => async (dispatch: Dispatch) => await removeBatch(ids, {
    dispatch,
    apiCall: backups.removeBatch,
    action: removeBackupItems,
    loadingFlag: LOADING_FLAGS.BACKUP_BATCH_REMOVE,
    translations: {
        success: 'backup.toasters.batchRemoved',
    },
});

export const getNextScheduledDate = () => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.BACKUP_NEXT_SCHEDULED_DATE));

    try {
        const result = await backups.getNextScheduledDate();
        if (result.status === HTTP_CODES.OK) {
            dispatch(setNextScheduledBackupDate(result.data.data.next_scheduled_date));
        }

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.BACKUP_NEXT_SCHEDULED_DATE));
    }
};
