// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

export const APPEND_API_TOKEN_ITEM = 'api_tokens/APPEND_API_TOKEN_ITEM';
export const UPDATE_API_TOKEN_ITEM = 'api_tokens/UPDATE_API_TOKEN_ITEM';
export const APPEND_API_TOKENS = 'api_tokens/APPEND_API_TOKENS';
export const SET_API_TOKEN_LIST = 'api_tokens/SET_API_TOKEN_LIST';
export const SET_API_TOKEN_LIST_ALL = 'api_tokens/SET_API_TOKEN_LIST_ALL';
export const REMOVE_API_TOKEN_ITEM = 'api_tokens/REMOVE_API_TOKEN_ITEM';
export const SET_API_TOKEN_ITEM = 'api_tokens/SET_API_TOKEN_ITEM';
export const UNSET_API_TOKEN_ITEM = 'api_tokens/UNSET_API_TOKEN_ITEM';
export const SET_IS_LOADING = 'api_tokens/SET_IS_LOADING';
export const UNSET_IS_LOADING = 'api_tokens/UNSET_IS_LOADING';
