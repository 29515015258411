// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { FC } from 'react';
import { Translate } from '@plesk/ui-library';
import {
    ISwitchWithConfirmationProps,
    SwitchWithConfirmation,
} from 'common/components/SwitchWithConfirmation';

export interface ISuspendResumeSwitchControlProps extends Omit<
    ISwitchWithConfirmationProps,
    'confirmOnDisable' | 'confirmOnEnable' | 'checked' | 'value'
> {
    value: boolean;
}

const SuspendResumeControl: FC<ISuspendResumeSwitchControlProps> = ({
    value,
    ...props
}) => (
    <SwitchWithConfirmation
        {...props}
        checked={value}
        confirmOnDisable={{
            enabled: true,
            translations: {
                title: <Translate content="servers.controls.suspendResume.confirmation.resume.title" />,
                text: <Translate content="servers.controls.suspendResume.confirmation.resume.text" />,
                button: <Translate content="servers.controls.suspendResume.confirmation.resume.button" />,
            },
        }}
        confirmOnEnable={{
            enabled: true,
            translations: {
                title: <Translate content="servers.controls.suspendResume.confirmation.suspend.title" />,
                text: <Translate content="servers.controls.suspendResume.confirmation.suspend.text" />,
                button: <Translate content="servers.controls.suspendResume.confirmation.suspend.button" />,
            },
        }}
    >
        <Translate content="servers.controls.suspendResume.label" />
    </SwitchWithConfirmation>
);

export default SuspendResumeControl;
