// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { LoaderWrapper } from 'common/components/Loader/Styles';
import { ContentLoader } from '@plesk/ui-library';
import { LOADER } from 'common/components/Loader/test';

interface ILoaderProps extends React.HTMLProps<HTMLElement> {
    isLoading: boolean;
    center?: boolean;
    indicatorSize?: string;
    text?: string | JSX.Element;
}

export const Loader: React.FC<ILoaderProps> = ({
    center = true,
    indicatorSize,
    isLoading,
    text,
    children,
}) => {
    if (isLoading) {
        return (
            <LoaderWrapper center={center} data-cy={LOADER} indicatorSize={indicatorSize}>
                <ContentLoader text={text} />
            </LoaderWrapper>
        );
    }

    return <>{children}</>;
};

