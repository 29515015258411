// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import {
    api,
    IApiResponse,
    IPaginateApiResponse,
} from 'common/api/resources/Response';
import {
    IRoleRequest,
    IRoleResponse,
} from 'common/api/resources/Role/model';
import { QueryBuilder } from 'common/api/resources/Request/QueryBuilder';
import { IPaginatedWithSearch } from 'common/api/resources/Request/request';

export const roles = {
    create: (data: IRoleRequest) => api.post<IApiResponse<IRoleResponse>>('roles', data),
    list: (params?: IPaginatedWithSearch) => api.get<IPaginateApiResponse<IRoleResponse[]>>('roles', {
        params: new QueryBuilder(params).build(),
    }),
    item: (id: number) => api.get<IApiResponse<IRoleResponse>>(`roles/${id}`),
    update: (id: number, data: IRoleRequest) => api.put<IApiResponse<IRoleResponse>>(`roles/${id}`, data),
    remove: (id: number) => api.delete(`roles/${id}`),
};
