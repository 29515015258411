// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { Loader } from 'common/components';
import { connect } from 'react-redux';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { RootState } from 'admin/core/store';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import * as applicationActions from 'common/modules/application/actions';
import { PageHeader } from 'admin/common/components/PageHeader/PageHeader';
import InfiniteScroll from 'common/components/InfinityScroll/InfinityScroll';
import { StyledTable } from 'common/components/styles/StyledTable';
import { StyledActions } from 'common/components/Actions/Styles';
import { IApplicationResponse } from 'common/api/resources/Application';
import {
    Icon,
    List,
    Switch,
    Toolbar,
    ToolbarGroup,
    Tooltip,
    Translate,
} from '@plesk/ui-library';
import ButtonWithConfirmation from 'common/components/ButtonWithConfirmation';
import { SetAsDefaultAction } from 'admin/common/components/SetAsDefaultAction';
import { dataCySelector } from 'common/tests/selectors';
import { EmptyView } from 'common/components/EmptyView/EmptyView';
import { RouteComponentProps } from 'react-router';
import CopyText from 'common/containers/CopyText/CopyText';
import { TABLE_ACTIONS } from 'admin/application/constants/tests';
import Action from 'common/components/Action/Action';
import CellIcon from 'admin/icon/components/CellIcon/CellIcon';
import { IListReorderedItem } from 'common/reducers/list';
import { getNewPosition } from 'common/helpers/position';
import { getActionColumnProps } from 'common/helpers/list';
import { pathTo } from 'common/helpers/core';
import { Link } from 'react-router-dom';

export type ApplicationProps =
    RouteComponentProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

const columns = [{
    key: 'colId',
    width: '1%',
    title: <Translate content="application.list.id" />,
}, {
    key: 'colName',
    title: <Translate content="application.list.name" />,
    cellProps: {
        className: 'cell-bold',
    },
}, {
    key: 'colUrl',
    truncate: true,
    title: <Translate content="application.list.url" />,
}, {
    key: 'colCloudInitVersion',
    title: <Translate content="application.list.cloudInitVersion" />,
}, {
    width: '1%',
    key: 'colVisibility',
    title: <Translate content="application.list.visibility" />,
    cellProps: {
        style: { textAlign: 'center' },
    },
}, getActionColumnProps(),
];

const ROUTE_APPLICATIONS_NEW = 'applications/new';

export const Application: React.FC<ApplicationProps> = ({
    history,
    applicationActions: {
        loadApplicationsOnScroll,
        getApplications,
        removeApplication,
        updateDefaultApplication,
        updateApplicationVisibility,
        removeApplications,
        updateApplicationPosition,
    },
    list,
    hasMore,
    loadingFlags: {
        isLoadingList,
        isBatchRemoving,
    },
    reorderable,
}) => {
    const [selection, setSelection] = React.useState<string[]>([]);
    const [blockDefault, setBlockDefault] = React.useState(false);
    const [blockVisibility, setBlockVisibility] = React.useState(false);

    React.useEffect(() => {
        getApplications();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleClone = (sourceId: number) => () =>
        history.push(pathTo(ROUTE_APPLICATIONS_NEW), { sourceId });
    const handleRemove = (id: number) => () => removeApplication(id);
    const handleSetDefault = (application: IApplicationResponse) => async() => {
        if (blockDefault) {
            return;
        }

        try {
            setBlockDefault(true);
            await updateDefaultApplication(application.id, { is_default: !application.is_default });
        } finally {
            setBlockDefault(false);
        }
    };
    const handleToggleVisibility = (application: IApplicationResponse) => async() => {
        if (blockVisibility && application.is_loading) {
            return;
        }

        try {
            setBlockVisibility(true);
            await updateApplicationVisibility(application.id, { is_visible: !application.is_visible });
        } finally {
            setBlockVisibility(false);
        }
    };
    const handleRemoveSelected = async() => {
        try {
            await removeApplications(selection.map(id => parseInt(id, 10)));

            setSelection([]);
        } catch (e) {
            throw e;
        }
    };

    const handleDragEnd = async (reorderData: IListReorderedItem) => {
        if (list[reorderData.oldIndex] !== undefined) {
            const newPos = getNewPosition(reorderData.oldIndex, reorderData.newIndex, list);

            await updateApplicationPosition(list[reorderData.oldIndex].id, { position: newPos });
        }
    };

    const data = list.map((application, index) => {
        const actionsEl = (
            <StyledActions key={index}>
                <Tooltip title={<Translate content="application.tooltip.download" />}>
                    <Action
                        icon="arrow-down-tray"
                        className="action-icon"
                        href={application.url}
                        target="_blank"
                    />
                </Tooltip>
                <SetAsDefaultAction
                    title={<Translate content="application.tooltip.setAsDefault"/>}
                    isDefault={application.is_default}
                    isLoading={application.is_loading && blockDefault}
                    onClick={handleSetDefault(application)}
                    data-cy={dataCySelector(application.id, TABLE_ACTIONS.DEFAULT)}
                />
                <Tooltip title={<Translate content="application.tooltip.clone" />}>
                    <Action
                        data-cy={dataCySelector(application.id, TABLE_ACTIONS.CLONE)}
                        icon={<Icon name="clone" />}
                        className="action-icon"
                        onClick={handleClone(application.id)}
                    />
                </Tooltip>
                <Tooltip title={<Translate content="application.tooltip.edit"/>}>
                    <Action
                        data-cy={dataCySelector(application.id, TABLE_ACTIONS.EDIT)}
                        icon={<Icon name="pencil" />}
                        className="action-icon"
                        component={Link}
                        to={pathTo(`applications/${application.id}`)}
                    />
                </Tooltip>
                <ButtonWithConfirmation
                    data-cy={dataCySelector(application.id, TABLE_ACTIONS.REMOVE)}
                    disabled={application.is_buildin}
                    isLoading={application.is_deleting}
                    withStyledButton={true}
                    translations={{
                        button: (
                            <Translate content="application.buttonWithConfirmation.button" />
                        ),
                        title: (
                            <Translate content="application.buttonWithConfirmation.title" />
                        ),
                        tooltip: (
                            <Translate content={application.is_buildin ?
                                'application.tooltip.remove.disabled' :
                                'application.tooltip.remove.enabled'
                            } />
                        ),
                    }}
                    handleConfirm={handleRemove(application.id)}
                    icon="recycle"
                />
            </StyledActions>
        );

        return {
            colId: application.id,
            colName: <CellIcon icon={application.icon} name={application.name} />,
            colUrl: (<CopyText>{application.url}</CopyText>),
            colCloudInitVersion: application.cloud_init_version,
            colVisibility: <Switch
                checked={application.is_visible}
                onChange={handleToggleVisibility(application)}
                loading={application.is_loading && blockVisibility}
                data-cy={dataCySelector(application.id, TABLE_ACTIONS.VISIBILITY)}
            />,
            colActions: actionsEl,
            disabled: application.is_buildin,
            key: application.id.toString(),
        };
    });

    return (
        <>
            <PageHeader
                title={<Translate content="application.title"/>}
                buttonText="application.addBtn"
                buttonIcon="cd"
                buttonTo={pathTo(ROUTE_APPLICATIONS_NEW)}
                isButtonShown={data.length > 0}
            />
            <StyledTable>
                {data.length > 0 && (
                    <Toolbar>
                        <ToolbarGroup title="actions">
                            <ButtonWithConfirmation
                                data-cy={TABLE_ACTIONS.BATCH_DELETE_BTN}
                                disabled={!selection.length}
                                isLoading={isBatchRemoving}
                                confirmationButtonGhost={false}
                                confirmationButtonText={<Translate content="application.removeBtn" />}
                                translations={{
                                    title: (
                                        <Translate content="application.batchButtonWithConfirmation.title" />
                                    ),
                                    button: (
                                        <Translate content="application.batchButtonWithConfirmation.button" />
                                    ),
                                    tooltip: (
                                        <Translate content="application.batchButtonWithConfirmation.tooltip" />
                                    ),
                                }}
                                handleConfirm={handleRemoveSelected}
                                icon="recycle"
                            />
                        </ToolbarGroup>
                    </Toolbar>
                )}
                <InfiniteScroll
                    loadMore={loadApplicationsOnScroll}
                    hasMore={hasMore}
                >
                    <Loader isLoading={isLoadingList}>
                        <List
                            emptyView={
                                <EmptyView
                                    title="application.emptyView.title"
                                    description="application.emptyView.description"
                                    buttonText="application.emptyView.buttonText"
                                    buttonTo={pathTo(ROUTE_APPLICATIONS_NEW)}
                                    icon="cd"
                                />
                            }
                            columns={columns}
                            data={data}
                            selection={selection}
                            onSelectionChange={setSelection}
                            reorderable={reorderable}
                            onReorderEnd={handleDragEnd}
                        />
                    </Loader>
                </InfiniteScroll>
            </StyledTable>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    list: state.application.list.data.sort((a, b) => a.position - b.position),
    hasMore: !!state.application.list.links.next,
    reorderable: true,
    loadingFlags: {
        isBatchRemoving: state.app.loadingFlags.has(LOADING_FLAGS.APPLICATION_BATCH_REMOVE),
        isLoadingList: state.app.loadingFlags.has(LOADING_FLAGS.APPLICATION_LIST) && !state.app.loadingFlags.has(LOADING_FLAGS.APPLICATION_LIST_INFINITY_SCROLL),
    },
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    applicationActions: bindActionCreators(applicationActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Application);
