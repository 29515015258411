// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';
import { Button as UiLibButton } from '@plesk/ui-library';
import { INTENT_TYPE } from 'common/constants';
import { COLORS } from 'admin/core/theme';
import { darken } from 'polished';

export const StyledButton = styled(UiLibButton)`
    ${props => props.ghost ? `
        color: ${props.intent === INTENT_TYPE.PRIMARY ? props.theme.primary_color : 'currentColor'} !important;
    ` : `
        background-color: ${props.intent === INTENT_TYPE.PRIMARY ? props.theme.primary_color : COLORS.GREY_30} !important;
    `};

    &:hover {
        ${props => props.ghost ? `
            color: ${props.intent === INTENT_TYPE.PRIMARY ? props.theme.primary_color : COLORS.GREY_100} !important;
        ` : `
            background-color: ${
    props.intent === INTENT_TYPE.PRIMARY && props.theme !== undefined && props.theme.primary_color !== undefined ?
        darken(0.03, props.theme.primary_color) :
        COLORS.GREY_40
} !important;
        `};
    }

    .pul-icon {
        ${props => props.ghost && `
            color: ${props.intent === INTENT_TYPE.PRIMARY ? props.theme.primary_color : 'currentColor'} !important;
        `};
    }
`;
