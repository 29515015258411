// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

export enum ACTIVITY_LOG_EVENTS {
    USER_CREATE_REQUESTED = 'user_create_requested',
    USER_CREATE_SUCCESS = 'user_created',
    USER_CREATE_FAILED = 'user_create_failed',
    USER_DELETE_REQUESTED = 'user_delete_requested',
    USERS_DELETED = 'users_deleted',
    USER_AUTHORIZED_SUCCESS = 'user_authorized_success',
    USER_ALLOWED_IP_ADDED = 'user_allowed_ip_added',
    USER_ALLOWED_IP_REMOVED = 'user_allowed_ip_removed',
    COMPUTE_RESOURCE_CREATE_REQUESTED = 'compute_resource_create_requested',
    COMPUTE_RESOURCE_DELETE_REQUESTED = 'compute_resource_delete_requested',
    SERVER_CREATE_REQUESTED = 'compute_resource_vm_create_requested',
    SERVER_CREATE_CREATE_SUCCESS = 'compute_resource_vm_created',
    SERVER_REINSTALLED_REQUESTED = 'compute_resource_vm_reinstall_requested',
    SERVER_REINSTALLED = 'compute_resource_vm_reinstalled',
    SERVER_RESIZED = 'compute_resource_vm_resized',
    SERVER_UPDATED = 'compute_resource_vm_updated',
    SERVER_SETTINGS_UPDATED = 'compute_resource_vm_settings_updated',
    SERVER_PASSWORD_CHANGED = 'compute_resource_vm_password_changed',
    SERVER_CREATE_CREATE_FAILED = 'compute_resource_vm_create_failed',
    SERVER_DELETE_REQUESTED = 'compute_resource_vm_delete_requested',
    SERVER_DELETED = 'compute_resource_vm_deleted',
    SERVERS_DELETED = 'compute_resource_vm_batch_deleted',
    BACKUPS_ENABLED = 'backup_enabled',
    BACKUPS_DISABLED = 'backup_disabled',
    BACKUPS_DELETED = 'backups_deleted',
    SERVER_BOOT_MODE_CHANGED = 'compute_resource_vm_boot_mode_changed',
    SERVER_SUSPENDED = 'compute_resource_vm_suspended',
    SERVER_RESUMED = 'compute_resource_vm_resumed',
    LOCATION_CREATE_SUCCESS = 'location_created',
    LOCATION_DELETE_SUCCESS = 'location_deleted',
    LOCATION_CHANGE_SUCCESS = 'location_changed',
    IP_BLOCK_CHANGE_SUCCESS = 'ip_block_changed',
    ADDITIONAL_IP_CREATE_REQUESTED = 'additional_ip_create_requested',
    ADDITIONAL_IP_CREATE_SUCCESS = 'additional_ip_created',
    ADDITIONAL_IP_CREATE_FAILED = 'additional_ip_create_failed',
    ADDITIONAL_IP_DELETE_REQUESTED = 'additional_ip_delete_requested',
    ADDITIONAL_IPS_DELETE_REQUESTED = 'additional_ips_delete_requested',
    ADDITIONAL_IP_DELETE_SUCCESS = 'additional_ip_deleted',
    ADDITIONAL_IP_DELETE_FAILED = 'additional_ip_delete_failed',
    ADDITIONAL_IPS_DELETE_FAILED = 'additional_ips_delete_failed',
    GUEST_TOOLS_INSTALL_REQUESTED = 'guest_tools_install_requested',
    GUEST_TOOLS_INSTALL_SUCCESS = 'guest_tools_install_succeeded',
    GUEST_TOOLS_INSTALL_FAILED = 'guest_tools_install_failed',
    EXTERNAL_INTEGRATION_REQUESTED = 'external_integration_requested',
    FAILED_TO_SEND_EMAIL_NOTIFICATION = 'failed_to_send_email_notification',
    MANAGEMENT_NODE_BACKUPS_ENABLED = 'management_node_backup_enabled',
    MANAGEMENT_NODE_BACKUPS_DISABLED = 'management_node_backup_disabled',
    MANAGEMENT_NODE_BACKUP_REQUESTED = 'management_node_backup_requested',
    MANAGEMENT_NODE_BACKUP_RESTORE_REQUESTED = 'management_node_backup_restore_requested',
    MANAGEMENT_NODE_BACKUP_RESTORED = 'management_node_backup_restored',
    MANAGEMENT_NODE_BACKUP_DELETED = 'management_node_backup_deleted',
    GLOBAL_SERVER_BACKUPS_ENABLED = 'global_server_backup_enabled',
    GLOBAL_SERVER_BACKUPS_DISABLED = 'global_server_backup_disabled',
    EVENT_HANDLER_ADDED = 'event_handler_added',
    EVENT_HANDLER_DELETED = 'event_handler_deleted',
    EXTERNAL_INTEGRATION_DELETED = 'external_integration_deleted',
    TWO_FACTOR_AUTH_ENABLED = 'two_factor_auth_enabled',
    TWO_FACTOR_AUTH_DISABLED = 'two_factor_auth_disabled',
    TWO_FACTOR_AUTH_RECOVERY_CODES_GENERATED = 'two_factor_auth_recovery_codes_generated',
    TWO_FACTOR_AUTH_RECOVERY_CODES_SENT = 'two_factor_auth_recovery_code_sent',
    ADDITIONAL_DISK_ADDED = 'additional_disk_added',
    ADDITIONAL_DISK_RESIZED = 'additional_disk_resized',
    ADDITIONAL_DISK_DELETED = 'additional_disk_deleted',
    SERVER_ATTACHED_TO_VPC_NETWORK = 'server_attached_to_vpc_network',
    SERVER_DETACHED_FROM_VPC_NETWORK = 'server_detached_from_vpc_network',
}

// Activity log translation keys
export const activityLogEventMap: Record<ACTIVITY_LOG_EVENTS, string> = {
    [ACTIVITY_LOG_EVENTS.USER_CREATE_REQUESTED]: 'activityLog.events.userCreateRequested',
    [ACTIVITY_LOG_EVENTS.USER_CREATE_SUCCESS]: 'activityLog.events.userCreateSuccess',
    [ACTIVITY_LOG_EVENTS.USER_CREATE_FAILED]: 'activityLog.events.userCreateFailed',
    [ACTIVITY_LOG_EVENTS.USER_DELETE_REQUESTED]: 'activityLog.events.userDeleteRequested',
    [ACTIVITY_LOG_EVENTS.USERS_DELETED]: 'activityLog.events.usersDeleted',
    [ACTIVITY_LOG_EVENTS.USER_AUTHORIZED_SUCCESS]: 'activityLog.events.userAuthorizedSuccess',
    [ACTIVITY_LOG_EVENTS.USER_ALLOWED_IP_ADDED]: 'activityLog.events.userAllowedIpAdded',
    [ACTIVITY_LOG_EVENTS.USER_ALLOWED_IP_REMOVED]: 'activityLog.events.userAllowedIpRemoved',
    [ACTIVITY_LOG_EVENTS.COMPUTE_RESOURCE_CREATE_REQUESTED]: 'activityLog.events.computeResourceCreateRequested',
    [ACTIVITY_LOG_EVENTS.COMPUTE_RESOURCE_DELETE_REQUESTED]: 'activityLog.events.computeResourceDeleteRequested',
    [ACTIVITY_LOG_EVENTS.SERVER_CREATE_REQUESTED]: 'activityLog.events.serverCreateRequested',
    [ACTIVITY_LOG_EVENTS.SERVER_CREATE_CREATE_SUCCESS]: 'activityLog.events.serverCreateSuccess',
    [ACTIVITY_LOG_EVENTS.SERVER_CREATE_CREATE_FAILED]: 'activityLog.events.serverCreateFailed',
    [ACTIVITY_LOG_EVENTS.SERVER_DELETE_REQUESTED]: 'activityLog.events.serverDeleteRequested',
    [ACTIVITY_LOG_EVENTS.SERVER_DELETED]: 'activityLog.events.serverDeleted',
    [ACTIVITY_LOG_EVENTS.SERVERS_DELETED]: 'activityLog.events.serversDeleted',
    [ACTIVITY_LOG_EVENTS.SERVER_SUSPENDED]: 'activityLog.events.serverSuspended',
    [ACTIVITY_LOG_EVENTS.SERVER_RESUMED]: 'activityLog.events.serverResumed',
    [ACTIVITY_LOG_EVENTS.BACKUPS_ENABLED]: 'activityLog.events.backupsEnabled',
    [ACTIVITY_LOG_EVENTS.BACKUPS_DISABLED]: 'activityLog.events.backupsDisabled',
    [ACTIVITY_LOG_EVENTS.BACKUPS_DELETED]: 'activityLog.events.backupsDeleted',
    [ACTIVITY_LOG_EVENTS.SERVER_BOOT_MODE_CHANGED]: 'activityLog.events.bootModeChanged',
    [ACTIVITY_LOG_EVENTS.SERVER_REINSTALLED_REQUESTED]: 'activityLog.events.serverReinstallRequested',
    [ACTIVITY_LOG_EVENTS.SERVER_REINSTALLED]: 'activityLog.events.serverReinstalled',
    [ACTIVITY_LOG_EVENTS.SERVER_RESIZED]: 'activityLog.events.serverResized',
    [ACTIVITY_LOG_EVENTS.SERVER_UPDATED]: 'activityLog.events.serverUpdated',
    [ACTIVITY_LOG_EVENTS.SERVER_SETTINGS_UPDATED]: 'activityLog.events.serverSettingsUpdated',
    [ACTIVITY_LOG_EVENTS.SERVER_PASSWORD_CHANGED]: 'activityLog.events.serverPasswordChanged',
    [ACTIVITY_LOG_EVENTS.LOCATION_CREATE_SUCCESS]: 'activityLog.events.locationCreateSuccess',
    [ACTIVITY_LOG_EVENTS.LOCATION_DELETE_SUCCESS]: 'activityLog.events.locationDeleteSuccess',
    [ACTIVITY_LOG_EVENTS.LOCATION_CHANGE_SUCCESS]: 'activityLog.events.locationChangeSuccess',
    [ACTIVITY_LOG_EVENTS.IP_BLOCK_CHANGE_SUCCESS]: 'activityLog.events.ipBlockChangeSuccess',
    [ACTIVITY_LOG_EVENTS.ADDITIONAL_IP_CREATE_REQUESTED]: 'activityLog.events.additionalIpCreateRequested',
    [ACTIVITY_LOG_EVENTS.ADDITIONAL_IP_CREATE_SUCCESS]: 'activityLog.events.additionalIpCreateSuccess',
    [ACTIVITY_LOG_EVENTS.ADDITIONAL_IP_CREATE_FAILED]: 'activityLog.events.additionalIpCreateFailed',
    [ACTIVITY_LOG_EVENTS.ADDITIONAL_IP_DELETE_REQUESTED]: 'activityLog.events.additionalIpDeleteRequested',
    [ACTIVITY_LOG_EVENTS.ADDITIONAL_IPS_DELETE_REQUESTED]: 'activityLog.events.additionalIpsDeleteRequested',
    [ACTIVITY_LOG_EVENTS.ADDITIONAL_IP_DELETE_SUCCESS]: 'activityLog.events.additionalIpDeleteSuccess',
    [ACTIVITY_LOG_EVENTS.ADDITIONAL_IP_DELETE_FAILED]: 'activityLog.events.additionalIpDeleteFailed',
    [ACTIVITY_LOG_EVENTS.ADDITIONAL_IPS_DELETE_FAILED]: 'activityLog.events.additionalIpsDeleteFailed',
    [ACTIVITY_LOG_EVENTS.GUEST_TOOLS_INSTALL_REQUESTED]: 'activityLog.events.guestToolsInstallRequested',
    [ACTIVITY_LOG_EVENTS.GUEST_TOOLS_INSTALL_SUCCESS]: 'activityLog.events.guestToolsInstallSucceeded',
    [ACTIVITY_LOG_EVENTS.GUEST_TOOLS_INSTALL_FAILED]: 'activityLog.events.guestToolsInstallFailed',
    [ACTIVITY_LOG_EVENTS.EXTERNAL_INTEGRATION_REQUESTED]: 'activityLog.events.externalIntegrationRequested',
    [ACTIVITY_LOG_EVENTS.FAILED_TO_SEND_EMAIL_NOTIFICATION]: 'activityLog.events.failedToSendEmailNotification',
    [ACTIVITY_LOG_EVENTS.MANAGEMENT_NODE_BACKUPS_ENABLED]: 'activityLog.events.managementNodeBackupsEnabled',
    [ACTIVITY_LOG_EVENTS.MANAGEMENT_NODE_BACKUPS_DISABLED]: 'activityLog.events.managementNodeBackupsDisabled',
    [ACTIVITY_LOG_EVENTS.MANAGEMENT_NODE_BACKUP_REQUESTED]: 'activityLog.events.managementNodeBackupRequested',
    [ACTIVITY_LOG_EVENTS.MANAGEMENT_NODE_BACKUP_RESTORE_REQUESTED]: 'activityLog.events.managementNodeBackupRestoreRequested',
    [ACTIVITY_LOG_EVENTS.MANAGEMENT_NODE_BACKUP_RESTORED]: 'activityLog.events.managementNodeBackupRestored',
    [ACTIVITY_LOG_EVENTS.MANAGEMENT_NODE_BACKUP_DELETED]: 'activityLog.events.managementNodeBackupDeleted',
    [ACTIVITY_LOG_EVENTS.GLOBAL_SERVER_BACKUPS_ENABLED]: 'activityLog.events.globalVsBackupsEnabled',
    [ACTIVITY_LOG_EVENTS.GLOBAL_SERVER_BACKUPS_DISABLED]: 'activityLog.events.globalVsBackupsDisabled',
    [ACTIVITY_LOG_EVENTS.EVENT_HANDLER_ADDED]: 'activityLog.events.eventHandlerAdded',
    [ACTIVITY_LOG_EVENTS.EVENT_HANDLER_DELETED]: 'activityLog.events.eventHandlerDeleted',
    [ACTIVITY_LOG_EVENTS.EXTERNAL_INTEGRATION_DELETED]: 'activityLog.events.externalIntegrationDeleted',
    [ACTIVITY_LOG_EVENTS.TWO_FACTOR_AUTH_ENABLED]: 'activityLog.events.twoFactorAuthEnabled',
    [ACTIVITY_LOG_EVENTS.TWO_FACTOR_AUTH_DISABLED]: 'activityLog.events.twoFactorAuthDisabled',
    [ACTIVITY_LOG_EVENTS.TWO_FACTOR_AUTH_RECOVERY_CODES_GENERATED]: 'activityLog.events.twoFactorAuthRecoveryCodesGenerated',
    [ACTIVITY_LOG_EVENTS.TWO_FACTOR_AUTH_RECOVERY_CODES_SENT]: 'activityLog.events.twoFactorAuthRecoveryCodeSent',
    [ACTIVITY_LOG_EVENTS.ADDITIONAL_DISK_ADDED]: 'activityLog.events.additionalDiskAdded',
    [ACTIVITY_LOG_EVENTS.ADDITIONAL_DISK_RESIZED]: 'activityLog.events.additionalDiskResized',
    [ACTIVITY_LOG_EVENTS.ADDITIONAL_DISK_DELETED]: 'activityLog.events.additionalDiskDeleted',
    [ACTIVITY_LOG_EVENTS.SERVER_ATTACHED_TO_VPC_NETWORK]: 'activityLog.events.serverAttachedToVpcNetwork',
    [ACTIVITY_LOG_EVENTS.SERVER_DETACHED_FROM_VPC_NETWORK]: 'activityLog.events.serverDetachedFromVpcNetwork',
};

export const activityLogBatchEvents = [
    ACTIVITY_LOG_EVENTS.USERS_DELETED,
    ACTIVITY_LOG_EVENTS.SERVERS_DELETED,
    ACTIVITY_LOG_EVENTS.BACKUPS_DELETED,
];
