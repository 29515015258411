// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';

import AsyncSelectInput from 'common/components/Select/AsyncSelectInput';
import { createOptionsLoader } from 'common/components/Select/helpers';
import {
    icons,
    IconType,
    IIconListRequest,
    IIconResponse,
} from 'common/api/resources/Icon';
import { ServerIcon } from 'common/components/ServerIcon/ServerIcon';
import { ISelectOption } from 'common/components';
import { Translate } from '@plesk/ui-library';

interface IIconSelectorProps {
    selected: IIconResponse | null;
    onChange: (value: number) => void;
    type: IconType;
    menuPosition?: string;
    placeholder?: React.ReactNode;
    inputId?: string;
}

const iconToSelectOptions = (icon: IIconResponse) => ({
    label: (
        <ServerIcon width={15} icon={icon} withName={true} />
    ),
    value: icon.id,
});

export const IconSelector: React.FC<IIconSelectorProps> = ({
    type,
    onChange,
    selected,
    menuPosition,
    placeholder,
    inputId,
}) => {
    const [selectedIcon, setSelectedIcon] = React.useState<ISelectOption | null>(
        selected ? iconToSelectOptions(selected) : null
    );

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleChange = (option: any) => {
        onChange(option ? option.value : null);

        setSelectedIcon(option);
    };

    const apiCall = (params?: IIconListRequest) => icons.list({
        ...params,
        filters: {
            ...params?.filters,
            type,
        },
    });

    const loadIconOptions = createOptionsLoader(
        apiCall,
        iconToSelectOptions
    );

    return (
        <AsyncSelectInput
            inputId={inputId}
            isClearable={true}
            placeholder={placeholder ? placeholder : <Translate content="icon.selectIcon" />}
            onChange={handleChange}
            value={selectedIcon}
            loadOptions={loadIconOptions}
            debounceTimeout={1000}
            additional={{ page: 1 }}
            menuPosition={menuPosition}
        />
    );
};
