// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';

export const Footer = styled.div`
    border-top: 1px solid #ECECEC;
    width: 100%;
    background: #FBFBFB;
    margin-top: auto;

    button {
        margin: 12px 16px;
        box-shadow: unset;
        border-radius: 2px;
        min-width: 141px;
        min-height: 40px;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;
    }
`;
