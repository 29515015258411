// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';
import { COLORS } from 'admin/core/theme';

export const NotificationListContainer = styled.div`
    display: flex;
    flex-direction: column;
`;

export const ShowMoreContainer = styled.div`
    text-align: center;
    padding: 12px 0;

    .pul-link {
        color: ${COLORS.PRIMARY};
        font-size: 12px;
    }

    .pul-content-loader__container {
        height: 0;
    }
`;

export const EmptyList = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 40px;

    font-weight: 600;
    font-size: 20px;
    line-height: 24px;

    span:first-child {
        margin-bottom: 40px;
    }
`;
