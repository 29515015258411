// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { StyledActions } from 'common/components/Actions/Styles';
import {
    Button,
    Icon,
    Translate,
} from '@plesk/ui-library';
import { ICONS } from 'common/constants';
import { dataCySelector } from 'common/tests/selectors';
import { TABLE_ACTIONS } from 'common/modules/isoImage/constants/test';
import ButtonWithConfirmation from 'common/components/ButtonWithConfirmation';
import { IIsoImageResponse } from 'common/api/resources/IsoImage';

export interface IIsoImageProps {
    item: IIsoImageResponse;
    disabled?: boolean;
    handleEdit: () => void;
    handleRemove: () => void;
}

export const IsoImageActions: React.FC<IIsoImageProps> = ({
    item,
    disabled,
    handleEdit,
    handleRemove,
}) => (
    <StyledActions>
        <Button
            ghost={true}
            disabled={disabled}
            icon={<Icon name={ICONS.PENCIL} size={18} />}
            tooltip={disabled ? (
                <Translate content="isoImage.tooltip.disabled"/>
            ) : (
                <Translate content="isoImage.tooltip.edit"/>
            )}
            onClick={handleEdit}
            data-cy={dataCySelector(item.id, TABLE_ACTIONS.EDIT)}
        />
        <ButtonWithConfirmation
            isLoading={item.is_deleting}
            translations={{
                title: (
                    <Translate content="isoImage.removePopover.title" />
                ),
                button: (
                    <Translate content="isoImage.removePopover.button" />
                ),
                tooltip: disabled ? (
                    <Translate content="isoImage.removePopover.disabledTooltip"/>
                ) : (
                    <Translate content="isoImage.removePopover.tooltip"/>
                ),
            }}
            handleConfirm={handleRemove}
            data-cy={dataCySelector(item.id, TABLE_ACTIONS.REMOVE)}
            icon={ICONS.RECYCLE}
            disabled={disabled}
        />
    </StyledActions>
);