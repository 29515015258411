// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import {
    ActionType,
    getType,
} from 'typesafe-actions';
import * as featuresActions from 'common/modules/app/features/actions';
import { FEATURES } from 'common/modules/app/features/constants';

export type FeaturesAction = ActionType<typeof featuresActions>;
export type FeaturesState = Set<FEATURES>;

export default (state = new Set<FEATURES>([]), action: FeaturesAction) => {
    switch (action.type) {
    case getType(featuresActions.enableFeature): {
        const features = new Set(state.values());
        features.add(action.payload);
        return features;
    }
    case getType(featuresActions.disableFeature): {
        const features = new Set(state.values());
        features.delete(action.payload);
        return features;
    }
    default:
        return state;
    }
};
