// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    Form,
    FormField,
    Section,
    Translate,
} from '@plesk/ui-library';
import {
    intMinRule,
    validate,
} from 'common/validator';
import {
    IBackupResponse,
    IRestoreServerRequest,
} from 'common/api/resources/Backup';
import { RootState } from 'admin/core/store';
import { nestStringProperties } from 'common/modules/app/formErrors/selectors';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import { connect } from 'react-redux';
import * as backupActions from 'common/modules/backup/actions';
import {
    INTENT_TYPE,
    SIZE,
} from 'common/constants';
import { Button } from 'admin/common/components/Button/Button';
import AsyncSelectInput from 'common/components/Select/AsyncSelectInput';
import SelectInput from 'common/components/Select/SelectInput';
import { createOptionsLoader } from 'common/components/Select/helpers';
import {
    IUserResponse,
    users,
} from 'common/api/resources/User';
import { IProjectResponse } from 'common/api/resources/Project';
import * as userProjectsActions from 'admin/user/actions/projects';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { osImages } from 'common/api/resources/OsImage';
import {
    computeResources,
    IComputeResourceResponse,
} from 'common/api/resources/ComputeResource';
import { hasPermission } from 'common/modules/permission/selectors';
import { PERMISSION_LIST } from 'common/modules/permission/constants';

interface IRestoreFormProps {
    backup?: IBackupResponse;
    onSubmit: () => void;
}

export type RestoreFormProps =
    IRestoreFormProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const RestoreForm: React.FC<RestoreFormProps> = ({
    backup,
    onSubmit,
    formErrors,
    projects,
    isProjectsLoading,
    permissions: {
        canGetComputeResources,
        canGetOsImages,
        canGetUsers,
    },
    formErrorsActions: {
        setFormErrors,
        clearFormErrors,
    },
    backupActions: {
        restoreDeletedServerFromBackup,
    },
    userProjectsActions: {
        getProjects,
    },
}) => {
    const [submitValues, setSubmitValues] = React.useState<IRestoreServerRequest>({
        destination_compute_resource_id: backup?.compute_resource_vm?.compute_resource?.id ?? 0,
        temporary_os_image_version_id: 0,
        user_id: backup?.compute_resource_vm?.user?.id ?? 0,
        project_id: backup?.compute_resource_vm?.project?.id ?? 0,
    });

    React.useEffect(() => {
        if (backup?.compute_resource_vm?.user?.id) {
            loadUserProjects(backup.compute_resource_vm.user.id);
        }
        clearFormErrors();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const projectOptions = React.useMemo(() => projects.data.map((item: IProjectResponse) => ({
        label: item.name,
        value: item.id,
    })), [projects]);

    const loadUserProjects = React.useCallback((userId: number) => {
        getProjects(userId, {
            filters: {
                owner_id: userId,
            },
        });
    }, [getProjects]);

    const projectOption = React.useCallback(
        () => projectOptions.find((item) => item.value === submitValues.project_id),
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [submitValues.project_id]
    );

    if (backup === undefined) {
        onSubmit();
        return null;
    }

    const handleSubmit = async (values: IRestoreServerRequest) => {
        const errors = validate<IRestoreServerRequest>(values, {
            destination_compute_resource_id: intMinRule(<Translate content="validate.fieldRequired" />, 1),
            temporary_os_image_version_id: intMinRule(<Translate content="validate.fieldRequired" />, 1),
            user_id: intMinRule(<Translate content="validate.fieldRequired" />, 1),
            project_id: intMinRule(<Translate content="validate.fieldRequired" />, 1),
        });

        if (Object.keys(errors).length) {
            setFormErrors(errors);
            return;
        }

        restoreDeletedServerFromBackup(backup.id, values);
        onSubmit();
    };

    const loadComputeResourceOptions = createOptionsLoader(
        computeResources.list,
        (cr: IComputeResourceResponse) => ({
            label: cr.name,
            value: cr.id,
        }),
        canGetComputeResources
    );

    const loadOsImagesOptions = createOptionsLoader(
        osImages.list,
        (image) => ({
            label: image.name,
            options: image.versions.map((version) => ({
                label: version.version,
                value: version.id,
            })),
        }),
        canGetOsImages,
        {
            virtualization_type: backup.compute_resource_vm?.virtualization_type,
        }
    );

    const loadUserOptions = createOptionsLoader(
        users.list,
        (item: IUserResponse) => ({
            label: item.email,
            value: item.id,
        }),
        canGetUsers
    );

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleComputeResourceChange = (option: any) => {
        setSubmitValues(values => ({
            ...values,
            destination_compute_resource_id: option.value,
        }));
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleOsImageChange = (option: any) => {
        setSubmitValues(values => ({
            ...values,
            temporary_os_image_version_id: option.value,
        }));
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleUserChange = (option: any) => {
        setSubmitValues(values => ({
            ...values,
            user_id: option.value,
        }));
        loadUserProjects(option.value);
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleProjectChange = (option: any) => {
        setSubmitValues(values => ({
            ...values,
            project_id: option.value,
        }));
    };

    return (
        <>
            <Form
                id="restoreForm"
                onSubmit={handleSubmit}
                values={submitValues}
                footerClassName="hidden"
                errors={formErrors}
                hideRequiredLegend={true}
                submitButton={false}
                cancelButton={false}
                applyButton={false}
                vertical={true}
            >
                <Section>
                    <FormField
                        name="destination_compute_resource_id"
                        label={<Translate content="backups.restoreForm.destinationComputeResource" />}
                        required={true}
                    >
                        {({ getId }) => (
                            <AsyncSelectInput
                                inputId={getId()}
                                defaultValue={{
                                    value: backup.compute_resource_vm?.compute_resource?.id,
                                    label: backup.compute_resource_vm?.compute_resource?.name,
                                }}
                                loadOptions={loadComputeResourceOptions}
                                onChange={handleComputeResourceChange}
                                debounceTimeout={1000}
                                additional={{ page: 1 }}
                                menuPosition="fixed"
                            />
                        )}
                    </FormField>
                    <FormField
                        name="temporary_os_image_version_id"
                        label={<Translate content="backups.restoreForm.temporaryOsImageVersion" />}
                        required={true}
                    >
                        {({ getId }) => (
                            <AsyncSelectInput
                                inputId={getId()}
                                menuPosition="fixed"
                                loadOptions={loadOsImagesOptions}
                                onChange={handleOsImageChange}
                                debounceTimeout={1000}
                                additional={{ page: 1 }}
                            />
                        )}
                    </FormField>
                    <FormField
                        name="user_id"
                        label={<Translate content="backups.restoreForm.user" />}
                        required={true}
                    >
                        {({ getId }) => (
                            <AsyncSelectInput
                                inputId={getId()}
                                menuPosition="fixed"
                                loadOptions={loadUserOptions}
                                onChange={handleUserChange}
                                debounceTimeout={1000}
                                additional={{ page: 1 }}
                                defaultValue={{
                                    value: backup.compute_resource_vm?.user?.id,
                                    label: backup.compute_resource_vm?.user?.email,
                                }}
                            />
                        )}
                    </FormField>
                    <FormField
                        name="project_id"
                        label={<Translate content="backups.restoreForm.project" />}
                        required={true}
                    >
                        {({ getId }) => (
                            <SelectInput
                                inputId={getId()}
                                menuPosition="fixed"
                                options={projectOptions}
                                onChange={handleProjectChange}
                                isDisabled={projectOptions.length === 0}
                                value={projectOption()}
                                defaultValue={{
                                    value: backup.compute_resource_vm?.project?.id,
                                    label: backup.compute_resource_vm?.project?.name,
                                }}
                                isLoading={isProjectsLoading}
                            />
                        )}
                    </FormField>
                </Section>
            </Form>
            <Button
                type="submit"
                form="restoreForm"
                fill={true}
                intent={INTENT_TYPE.PRIMARY}
                size={SIZE.LG}
            >
                <Translate content="backups.restoreForm.button" />
            </Button>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    formErrors: nestStringProperties(state),
    projects: state.project.list,
    isProjectsLoading: state.app.loadingFlags.has(LOADING_FLAGS.PROJECT_LIST),
    permissions: {
        canGetComputeResources: hasPermission(state, PERMISSION_LIST.MANAGE_COMPUTE_RESOURCES, PERMISSION_LIST.GET_COMPUTE_RESOURCES),
        canGetOsImages: hasPermission(state, PERMISSION_LIST.MANAGE_OS_IMAGES, PERMISSION_LIST.GET_OS_IMAGES),
        canGetUsers: hasPermission(state, PERMISSION_LIST.MANAGE_USERS, PERMISSION_LIST.GET_USERS),
    },
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    backupActions: bindActionCreators(backupActions, dispatch),
    formErrorsActions: bindActionCreators(formErrorsActions, dispatch),
    userProjectsActions: bindActionCreators(userProjectsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(RestoreForm);