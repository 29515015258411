// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { createCustomAction } from 'typesafe-actions';
import { Dispatch } from 'redux';
import * as types from 'admin/user/constants/types';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import {
    create,
    get,
    removeBatchSilent,
    removeSilent,
} from 'common/actions/actionsWrapper';
import { IPaginateApiResponse } from 'common/api/resources/Response';
import {
    setIsLoading,
    unsetIsLoading,
} from 'common/modules/app/loadingFlags/actions';
import { HTTP_CODES } from 'common/api/constants';
import {
    IUserListRequest,
    IUserRequest,
    IUserResponse,
    users,
} from 'common/api/resources/User';
import { clearFormErrors } from 'common/modules/app/formErrors/actions';
import { INTENT_TYPE } from 'common/constants';
import { bakeForegroundToast } from 'common/modules/app/toaster/actions';
import {
    setIsAuth,
    setUser,
    setUserCredentials,
} from 'common/modules/auth/actions';
import { CancelTokenSource } from 'axios';

export const appendUsers = createCustomAction(
    types.APPEND_USERS,
    (data: IPaginateApiResponse<IUserResponse[]>) => ({ payload: data })
);
export const setUserItem = createCustomAction(
    types.SET_USER_ITEM,
    (data: IUserResponse) => ({ payload: data })
);
export const unsetUserItem = createCustomAction(types.UNSET_USER_ITEM);
export const addUserItem = createCustomAction(
    types.ADD_USER_ITEM,
    (data: IUserResponse) => ({ payload: data })
);
export const updateUserItem = createCustomAction(
    types.UPDATE_USER_ITEM,
    (data: IUserResponse) => ({ payload: data })
);
export const setUserList = createCustomAction(
    types.SET_USER_LIST,
    (data: IPaginateApiResponse<IUserResponse[]>) => ({ payload: data })
);
export const setUserItemIsDeleting = createCustomAction(
    types.SET_USER_ITEM_IS_DELETING,
    (id: number, isDeleting: boolean) => ({ payload: { id, is_deleting: isDeleting } })
);
export const setUserItemIsTwoFactorAuthRecoveryCodeSending = createCustomAction(
    types.SET_USER_ITEM_IS_TWO_FACTOR_AUTH_RECOVERY_CODE_SENDING,
    (id: number, isSending: boolean) => ({ payload: { id, is_two_factor_auth_recovery_code_sending: isSending } })
);

export const createUser = (data: IUserRequest) => async(dispatch: Dispatch) => await create({
    data,
    dispatch,
    loadingFlag: LOADING_FLAGS.SAVE_USER_ITEM,
    action: addUserItem,
    apiCall: users.create,
    translations: {
        success: 'user.toasts.userSaved',
    },
});

export const getUsers = (request?: IUserListRequest, cancelToken?: CancelTokenSource) => async(dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.USER_LIST));

    try {
        const result = await users.list(request, cancelToken);

        if (result.status === HTTP_CODES.OK) {
            dispatch(setUserList(result.data));
        }

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.USER_LIST));
    }
};

export const getUser = (id: number) => async(dispatch: Dispatch) => await get(id, {
    dispatch,
    apiCall: users.item,
    action: setUserItem,
    loadingFlag: LOADING_FLAGS.USER_ITEM,
});

export const updateUser = (id: number, data: IUserRequest) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.SAVE_USER_ITEM));

    try {
        const result = await users.update(id, data);
        if (result.status === HTTP_CODES.OK) {
            dispatch(clearFormErrors());
            bakeForegroundToast(INTENT_TYPE.SUCCESS, 'user.toasts.userSaved')(dispatch);
            // ILoginResponse is returned only when user changes password for himself
            if ('credentials' in result.data.data) {
                dispatch(setUserCredentials(result.data.data.credentials));
                dispatch(setUser(result.data.data.user));
                dispatch(updateUserItem(result.data.data.user));
                dispatch(setIsAuth(true));
            }

            // IUserResponse returned otherwise
            if ('id' in result.data.data) {
                dispatch(updateUserItem(result.data.data));
            }
        }

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.SAVE_USER_ITEM));
    }
};

export const removeUser = (id: number, force?: boolean) => async (dispatch: Dispatch) => await removeSilent(id, {
    dispatch,
    apiCall: users.remove,
    setLoadingAction: setUserItemIsDeleting,
    loadingFlag: LOADING_FLAGS.REMOVE_USER_ITEM,
    translations: {
        success: 'user.toasts.userDeleted',
    },
}, force);

export const removeUsers = (ids: number[], force?: boolean) => async (dispatch: Dispatch) => await removeBatchSilent(ids, {
    dispatch,
    apiCall: users.removeBatch,
    loadingFlag: LOADING_FLAGS.REMOVE_USER_ITEM,
    translations: {
        success: 'user.toasts.batchDeleted',
    },
}, force);

export const sendTwoFactorAuthRecoveryCode = (id: number) => async (dispatch: Dispatch) => {
    dispatch(setUserItemIsTwoFactorAuthRecoveryCodeSending(id, true));

    try {
        const result = await users.sendTwoFactorAuthRecoveryCode(id);
        bakeForegroundToast(INTENT_TYPE.SUCCESS, 'user.toasts.twoFactorAuthRecoveryCodeSent')(dispatch);
        return result;
    } finally {
        dispatch(setUserItemIsTwoFactorAuthRecoveryCodeSending(id, false));
    }
};

