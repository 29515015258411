// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import {
    ActionType,
    getType,
} from 'typesafe-actions';
import * as storageTypeActions from 'admin/storageType/actions';
import { IStorageTypeResponse } from 'common/api/resources/StorageType';

export type StorageTypeState = IStorageTypeResponse[];
export type StorageTypeAction = ActionType<typeof storageTypeActions>;

export default (state: StorageTypeState = [], action: StorageTypeAction): StorageTypeState => {
    switch (action.type) {
    case getType(storageTypeActions.setList): {
        return action.payload;
    }
    default:
        return state;
    }
};
