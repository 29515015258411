// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { connect } from 'react-redux';
import * as tagActions from 'admin/tag/actions';
import { RootState } from 'admin/core/store';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { EntityType } from 'common/api/resources/Tag';
import {
    Form,
    Grid,
    Item,
    Section,
    Translate,
} from '@plesk/ui-library';
import {
    INTENT_TYPE,
    SIZE,
} from 'common/constants';
import { Button } from 'admin/common/components/Button/Button';
import { Loader } from 'common/components';
import { GridCol } from 'common/components/ServerTabs/NetworkingTab/GridCol';
import { EntityTagInputs } from 'admin/tag/components/EntityTagInputs';
import { nestStringProperties } from 'common/modules/app/formErrors/selectors';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import {
    requiredRule,
    validate,
} from 'common/validator';

export interface IVmTagsFormRequest {
    tag_value_ids: Array<number|undefined>;
}

export type VmTagProps =
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const VmTagsTab: React.FC<VmTagProps> = ({
    server,
    entity,
    isLoading,
    isSaving,
    formErrors,
    formErrorsActions: {
        setFormErrors,
        clearFormErrors,
    },
    tagActions: {
        getTaggableEntity,
        updateEntityTags,
        unsetTaggableEntity,
    },
}) => {
    const [submitValues, setSubmitValues] = React.useState<IVmTagsFormRequest>({
        tag_value_ids: [],
    });

    const handleTagValueIdsChange = (tagValueIds: Array<number|undefined>) => {
        clearFormErrors();
        setSubmitValues({
            ...submitValues,
            tag_value_ids: tagValueIds,
        });
    };

    const handleSubmit = async (data: IVmTagsFormRequest) => {
        const errors = validate(data, {
            ...Object.fromEntries(Object.entries(data.tag_value_ids).map(([idx]) =>
                [`tag_value_ids.${idx}`, requiredRule(<Translate content="validate.fieldRequired" />)])),
        });

        if (Object.keys(errors).length) {
            setFormErrors(errors);
            return;
        }

        updateEntityTags({
            id: server.id,
            type: EntityType.SERVER,
            tag_value_ids: data.tag_value_ids as number[],
        });
    };

    React.useEffect(() => {
        if (server.id) {
            getTaggableEntity(server.id, EntityType.SERVER);
        }

        return () => {
            clearFormErrors();
            unsetTaggableEntity();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [server.id]);

    React.useEffect(() => {
        setSubmitValues({
            tag_value_ids: entity.tags.map(t => t.value.id),
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [entity]);

    return (
        <Loader isLoading={isLoading || entity.id === 0}>
            <Grid gap="md">
                <GridCol>
                    <Item view="card">
                        <Form
                            id="serverTagsForm"
                            onSubmit={handleSubmit}
                            footerClassName="hidden"
                            submitButton={false}
                            cancelButton={false}
                            applyButton={false}
                            vertical={true}
                            values={submitValues}
                            errors={formErrors}
                        >
                            <Section>
                                <EntityTagInputs
                                    tags={entity.tags}
                                    onChange={handleTagValueIdsChange}
                                    emptyViewText={<Translate content="servers.tabs.tags.emptyViewText" />}
                                />
                            </Section>
                        </Form>
                        <Button
                            type="submit"
                            form="serverTagsForm"
                            intent={INTENT_TYPE.PRIMARY}
                            size={SIZE.LG}
                            isLoading={isSaving}
                        >
                            <Translate content="servers.tabs.tags.saveBtn" />
                        </Button>
                    </Item>
                </GridCol>
            </Grid>
        </Loader>
    );
};

const mapStateToProps = (state: RootState) => ({
    server: state.computeResourceVm.item,
    entity: state.tag.entity,
    isLoading: state.app.loadingFlags.has(LOADING_FLAGS.TAGGABLE_ENTITY),
    isSaving: state.app.loadingFlags.has(LOADING_FLAGS.TAGGABLE_ENTITY_SAVE),
    formErrors: nestStringProperties(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    tagActions: bindActionCreators(tagActions, dispatch),
    formErrorsActions: bindActionCreators(formErrorsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(VmTagsTab);
