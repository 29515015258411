// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';
import { COLORS } from 'common/theme';
import { CardItem } from 'common/components/styles/CardItem';

export const SSHKeyItem = styled(CardItem)`
    height: 60px;
    padding: 12px 20px;

    justify-content: center;
`;

export const ActionItem = styled.div`
    background: ${COLORS.WHITE_0};
    box-sizing: border-box;
    border-radius: 2px;
    border: 1px dashed ${props => props.theme.primary_color};
    flex-basis: 190px;
    height: 60px;
    padding: 12px 20px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-weight: 600;
    font-size: 14px;
    line-height: 20px;
    text-align: center;
    color: ${props => props.theme.primary_color};
    
    &:hover {
      border: 1px solid ${props => props.theme.primary_color};
    }
    
    .pul-icon {
        margin-right: 12px;
    }
`;

export const SSHKeyItemContent = styled.div`
    font-size: 14px;
    line-height: 34px;
    font-weight: 600;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
`;

export const SshList = styled.div`
    display: flex;
    flex-direction: column;
    padding: 20px 24px;
    
    div {
      margin: 3px 0;
    }
    
    .pul-checkbox {
        margin-bottom: 16px;
        font-size: 14px;
        line-height: 20px;
        color: ${COLORS.GREY_100};
    }
`;

export const InputContainer = styled.div`
    background: ${COLORS.GREY_20};
    padding: 24px 24px 16px;
    position: relative;
    
    .input-icon {
        color: ${COLORS.GREY_70};
        position: absolute;
        top: 36px;
        left: 36px;
    }
    
    input {
        background: ${COLORS.WHITE_0};
        border: 1px solid ${COLORS.GREY_50};
        box-sizing: border-box;
        border-radius: 2px;
        width: 100%;
        color: #979797;
        font-size: 14px;
        line-height: 20px;
        padding: 10px 36px;
    }
`;

export const HeaderContainer = styled.div`
  display: flex;
`;

export const SSHKeysContainer = styled.div`
    height: 100%;
    display: flex;
    flex-direction: column;
`;
