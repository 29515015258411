// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { ValueType } from 'react-select';
import { ISelectRequiredOption } from 'common/components';
import React from 'react';
import { FixedFormField } from 'admin/common/components/Filters/Styles';
import { FILTERS } from 'admin/common/constants/tests';
import SelectInput from 'common/components/Select/SelectInput';
import {
    Translate,
    useTranslate,
} from '@plesk/ui-library';

export interface IForDeletedServersFilterProps {
    onChange?: (option : ValueType<ISelectRequiredOption>) => void;
    value?: ValueType<ISelectRequiredOption>;
}

export const ForDeletedServersFilter: React.FC<IForDeletedServersFilterProps> = ({
    onChange,
    value,
}) => {
    const translate = useTranslate();
    const values: ISelectRequiredOption[] = [
        {
            label: translate('filters.no').toString(),
            value: '',
        },
        {
            label: translate('filters.yes').toString(),
            value: 'true',
        },
    ];

    return (
        <FixedFormField data-cy={FILTERS.FOR_DELETED_SERVERS}>
            <SelectInput
                inputId="for-deleted-servers-filter"
                placeholder={<Translate content="filters.forDeletedServers"/>}
                options={values}
                isClearable={true}
                onChange={onChange}
                value={value}
                menuPosition="absolute"
                menuPortalTarget={document.body}
            />
        </FixedFormField>
    );
};