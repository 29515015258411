// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import * as vpcNetworkActions from 'common/modules/vpcNetwork/actions';
import { connect } from 'react-redux';
import { RootState } from 'admin/core/store';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { PageHeader } from 'admin/common/components/PageHeader/PageHeader';
import {
    Action,
    Translate,
} from '@plesk/ui-library';
import { Loader } from 'common/components';
import { EmptyView } from 'common/components/EmptyView/EmptyView';
import { Dialog } from 'common/components/Dialog/Dialog';
import {
    ICONS,
    SIZE,
} from 'common/constants';
import {
    getActionColumnProps,
    reloadListData,
} from 'common/helpers/list';
import List from 'common/components/List/List';
import VpcNetworkForm,
{
    vpcNetworkFormDefaultFields,
    VpcNetworkFormFields,
} from 'common/modules/vpcNetwork/containers/VpcNetworkForm';
import { VpcNetworkActions } from 'common/modules/vpcNetwork/components/VpcNetworkActions';
import VpcNetworkFilters,
{ IVpcNetworkFilters } from 'admin/vpcNetwork/components/VpcNetworkFilters';
import { IpListTypeTranslations } from 'common/api/resources/IpBlock';
import { Link } from 'react-router-dom';
import { pathTo } from 'common/helpers/core';
import useQuery from 'common/hooks/useQuery';
import { useRequestCancellationEffect } from 'common/hooks/useRequestCancellationEffect';

export type VpcNetworksProps =
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export enum VpcNetworkTableColumns {
    ID = 'colId',
    NAME = 'colName',
    LIST_TYPE = 'colListType',
    LOCATION = 'colLocation',
    USER = 'colUser',
    ACTIONS = 'colActions',
}

const columns = [
    {
        width: '1%',
        key: VpcNetworkTableColumns.ID,
        title: <Translate content="vpcNetwork.list.id" />,
    },
    {
        width: '15%',
        key: VpcNetworkTableColumns.NAME,
        title: <Translate content="vpcNetwork.list.name" />,
    },
    {
        key: VpcNetworkTableColumns.LIST_TYPE,
        title: <Translate content="vpcNetwork.list.listType" />,
    },
    {
        key: VpcNetworkTableColumns.LOCATION,
        title: <Translate content="vpcNetwork.list.location" />,
    },
    {
        key: VpcNetworkTableColumns.USER,
        title: <Translate content="vpcNetwork.list.user" />,
    },
    getActionColumnProps(),
];

export const VpcNetworks: React.FC<VpcNetworksProps> = ({
    item,
    list,
    isLoadingList,
    isLoadingItem,
    vpcNetworkActions: {
        getVpcNetworks,
        getVpcNetwork,
        removeVpcNetwork,
    },
}) => {
    const [filters, setFilters] = useQuery<IVpcNetworkFilters>();
    const filtered = filters !== undefined && Object.values(filters).some(filter => !!filter);

    const [isDialogOpen, setDialogOpen] = React.useState(false);

    const loadPaginated = (page: number) => getVpcNetworks({ page, filters });

    const isFirstLoading = useRequestCancellationEffect(
        token => getVpcNetworks({ filters }, token),
        [filters]
    );

    const handleRemove = (id: number) => async () => {
        await removeVpcNetwork(id);
        reloadListData(list, loadPaginated);
    };

    const handleEdit = (id: number) => () => {
        getVpcNetwork(id);
        setDialogOpen(true);
    };

    const data = list.data.map((vpcNetwork) => ({
        [VpcNetworkTableColumns.ID]: (
            <Action
                component={Link}
                to={pathTo(`vpc_networks/${vpcNetwork.id}/ips`)}
            >
                {vpcNetwork.id}
            </Action>
        ),
        [VpcNetworkTableColumns.NAME]: (
            <Action
                component={Link}
                to={pathTo(`vpc_networks/${vpcNetwork.id}/ips`)}
            >
                {vpcNetwork.name}
            </Action>
        ),
        [VpcNetworkTableColumns.LIST_TYPE]: <Translate content={IpListTypeTranslations[vpcNetwork.list_type]}/>,
        [VpcNetworkTableColumns.LOCATION]: vpcNetwork.location.name,
        [VpcNetworkTableColumns.USER]: vpcNetwork.user ? (
            <Action
                component={Link}
                to={pathTo(`users?id=${vpcNetwork.user.id}`)}
            >
                {vpcNetwork.user.email}
            </Action>
        ) : (
            <Translate content="vpcNetwork.list.userNone" />
        ),
        [VpcNetworkTableColumns.ACTIONS]: (
            <VpcNetworkActions
                item={vpcNetwork}
                handleEdit={handleEdit(vpcNetwork.id)}
                handleRemove={handleRemove(vpcNetwork.id)}
                canRemove={vpcNetwork.can_delete}
            />
        ),
        key: vpcNetwork.id.toString(),
    }));

    return (
        <>
            <PageHeader
                isButtonShown={list.data.length > 0}
                title={<Translate content="vpcNetwork.title"/>}
                buttonText="vpcNetwork.addBtn"
                buttonIcon={ICONS.PLUS}
                onButtonClick={() => setDialogOpen(true)}
            />
            <Loader isLoading={isFirstLoading}>
                <List
                    emptyView={
                        <EmptyView
                            title="vpcNetwork.emptyView.title"
                            description="vpcNetwork.emptyView.description"
                            buttonText="vpcNetwork.emptyView.buttonText"
                            onButtonClick={() => setDialogOpen(true)}
                            icon={ICONS.PLUS}
                        />
                    }
                    toolbar={
                        <VpcNetworkFilters
                            filters={filters}
                            setFilters={setFilters}
                        />
                    }
                    columns={columns}
                    data={data}
                    loadItems={loadPaginated}
                    meta={list.meta}
                    isLoading={isLoadingList}
                    isFirstLoading={isFirstLoading}
                    filtered={filtered || isLoadingList}
                />
            </Loader>
            <Dialog
                heading={<Translate content={item.id
                    ? 'vpcNetwork.form.title.edit'
                    : 'vpcNetwork.form.title.create'
                } />}
                closeHandler={() => setDialogOpen(false)}
                isOpen={isDialogOpen}
                size={SIZE.XS}
            >
                <Loader isLoading={isLoadingItem} center={false}>
                    <VpcNetworkForm
                        onSubmit={() => setDialogOpen(false)}
                        fields={[
                            ...vpcNetworkFormDefaultFields,
                            VpcNetworkFormFields.LOCATION_ID,
                            VpcNetworkFormFields.USER_ID,
                        ]}
                    />
                </Loader>
            </Dialog>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    item: state.vpcNetwork.item,
    list: state.vpcNetwork.list,
    isLoadingList: state.app.loadingFlags.has(LOADING_FLAGS.VPC_NETWORK_LIST),
    isLoadingItem: state.app.loadingFlags.has(LOADING_FLAGS.VPC_NETWORK_ITEM),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    vpcNetworkActions: bindActionCreators(vpcNetworkActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(VpcNetworks);
