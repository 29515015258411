// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { RootState } from 'admin/core/store';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import IpBlockForm from 'admin/ipBlock/containers/IpBlockForm';
import * as ipBlockActions from 'admin/ipBlock/actions';
import { History } from 'history';
import * as computeResourceActions from 'admin/computeResource/actions';
import { Loader } from 'common/components';
import { hasPermission } from 'common/modules/permission/selectors';
import { PERMISSION_LIST } from 'common/modules/permission/constants';
import { PageHeader } from 'admin/common/components/PageHeader/PageHeader';
import { StyledTable } from 'common/components/styles/StyledTable';
import InfiniteScroll from 'common/components/InfinityScroll/InfinityScroll';
import {
    Action,
    List,
    Toolbar,
    ToolbarGroup,
    Tooltip,
    Translate,
} from '@plesk/ui-library';
import {
    IP_BLOCKS,
    TABLE_ACTIONS,
} from 'admin/ipBlock/constants/tests';
import { StyledActions } from 'common/components/Actions/Styles';
import { EmptyView } from 'common/components/EmptyView/EmptyView';
import { Dialog } from 'common/components/Dialog/Dialog';
import ButtonWithConfirmation from 'common/components/ButtonWithConfirmation';
import { dataCySelector } from 'common/tests/selectors';
import { getActionColumnProps } from 'common/helpers/list';
import { IpListTypeTranslations } from 'common/api/resources/IpBlock';
import { Link } from 'react-router-dom';
import { pathTo } from 'common/helpers/core';

interface IIpBlockProps {
    history: History;
}

export type IpBlockProps =
    IIpBlockProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

const columns = [{
    key: 'colId',
    width: '1%',
    title: <Translate content="ipBlock.list.id" />,
}, {
    width: '15%',
    key: 'colName',
    title: <Translate content="ipBlock.list.name" />,
    cellProps: {
        className: 'cell-bold',
    },
}, {
    key: 'colType',
    title: <Translate content="ipBlock.list.type" />,
}, {
    key: 'colListType',
    title: <Translate content="ipBlock.list.listType" />,
}, {
    key: 'colTotalIps',
    title: <Translate content="ipBlock.list.totalIps" />,
}, {
    key: 'reservedIps',
    title: <Translate content="ipBlock.list.reservedIps" />,
}, getActionColumnProps(),
];

export const IpBlock: React.FC<IpBlockProps> = ({
    unsetIpBlockItem: unsetItem,
    history,
    loadingFlags: {
        isLoadingList,
        isLoadingItem,
        isDeletingItem,
    },
    ipBlockActions: {
        loadIpBlocksOnScroll,
        getIpBlocks,
        getIpBlock,
        removeIpBlock,
        removeIpBlocks,
    },
    ipBlock: {
        list,
        item,
    },
    permissions: {
        canManageComputeResources,
    },
    computeResourceActions: {
        getComputeResources,
    },
}) => {
    const [dialogOpened, setDialogOpened] = React.useState(false);
    const [selection, setSelection] = React.useState<string[]>([]);

    React.useEffect(() => {
        getIpBlocks();

        if (canManageComputeResources) {
            getComputeResources();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const removeItems = async (ids: number | number[]) => {
        try {
            Array.isArray(ids) ? await removeIpBlocks(ids) : await removeIpBlock(ids);
            setSelection([]);
        } catch (e) {
            throw e;
        }
    };

    const handleRemoveSelected = async () => await removeItems(selection.map(id => parseInt(id, 10)));

    const handleEdit = (id: number) => () => {
        getIpBlock(id);
        setDialogOpened(true);
    };
    const handleCreate = () => {
        unsetItem();
        setDialogOpened(true);
    };
    const handleClose = () => setDialogOpened(false);
    const handleRemove = (id: number) => async () => await removeItems(id);

    const data = React.useMemo(() => list.data.map((ipBlock) => {
        const actionsEl = (
            <StyledActions>
                <Tooltip title={<Translate content="ipBlock.tooltip.edit" />}>
                    <Action
                        icon="pencil"
                        className="action-icon"
                        onClick={handleEdit(ipBlock.id)}
                        data-cy={dataCySelector(ipBlock.id, TABLE_ACTIONS.EDIT)}
                    />
                </Tooltip>
                <ButtonWithConfirmation
                    isLoading={ipBlock.is_deleting}
                    translations={{
                        title: (
                            <Translate content="ipBlock.buttonWithConfirmation.title" />
                        ),
                        button: (
                            <Translate content="ipBlock.buttonWithConfirmation.button" />
                        ),
                        tooltip: (
                            <Translate content="ipBlock.buttonWithConfirmation.tooltip" />
                        ),
                    }}
                    handleConfirm={handleRemove(ipBlock.id)}
                    data-cy={dataCySelector(ipBlock.id, TABLE_ACTIONS.REMOVE)}
                    icon="recycle"
                />
            </StyledActions>
        );

        return {
            colId: ipBlock.id,
            colName: ipBlock.name,
            colFrom: ipBlock.from,
            colListType: <Translate content={IpListTypeTranslations[ipBlock.list_type]}/>,
            colTotalIps: ipBlock.total_ips_count,
            colType: ipBlock.type,
            colTo: ipBlock.to,
            reservedIps: (
                <Action
                    component={Link}
                    to={pathTo(`ip_blocks/${ipBlock.id}/ips`)}
                    data-cy={dataCySelector(ipBlock.id, TABLE_ACTIONS.IPS)}
                >
                    {ipBlock.reserved_ips_count}
                </Action>
            ),
            colActions: actionsEl,
            key: ipBlock.id.toString(),
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [list.data, isDeletingItem]);

    let title = 'ipBlock.form.titleAdd';
    let titleParams = {};
    if (item.id) {
        title = 'ipBlock.form.titleEdit';
        titleParams = {
            ipBlock: item.name,
        };
    }

    return (
        <>
            <PageHeader
                isButtonShown={data.length > 0}
                title={<Translate content="ipBlock.title" />}
                buttonText="ipBlock.addBtn"
                buttonIcon="ip-addresses"
                onButtonClick={handleCreate}
            />
            <StyledTable>
                {data.length > 0 && (
                    <Toolbar data-cy={IP_BLOCKS.TOOLBAR}>
                        <ToolbarGroup title="actions">
                            <ButtonWithConfirmation
                                data-cy={IP_BLOCKS.BATCH_DELETE_BTN}
                                disabled={!selection.length}
                                isLoading={isDeletingItem}
                                confirmationButtonGhost={false}
                                confirmationButtonText={<Translate content="ipBlock.removeBtn" />}
                                translations={{
                                    title: (
                                        <Translate content="ipBlock.batchButtonWithConfirmation.title" />
                                    ),
                                    button: (
                                        <Translate content="ipBlock.batchButtonWithConfirmation.button" />
                                    ),
                                    tooltip: (
                                        <Translate content="ipBlock.batchButtonWithConfirmation.tooltip" />
                                    ),
                                }}
                                handleConfirm={handleRemoveSelected}
                                icon="recycle"
                            />
                        </ToolbarGroup>
                    </Toolbar>
                )}
                <InfiniteScroll
                    loadMore={loadIpBlocksOnScroll}
                    hasMore={!!list.links.next}
                >
                    <Loader isLoading={isLoadingList}>
                        <List
                            emptyView={
                                <EmptyView
                                    title="ipBlock.emptyView.title"
                                    description="ipBlock.emptyView.description"
                                    buttonText="ipBlock.emptyView.buttonText"
                                    onButtonClick={handleCreate}
                                    icon="ip-addresses"
                                />
                            }
                            data-cy={IP_BLOCKS.TABLE}
                            columns={columns}
                            data={data}
                            selection={selection}
                            onSelectionChange={setSelection}
                        />
                    </Loader>
                </InfiniteScroll>
            </StyledTable>
            <Dialog
                heading={<Translate content={title} params={titleParams} />}
                closeHandler={handleClose}
                isOpen={dialogOpened}
                size="xs"
            >
                <Loader isLoading={isLoadingItem} center={false}>
                    <IpBlockForm onSubmit={handleClose} />
                </Loader>
            </Dialog>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    ipBlock: state.ipBlock,
    loadingFlags: {
        isLoadingList: !state.ipBlock.list.data.length && state.app.loadingFlags.has(LOADING_FLAGS.IPBLOCK_LIST),
        isLoadingItem: state.app.loadingFlags.has(LOADING_FLAGS.IPBLOCK_ITEM),
        isDeletingItem: state.app.loadingFlags.has(LOADING_FLAGS.REMOVE_IPBLOCK_ITEM),
    },
    permissions: {
        canManageComputeResources: hasPermission(state, PERMISSION_LIST.MANAGE_COMPUTE_RESOURCES),
    },
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    computeResourceActions: bindActionCreators(computeResourceActions, dispatch),
    ipBlockActions: bindActionCreators(ipBlockActions, dispatch),
    unsetIpBlockItem: bindActionCreators(ipBlockActions.unsetIpBlockItem, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(IpBlock);
