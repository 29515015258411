// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { INTENT_TYPE } from 'common/constants';

export enum ClusterImportCredentialsType {
    PASS = 'lpass',
    KEY = 'lkey',
}

// Should be synced with backend/api/v1/ClusterImports/Products/Product.php
export enum ClusterImportProductType {
    SOLUS_VM_1 = 'solusvm1',
    SOLUS_VM_2 = 'solusvm2'
}

export interface IClusterImportResponse {
    id: number;
    name: string;
    configuration: IClusterImportConfiguration;
    status: string;
    fail_reason: string;
    is_ready: boolean;
    errors_on_import: IClusterImportError[];
    is_deleting?: boolean;
}

export interface IClusterImportResource {
    clusterImport: IClusterImportResponse;
}

export interface IClusterImportConfiguration {
    host: string;
    port: number;
}

export interface IClusterImportRequest {
    name: string;
    configuration: IClusterImportConfiguration;
    credentials: IClusterImportCredentials;
}

export interface IClusterImportCredentials {
    type: string;
    data: IClusterImportCredentialsData;
}

export interface IClusterImportCredentialsData {
    login: string;
    password?: string;
    port: number;
    key?: string;
}

export interface IClusterImportComputeResourceResource {
    id: number;
    name: string;
    type: string;
    source_id: number;
    server_count: number;
    is_importable: boolean;
    not_importable_reasons: string[];
    is_imported: boolean;
}

export interface IClusterImportError {
    source_name: string;
    source_id: number;
    reason: string;
}

// Should be synced with `backend/api/v1/ClusterImports/Enums/ClusterImportStatus.php`
export enum CLUSTER_IMPORT_STATUS {
    PREPARING = 'preparing',
    PRE_CHECKING = 'pre_checking',
    PREPARED = 'prepared',
    REFRESHING = 'refreshing',
    IMPORTING = 'importing',
    FAILED_PREPARE = 'failed_prepare',
    FAILED_PRE_CHECK = 'failed_pre_check',
    FAILED_IMPORT = 'failed_import',
    FAILED_REFRESH = 'failed_refresh',
}

export const ClusterImportStatusIntentMap = {
    [CLUSTER_IMPORT_STATUS.PREPARED]: INTENT_TYPE.SUCCESS,
    [CLUSTER_IMPORT_STATUS.PRE_CHECKING]: INTENT_TYPE.INFO,
    [CLUSTER_IMPORT_STATUS.PREPARING]: INTENT_TYPE.INFO,
    [CLUSTER_IMPORT_STATUS.IMPORTING]: INTENT_TYPE.INFO,
    [CLUSTER_IMPORT_STATUS.REFRESHING]: INTENT_TYPE.INFO,
    [CLUSTER_IMPORT_STATUS.FAILED_PREPARE]: INTENT_TYPE.DANGER,
    [CLUSTER_IMPORT_STATUS.FAILED_PRE_CHECK]: INTENT_TYPE.DANGER,
    [CLUSTER_IMPORT_STATUS.FAILED_IMPORT]: INTENT_TYPE.DANGER,
    [CLUSTER_IMPORT_STATUS.FAILED_REFRESH]: INTENT_TYPE.DANGER,
};
