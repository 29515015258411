// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';

export const DisabledContainer = styled.div`
  opacity: 0.47;
  display: inline-block;
  cursor: not-allowed;

  .pul-action__icon:hover {
    background-color: inherit;
    color: #737373;
  }

  .action-icon {
    pointer-events: none;
  }
`;
