// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    Section,
    SectionItem,
} from '@plesk/ui-library';
import {
    Title,
    Loader,
} from 'common/components';
import { InfoBlock } from 'admin/computeResource/list/components/InfoBlock/Styles';
import { IComputeResourceResponse } from 'common/api/resources/ComputeResource';

export interface IComputeResourceInfoBlockProps {
    item: IComputeResourceResponse;
    isLoading: boolean;
}

export const ComputeResourceInfoBlock: React.FC<IComputeResourceInfoBlockProps> = ({
    item,
    isLoading,
}) => (
    <Loader isLoading={isLoading}>
        <InfoBlock>
            <Section vertical={true}>
                <SectionItem title={<Title content="computeResource.actionDialog.name" />}>
                    {item.name}
                </SectionItem>
                <SectionItem title={<Title content="computeResource.actionDialog.host" />}>
                    {item.host}
                </SectionItem>
                {item.locations.length > 0 && (
                    <SectionItem title={<Title content="computeResource.actionDialog.locations" />}>
                        {item.locations.map(location => location.name).join(', ')}
                    </SectionItem>
                )}
            </Section>
        </InfoBlock>
    </Loader>
);
