// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as storageActions from 'admin/storage/actions';
import { combineReducers } from 'redux';
import {
    ActionType,
    getType,
} from 'typesafe-actions';
import {
    IPaginateApiResponse,
    paginateInitialState,
} from 'common/api/resources/Response';
import {
    IStorageDiskResponse,
    IStorageResponse,
} from 'common/api/resources/Storage';
import { StorageType } from 'common/api/resources/StorageType';
import {
    addToPaginated,
    appendToPaginated,
    deleteFromPaginated,
    updateInPaginated,
} from 'common/reducers/list';

interface IStorage {
    list: IPaginateApiResponse<IStorageResponse[]>;
    item: IStorageResponse;
    disks: IPaginateApiResponse<IStorageDiskResponse[]>;
}

export type StorageAction = ActionType<typeof storageActions>;
export type StorageState = IStorage;

export const initialStorageState: IStorageResponse = {
    id: 0,
    servers_count: 0,
    compute_resources_count: 0,
    is_available_for_balancing: true,
    isDeleting: false,
    isLoading: false,
    name: '',
    mount: '',
    path: '',
    thin_pool: '',
    free_space: 0,
    type: {
        name: StorageType.NFS,
        formats: [],
    },
    credentials: {},
};

export default combineReducers<StorageState, StorageAction>({
    list: (state = paginateInitialState, action: StorageAction) => {
        switch (action.type) {
        case getType(storageActions.appendStorages):
            return appendToPaginated(state, action.payload);
        case getType(storageActions.addStorageItem):
            return addToPaginated(state, action.payload);
        case getType(storageActions.setStorageList):
            return action.payload;
        case getType(storageActions.removeStorageItem):
            return deleteFromPaginated(state, action.payload);
        case getType(storageActions.updateStorageItem):
            return updateInPaginated(state, action.payload);
        case getType(storageActions.setStorageItemIsDeleting):
            return updateInPaginated(state, action.payload);
        case getType(storageActions.setStorageItemIsLoading):
            return updateInPaginated(state, action.payload);
        default:
            return state;
        }
    },
    item: (state = initialStorageState, action) => {
        switch (action.type) {
        case getType(storageActions.setStorageItem):
            return action.payload;
        case getType(storageActions.unsetStorageItem):
            return { ...initialStorageState };
        default:
            return state;
        }
    },
    disks: (state = paginateInitialState, action) => {
        switch (action.type) {
        case getType(storageActions.setStorageDiskList):
            return action.payload;
        default:
            return state;
        }
    },

});
