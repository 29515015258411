// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import { ICommonState } from 'common/store';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import {
    Button,
    copyToClipboard,
    Paragraph,
    Section,
    Translate,
} from '@plesk/ui-library';
import {
    INTENT_TYPE,
    SIZE,
} from 'common/constants';
import { Dialog } from 'common/components/Dialog/Dialog';
import * as twoFactorAuthActions from 'common/modules/auth/twoFactorAuth/actions';
import { RecoveryCodes } from 'common/modules/account/containers/Styles';
import ButtonWithConfirmation from 'common/components/ButtonWithConfirmation';
import { useContext } from 'react';
import { ThemeContext } from 'styled-components';
import { setAndCallInsecureAction } from 'common/modules/auth/confirmIdentity/insecureActionHelpers';

interface ITwoFactorAuthFormEnabledProps {
    shouldGenerateRecoveryCodes: boolean;
}

export type TwoFactorAuthFormEnabledProps =
    ITwoFactorAuthFormEnabledProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const TwoFactorAuthFormEnabled: React.FC<TwoFactorAuthFormEnabledProps> = ({
    recoveryCodeCount,
    recoveryCodes,
    isGeneratingRecoveryCodes,
    isSwitching,
    shouldGenerateRecoveryCodes,
    twoFactorAuthActions: {
        generateNewTwoFactorAuthRecoveryCodes,
        disableTwoFactorAuth,
        unsetTwoFactorAuthRecoveryCodes,
        setShouldGenerateTwoFactorAuthRecoveryCodes,
    },
}) => {
    const { primary_color } = useContext(ThemeContext);

    const [isRecoveryCodesDialogOpened, setIsRecoveryCodesDialogOpened] = React.useState(false);

    const generateAndShowRecoveryCodes = async () => {
        await generateNewTwoFactorAuthRecoveryCodes();
        setIsRecoveryCodesDialogOpened(true);
    };

    const handleGenerateNewRecoveryCodes = async () => {
        await setAndCallInsecureAction(generateAndShowRecoveryCodes);
    };

    const handleCloseRecoveryDialog = (copy: boolean) => () => {
        if (copy) {
            copyToClipboard(recoveryCodes.join('\n'));
        }

        setIsRecoveryCodesDialogOpened(false);
    };

    const handleDisable = async () => {
        await setAndCallInsecureAction(disableTwoFactorAuth);
    };

    React.useEffect(() => {
        if (shouldGenerateRecoveryCodes) {
            generateAndShowRecoveryCodes();
            setShouldGenerateTwoFactorAuthRecoveryCodes(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    React.useEffect(() => () => {
        unsetTwoFactorAuthRecoveryCodes();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <>
            <Section>
                <Paragraph>
                    <Translate content="commonAccount.twoFactorAuthForm.recoveryCodeCount" params={{
                        count: recoveryCodeCount,
                    }}/>
                </Paragraph>
                <ButtonWithConfirmation
                    buttonSize={SIZE.LG}
                    buttonIntent={INTENT_TYPE.INFO}
                    buttonColor={primary_color}
                    confirmationButtonGhost={false}
                    isLoading={isGeneratingRecoveryCodes}
                    confirmationButtonText={<Translate content="commonAccount.twoFactorAuthForm.generateNewRecoveryCodes" />}
                    handleConfirm={handleGenerateNewRecoveryCodes}
                    translations={{
                        title: <Translate content="commonAccount.twoFactorAuthForm.generatingNewRecoveryCodesConfirmationDialog.title" />,
                        text: <Translate content="commonAccount.twoFactorAuthForm.generatingNewRecoveryCodesConfirmationDialog.content" />,
                        button: <Translate content="commonAccount.twoFactorAuthForm.generatingNewRecoveryCodesConfirmationDialog.button" />,
                    }}
                />
            </Section>
            <Section>
                <Paragraph><Translate content="commonAccount.twoFactorAuthForm.isEnabled" /></Paragraph>
                <ButtonWithConfirmation
                    buttonSize={SIZE.LG}
                    buttonIntent={INTENT_TYPE.PRIMARY}
                    confirmationButtonGhost={false}
                    isLoading={isSwitching}
                    confirmationButtonText={<Translate content="commonAccount.twoFactorAuthForm.disableBtn" />}
                    handleConfirm={handleDisable}
                    translations={{
                        title: <Translate content="commonAccount.twoFactorAuthForm.disablingConfirmationDialog.title" />,
                        text: <Translate content="commonAccount.twoFactorAuthForm.disablingConfirmationDialog.content" />,
                        button: <Translate content="commonAccount.twoFactorAuthForm.disablingConfirmationDialog.button" />,
                    }}
                />
            </Section>
            <Dialog
                heading={<Translate content="commonAccount.twoFactorAuthForm.recoveryCodesDialog.title"/>}
                closeHandler={handleCloseRecoveryDialog(false)}
                isOpen={isRecoveryCodesDialogOpened}
                size={SIZE.XS}
            >
                <Section>
                    <Translate content="commonAccount.twoFactorAuthForm.recoveryCodesDialog.info"/>
                </Section>
                <Section>
                    <RecoveryCodes>{recoveryCodes.map((code, idx) => (<div key={idx}>{code}</div>))}</RecoveryCodes>
                </Section>
                <Button
                    fill={true}
                    intent={INTENT_TYPE.PRIMARY}
                    size={SIZE.LG}
                    onClick={handleCloseRecoveryDialog(true)}
                >
                    <Translate content="commonAccount.twoFactorAuthForm.recoveryCodesDialog.copyAndClose"/>
                </Button>
            </Dialog>
        </>
    );
};

const mapStateToProps = (state: ICommonState) => ({
    recoveryCodeCount: state.auth.user.two_factor_auth_recovery_code_count,
    recoveryCodes: state.twoFactorAuth.recovery_codes,
    shouldGenerateRecoveryCodes: state.twoFactorAuth.should_generate_recovery_codes,
    isSwitching: state.app.loadingFlags.has(LOADING_FLAGS.TWO_FACTOR_AUTH_SWITCH),
    isGeneratingRecoveryCodes: state.app.loadingFlags.has(LOADING_FLAGS.TWO_FACTOR_AUTH_GENERATE_RECOVERY_CODES),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    twoFactorAuthActions: bindActionCreators(twoFactorAuthActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(TwoFactorAuthFormEnabled);
