// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { Dispatch } from 'redux';
import {
    setIsLoading,
    unsetIsLoading,
} from 'common/modules/app/loadingFlags/actions';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { HTTP_CODES } from 'common/api/constants';
import { createCustomAction } from 'typesafe-actions';
import * as types from 'client/account/constants/types';
import { accounts } from 'common/api/resources/Account';
import {
    ISshKeyRequest,
    ISshKeyResponse,
    sshKeys,
} from 'common/api/resources/SshKey';

export const appendSshKeyItem = createCustomAction(
    types.APPEND_SSH_KEY_ITEM,
    (data: ISshKeyResponse) => ({ payload: data })
);
export const removeSshKeyItem = createCustomAction(
    types.REMOVE_SSH_KEY_ITEM,
    (id: number) => ({ payload: id })
);
export const setSshKeys = createCustomAction(
    types.SET_SSH_KEY_LIST,
    (data: ISshKeyResponse[]) => ({ payload: data })
);
export const setItemIsLoading = createCustomAction(
    types.SET_IS_LOADING,
    (id: number) => ({ payload: id })
);
export const unsetItemIsLoading = createCustomAction(
    types.UNSET_IS_LOADING,
    (id: number) => ({ payload: id })
);

export const getSshKeys = () => async(dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.AUTH_SSH_KEY_LIST));
    try {
        const result = await accounts.sshKeys.list();
        if (result.status === HTTP_CODES.OK) {
            dispatch(setSshKeys(result.data.data));
        }

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.AUTH_SSH_KEY_LIST));
    }
};

export const createSshKey = (data: ISshKeyRequest) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.SAVE_AUTH_SSH_KEY));

    try {
        const result = await accounts.sshKeys.create(data);

        if (result.status === HTTP_CODES.CREATED) {
            dispatch(appendSshKeyItem(result.data.data));
        }

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.SAVE_AUTH_SSH_KEY));
    }
};

export const removeSshKey = (data: ISshKeyResponse) => async (dispatch: Dispatch) => {
    dispatch(setItemIsLoading(data.id));

    try {
        const result = await sshKeys.remove(data.id);

        if (result.status === HTTP_CODES.NO_CONTENT) {
            dispatch(removeSshKeyItem(data.id));
        }

        return result;
    } finally {
        dispatch(unsetItemIsLoading(data.id));
    }
};
