// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import React from 'react';
import {
    Action,
    Translate,
    ProgressStep,
} from '@plesk/ui-library';
import {
    StatusText,
    StatusIconContainer,
    StatusSubtext,
    ProgressContainer,
} from 'admin/update/containers/VersionState/Styles';
import { IComputeResourcesUpgradeState } from 'common/api/resources/Update';
import { PROGRESS_STATUS } from 'common/constants';
import { formatUpdateDate } from 'common/date';

export interface IVersionUpdatingProps {
    isMasterUpdated: boolean;
    startedAt: string;
    computeResources: IComputeResourcesUpgradeState;
}

export const VersionUpdating: React.FC<IVersionUpdatingProps> = ({
    isMasterUpdated,
    startedAt,
    computeResources,
}) => {
    const { total, done } = computeResources;
    const progress = done / total * 100;

    return (
        <>
            <StatusIconContainer>
                <Action progress={true} />
            </StatusIconContainer>
            <div>
                <StatusText>
                    <Translate content="update.status.updating" />
                </StatusText>
                <StatusSubtext>
                    <Translate content="update.startedAt" />
                    &nbsp;
                    {formatUpdateDate(startedAt)}
                </StatusSubtext>
                <ProgressContainer>
                    <ProgressStep
                        title={<Translate content="update.step.downloadingUpdate" />}
                        status={PROGRESS_STATUS.DONE}
                    />
                    <ProgressStep
                        title={<Translate content="update.step.masterNodeUpdating" />}
                        status={isMasterUpdated ? PROGRESS_STATUS.DONE : PROGRESS_STATUS.RUNNING}
                    />
                    <ProgressStep
                        title={<Translate content="update.step.computeResourcesUpgrading" />}
                        status={isMasterUpdated ? PROGRESS_STATUS.RUNNING : PROGRESS_STATUS.NOT_STARTED}
                        progress={progress}
                        statusText={`${done} of ${total}`}
                    />
                </ProgressContainer>
            </div>
        </>
    );
};

export default VersionUpdating;
