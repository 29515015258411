// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';
import { Button as UIButton } from '@plesk/ui-library';
import { rgba } from 'polished';

interface IStyledButton {
    color: string;
}

export const Button = styled(UIButton)`
  .pul-button__icon {
    color: inherit !important;
  }
`;

export const StyledButton = styled.div<IStyledButton>`
    a.pul-button--disabled {
      margin-top: 15px;
      background: ${props => props.color} !important;
      border-radius: 2px;
      min-width: 141px;
      min-height: 40px;
      font-weight: 600;
      font-size: 14px;
      line-height: 28px;
    }

    button {
        margin-top: 15px;
        background: ${props => props.color} !important;
        box-shadow: 0 1px 1px ${props => props.color && rgba(props.color, 0.28)};
        color: #fff;
        border-radius: 2px;
        min-width: 141px;
        min-height: 40px;
        font-weight: 600;
        font-size: 14px;
        line-height: 20px;

        &:hover {
            background: ${props => props.color} !important;
            box-shadow: 0 1px 1px ${props => props.color && rgba(props.color, 0.28)};
            color: #fff;
        }
    }
`;