// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { Translate } from '@plesk/ui-library';
import {
    Header,
    Title,
} from 'admin/common/components/PageHeader/Styles';
import { Button } from 'admin/common/components/Button/Button';
import {
    INTENT_TYPE,
    SIZE,
} from 'common/constants';
import { Link } from 'react-router-dom';

export interface IPageHeaderProps {
  title: React.ReactNode;
  onButtonClick?: () => void;
  buttonText?: string;
  buttonTo?: React.ComponentProps<typeof Link>['to'];
  buttonIcon?: string;
  isButtonShown: boolean;
}

export const PageHeader: React.FC<IPageHeaderProps> = ({
    title,
    onButtonClick,
    buttonTo,
    buttonText,
    buttonIcon,
    isButtonShown,
}) => {
    const btnProps = buttonTo
        ? { to: buttonTo }
        : {
            type: 'submit',
            onClick: onButtonClick,
        } as const;

    return (
        <Header>
            <Title>{title}</Title>

            {isButtonShown && (
                <Button
                    size={SIZE.LG}
                    intent={INTENT_TYPE.PRIMARY}
                    icon={buttonIcon}
                    {...btnProps}
                >
                    {buttonText && (<Translate content={buttonText}/>)}
                </Button>
            )}
        </Header>
    );
};
