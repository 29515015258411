// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { ResetLimitPolicy } from 'common/api/resources/Plan';
import {
    FormFieldSelect,
    Translate,
    useTranslate,
} from '@plesk/ui-library';

const ResetLimitField: React.FC = () => {
    const translate = useTranslate();

    const localizationMap = React.useMemo(() => ({
        [ResetLimitPolicy.FirstDayOfMonth]: translate('plan.actionDialog.resetLimitPolicy.firstDayOfMonth'),
        [ResetLimitPolicy.VmCreatedDay]: translate('plan.actionDialog.resetLimitPolicy.vmCreatedDay'),
        [ResetLimitPolicy.Never]: translate('plan.actionDialog.resetLimitPolicy.never'),
    }), [translate]);

    return (
        <FormFieldSelect
            size="fill"
            name="reset_limit_policy"
            label={<Translate content="plan.actionDialog.whenLimitsAreReset"/>}
        >
            {Object.values(ResetLimitPolicy).map((policy, key) => (
                <option key={key} value={policy}>{localizationMap[policy]}</option>
            ))}
        </FormFieldSelect>
    );
};

export default ResetLimitField;
