// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'admin/core/store';
import * as settingsActions from 'common/modules/settings/actions';
import {
    UPDATE_METHOD,
    ISettingsResponse,
} from 'common/api/resources/Settings';
import { ISelectOption } from 'common/components';
import {
    FormField,
    Section,
    Translate,
} from '@plesk/ui-library';
import SelectInput from 'common/components/Select/SelectInput';
import { SegmentedControl } from 'common/components/SegmentedControl/SegmentedControl';
import { FormFieldTime } from 'common/components/Form/FormFieldTime/FormFieldTime';
import SettingsWrapper from 'admin/settings/containers/Settings/SettingsWrapper';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import WeekdaysSelector from 'common/components/WeekdaysSelector/WeekdaysSelector';

export type SystemUpdatesSettingsProps =
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

const updateMethods = [
    {
        value: UPDATE_METHOD.AUTO,
        label: <Translate content="settings.update.updateMethods.auto" />,
    },
    {
        value: UPDATE_METHOD.MANUAL,
        label: <Translate content="settings.update.updateMethods.manual" />,
    },
];

export const SystemUpdatesSettings: React.FC<SystemUpdatesSettingsProps> = ({
    settings,
    settingsActions: {
        setSettings,
        saveSettings,
    },
}) => {
    const channelsOpts = settings.update_channels.map(item => ({ label: item, value: item }));
    const getChannelValue = (value: string) => channelsOpts.find((channel) => channel.value === value);

    const handleChangeWeekdays = (days: number[]) => {
        setSettings({
            ...settings,
            update_schedule: {
                ...settings.update_schedule,
                scheduled_days: days,
            },
        });
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleChange = (selected: any) => {
        const values = selected ? selected.map((item: ISelectOption) => item.value) : [];
        handleChangeWeekdays(values);
    };

    const handleChangeMethod = (value: string) => {
        setSettings({
            ...settings,
            update: {
                ...settings.update,
                method: value as UPDATE_METHOD,
            },
        });
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleChangeChannel = (selected: any) => {
        setSettings({
            ...settings,
            update: {
                ...settings.update,
                channel: selected.value,
            },
        });
    };

    const handleSubmit = (values: ISettingsResponse) => {
        saveSettings({
            update: values.update,
            update_schedule: values.update_schedule,
        });
    };

    return (
        <SettingsWrapper
            title="settings.titles.update"
            onSubmit={handleSubmit}
        >
            <FormField
                name="update[channel]"
                label={<Translate content="settings.update.channel" />}
                required={true}
                style={{ maxWidth: '500px' }}
            >
                <SelectInput<ISelectOption>
                    options={channelsOpts}
                    onChange={handleChangeChannel}
                    value={getChannelValue(settings.update.channel)}
                    menuPosition="fixed"
                />
            </FormField>
            <FormField
                name="update[method]"
                label={<Translate content="settings.update.method" />}
            >
                <SegmentedControl
                    buttons={updateMethods.map(type => ({ value: type.value.toString(), title: type.label }))}
                    selected={settings.update.method}
                    onChange={handleChangeMethod}
                />
            </FormField>
            <Section title={<Translate content="settings.update.schedule" />}>
                <FormField
                    name="update_schedule[scheduled_days]"
                    label={<Translate content="settings.update.weekDays" />}
                    style={{ maxWidth: '500px' }}
                >
                    <WeekdaysSelector
                        isMulti={true}
                        isDisabled={settings.update.method === UPDATE_METHOD.MANUAL}
                        onChange={handleChange}
                        value={settings.update_schedule.scheduled_days}
                        menuPosition="fixed"
                    />
                </FormField>
                <FormFieldTime
                    label={<Translate content="settings.update.time" />}
                    disabled={settings.update.method === UPDATE_METHOD.MANUAL}
                    name="update_schedule[scheduled_time]"
                    size="md"
                    required={false}
                />
            </Section>
        </SettingsWrapper>
    );
};

const mapStateToProps = (state: RootState) => ({
    settings: state.settings,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    settingsActions: bindActionCreators(settingsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(SystemUpdatesSettings);
