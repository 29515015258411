// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    FormField,
    Input,
    Size,
} from '@plesk/ui-library';
import moment from 'moment';

export interface IFormFieldTimeProps {
    name: string;
    label: JSX.Element;
    size: number | Size;
    required?: boolean;
    disabled?: boolean;
    value?: string;
    onChange?: (time: string) => void;
}

/**
 * FormFieldTime component works with date in UTC timezone but displays it in local timezone.
 */
export const FormFieldTime: React.FC<IFormFieldTimeProps> = ({
    name,
    label,
    size,
    disabled,
    value,
    onChange,
    ...props
}) => {
    const handleChangeCommand = (command: (value: string) => void) => (event: React.FormEvent<HTMLInputElement>) => {
        command(convertLocalTimeToUTC(event.currentTarget.value || undefined));
    };

    return (
        <FormField
            size="fill"
            id={name}
            name={name}
            label={label}
            onChange={onChange}
            {...props}
        >
            {({ setValue, getValue }) => (
                <Input
                    type="time"
                    disabled={disabled}
                    value={convertUTCToLocalTime(value !== undefined ? value : getValue())}
                    onChange={handleChangeCommand(setValue)}
                    size={size}
                    id={name}
                    name={name}
                />
            )}
        </FormField>
    );
};

const DEFAULT_TIME = '00:00';

const convertUTCToLocalTime = (time: string = DEFAULT_TIME) => moment
    .utc(time, 'HH:mm')
    .local()
    .format('HH:mm');

const convertLocalTimeToUTC = (time: string = DEFAULT_TIME) => moment(time, 'HH:mm')
    .utc()
    .format('HH:mm');
