// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { GridCol as UILibGridCol } from '@plesk/ui-library';

export const GridCol: React.FC<React.PropsWithChildren> = ({ children }) => (
    <UILibGridCol
        xs={12}
        sm={12}
        md={12}
        xl={7}
        lg={7}
    >
        {children}
    </UILibGridCol>
);
