// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { SIZE } from 'common/constants';
import { GridCol } from '@plesk/ui-library';
import { ItemList } from 'common/components/ServerTabs/NetworkingTab/Styles';
import LimitCard from 'common/components/ServerTabs/components/LimitCard/LimitCard';
import { IServerLimitResponse } from 'common/api/resources/ComputeResourceVm';

export interface ILimitsGridProps {
    limits: IServerLimitResponse[];
}

export const LimitsGrid: React.FC<ILimitsGridProps> = ({ limits }) => (
    <GridCol xs={12} sm={12} md={12} xl={7} lg={7}>
        <ItemList xs={1} sm={1} md={2} xl={2} lg={2} gap={SIZE.MD} stretchable={true}>
            {limits.map(limit => (
                <LimitCard limit={limit} key={limit.name} />
            ))}
        </ItemList>
    </GridCol>
);

export default LimitsGrid;
