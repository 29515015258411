// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as osImageVersionActions from 'common/modules/osImage/actionsVersions';
import { combineReducers } from 'redux';
import {
    ActionType,
    getType,
} from 'typesafe-actions';
import { IOsImageVersionResponse } from 'common/api/resources/OsImageVersion';
import { VirtualizationType } from 'common/api/resources/ComputeResource';
import { DiskDriver } from 'common/api/resources/ComputeResourceVm';

interface IOsImageVersion {
    item: IOsImageVersionResponse;
}

export type OsImageVersionAction = ActionType<typeof osImageVersionActions>;
export type OsImageVersionState = IOsImageVersion;

export const initialOsImageVersionState: IOsImageVersionResponse = {
    id: 0,
    position: 0,
    version: '',
    url: '',
    cloud_init_version: '',
    os_image_id: 0,
    virtualization_type: VirtualizationType.KVM,
    is_visible: true,
    is_loading: false,
    available_plans: [],
    vs_disk_driver: DiskDriver.SCSI,
};

export default combineReducers<OsImageVersionState, OsImageVersionAction>({
    item: (state = initialOsImageVersionState, action) => {
        switch (action.type) {
        case getType(osImageVersionActions.setOsImageVersionItem):
            return action.payload;
        case getType(osImageVersionActions.unsetOsImageVersionItem):
            return { ...initialOsImageVersionState };
        default:
            return state;
        }
    },
});
