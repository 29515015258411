// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import {
    ActionType,
    getType,
} from 'typesafe-actions';
import * as confirmIdentityActions from 'common/modules/auth/confirmIdentity/actions';

interface IConfirmIdentityReducer {
    isDialogOpened: boolean;
}

export type ConfirmIdentityAction = ActionType<typeof confirmIdentityActions>;
export type ConfirmIdentityState = Readonly<IConfirmIdentityReducer>;

const initialConfirmIdentityState: ConfirmIdentityState = {
    isDialogOpened: false,
};

export default (state: ConfirmIdentityState = initialConfirmIdentityState, action: ConfirmIdentityAction) => {
    switch (action.type) {
    case getType(confirmIdentityActions.setConfirmIdentityIsIsOpened):
        return {
            ...state,
            isDialogOpened: action.payload,
        };
    default:
        return state;
    }
};
