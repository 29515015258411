// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

export const SET_ROLE_LIST = 'role/SET_ROLE_LIST';
export const APPEND_ROLES = 'role/APPEND_ROLES';
export const ADD_ROLE_ITEM = 'role/ADD_ROLE_ITEM';
export const SET_ROLE_ITEM = 'role/SET_ROLE_ITEM';
export const UNSET_ROLE_ITEM = 'role/UNSET_ROLE_ITEM';
export const UPDATE_ROLE_ITEM = 'role/UPDATE_ROLE_ITEM';
export const REMOVE_ROLE_ITEM = 'role/REMOVE_ROLE_ITEM';
export const SET_ROLE_ITEM_IS_DELETING = 'role/SET_ROLE_ITEM_IS_DELETING';
