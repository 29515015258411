// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { createCustomAction } from 'typesafe-actions';
import * as types from 'common/modules/app/formErrors/constants';

export const setFormErrors = createCustomAction(
    types.SET_FORM_ERRORS,
    (data: Record<string, unknown>) => ({ payload: data })
);
export const clearFormErrors = createCustomAction(
    types.CLEAR_FORM_ERRORS,
    () => ({})
);
