// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    ServerInfoBlockWrapper,
    ServerInfoDescription,
    ServerInfoTitle,
} from 'admin/computeResourceVm/containers/ComputeResourceVmRow/Styles';

export interface IServerInfoBlockProps {
    title: React.ReactNode;
}

export const ServerInfoBlock: React.FC<React.PropsWithChildren<IServerInfoBlockProps>> = ({
    title,
    children,
    ...rest
}) => (
    <ServerInfoBlockWrapper {...rest}>
        <ServerInfoTitle>
            {title}
        </ServerInfoTitle>
        <ServerInfoDescription>
            {children}
        </ServerInfoDescription>
    </ServerInfoBlockWrapper>
);

export default ServerInfoBlock;
