// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { IIconResponse } from 'common/api/resources/Icon';

export interface IServerIconProps extends React.HTMLProps<HTMLImageElement> {
    withName?: boolean;
    width?: number;
    icon: IIconResponse | null;
}

const DEFAULT_WIDTH = 25;

export const ServerIcon: React.FC<IServerIconProps> = ({
    icon,
    withName,
    width= DEFAULT_WIDTH,
}) => {
    if (icon === null) {
        return null;
    }

    if (!withName) {
        return (<img width={width} src={icon.url}  alt={icon.name}/>);
    }

    return (
        <>
            <img
                width={width}
                src={icon.url}
                style={{ marginRight: '8px' }}
                alt={icon.name}
            /> {icon.name}
        </>
    );
};
