// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    useHistory,
    useLocation,
} from 'react-router';

function paramsToObject(params: URLSearchParams): Record<string, string> {
    const result = {};

    params.forEach((value, key) => {
        result[key] = value;
    });

    return result;
}

export type SetQuery<T> = React.SetStateAction<T>;
export type QueryDispatch<T> = React.Dispatch<SetQuery<T>>;

export default function useQuery<T = Record<string, string | undefined | null>>(): [T, QueryDispatch<T>] {
    const history = useHistory();
    const { search } = useLocation();

    const query = React.useMemo(() => {
        const queryParams = new URLSearchParams(search);
        return paramsToObject(queryParams) as T;
    }, [search]);
    const setQuery = React.useCallback((newQuery: SetQuery<T>) => {
        if (typeof newQuery === 'function') {
            newQuery = (newQuery as (prevQuery: T) => T)(query) as T;
        }

        const params = Object.fromEntries(
            Object.entries(newQuery as Record<string, string | undefined | null>)
                .filter(([_, value]) => value !== undefined && value !== null) as Array<[string, string]>
        );

        const newQueryParams = new URLSearchParams(params);
        history.replace({
            ...history.location,
            search: newQueryParams.toString(),
        });
    }, [query, history]);

    return [
        query,
        setQuery,
    ];
}