// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { IPlanLimit } from 'common/api/resources/Plan';
import { INetworkTraffic } from 'common/api/resources/ComputeResourceVm';
import { colorBetween } from 'common/helpers/color';
import { COLORS } from 'common/theme';
import {
    ICONS,
    INTENT_TYPE,
    ITEM_VIEW,
    SIZE,
} from 'common/constants';
import {
    Item,
    ProgressBar,
} from 'common/components/ServerTabs/NetworkingTab/components/TrafficCard/Styles';
import {
    Alert,
    Column,
    Columns,
    Icon,
    Translate,
    Text,
} from '@plesk/ui-library';
import {
    convertToDataUnit,
    DataUnit,
} from 'common/helpers/units';
import TrafficProgress from 'common/components/ServerTabs/NetworkingTab/components/TrafficCard/TrafficProgress';

export interface ITotalTrafficCardProps {
    incomingTraffic: INetworkTraffic;
    outgoingTraffic: INetworkTraffic;
    limit: IPlanLimit<DataUnit>;
}

export const TotalTrafficCard: React.FC<ITotalTrafficCardProps> = ({
    incomingTraffic,
    outgoingTraffic,
    limit,
}) => {
    const convertedValue = convertToDataUnit(incomingTraffic.value + outgoingTraffic.value, limit.unit);
    const progressPercent = limit.limit ? (100 * convertedValue) / limit.limit : 0;
    const progress = progressPercent >= 100 ? 100 : progressPercent;
    const isExceeded = incomingTraffic.is_exceeded || outgoingTraffic.is_exceeded;
    return (
        <Item
            icon={isExceeded &&(
                <Icon
                    size={24}
                    name={ICONS.TRIANGLE_EXCLAMATION_MARK_FILLED}
                    intent={INTENT_TYPE.WARNING}
                />
            )}
            title={
                <TrafficProgress
                    limit={limit}
                    title={<Translate content="servers.tabs.networking.traffic.total.title" />}
                    component="h3"
                    value={convertedValue}
                    isExceeded={isExceeded}
                />
            }
            view={ITEM_VIEW.CARD}
            footer={
                <div>
                    <ProgressBar
                        color={colorBetween(COLORS.SUCCESS, COLORS.WARNING, progress)}
                        progress={limit.is_enabled ? progress : 0}
                    />
                    <Columns gap={SIZE.XL} vertical={false} style={{ marginTop: '16px', marginBottom: '16px' }}>
                        <Column>
                            <Translate content="servers.tabs.networking.traffic.total.outgoing" />
                            <Text bold> {convertToDataUnit(outgoingTraffic.value, limit.unit)} </Text>
                            {limit.unit}
                        </Column>
                        <Column>
                            <Translate content="servers.tabs.networking.traffic.total.incoming" />
                            <Text bold> {convertToDataUnit(incomingTraffic.value, limit.unit)} </Text>
                            {limit.unit}
                        </Column>
                    </Columns>
                    {isExceeded ? (
                        <Alert intent={INTENT_TYPE.WARNING}>
                            <Translate content="servers.tabs.networking.traffic.total.exceededDescription"/>
                        </Alert>
                    ) : (
                        <Translate content="servers.tabs.networking.traffic.total.description"/>
                    )}
                </div>
            }
        />
    );
};

export default TotalTrafficCard;
