// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';

export const ProgressWrapper = styled.div`
  padding-bottom: 20px;

  > div:first-child {
    margin-bottom: 18px;
  }
`;
