// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';
import { COLORS } from 'admin/core/theme';

export const Header = styled.div`
    display: flex;
    align-items: baseline;

    padding: 30px 0 0;
    margin-bottom: 12px;
    
    @media (max-width: 560px) {
        padding: 15px 0 5px;
    }
`;

export const Title = styled.div`
    font-style: normal;
    font-weight: 600;
    font-size: 20px;
    line-height: 26px;
    color: ${COLORS.GREY_100};

    @media (max-width: 560px) {
        font-size: 14px;
        line-height: 24px;
        margin-bottom: 6px;
    }
`;

export const Action = styled.div`
  margin-left: 12px;
`;
