// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { Icon } from '@plesk/ui-library';
import { ICONS } from 'common/constants';
import {
    Message,
    MessageContainer,
    MessageContent,
    MessageTitle,
} from 'client/common/components/AlertMessage/Styles';

export interface IAlertMessageProps {
    message?: string | React.ReactNode;
    title: string | React.ReactNode;
    isError?: boolean;
    onClose?: () => void;
}

export const AlertMessage: React.FC<IAlertMessageProps> = ({
    title,
    message,
    isError,
    onClose,
}) => (
    <MessageContainer>
        <Message error={isError}>
            <MessageTitle>
                {title}
            </MessageTitle>
            {message && (<MessageContent>
                {message}
            </MessageContent>)}
            {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
            {onClose && (<a onClick={onClose}>
                <Icon className="close" name={ICONS.CROSS_MARK} />
            </a>)}
        </Message>
    </MessageContainer>
);
