// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { FC } from 'react';
import { IVlanResponse } from 'common/api/resources/Vlan';
import { StyledActions } from 'common/components/Actions/Styles';
import {
    Action,
    Tooltip,
    Translate,
} from '@plesk/ui-library';
import ButtonWithConfirmation from 'common/components/ButtonWithConfirmation';
import { ICONS } from 'common/constants';
import { dataCySelector } from 'common/tests/selectors';
import { TABLE_ACTIONS } from 'admin/vlan/constants';

export interface IActionColumnProps {
    vlan: IVlanResponse;
    handleEdit: (id: number) => () => void;
    handleRemove: (id: number) => () => void;
}

const ActionsColumn: FC<IActionColumnProps> = ({
    vlan,
    handleEdit,
    handleRemove,
}) => (
    <StyledActions>
        <Tooltip title={<Translate content="vlan.tooltip.edit"/>}>
            <Action
                icon={ICONS.PENCIL}
                className="action-icon"
                onClick={handleEdit(vlan.id)}
                data-cy={dataCySelector(vlan.id, TABLE_ACTIONS.EDIT)}
            />
        </Tooltip>
        <ButtonWithConfirmation
            translations={{
                title: (
                    <Translate content="vlan.confirmationRemove.title" />
                ),
                button: (
                    <Translate content="vlan.confirmationRemove.button" />
                ),
                tooltip: (
                    <Translate content="vlan.confirmationRemove.tooltip" />
                ),
            }}
            handleConfirm={handleRemove(vlan.id)}
            data-cy={dataCySelector(vlan.id, TABLE_ACTIONS.REMOVE)}
            icon={ICONS.RECYCLE}
        />
    </StyledActions>
);

export default ActionsColumn;