// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import projectReducer,
{ ProjectState as ProjectModuleState } from 'common/modules/project/reducer';
import serverReducer,
{ VmState } from 'common/modules/computeResourceVm/reducers';
import sshKeyReducer,
{ SshKeyState } from 'common/modules/sshKey/reducer';
import memberReducer,
{ MemberState } from 'client/project/reducers/member';
import mailConfirmationReducer from 'client/project/reducers/mailConfirmation';
import { combineReducers } from 'redux';

interface IProject {
    projects: ProjectModuleState;
    servers: VmState;
    ssh_keys: SshKeyState;
    members: MemberState;
    is_verify_message_shown: boolean;
}

export type ProjectState = Readonly<IProject>;

export default combineReducers({
    projects: projectReducer,
    members: memberReducer,
    servers: serverReducer,
    ssh_keys: sshKeyReducer,
    is_verify_message_shown: mailConfirmationReducer,
});
