// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { createCustomAction } from 'typesafe-actions';
import * as types from 'common/modules/project/constants/types';
import { Dispatch } from 'redux';
import { HTTP_CODES } from 'common/api/constants';
import {
    graphs,
    ICpuItem,
    IDisksItem,
    IGraphResponse,
    IMemoryItem,
    INetworkItem,
    IUpdateChartItem,
} from 'common/api/resources/Graph';
import {
    setIsLoading,
    unsetIsLoading,
} from 'common/modules/app/loadingFlags/actions';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';

export const setDisks = createCustomAction(
    types.SET_DISKS_CHART_DATA,
    (data: IGraphResponse<IDisksItem[]>) => ({ payload: data })
);
export const setNetwork = createCustomAction(
    types.SET_NETWORK_CHART_DATA,
    (data: IGraphResponse<INetworkItem[]>) => ({ payload: data })
);
export const setCpu = createCustomAction(
    types.SET_CPU_CHART_DATA,
    (data: IGraphResponse<ICpuItem[]>) => ({ payload: data })
);
export const setMemory = createCustomAction(
    types.SET_MEMORY_CHART_DATA,
    (data: IGraphResponse<IMemoryItem[]>) => ({ payload: data })
);

export const updateChart = createCustomAction(
    types.UPDATE_CHART,
    (data: IUpdateChartItem) => ({ payload: data })
);

export const loadChartData = (uuid: string) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.PROJECT_SERVER_GRAPHS));

    try {
        await Promise.all([
            getCpuGraph(uuid)(dispatch),
            getNetworkGraph(uuid)(dispatch),
            getDisksGraph(uuid)(dispatch),
            getMemoryGraph(uuid)(dispatch),
        ]);
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.PROJECT_SERVER_GRAPHS));
    }
};

export const getMemoryGraph = (uuid: string) => async (dispatch: Dispatch) => {
    const result = await graphs.memory(uuid);
    if (result.status === HTTP_CODES.OK) {
        dispatch(setMemory(result.data.data));
    }

    return result;
};

export const getNetworkGraph = (uuid: string) => async (dispatch: Dispatch) => {
    const result = await graphs.network(uuid);
    if (result.status === HTTP_CODES.OK) {
        dispatch(setNetwork(result.data.data));
    }

    return result;
};

export const getCpuGraph = (uuid: string) => async (dispatch: Dispatch) => {
    const result = await graphs.cpu(uuid);
    if (result.status === HTTP_CODES.OK) {
        dispatch(setCpu(result.data.data));
    }

    return result;
};

export const getDisksGraph = (uuid: string) => async (dispatch: Dispatch) => {
    const result = await graphs.disks(uuid);
    if (result.status === HTTP_CODES.OK) {
        dispatch(setDisks(result.data.data));
    }

    return result;
};
