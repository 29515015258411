// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { IShortBackupNodeResponse } from 'common/api/resources/BackupNode';
import { IShortUserResponse } from 'common/api/resources/User';
import {
    IPaginated,
    IRequest,
} from 'common/api/resources/Request/request';
import {
    IShortComputeResourceResponse,
    VirtualizationType,
} from 'common/api/resources/ComputeResource';
import { IShortProjectResponse } from 'common/api/resources/Project';

// Should be synced with `backend/api/v1/Backup/Enums/BackupCreationMethod.php`
export enum BackupCreationMethod {
    AUTO = 'auto',
    MANUAL = 'manual',
}

// Should be synced with `backend/api/v1/Backup/Enums/BackupStatus.php`
// GARBAGE status should not be received on the frontend side.
export enum BackupStatus {
    PENDING = 'pending',
    IN_PROGRESS = 'in_progress',
    CREATED = 'created',
    FAILED = 'failed',
    CREATING = 'creating',
    RESTORING = 'restoring',
    HIDDEN = 'hidden',
}

// Should be synced with `backend/api/v1/Backup/Enums/BackupType.php`
// FULL type is returned in case backup has type FULL or FULL_BEFORE_INCREMENTAL
export enum BackupType {
    FULL = 'full',
    INCREMENTAL = 'incremental',
}

export enum BackupRestoreUnavailableReason {
    NO_REASON = '',
    DOES_NOT_HAVE_VM = 'DoesNotHaveVmForRestore',
    BACKUP_DISK_IS_TOO_BIG = 'BackupDiskIsTooBig',
    IS_GARBAGE_BACKUP = 'IsGarbageBackup',
    BACKUP_IS_NOT_CREATED = 'BackupIsNotCreated',
    VM_IS_RESCUE_MODE = 'VmInRescueMode',
    VM_IS_SUSPENDED = 'VmIsSuspended',
    BACKUP_IS_DISABLED = 'BackupIsDisabled',
    NO_AVAILABLE_BACKUP_NODES = 'NoAvailableBackupNodes',
    TASK_IN_PROGRESS = 'TaskInProgress',
    CR_INVALID_STATUS = 'ComputeResourceInvalidStatus',
}

export interface IBackupRestoreResponse {
    is_available: boolean;
    unavailable_reason: BackupRestoreUnavailableReason;
}

export interface IBackupResponse {
    id: number;
    creation_method: BackupCreationMethod;
    status: BackupStatus;
    size: number;
    compute_resource_vm: IBackupServerResponse | null;
    backup_node: IShortBackupNodeResponse;
    creator: IShortUserResponse;
    created_at: string;
    backup_progress?: number;
    backup_fail_reason: string;
    is_deleting: boolean;
    disk: number;
    // next props are available for users with MANAGE_BACKUPS permission
    type?: BackupType;
    incremental_backups?: number[];
    restore: IBackupRestoreResponse;
}

export interface IBackupServerResponse {
    id: number;
    name: string;
    virtualization_type: VirtualizationType;
    uuid: string;
    full_backup_count: number;
    deleted_at: string | null;
    compute_resource?: IShortComputeResourceResponse;
    user?: IShortUserResponse;
    project?: IShortProjectResponse;
}

export interface IUpdateBackupResource {
    backup: IBackupResponse;
}

export interface IBackupListFilters {
    creator_id?: number;
    compute_resource_id?: number;
    compute_resource_vm_id?: number;
    backup_node_id?: number;
    only_for_deleted_servers?: string;
}

export interface IBackupListRequest extends IRequest<IBackupListFilters>, IPaginated {}

export interface IRestoreServerRequest {
    destination_compute_resource_id: number;
    temporary_os_image_version_id: number;
    user_id: number;
    project_id: number;
}
