// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { StyledButton } from 'admin/common/components/Button/Styles';
import { ButtonProps as UIButtonProps } from '@plesk/ui-library';
import { Link } from 'react-router-dom';
import React from 'react';

type ButtonProps = Omit<UIButtonProps, 'component'> & {
    to?: React.ComponentProps<typeof Link>['to'];
    isLoading?: boolean;
};

export function Button({
    isLoading,
    state,
    to,
    ...rest
}: ButtonProps) {

    const props = { ...rest, state: isLoading ? 'loading' : (state || null) };

    return to
        ? <StyledButton component={Link} to={to} {...props} />
        : <StyledButton {...props} />;
}

