// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { Dispatch } from 'redux';
import {
    setIsLoading,
    unsetIsLoading,
} from 'common/modules/app/loadingFlags/actions';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { HTTP_CODES } from 'common/api/constants';
import { createCustomAction } from 'typesafe-actions';
import * as types from 'common/modules/apiToken/constants/types';
import {
    apiTokens,
    IApiTokenCreateRequest,
    IApiTokenResponse,
} from 'common/api/resources/ApiToken';
import { accounts } from 'common/api/resources/Account';

export const appendApiTokenItem = createCustomAction(
    types.APPEND_API_TOKEN_ITEM,
    (data: IApiTokenResponse) => ({ payload: data })
);
export const removeApiTokenItem = createCustomAction(
    types.REMOVE_API_TOKEN_ITEM,
    (id: string) => ({ payload: id })
);
export const setApiTokens = createCustomAction(
    types.SET_API_TOKEN_LIST,
    (data: IApiTokenResponse[]) => ({ payload: data })
);
export const setItemIsLoading = createCustomAction(
    types.SET_IS_LOADING,
    (id: string) => ({ payload: id })
);
export const unsetItemIsLoading = createCustomAction(
    types.UNSET_IS_LOADING,
    (id: string) => ({ payload: id })
);

export const getApiTokens = () => async(dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.API_TOKEN_LIST));
    try {
        const result = await accounts.tokens.list();
        if (result.status === HTTP_CODES.OK) {
            dispatch(setApiTokens(result.data.data));
        }

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.API_TOKEN_LIST));
    }
};

export const createApiToken = (data: IApiTokenCreateRequest) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.SAVE_AUTH_API_TOKEN));

    try {
        const result = await accounts.tokens.create(data);

        if (result.status === HTTP_CODES.CREATED) {
            dispatch(appendApiTokenItem(result.data.token));

            return result.data.access_token;
        }

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.SAVE_AUTH_API_TOKEN));
    }
};

export const removeApiToken = (data: IApiTokenResponse) => async (dispatch: Dispatch) => {
    dispatch(setItemIsLoading(data.id));

    try {
        const result = await apiTokens.remove(data.id);

        if (result.status === HTTP_CODES.NO_CONTENT) {
            dispatch(removeApiTokenItem(data.id));
        }

        return result;
    } finally {
        dispatch(unsetItemIsLoading(data.id));
    }
};
