// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';
import { COLORS } from 'admin/core/theme';

export const TitleContent = styled.div`
    display: flex;
    align-items: center;

    h3 {
        padding-right: 8px;
    }

    .pul-label {
        margin-bottom: 2px;
    }
`;

export const Subtitle = styled.div`
    font-size: 12px;
    line-height: 16px;

    color: ${COLORS.GREY_80};
`;
