// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import {
    api,
    IApiResponse,
} from 'common/api/resources/Response';
import { IDashboardResponse } from 'common/api/resources/Dashboard/model';
import { CancelTokenSource } from 'axios';

export const dashboard = {
    data: (cancelToken?: CancelTokenSource) => api.get<IApiResponse<IDashboardResponse>>('dashboard', {
        cancelToken: cancelToken?.token,
    }),
};
