// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import {
    DISABLE_FEATURE,
    ENABLE_FEATURE,
    FEATURES,
} from 'common/modules/app/features/constants';
import { createCustomAction } from 'typesafe-actions';

export const enableFeature = createCustomAction(
    ENABLE_FEATURE,
    (feature: FEATURES) => ({ payload: feature })
);
export const disableFeature = createCustomAction(
    DISABLE_FEATURE,
    (feature: FEATURES) => ({ payload: feature })
);
