// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { IVmResponse } from 'common/api/resources/ComputeResourceVm/model';
import {
    IIpResponse,
    primaryIps,
} from 'common/api/resources/Ip';

export const isBackupEnabled = (vm: IVmResponse): boolean =>
    vm.is_backup_available &&
    vm.settings &&
    !!vm.backup_settings?.enabled;

export const getViewableIps = (vm: IVmResponse): IIpResponse[] => primaryIps(vm.ips);

export const updateFqdns = (vm: IVmResponse, newName: string): string[] => {
    if (vm.fqdns.includes(vm.name)) {
        return vm.fqdns.map(fqdn => (fqdn === vm.name) ? newName : fqdn);
    }

    if (vm.fqdns.includes(newName)) {
        return vm.fqdns;
    }

    return [ newName, ...vm.fqdns ];
};
