// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { createCustomAction } from 'typesafe-actions';
import { Dispatch } from 'redux';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import {
    create,
    get,
    paginateList,
    remove,
    update,
} from 'common/actions/actionsWrapper';
import { IPaginateApiResponse } from 'common/api/resources/Response';
import { IAppState } from 'admin/core/store';
import * as types from 'admin/icon/constants/types';
import {
    icons,
    IIconListRequest,
    IIconRequest,
    IIconResponse,
} from 'common/api/resources/Icon';

export const setIconList = createCustomAction(
    types.SET_ICON_LIST,
    (data: IPaginateApiResponse<IIconResponse[]>) => ({ payload: data })
);
export const setIconItem = createCustomAction(
    types.SET_ICON_ITEM,
    (data: IIconResponse) => ({ payload: data })
);
export const unsetIconItem = createCustomAction(types.UNSET_ICON_ITEM);
export const addIconItem = createCustomAction(
    types.ADD_ICON_ITEM,
    (data: IIconResponse) => ({ payload: data })
);
export const updateIconItem = createCustomAction(
    types.UPDATE_ICON_ITEM,
    (data: IIconResponse) => ({ payload: data })
);
export const removeIconItem = createCustomAction(
    types.REMOVE_ICON_ITEM,
    (id: number) => ({ payload: id })
);
export const setIconItemIsDeleting = createCustomAction(
    types.SET_ICON_ITEM_IS_DELETING,
    (id: number, isDeleting: boolean) => ({ payload: { id, is_deleting: isDeleting } })
);

export const getIcons = (request?: IIconListRequest) => async (dispatch: Dispatch) => {
    const apiCall = () => icons.list(request);

    return await paginateList({
        dispatch,
        loadingFlag: LOADING_FLAGS.ICON_LIST,
        action: setIconList,
        apiCall,
    });
};

export const createIcon = (data: IIconRequest) => async (dispatch: Dispatch) => await create({
    data,
    dispatch,
    loadingFlag: LOADING_FLAGS.ICON_ITEM_SAVE,
    action: addIconItem,
    apiCall: icons.create,
    translations: {
        success: 'icon.toasts.iconSaved',
    },
});

export const getIcon = (id: number) => async (dispatch: Dispatch) => await get(id, {
    dispatch,
    apiCall: icons.item,
    action: setIconItem,
    loadingFlag: LOADING_FLAGS.ICON_ITEM,
});

export const updateIcon = (id: number, data: IIconRequest) => async (dispatch: Dispatch) => await update(id, {
    data,
    dispatch,
    apiCall: icons.update,
    action: updateIconItem,
    loadingFlag: LOADING_FLAGS.ICON_ITEM_SAVE,
    translations: {
        success: 'icon.toasts.iconSaved',
    },
});

export const createOrUpdateIcon = (data: IIconRequest) => async (dispatch: Dispatch, getState: () => IAppState) => {
    const { icon: { item } } = getState();

    if (item.id) {
        return await updateIcon(item.id, data)(dispatch);
    }

    return await createIcon(data)(dispatch);
};

export const removeIcon = (id: number) => async (dispatch: Dispatch) => await remove(id, {
    dispatch,
    apiCall: icons.remove,
    setLoadingAction: setIconItemIsDeleting,
    action: removeIconItem,
    loadingFlag: LOADING_FLAGS.ICON_ITEM_REMOVE,
    translations: {
        success: 'icon.toasts.iconDeleted',
    },
});
