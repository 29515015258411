// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import ConfirmationPopover from 'common/components/ConfirmationPopover';
import { Action } from '@plesk/ui-library';
import { ThemeContext } from 'styled-components';
import { useContext } from 'react';
import { StyledRadio } from 'common/components/RadioWithConfirmation/Styles';
import { Tooltip } from 'common/components/Tooltip/Tooltip';

export interface IRadioWithConfirmationProps {
    id: string;
    checked?: boolean;
    name?: string;
    isLoading?: boolean;
    disabled?: boolean;
    onClose?: () => void;
    onConfirm: () => void;
    translations: {
        button: React.ReactNode;
        text?: React.ReactNode;
        title?: React.ReactNode;
        tooltip?: React.ReactNode;
    };
    showTooltip?: boolean;
    'data-cy'?: string;
}

export const RadioWithConfirmation: React.FC<IRadioWithConfirmationProps> = ({
    id,
    checked = false,
    name,
    isLoading = false,
    disabled = false,
    onClose,
    onConfirm,
    translations: {
        button,
        text,
        title,
        tooltip,
    },
    showTooltip,
    'data-cy': dataCy,
}) => {
    const { primary_color } = useContext(ThemeContext);
    const [isPopoverOpened, setPopoverOpened] = React.useState(false);

    const handleClose = () => {
        setPopoverOpened(false);
        onClose?.();
    };

    const handleChange = () => {
        setPopoverOpened(true);
    };

    const handleClickConfirm = async (e: React.MouseEvent<HTMLButtonElement>) => {
        e.stopPropagation();
        setPopoverOpened(false);

        await onConfirm();
    };

    return (
        <ConfirmationPopover
            opened={isPopoverOpened}
            translations={{
                button,
                text,
                title,
            }}
            onButtonClick={handleClickConfirm}
            buttonColor={primary_color}
            onClose={handleClose}
        >
            {
                isLoading
                    ? <Action progress={true}/>
                    : <Tooltip
                        shown={showTooltip}
                        title={tooltip}
                    >
                        <StyledRadio
                            checked={checked}
                            name={name}
                            data-cy={dataCy}
                            onChange={handleChange}
                            disabled={disabled}
                            // We have to do that because autogenerated input IDs will
                            // break our snapshot tests.
                            // https://git.plesk.tech/projects/PLESK/repos/ui-library/browse/src/components/Radio/Radio.tsx?at=v3.25.5#54
                            inputProps={{ id }}
                        />
                    </Tooltip>
            }
        </ConfirmationPopover>
    );
};
