// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import {
    api,
    IApiResponse,
    IPaginateApiResponse,
} from 'common/api/resources/Response';
import { QueryBuilder } from 'common/api/resources/Request/QueryBuilder';
import {
    IStorageTagListRequest,
    IStorageTagRequest,
    IStorageTagPatchRequest,
    IStorageTagResponse,
} from 'common/api/resources/StorageTag/model';

export const storageTag = {
    list: (params?: IStorageTagListRequest) => api.get<IPaginateApiResponse<IStorageTagResponse[]>>('storage_tags', {
        params: new QueryBuilder(params).build(),
    }),
    item: (id: number) => api.get<IApiResponse<IStorageTagResponse>>(`storage_tags/${id}`),
    remove: (id: number) => api.delete(`storage_tags/${id}`),
    create: (data: IStorageTagRequest) => api.post<IApiResponse<IStorageTagResponse>>('storage_tags', data),
    update: (id: number, data: IStorageTagRequest) => api.put<IApiResponse<IStorageTagResponse>>(`storage_tags/${id}`, data),
    patch: (id: number, data: IStorageTagPatchRequest) => api.patch<IApiResponse<IStorageTagResponse>>(`storage_tags/${id}`, data),
};
