// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { Dispatch } from 'redux';
import {
    setNewPassword,
    setServerLimits,
    updateVm,
    updateVmProgress,
} from 'common/modules/computeResourceVm/actions';
import {
    IServerLimitResponse,
    IUpdateComputeResourceVm,
} from 'common/api/resources/ComputeResourceVm';
import { IUpdateUser } from 'common/api/resources/ComputeResource';
import {
    setIsAuth,
    updateUser,
} from 'common/modules/auth/actions';
import { IUpdateBackupResource } from 'common/api/resources/Backup';
import {
    updateItem as updateBackupItem,
    updateItemProgress,
} from 'common/modules/backup/actions';
import { IUpdateTask } from 'common/api/resources/Task';
import { ISnapshotResponse } from 'common/api/resources/Snapshot';
import { updateSnapshotItem } from 'common/modules/snapshot/actions';

export const logout = () => async (dispatch: Dispatch) => dispatch(setIsAuth(false));

export const updateProjectServerItemAction = (data: IUpdateComputeResourceVm) => async (dispatch: Dispatch) => {
    dispatch(updateVm(data.computeResourceVm));
};

export const updateProjectServerPasswordAction = (id: number, password: string) => async (dispatch: Dispatch) => {
    dispatch(setNewPassword(id, password));
};

export const updateUserAction = (data: IUpdateUser) => async (dispatch: Dispatch) => {
    dispatch(updateUser(data.user));
};

export const updateBackupItemAction = (data: IUpdateBackupResource) => async (dispatch: Dispatch) => {
    dispatch(updateBackupItem(data.backup));
};

export const updateTaskAction = (data: IUpdateTask) => async (dispatch: Dispatch) => {
    if (data.task.backup_id && data.task.progress > 0) {
        dispatch(updateItemProgress(data.task.backup_id, data.task.progress));
    }
    if (data.task.compute_resource_vm_id && data.task.progress > 0) {
        dispatch(updateVmProgress(
            data.task.compute_resource_vm_id,
            data.task.progress
        ));
    }
};

export const updateServerLimitsAction = (serverLimits: IServerLimitResponse[]) => async (dispatch: Dispatch) => {
    dispatch(setServerLimits(serverLimits));
};

export const updateSnapshotItemAction = (snapshot: ISnapshotResponse) => async (dispatch: Dispatch) => {
    dispatch(updateSnapshotItem(snapshot));
};
