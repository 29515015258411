// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import * as featuresActions from 'common/modules/app/features/actions';
import { connect } from 'react-redux';
import { FEATURES } from 'common/modules/app/features/constants';

export type FeaturesRegisterProps = ReturnType<typeof mapDispatchToProps>;

const FeaturesRegister: React.FC<FeaturesRegisterProps> = ({
    featuresActions: {
        enableFeature,
        disableFeature,
    },
}) => {
    // This component just register features functions globally.

    const wrap = (fn: (f: FEATURES) => void) => (feature: string) => {
        if (!Object.values(FEATURES).includes(feature as FEATURES)) {
            throw new Error(`Unknown feature "${feature}". Expects one of ${Object.values(FEATURES).join(', ')}`);
        }
        fn(feature as FEATURES);
    };

    window['enableFeature'] = wrap(enableFeature);
    window['disableFeature'] = wrap(disableFeature);

    return null;
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    featuresActions: bindActionCreators(featuresActions, dispatch),
});

export default connect(null, mapDispatchToProps)(FeaturesRegister);
