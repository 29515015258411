// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';
import { Button } from '@plesk/ui-library';

export const NameInputContainer = styled.div`
  position: relative;

  .pul-input__input {
    padding-right: 26px;
  }
`;

export const GenerateButton = styled(Button)`
  position: absolute;
  top: 28px;
  right: 0;
  outline: none;
  border: 0;
  background: none;
  cursor: pointer;
  vertical-align: middle;

  &:active {
    border: none;
  }
`;

export const InlineFields = styled.div`
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 100%;

    > div {
        width: 200px;
        vertical-align: top;

        .pul-input__input {
            min-height: 30px;
        }
    }
`;
