// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as activityLogActions from 'admin/activityLog/actions';
import { combineReducers } from 'redux';
import {
    ActionType,
    getType,
} from 'typesafe-actions';
import { IActivityLogResponse } from 'common/api/resources/ActivityLog';
import {
    IPaginateApiResponse,
    paginateInitialState,
} from 'common/api/resources/Response';
import { appendToPaginated } from 'common/reducers/list';

interface IActivityLog {
    list: IPaginateApiResponse<IActivityLogResponse[]>;
}

export type ActivityLogAction = ActionType<typeof activityLogActions>;
export type ActivityLogState = IActivityLog;

export const initialActivityLogState: IActivityLogResponse = {
    id: '1',
    event: 'test',
    user_email: 'test@example.com',
    user_ip: '10.0.0.1',
    data: [],
    created_at: '',
};

export default combineReducers<ActivityLogState, ActivityLogAction>({
    list: (state = paginateInitialState, action: ActivityLogAction) => {
        switch (action.type) {
        case getType(activityLogActions.appendActivityLogs):
            return appendToPaginated(state, action.payload);
        case getType(activityLogActions.setActivityLogsList):
            return action.payload;
        default:
            return state;
        }
    },
});
