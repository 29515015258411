// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

export const SET_USER_LIST = 'user/SET_USER_LIST';
export const APPEND_USERS = 'user/APPEND_USERS';
export const ADD_USER_ITEM = 'user/ADD_USER_ITEM';
export const SET_USER_ITEM = 'user/SET_USER_ITEM';
export const UNSET_USER_ITEM = 'user/UNSET_USER_ITEM';
export const UPDATE_USER_ITEM = 'user/UPDATE_USER_ITEM';
export const SET_USER_ITEM_IS_DELETING = 'user/SET_USER_ITEM_IS_DELETING';
export const SET_USER_ITEM_IS_TWO_FACTOR_AUTH_RECOVERY_CODE_SENDING = 'user/SET_USER_ITEM_IS_TWO_FACTOR_AUTH_RECOVERY_CODE_SENDING';
