// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import * as projectServerActions from 'common/modules/computeResourceVm/actions';
import { connect } from 'react-redux';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { RootState } from 'client/core/store';
import VncDialog from 'common/containers/VncDialog/VncDialog';
import { hasPermission } from 'common/modules/permission/selectors';
import { PERMISSION_LIST } from 'common/modules/permission/constants';
import { FEATURES } from 'common/modules/app/features/constants';

const mapStateToProps = (state: RootState) => ({
    isAuthenticated: state.auth.isAuth,
    vm: state.project.servers.item,
    isLoadingVm: state.app.loadingFlags.has(LOADING_FLAGS.COMPUTE_RESOURCE_VM),
    isSettingVncUp: state.app.loadingFlags.has(LOADING_FLAGS.VNC_UP),
    canOpenVncConsole: hasPermission(state, PERMISSION_LIST.OPEN_VNC_CONSOLE, PERMISSION_LIST.MANAGE_SERVERS),
    directConnection: state.app.features.has(FEATURES.DIRECT_VNC),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getComputeResourceVm: bindActionCreators(projectServerActions.getComputeResourceVm, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(VncDialog);
