// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { StyledActions } from 'common/components/Actions/Styles';
import {
    Button,
    Icon,
    Translate,
} from '@plesk/ui-library';
import { ICONS } from 'common/constants';
import { dataCySelector } from 'common/tests/selectors';
import { TABLE_ACTIONS } from 'admin/tag/constants/tests';
import ButtonWithConfirmation from 'common/components/ButtonWithConfirmation';
import { ITagResponse } from 'common/api/resources/Tag';

export interface ITagProps {
    item: ITagResponse;
    handleEdit: () => void;
    handleRemove: () => void;
}

export const TagActions: React.FC<ITagProps> = ({
    item,
    handleEdit,
    handleRemove,
}) => (
    <StyledActions>
        <Button
            ghost={true}
            icon={<Icon name={ICONS.PENCIL} size={18} />}
            tooltip={<Translate content="tag.tooltip.edit"/>}
            onClick={handleEdit}
            data-cy={dataCySelector(item.id, TABLE_ACTIONS.EDIT)}
        />
        <ButtonWithConfirmation
            isLoading={item.is_deleting}
            translations={{
                title: (
                    <Translate content="tag.removePopover.title" />
                ),
                button: (
                    <Translate content="tag.removePopover.button" />
                ),
                tooltip: (
                    <Translate content="tag.removePopover.tooltip" />
                ),
            }}
            handleConfirm={handleRemove}
            data-cy={dataCySelector(item.id, TABLE_ACTIONS.REMOVE)}
            icon={ICONS.RECYCLE}
        />
    </StyledActions>
);