// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';

interface IColorExampleProps {
    color: string;
}

export const Popover = styled.div`
   position: absolute;
   z-index: 2;
`;
export const ColorPickerCover = styled.div`
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
`;

export const ColorBox = styled.div`
  width: 20px;
  height: 14px; 
  border-radius: 2px; 
  border: 1px solid #b5b5b5; 
  margin-top: -3px;
  padding: 5px 7px 7px 5px;
`;

export const ColorExample = styled.div<IColorExampleProps>`
  background: ${({ color }) => color};
  width: 100%; 
  height: 100%;
  border: 1px solid #999999;
`;

export const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;
