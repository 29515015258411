// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { Units } from 'common/helpers/units';

export interface ILimit<T = Units> {
    limit: number;
    is_enabled: boolean;
    unit?: T;
}

// Should be synced with `backend/app/Modules/Schedule/ScheduleType.php`
export enum ScheduleType {
    MONTHLY = 'monthly',
    WEEKLY = 'weekly',
    DAILY = 'daily',
    HOURLY = 'hourly',
}

// Should be synced with `backend/app/Modules/Schedule/ScheduleDTO.php`
export interface ISchedule {
    type: ScheduleType;
    time: IScheduleTime;
    days: number[];
}

export interface IScheduleTime {
    hour: number;
    minutes: number;
}

export interface IActionResult {
    id: number;
    result: boolean;
    reason?: string;
    task_id?: number;
}
