// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { connect } from 'react-redux';
import { RootState } from 'admin/core/store';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import {
    Action,
    Icon,
    Translate,
} from '@plesk/ui-library';
import { EmptyView } from 'common/components/EmptyView/EmptyView';
import { ICONS } from 'common/constants';
import * as storageActions from 'admin/storage/actions';
import * as computeResourceActions from 'admin/computeResource/actions';
import {
    RouteComponentProps,
    withRouter,
} from 'react-router';
import CopyText from 'common/containers/CopyText/CopyText';
import { pathTo } from 'common/helpers/core';
import {
    PageSection,
    SubTitleRow,
    SubTitleSection,
    SubTitleSectionLabel,
} from 'common/components/PageHeader/Styles';
import PageHeader from 'common/components/PageHeader/PageHeader';
import { STORAGE_TYPES_TRANSLATION_MAP } from 'common/api/resources/StorageType';
import { Loader } from 'common/components';
import List from 'common/components/List/List';
import { useIsFirstLoading } from 'common/hooks/useIsFirstLoading';
import { Link } from 'react-router-dom';

export type StorageDisksProps =
    RouteComponentProps<{ storageId: string; computeResourceId?: string }> &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export enum StorageDiskTableColumns {
    ID = 'colId',
    NAME = 'colName',
    IS_PRIMARY = 'colIsPrimary',
    PATH = 'colPath',
    SIZE = 'colSize',
    ACTUAL_SIZE = 'colActualSize',
    SERVER_HOST_NAME = 'colServerHostname',
    SERVER_UUID = 'colServerUuid',
    OWNER = 'colOwner',
}

const columns = [{
    width: '1%',
    key: StorageDiskTableColumns.ID,
    title: <Translate content="storage.disk.list.id" />,
}, {
    key: StorageDiskTableColumns.NAME,
    title: <Translate content="storage.disk.list.name" />,
}, {
    key: StorageDiskTableColumns.IS_PRIMARY,
    title: <Translate content="storage.disk.list.isPrimary" />,
}, {
    key: StorageDiskTableColumns.PATH,
    title: <Translate content="storage.disk.list.path" />,
}, {
    key: StorageDiskTableColumns.SIZE,
    title: <Translate content="storage.disk.list.size" />,
}, {
    key: StorageDiskTableColumns.ACTUAL_SIZE,
    title: <Translate content="storage.disk.list.actualSize" />,
}, {
    key: StorageDiskTableColumns.SERVER_HOST_NAME,
    title: <Translate content="storage.disk.list.serverHostname" />,
}, {
    key: StorageDiskTableColumns.SERVER_UUID,
    title: <Translate content="storage.disk.list.serverUuid" />,
}, {
    key: StorageDiskTableColumns.OWNER,
    title: <Translate content="storage.disk.list.owner" />,
},
];

export const StorageDisks: React.FC<StorageDisksProps> = ({
    storage,
    list,
    cr,
    isLoading,
    isLoadingList,
    storageActions: {
        getStorage,
        unsetStorageItem,
        getStorageDisks,
    },
    computeResourceActions: {
        getComputeResource,
        unsetItem,
    },
    history,
    match: { params },
}) => {
    const storageId = parseInt(params.storageId, 10);
    const crId = params.computeResourceId && parseInt(params.computeResourceId, 10);

    React.useEffect(() => {
        getStorage(storageId);
        if (crId) {
            getComputeResource(crId);
        }
        getStorageDisks(storageId);

        return () => {
            unsetItem();
            unsetStorageItem();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [storageId, crId]);

    const loadPaginated = (page: number) => getStorageDisks(storageId, { page });

    const isFirstLoading = useIsFirstLoading(isLoadingList);

    const data = list.data.map((storageDisk) => ({
        [StorageDiskTableColumns.ID]: storageDisk.id,
        [StorageDiskTableColumns.NAME]: storageDisk.name,
        [StorageDiskTableColumns.IS_PRIMARY]: storageDisk.is_primary && (
            <Icon name={ICONS.CHECK_MARK} />
        ),
        [StorageDiskTableColumns.PATH]: storageDisk.path,
        [StorageDiskTableColumns.SIZE]: storageDisk.size,
        [StorageDiskTableColumns.ACTUAL_SIZE]: storageDisk.actual_size,
        [StorageDiskTableColumns.SERVER_HOST_NAME]: (
            <Action
                component={Link}
                to={pathTo(`servers/${storageDisk.server.id}`)}
            >
                {storageDisk.server.name}
            </Action>
        ),
        [StorageDiskTableColumns.SERVER_UUID]: (<CopyText>{storageDisk.server.uuid}</CopyText>),
        [StorageDiskTableColumns.OWNER]: storageDisk.user.email,
        key: storageDisk.id.toString(),
    }));

    const renderTitle = () => (
        <PageSection>
            {cr.id !== 0 &&
                <>
                    <Action
                        component={Link}
                        to={pathTo(`compute_resources/${cr.id}#storages`)}
                    >
                        {cr.name}
                    </Action>
                    <Icon name={ICONS.CHEVRON_RIGHT} />
                </>
            }
            <Translate content="storage.disk.title"/>
        </PageSection>
    );

    const renderSubtitle = () => (
        <SubTitleRow>
            {cr.id !== 0 &&
                <SubTitleSection>
                    <SubTitleSectionLabel>
                        <Icon name={ICONS.RESOURCE} />
                    </SubTitleSectionLabel>
                    <Action
                        component={Link}
                        to={pathTo(`compute_resources/${cr.id}#storages`)}
                    >
                        {cr.name}
                    </Action>
                </SubTitleSection>
            }
            <SubTitleSection>
                <SubTitleSectionLabel>
                    <Translate content="storage.disk.path" />
                </SubTitleSectionLabel>
                {storage.path}
            </SubTitleSection>
            <SubTitleSection>
                <SubTitleSectionLabel>
                    <Translate content="storage.disk.type" />
                </SubTitleSectionLabel>
                {STORAGE_TYPES_TRANSLATION_MAP[storage.type.name]}
            </SubTitleSection>
            <SubTitleSection>
                <SubTitleSectionLabel>
                    <Translate content="storage.disk.freeSpace" />
                </SubTitleSectionLabel>
                {`${storage.free_space} GiB`}
            </SubTitleSection>
        </SubTitleRow>
    );

    return (
        <Loader isLoading={isLoading}>
            <PageHeader
                title={renderTitle()}
                subtitle={renderSubtitle()}
            />
            <List
                emptyView={
                    <EmptyView
                        title="storage.disk.emptyView.title"
                        description="storage.disk.emptyView.description"
                        icon={ICONS.RESOURCE}
                    />
                }
                columns={columns}
                data={data}
                loadItems={loadPaginated}
                meta={list.meta}
                isLoading={isLoadingList}
                isFirstLoading={isFirstLoading}
            />
        </Loader>
    );
};

const mapStateToProps = (state: RootState) => ({
    storage: state.storage.item,
    list: state.storage.disks,
    cr: state.computeResource.item,
    isLoading: state.app.loadingFlags.has(LOADING_FLAGS.STORAGE_GET)
        || state.app.loadingFlags.has(LOADING_FLAGS.COMPUTE_RESOURCE_ITEM),
    isLoadingList: state.app.loadingFlags.has(LOADING_FLAGS.STORAGE_DISK_LIST),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    storageActions: bindActionCreators(storageActions, dispatch),
    computeResourceActions: bindActionCreators(computeResourceActions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(StorageDisks));
