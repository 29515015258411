// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { Dispatch } from 'redux';
import { bakeForegroundToast } from 'common/modules/app/toaster/actions';
import { INTENT_TYPE } from 'common/constants';
import {
    setIsLoading,
    unsetIsLoading,
} from 'common/modules/app/loadingFlags/actions';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import {
    clearFormErrors,
    setFormErrors,
} from 'common/modules/app/formErrors/actions';
import { HTTP_CODES } from 'common/api/constants';
import { createCustomAction } from 'typesafe-actions';
import * as types from 'admin/backupNode/constants/types';
import { IPaginateApiResponse } from 'common/api/resources/Response';
import { IAppState } from 'admin/core/store';
import {
    get,
    loadOnScroll,
    remove,
} from 'common/actions/actionsWrapper';
import { transformErrors } from 'common/validator';
import {
    backupNodes,
    IBackupNodeCreateRequest,
    IBackupNodeResponse,
} from 'common/api/resources/BackupNode';

export const addBackupNodeItem = createCustomAction(
    types.BACKUP_NODE_ADD_ITEM,
    (data: IBackupNodeResponse) => ({ payload: data })
);
export const appendBackupNodeList = createCustomAction(
    types.BACKUP_NODE_LIST_APPEND,
    (data: IPaginateApiResponse<IBackupNodeResponse[]>) => ({ payload: data })
);
export const setBackupNodeList = createCustomAction(
    types.BACKUP_NODE_LIST_SET,
    (data: IPaginateApiResponse<IBackupNodeResponse[]>) => ({ payload: data })
);
export const setBackupNodeItem = createCustomAction(
    types.BACKUP_NODE_SET_ITEM,
    (data: IBackupNodeResponse) => ({ payload: data })
);
export const unsetBackupNodeItem = createCustomAction(types.BACKUP_NODE_UNSET_ITEM);
export const removeBackupNodeItem = createCustomAction(
    types.BACKUP_NODE_REMOVE_ITEM,
    (id: number) => ({ payload: id })
);
export const updateBackupNodeItem = createCustomAction(
    types.BACKUP_NODE_UPDATE_ITEM,
    (data: IBackupNodeResponse) => ({ payload: data })
);

export const setBackupNodeItemIsDeleting = createCustomAction(
    types.SET_BACKUP_NODE_ITEM_IS_DELETING,
    (id: number, isDeleting: boolean) => ({ payload: { id, isDeleting } })
);

export const getBackupNodes = () => async(dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.BACKUP_NODE_LIST));

    try {
        const result = await backupNodes.list();
        if (result.status === HTTP_CODES.OK) {
            dispatch(setBackupNodeList(result.data));
        }

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.BACKUP_NODE_LIST));
    }
};

export const getBackupNode = (id: number) => async (dispatch: Dispatch) => await get(id, {
    dispatch,
    apiCall: backupNodes.item,
    action: setBackupNodeItem,
    loadingFlag: LOADING_FLAGS.BACKUP_NODE_GET,
});

export const loadBackupNodesOnScroll = () => async(dispatch: Dispatch, getState: () => IAppState) => {
    const state = getState();
    const nextPage = state.backupNode.list.links.next;
    const isLoading = state.app.loadingFlags.has(LOADING_FLAGS.BACKUP_NODE_LIST);

    return await loadOnScroll({
        nextPage,
        isLoading,
        dispatch,
        action: appendBackupNodeList,
        loadingFlag: LOADING_FLAGS.BACKUP_NODE_LIST,
    });
};

export const createBackupNode = (data: IBackupNodeCreateRequest) => async(dispatch: Dispatch) =>  {
    dispatch(setIsLoading(LOADING_FLAGS.BACKUP_NODE_SAVE));
    try {
        const result = await backupNodes.create(data);

        dispatch(clearFormErrors());
        dispatch(addBackupNodeItem(result.data.data));
        bakeForegroundToast(INTENT_TYPE.SUCCESS, 'backupNode.toasts.createSuccess')(dispatch);

        return result;
    } catch (e) {
        if (e.response.status === HTTP_CODES.VALIDATION_ERROR) {
            dispatch(setFormErrors(transformErrors(e.response.data.errors)));
        } else if (e.response.status === HTTP_CODES.BAD_REQUEST) {
            dispatch(setFormErrors({
                notAccessible: e.response.data.errors,
            }));
        }

        throw e;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.BACKUP_NODE_SAVE));
    }
};

export const removeBackupNode = (id: number) => async (dispatch: Dispatch) => await remove(id, {
    dispatch,
    apiCall: backupNodes.remove,
    setLoadingAction: setBackupNodeItemIsDeleting,
    action: removeBackupNodeItem,
    loadingFlag: LOADING_FLAGS.BACKUP_NODE_REMOVE,
    translations: {
        success: 'backupNode.toasts.deleted',
    },
});

export const updateBackupNode = (id: number, data: IBackupNodeCreateRequest) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.BACKUP_NODE_SAVE));

    try {
        const result = await backupNodes.update(id, data);
        if (result.status === HTTP_CODES.OK) {
            dispatch(clearFormErrors());
            bakeForegroundToast(INTENT_TYPE.SUCCESS, 'backupNode.toasts.updatedSuccess')(dispatch);
            dispatch(updateBackupNodeItem(result.data.data));
        }

        return result;
    } catch (e) {
        if (e.response.status === HTTP_CODES.VALIDATION_ERROR) {
            dispatch(setFormErrors(transformErrors(e.response.data.errors)));
        } else if (e.response.status === HTTP_CODES.BAD_REQUEST) {
            dispatch(setFormErrors({
                notAccessible: Object.entries(e.response.data.errors)
                    .map(([cr, error]) => `${cr}: ${error}`),
            }));
        }

        throw e;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.BACKUP_NODE_SAVE));
    }
};
