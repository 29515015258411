// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';
import { COLORS } from 'common/theme';

export const HeaderContainer = styled.div`
   display: flex;
   margin-top: 24px;
   margin-bottom: 20px;

   @media (max-width: 671px) {
    margin-top: 8px;
  }
`;

export const TitleContainer = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

export const MainTitleContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const PageSection = styled.div`
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;

  .pul-icon {
    color: ${COLORS.GREY_70};
    margin-top: 4px;
    margin-left: 6px;
    margin-right: 6px;
  }

  @media (max-width: 671px) {
    font-size: 24px;
    line-height: 32px;
  }
`;

export const MainTitle = styled.div`
  width: 0;
`;

export const PageName = styled.span`
  font-weight: 600;
  font-size: 32px;
  line-height: 40px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 16px;

  @media (max-width: 671px) {
    font-size: 24px;
    line-height: 32px;
  }
`;

export const MainTitleWithActionSubContainer = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  flex: 1;
  width: 0;

  .pul-action {
    .pul-status {
      color: ${COLORS.GREY_80};
    }
  }
`;

export const ActionContainer = styled.div`
  display: flex;
  align-items: center;
  height: 40px;

  @media (max-width: 671px) {
    height: 32px;
  }
`;

export const SubTitleContainer = styled.div`
  font-size: 14px;
  line-height: 16px;
  min-width: 375px;
`;

export const SubTitleRow = styled.div`
  display: flex;
  align-items: center;
  max-width: 800px;
  flex-wrap: wrap;

  div:last-of-type {
    margin-right: 0;
  }
`;

export const SubTitleSection = styled.div`
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-right: 20px;
  line-height: 20px;
  white-space: nowrap;
`;

export const SubTitleSectionLabel = styled.span`
  color: ${COLORS.GREY_80};
  margin-right: 5px;

  .pul-icon {
    vertical-align: middle;
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;

  button {
    margin-right: 5px;

    :last-of-type {
      margin-right: 0;
    }

    max-height: inherit !important;
  }

  a {
    margin-right: 5px;
  }
`;

export const ActionsBar = styled.div`
  line-height: 40px;
  display: flex;
`;

export const ActionsBarContent = styled.div`
  display: flex;

  @media (max-width: 767px) {
    display: none;
  }
`;

export const ActionButton = styled.div`
  align-self: flex-end;
  margin-top: auto;

  .pul-button {
    min-width: 180px;
  }

  .pul-button--primary {
    background: ${props => props.theme.primary_color};

    &:hover {
      background: ${props => props.theme.primary_color}
    }
  }
`;
