// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import {
    ipRule,
    requiredRule,
    validate,
} from 'common/validator';
import * as vpcNetworkActions from 'common/modules/vpcNetwork/actions';
import * as locationActions from 'common/modules/location/actions';
import * as userActions from 'admin/user/actions';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import {
    Column,
    Columns,
    Form,
    FormField,
    FormFieldText,
    Section,
    setIn,
    Translate,
} from '@plesk/ui-library';
import {
    INTENT_TYPE,
    SIZE,
} from 'common/constants';
import { Button } from 'admin/common/components/Button/Button';
import { nestStringProperties } from 'common/modules/app/formErrors/selectors';
import {
    IVpcNetworkRequest,
    IVpcNetworkResponse,
} from 'common/api/resources/VpcNetwork/model';
import { SegmentedControl } from 'common/components/SegmentedControl/SegmentedControl';
import {
    IpListType,
    IpListTypeTranslations,
} from 'common/api/resources/IpBlock';
import { IpDash } from 'admin/ipBlock/containers/Styles';
import { ISelectOption } from 'common/components';
import { IShortLocationResponse } from 'common/api/resources/Location';
import AsyncSelectInput from 'common/components/Select/AsyncSelectInput';
import { createOptionsLoader } from 'common/components/Select/helpers';
import {
    IShortUserResponse,
    IUserResponse,
} from 'common/api/resources/User';
import { ICommonState } from 'common/store';
import { hasPermission } from 'common/modules/permission/selectors';
import { PERMISSION_LIST } from 'common/modules/permission/constants';

export enum VpcNetworkFormFields {
    NAME = 'name',
    LIST_TYPE = 'list_type',
    NETMASK = 'netmask',
    FROM = 'from',
    TO = 'to',
    LOCATION_ID = 'location_id',
    USER_ID = 'user_id',
}

export const vpcNetworkFormDefaultFields = [
    VpcNetworkFormFields.NAME,
    VpcNetworkFormFields.LIST_TYPE,
    VpcNetworkFormFields.NETMASK,
    VpcNetworkFormFields.FROM,
    VpcNetworkFormFields.TO,
];

interface IVpcNetworkFormProps {
    onSubmit: () => void;
    fields?: VpcNetworkFormFields[];
}

export type VpcNetworkFormProps =
    IVpcNetworkFormProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

const vpcNetworkToRequest = ({ user, location, ...vpcNetwork }: IVpcNetworkResponse): IVpcNetworkRequest => ({
    name: vpcNetwork.name,
    list_type: vpcNetwork.list_type,
    from: vpcNetwork.from,
    to: vpcNetwork.to,
    netmask: vpcNetwork.netmask,
    location_id: location.id,
    user_id: user?.id,
});

const ipBlockListTypeOptions = Object.values(IpListType).map((type) => ({
    value: type.toString(),
    title: <Translate content={IpListTypeTranslations[type.toString()]}/>,
}));

interface ILocationSelectOption extends ISelectOption {
    value: number;
    label: string;
}
const locationToSelectOption = (tag: IShortLocationResponse): ILocationSelectOption => ({
    label: tag.name,
    value: tag.id,
});

const userToSelectOptions = (user: IShortUserResponse): ISelectOption => ({
    label: user.email,
    value: user.id.toString(),
});

export const VpcNetworkForm: React.FC<VpcNetworkFormProps> = ({
    vpcNetwork,
    user,
    isItemSaving,
    formErrors,
    canChangeLocation,
    canChangeUser,
    formErrorsActions: {
        setFormErrors,
        clearFormErrors,
    },
    vpcNetworkActions: {
        unsetVpcNetworkItem,
        createVpcNetwork,
        updateVpcNetwork,
    },
    locationActions: {
        getLocations,
    },
    userActions: {
        getUsers,
    },
    onSubmit,
    fields = vpcNetworkFormDefaultFields,
}) => {
    const [submitValues, setSubmitValues] = React.useState<IVpcNetworkRequest>(
        vpcNetworkToRequest(vpcNetwork)
    );
    const isNewItem = !vpcNetwork.id;

    React.useEffect(() => () => {
        clearFormErrors();
        unsetVpcNetworkItem();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleFieldChange = (field: string, value: string) => setSubmitValues(setIn(submitValues, field, value));

    const handleSubmit = async (values: IVpcNetworkRequest) => {
        const rules = {
            name: requiredRule(<Translate content="validate.fieldRequired" />),
            netmask: [
                requiredRule(<Translate content="validate.fieldRequired" />),
                ipRule(<Translate content="validate.badNetmask" />, 4),
            ],
        };

        if (values.list_type === IpListType.RANGE) {
            rules['from'] = [
                requiredRule(<Translate content="validate.fieldRequired" />),
                ipRule(<Translate content="validate.badIpAddress" />, 4),
            ];
            rules['to'] = [
                requiredRule(<Translate content="validate.fieldRequired" />),
                ipRule(<Translate content="validate.badIpAddress" />, 4),
            ];
        }

        const errors = validate<IVpcNetworkRequest>(values, rules);

        if (Object.keys(errors).length) {
            setFormErrors(errors);
            return;
        }

        vpcNetwork.id
            ? await updateVpcNetwork(vpcNetwork.id, values)
            : await createVpcNetwork(values);
        onSubmit();
    };

    const [selectedLocation, setSelectedLocation] = React.useState(
        vpcNetwork.location.id ? locationToSelectOption(vpcNetwork.location) : undefined
    );
    const locationsLoader = createOptionsLoader(
        getLocations,
        locationToSelectOption
    );
    const handleLocationChange = (option: ILocationSelectOption) => {
        setSelectedLocation(option);
        setSubmitValues((prevValues) => ({
            ...prevValues,
            location_id: option.value,
        }));
    };

    const [selectedUser, setSelectedUser] = React.useState(
        userToSelectOptions(vpcNetwork.user ?? user)
    );
    const usersLoader = createOptionsLoader<IUserResponse>(
        getUsers,
        userToSelectOptions
    );
    const handleUserChange = (option: ISelectOption) => {
        setSelectedUser(option);
        setSubmitValues((prevValues) => ({
            ...prevValues,
            user_id: option.value
                ? typeof option.value === 'string'
                    ? parseInt(option.value, 10)
                    : option.value
                : undefined,
        }));
    };

    return (
        <>
            <Form
                id="vpcNetworkForm"
                onSubmit={handleSubmit}
                values={submitValues}
                onFieldChange={handleFieldChange}
                footerClassName="hidden"
                errors={formErrors}
                hideRequiredLegend={true}
                submitButton={false}
                cancelButton={false}
                applyButton={false}
                vertical={true}
            >
                <Section>
                    {fields?.includes(VpcNetworkFormFields.NAME) && (
                        <FormFieldText
                            name="name"
                            size={SIZE.FILL}
                            label={<Translate content="vpcNetwork.form.name" />}
                            required={true}
                        />
                    )}
                    {fields?.includes(VpcNetworkFormFields.LIST_TYPE) && isNewItem && (
                        <FormField
                            name="list_type"
                            required={true}
                            label={<Translate content="vpcNetwork.form.listType" />}
                        >
                            {({ setValue }) => (
                                <SegmentedControl
                                    buttons={ipBlockListTypeOptions}
                                    selected={submitValues.list_type}
                                    onChange={setValue}
                                />
                            )}
                        </FormField>
                    )}
                    {fields?.includes(VpcNetworkFormFields.FROM) && (isNewItem || submitValues.list_type === IpListType.RANGE) && (
                        <Columns vertical={false}>
                            <Column>
                                <FormFieldText
                                    name="from"
                                    label={<Translate content="vpcNetwork.form.from" />}
                                    required={true}
                                    disabled={submitValues.list_type === IpListType.SET}
                                    vertical
                                />
                            </Column>
                            <Column width={24}>
                                <IpDash>&mdash;</IpDash>
                            </Column>
                            <Column>
                                <FormFieldText
                                    name="to"
                                    label={<Translate content="vpcNetwork.form.to" />}
                                    required={true}
                                    disabled={submitValues.list_type === IpListType.SET}
                                    vertical
                                />
                            </Column>
                        </Columns>
                    )}
                    {fields?.includes(VpcNetworkFormFields.NETMASK) && (
                        <FormFieldText
                            name="netmask"
                            placeholder="255.255.0.0"
                            label={<Translate content="vpcNetwork.form.netmask" />}
                            required={true}
                            size={SIZE.FILL}
                        />
                    )}
                    {fields?.includes(VpcNetworkFormFields.LOCATION_ID) && canChangeLocation && (
                        <FormField
                            name="location_id"
                            label={<Translate content="vpcNetwork.form.location" />}
                            required={true}
                        >
                            {({ getId }) => (
                                <AsyncSelectInput
                                    inputId={getId()}
                                    value={selectedLocation}
                                    loadOptions={locationsLoader}
                                    debounceTimeout={1000}
                                    additional={{ page: 1 }}
                                    onChange={handleLocationChange}
                                    isClearable={true}
                                    menuPosition="absolute"
                                    menuPortalTarget={document.body}
                                />
                            )}
                        </FormField>
                    )}
                    {fields?.includes(VpcNetworkFormFields.USER_ID) && canChangeUser && (
                        <FormField
                            name="user_id"
                            label={<Translate content="vpcNetwork.form.user" />}
                            required={true}
                        >
                            {({ getId }) => (
                                <AsyncSelectInput
                                    inputId={getId()}
                                    value={selectedUser}
                                    loadOptions={usersLoader}
                                    debounceTimeout={1000}
                                    additional={{ page: 1 }}
                                    onChange={handleUserChange}
                                    menuPosition={'fixed'}
                                />
                            )}
                        </FormField>
                    )}
                </Section>
            </Form>
            <Button
                type="submit"
                form="vpcNetworkForm"
                fill={true}
                intent={INTENT_TYPE.PRIMARY}
                size={SIZE.LG}
                isLoading={isItemSaving}
            >
                <Translate content="vpcNetwork.form.saveBtn" />
            </Button>
        </>
    );
};

const mapStateToProps = (state: ICommonState) => ({
    vpcNetwork: state.vpcNetwork.item,
    user: state.auth.user,
    isItemSaving: state.app.loadingFlags.has(LOADING_FLAGS.VPC_NETWORK_ITEM_SAVE),
    formErrors: nestStringProperties(state),
    canChangeLocation: hasPermission(state, PERMISSION_LIST.MANAGE_ALL_VPC_NETWORKS) || state.vpcNetwork.item.id === 0,
    canChangeUser: hasPermission(state, PERMISSION_LIST.MANAGE_ALL_VPC_NETWORKS),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    locationActions: bindActionCreators(locationActions, dispatch),
    userActions: bindActionCreators(userActions, dispatch),
    vpcNetworkActions: bindActionCreators(vpcNetworkActions, dispatch),
    formErrorsActions: bindActionCreators(formErrorsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(VpcNetworkForm);
