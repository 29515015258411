// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';

export const VncDialogHeader = styled.div`
    background: #fff;
    display: flex;
    align-items: center;
    padding: 20px;
    max-height: 20px;
`;

export const VncDialogHeaderItem = styled.div`
    font-weight: 600;
    display: flex;
    align-items: center;

    &:not(:last-child) {
        padding-right: 32px;
    }

    &:nth-last-child(2) {
        flex: 1;
    }

    .pul-content-loader__container {
        padding: 0;
    }

    .pul-content-loader__text {
        margin-left: 8px;
    }

    .pul-content-loader__indicator {
        width: 16px;
        height: 16px;
    }

    img {
        margin-right: 8px;
    }

    > span:first-child {
        margin-right: 8px;
        vertical-align: -2px!important;
    }
`;

export const VncDialogHeaderIpList = styled.div`
    flex-direction: column;

    .pul-action__content {
        margin-right: 5px !important;
    }
`;

export const VncInstance = styled.div`
    height: calc(100% - 60px);
`;

export const AlertContainer = styled.div`
    margin: auto;
    align-self: center;

    .pul-alert {
        margin-bottom: 40px;
    }
`;

export const VncDialogContainer = styled.div`
    height: inherit;
    display: flex;
    flex-direction: column;
`;
