// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { RootState } from 'admin/core/store';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import * as computeResourceVmActions from 'common/modules/computeResourceVm/actions';
import { connect } from 'react-redux';
import {
    DisksTab,
    DisksTableColumns,
} from 'common/components/ServerTabs/DisksTab/DisksTab';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { hasPermission } from 'common/modules/permission/selectors';
import { PERMISSION_LIST } from 'common/modules/permission/constants';

const mapStateToProps = (state: RootState) => ({
    server: state.computeResourceVm.item,
    columns: [
        DisksTableColumns.ID,
        DisksTableColumns.NAME,
        DisksTableColumns.IS_PRIMARY,
        DisksTableColumns.PATH,
        DisksTableColumns.SIZE,
        DisksTableColumns.ACTUAL_SIZE,
        DisksTableColumns.ACTIONS,
    ],
    disks: state.computeResourceVm.disks,
    isLoadingList: state.app.loadingFlags.has(LOADING_FLAGS.COMPUTE_RESOURCE_VM_DISK_LIST),
    echoCredentials: `${state.auth.authData.token_type} ${state.auth.authData.access_token}`,
    isAdditionalDiskAdding: state.app.loadingFlags.has(LOADING_FLAGS.COMPUTE_RESOURCE_VM_DISK_ADDING),
    isAdditionalDiskDeleting: state.app.loadingFlags.has(LOADING_FLAGS.COMPUTE_RESOURCE_VM_DISK_DELETING),
    isAdditionalDiskResizing: state.app.loadingFlags.has(LOADING_FLAGS.COMPUTE_RESOURCE_VM_DISK_RESIZING),
    isAdditionalDiskUpdating: state.app.loadingFlags.has(LOADING_FLAGS.COMPUTE_RESOURCE_VM_DISK_UPDATING),
    canManageStorage: hasPermission(state, PERMISSION_LIST.MANAGE_STORAGE),
    canAddAdditionalDisk: hasPermission(state, PERMISSION_LIST.ADD_ADDITIONAL_DISK)
        && hasPermission(state, PERMISSION_LIST.GET_OFFERS, PERMISSION_LIST.MANAGE_OFFERS),
    canResizeAdditionalDisk: hasPermission(state, PERMISSION_LIST.RESIZE_ADDITIONAL_DISK),
    canDeleteAdditionalDisk: hasPermission(state, PERMISSION_LIST.DELETE_ADDITIONAL_DISK),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    getDisks: bindActionCreators(computeResourceVmActions.getDisks, dispatch),
    removeAdditionalDisk: bindActionCreators(computeResourceVmActions.removeAdditionalDisk, dispatch),
    addAdditionalDisk: bindActionCreators(computeResourceVmActions.addAdditionalDisk, dispatch),
    updateAdditionalDisk: bindActionCreators(computeResourceVmActions.updateAdditionalDisk, dispatch),
    resizeAdditionalDisk: bindActionCreators(computeResourceVmActions.resizeAdditionalDisk, dispatch),
    commitAdditionalDiskCreatingSuccess: bindActionCreators(computeResourceVmActions.commitAdditionalDiskCreatingSuccess, dispatch),
    commitAdditionalDiskCreatingFail: bindActionCreators(computeResourceVmActions.commitAdditionalDiskCreatingFail, dispatch),
    commitAdditionalDiskDeletingSuccess: bindActionCreators(computeResourceVmActions.commitAdditionalDiskDeletingSuccess, dispatch),
    commitAdditionalDiskDeletingFail: bindActionCreators(computeResourceVmActions.commitAdditionalDiskDeletingFail, dispatch),
    commitAdditionalDiskResizingSuccess: bindActionCreators(computeResourceVmActions.commitAdditionalDiskResizingSuccess, dispatch),
    commitAdditionalDiskResizingFail: bindActionCreators(computeResourceVmActions.commitAdditionalDiskResizingFail, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DisksTab);
