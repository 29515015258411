// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import
{
    AsyncPaginate,
    AsyncPaginateProps,
} from 'react-select-async-paginate';
import { selectInputStyles } from 'common/components/Select/styles';

export interface IAsyncSelectInputProps<OptionType, Group, Additional, IsMulti extends boolean>
    extends AsyncPaginateProps<OptionType, Group, Additional, IsMulti> { }

export default function AsyncSelectInput<
    OptionType,
    Group,
    Additional,
    IsMulti extends boolean = false
    >(props: IAsyncSelectInputProps<OptionType, Group, Additional, IsMulti>)
{
    const {
        size,
        isSearchable,
        ...remain
    } = props;

    const actualProps = {
        ...props,
        components: {
            ...remain.components,
            // Hide small bar between input and indicator
            IndicatorSeparator: () => null,
        },
        isSearchable,
        styles: selectInputStyles,
    };

    return <AsyncPaginate {...actualProps} />;
}
