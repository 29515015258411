// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import {
    IPaginateApiResponse,
    paginateInitialState,
} from 'common/api/resources/Response';
import {
    ActionType,
    getType,
} from 'typesafe-actions';
import * as taskActions from 'admin/task/actions';
import { combineReducers } from 'redux';
import { ITaskResponse } from 'common/api/resources/Task';
import { initialComputeResourceState } from 'admin/computeResource/reducers';
import { updateInPaginated } from 'common/reducers/list';
import { initialComputeResourceVmState } from 'common/modules/computeResourceVm/reducers';

export interface ITaskReducer {
    item: ITaskResponse;
    list: IPaginateApiResponse<ITaskResponse[]>;
}

export type TaskActions = ActionType<typeof taskActions>;
export type TaskState = Readonly<ITaskReducer>;

export const initialTaskState: ITaskResponse = {
    id: 0,
    compute_resource: { ...initialComputeResourceState },
    compute_resource_vm_uuid: null,
    compute_resource_vm_id: null,
    compute_resource_vm: initialComputeResourceVmState,
    user_email: null,
    duration: 0,
    status: 'not_started',
    progress: 0,
    action: '',
    output: '',
    started_at: '',
    finished_at: null,
    is_cancelable: false,
    is_retryable: false,
    backup_id: null,
    management_node_backup_id: null,
};

export default combineReducers<TaskState, TaskActions>({
    item: (state = initialTaskState, action) => {
        switch (action.type) {
        case getType(taskActions.setTask):
            return action.payload;
        case getType(taskActions.updateTask):
            if (action.payload.id === state.id) {
                return {
                    ...state,
                    ...action.payload,
                };
            }
            // fallthrough
        default:
            return state;
        }
    },
    list: (state = paginateInitialState, action) => {
        switch (action.type) {
        case getType(taskActions.appendTasks):
            return {
                links: action.payload.links,
                data: [...state.data, ...action.payload.data],
                meta: action.payload.meta,
            };
        case getType(taskActions.appendTask):
            return {
                ...state,
                data: [action.payload, ...state.data],
            };
        case getType(taskActions.setTasks):
            return action.payload;
        case getType(taskActions.updateTask):
            return updateInPaginated(state, action.payload);
        default:
            return state;
        }
    },
});
