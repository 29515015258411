// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { IComputeResourceResponse } from 'common/api/resources/ComputeResource';

// Should be synced with `backend/api/v1/BackupNode/Enums/BackupNodeType.php`
export enum BackupNodeType {
    SSH_RSYNC = 'ssh_rsync',
    HETZNER_STORAGE_BOX = 'hetzner_storage_box',
    S3 = 's3',
}

export interface IShortBackupNodeResponse {
    id: number;
    name: string;
}

export type Credentials = ISSHRsyncCredentials | IHetznerStorageBoxCredentials | IS3Credentials;

export interface IBackupNodeResponse {
    id: number;
    name: string;
    type: BackupNodeType;
    credentials: Credentials;
    compute_resources_count: number;
    backups_count: number;
    total_backups_size: number;
    compute_resources: IComputeResourceResponse[];
    can_delete: boolean;
    settings: IBackupNodeSettings;

    is_deleting: boolean;
}

export interface IBackupNodeCreateRequest {
    name: string;
    type: BackupNodeType;
    credentials: Credentials;
    compute_resources: number[];
    settings: IBackupNodeSettings;
}

export interface IBackupNodeSettings {
    concurrent_backups: IConcurrentBackups;
}

export interface IConcurrentBackups {
    create: number;
    restore: number;
}

export interface ISSHRsyncCredentials {
    host: string;
    port: number;
    login: string;
    key: string;
    storage_path: string;
}

export interface IHetznerStorageBoxCredentials {
    host: string;
    login: string;
    key: string;
}

export interface IS3Credentials {
    access_key_id: string;
    secret_access_key: string;
    region: string;
    bucket: string;
    storage_path: string;
    endpoint: string;
}
