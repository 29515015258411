// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import React from 'react';
import {
    Translate,
    Icon,
    ProgressStep,
} from '@plesk/ui-library';
import {
    StatusText,
    StatusIconContainer,
    StatusSubtext,
    ProgressContainer,
} from 'admin/update/containers/VersionState/Styles';
import {
    INTENT_TYPE,
    PROGRESS_STATUS,
} from 'common/constants';
import { connect } from 'react-redux';
import { RootState } from 'admin/core/store';
import { TASK_ACTION } from 'common/api/resources/Task';
import { formatUpdateDate } from 'common/date';
import { Link } from 'react-router-dom';

interface IVersionUpdateCompleteWithErrorsProps {
    finishedAt: string | null;
    failedCount: number;
}

export type VersionUpdateCompleteWithErrorsProps =
    IVersionUpdateCompleteWithErrorsProps &
    ReturnType<typeof mapStateToProps>;

export const VersionUpdateCompleteWithErrors: React.FC<VersionUpdateCompleteWithErrorsProps> = ({
    finishedAt,
    failedCount,
    version,
}) => (
    <>
        <StatusIconContainer>
            <Icon
                name="exclamation-mark-circle-filled"
                intent={INTENT_TYPE.DANGER}
            />
        </StatusIconContainer>
        <div>
            <StatusText>
                <Translate content="update.status.completeWithErrors" />
            </StatusText>
            <StatusSubtext>
                <Translate content="update.finishedAt" />
                    &nbsp;
                {finishedAt && formatUpdateDate(finishedAt)}
            </StatusSubtext>
            <ProgressContainer>
                <ProgressStep
                    title={<Translate content="update.step.downloadingUpdate" />}
                    status={PROGRESS_STATUS.DONE}
                />
                <ProgressStep
                    title={<Translate content="update.step.masterNodeUpdating" />}
                    status={PROGRESS_STATUS.DONE}
                />
                <ProgressStep
                    title={<Translate content="update.step.computeResourcesUpgrading" />}
                    status={PROGRESS_STATUS.ERROR}
                >
                    <Translate
                        content="update.failedToUpdate"
                        params={{ count: <Link to={
                            `/admin/tasks?action=${TASK_ACTION.UPGRADE_COMPUTE_RESOURCE}&version=${version}`
                        }>{failedCount}</Link> }}
                    />
                </ProgressStep>
            </ProgressContainer>
        </div>
    </>
);

const mapStateToProps = (state: RootState) => ({
    version: state.app.meta.version,
});

export default connect(mapStateToProps)(VersionUpdateCompleteWithErrors);
