// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';
import { COLORS } from 'common/theme';

export const InlineFormField = styled.div`
  display: flex;
  justify-content: flex-start;
  min-height: 36px;
    

  > div {
    margin-bottom: 0 !important;
    padding-right: 8px;
  }

  > div:first-child {
    flex: 0;
    flex-basis: auto;
    padding-right: 16px;
  }

  > span {
    align-self: center;
    font-size: 12px;
    line-height: 16px;
    color: ${COLORS.GREY_80};
  }
`;
