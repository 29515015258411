// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import OsImageForm from 'admin/osImage/containers/OsImageForm';
import { connect } from 'react-redux';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { RootState } from 'admin/core/store';
import { Loader } from 'common/components';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import * as osImageActions from 'common/modules/osImage/actions';
import * as osImageVersionActions from 'common/modules/osImage/actionsVersions';
import {
    Action,
    Icon,
    List,
    Switch,
    Toolbar,
    ToolbarGroup,
    Tooltip,
    Translate,
} from '@plesk/ui-library';
import { PageHeader } from 'admin/common/components/PageHeader/PageHeader';
import InfiniteScroll from 'common/components/InfinityScroll/InfinityScroll';
import { StyledTable } from 'common/components/styles/StyledTable';
import { StyledActions } from 'common/components/Actions/Styles';
import { IOsImageResponse } from 'common/api/resources/OsImage';
import { OS_IMAGES } from 'common/modules/osImage/constants/tests';
import { EmptyView } from 'common/components/EmptyView/EmptyView';
import { Dialog } from 'common/components/Dialog/Dialog';
import OsImageRow from 'admin/osImage/containers/OsImageRow/OsImageRow';
import OsImageVersionForm from 'admin/osImage/containers/OsImageVersionForm';
import ButtonWithConfirmation from 'common/components/ButtonWithConfirmation';
import { Button } from 'admin/common/components/Button/Button';
import { INTENT_TYPE } from 'common/constants';
import { dataCySelector } from 'common/tests/selectors';
import CellIcon from 'admin/icon/components/CellIcon/CellIcon';
import { SetAsDefaultAction } from 'admin/common/components/SetAsDefaultAction';
import { IListRow } from 'common/list';
import { IListReorderedItem } from 'common/reducers/list';
import { getNewPosition } from 'common/helpers/position';
import { getActionColumnProps } from 'common/helpers/list';

export type OsImageProps =
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

const columns = [{
    key: 'colId',
    width: '1%',
    title: <Translate content="osImage.list.id" />,
}, {
    width: '50%',
    key: 'colName',
    title: <Translate content="osImage.list.name" />,
    cellProps: {
        className: 'cell-bold cell-default',
    },
}, {
    width: '1%',
    key: 'colVisibility',
    title: <Translate content="osImage.list.visibility" />,
    cellProps: {
        style: { textAlign: 'center' },
    },
}, getActionColumnProps(),
];

enum OPENED_DIALOG {
    NONE,
    OS_IMAGE,
    OS_IMAGE_VERSION,
}

export const OsImage: React.FC<OsImageProps> = ({
    loadingFlags: {
        isLoadingList,
        isLoadingOsImage,
        isLoadingOsImageVersion,
        isSavingItem,
        isDeletingItem,
    },
    osImageActions: {
        loadOsImagesOnScroll,
        getOsImage,
        getOsImages,
        removeOsImage,
        removeOsImages,
        updateDefaultOsImage,
        updateOsImageVisibility,
        updateOsImagePosition,
    },
    osImageVersionActions: {
        getOsImageVersion,
        unsetOsImageVersionItem,
    },
    list,
    item,
    hasMore,
    reorderable,
    osImageVersion,
}) => {
    const [osImageVersionForm, setOsImageVersionForm] = React.useState<React.ReactNode>();
    const [openedDialog, setOpenedDialog] = React.useState(OPENED_DIALOG.NONE);
    const [confirmationDialog, setConfirmationDialog] = React.useState();
    const [selection, setSelection] = React.useState<string[]>([]);
    const [blockDefault, setBlockDefault] = React.useState(false);
    const [blockVisibility, setBlockVisibility] = React.useState(false);
    const [expandedRows, setExpandedRows] = React.useState<string[]>([]);

    React.useEffect(() => {
        getOsImages();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleExpandedRowsChange = (rows: string[]) => setExpandedRows(rows);

    const removeItems = React.useCallback(async (ids: number | number[]) => {
        try {
            Array.isArray(ids) ? await removeOsImages(ids) : await removeOsImage(ids);
            setSelection([]);
        } finally {
            closeRemoveDialog();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleRemoveSelected = () => {
        removeItems(selection.map(id => parseInt(id, 10)));
    };

    const closeRemoveDialog = () => setConfirmationDialog(undefined);
    const handleCreateOsImage = () => setOpenedDialog(OPENED_DIALOG.OS_IMAGE);
    const handleClose = () => setOpenedDialog(OPENED_DIALOG.NONE);
    const handleEdit = (id: number) => async () => {
        getOsImage(id);
        setOpenedDialog(OPENED_DIALOG.OS_IMAGE);
    };

    const handleCreated = (index: number) => () => {
        setExpandedRows([...expandedRows, index.toString()]);
        setOpenedDialog(OPENED_DIALOG.NONE);
    };

    const handleCreateOsImageVersion = (osImage: IOsImageResponse) => () => {
        unsetOsImageVersionItem();
        setOsImageVersionForm(
            <OsImageVersionForm
                osImage={osImage}
                onCreated={handleCreated(osImage.id)}
            />
        );
        setOpenedDialog(OPENED_DIALOG.OS_IMAGE_VERSION);
    };
    const handleEditOsImageVersion = (osImage: IOsImageResponse) => async (id: number) => {
        setOsImageVersionForm(
            <OsImageVersionForm
                osImage={osImage}
                onCreated={handleClose}
            />
        );
        getOsImageVersion(id);
        setOpenedDialog(OPENED_DIALOG.OS_IMAGE_VERSION);
    };

    const handleRemove = (id: number) => async () => await removeItems(id);
    const handleSetDefault = (osImage: IOsImageResponse) => async () => {
        if (blockDefault) {
            return;
        }

        try {
            setBlockDefault(true);
            await updateDefaultOsImage(osImage.id, { is_default: !osImage.is_default });
        } finally {
            setBlockDefault(false);
        }
    };
    const handleToggleVisibility = (osImage: IOsImageResponse) => async() => {
        if (blockVisibility && osImage.is_loading) {
            return;
        }

        try {
            setBlockVisibility(true);
            await updateOsImageVisibility(osImage.id, { is_visible: !osImage.is_visible });
        } finally {
            setBlockVisibility(false);
        }
    };

    const handleDragEnd = async (reorderData: IListReorderedItem) => {
        if (list[reorderData.oldIndex] !== undefined) {
            const newPos = getNewPosition(reorderData.oldIndex, reorderData.newIndex, list);

            await updateOsImagePosition(list[reorderData.oldIndex].id, { position: newPos });
        }
    };

    const data = React.useMemo(() => list.map((osImage) => {
        const actionsEl = (
            <StyledActions>
                <Button
                    ghost={true}
                    intent={INTENT_TYPE.PRIMARY}
                    onClick={handleCreateOsImageVersion(osImage)}
                >
                    {<Translate content="osImage.addVersion" />}
                </Button>
                <SetAsDefaultAction
                    title={<Translate content="osImage.tooltip.setAsDefault"/>}
                    isDefault={osImage.is_default}
                    isLoading={osImage.is_loading && blockDefault}
                    onClick={handleSetDefault(osImage)}
                    data-cy={dataCySelector(osImage.id, OS_IMAGES.DEFAULT)}
                />
                <Tooltip title={<Translate content="osImage.tooltip.edit"/>}>
                    <Action
                        icon={<Icon name="pencil" />}
                        className="action-icon"
                        onClick={handleEdit(osImage.id)}
                    />
                </Tooltip>
                <ButtonWithConfirmation
                    isLoading={osImage.is_deleting}
                    translations={{
                        button: (
                            <Translate content="osImage.buttonWithConfirmation.button" />
                        ),
                        title: (
                            <Translate content="osImage.buttonWithConfirmation.title" />
                        ),
                        tooltip: (
                            <Translate content="osImage.buttonWithConfirmation.tooltip" />
                        ),
                    }}
                    handleConfirm={handleRemove(osImage.id)}
                    icon="recycle"
                />
            </StyledActions>
        );

        return {
            colId: osImage.id,
            colName: <CellIcon icon={osImage.icon} name={osImage.name} />,
            colVisibility: <Switch
                checked={osImage.is_visible}
                onChange={handleToggleVisibility(osImage)}
                loading={osImage.is_loading && blockVisibility}
                data-cy={dataCySelector(osImage.id, OS_IMAGES.VISIBILITY)}
            />,
            colActions: actionsEl,
            key: osImage.id.toString(),
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [list, isSavingItem, blockDefault, blockVisibility, isDeletingItem]);

    const renderRow = (row: IListRow) => {
        const osImage = list.find(element => element.id.toString() === row.key);

        return osImage !== undefined
            ? (
                <OsImageRow
                    onEdit={handleEditOsImageVersion(osImage)}
                    item={osImage}
                />
            )
            : null;
    };

    return (
        <>
            {confirmationDialog}
            <PageHeader
                title={<Translate content="osImage.title"/>}
                buttonText="osImage.addBtn"
                buttonIcon="cd"
                onButtonClick={handleCreateOsImage}
                isButtonShown={data.length > 0}
            />
            <StyledTable>
                {data.length > 0 && (
                    <Toolbar data-cy={OS_IMAGES.TOOLBAR}>
                        <ToolbarGroup title="actions">
                            <ButtonWithConfirmation
                                data-cy={OS_IMAGES.BATCH_DELETE_BTN}
                                disabled={!selection.length}
                                isLoading={isDeletingItem}
                                confirmationButtonGhost={false}
                                confirmationButtonText={<Translate content="plan.removeBtn" />}
                                translations={{
                                    title: (
                                        <Translate content="osImage.batchButtonWithConfirmation.title" />
                                    ),
                                    button: (
                                        <Translate content="osImage.batchButtonWithConfirmation.button" />
                                    ),
                                    tooltip: (
                                        <Translate content="osImage.batchButtonWithConfirmation.tooltip" />
                                    ),
                                }}
                                handleConfirm={handleRemoveSelected}
                                icon="recycle"
                            />
                        </ToolbarGroup>
                    </Toolbar>
                )}
                <InfiniteScroll
                    loadMore={loadOsImagesOnScroll}
                    hasMore={hasMore}
                >
                    <Loader isLoading={isLoadingList}>
                        <List
                            emptyView={
                                <EmptyView
                                    title="osImage.emptyView.title"
                                    description="osImage.emptyView.description"
                                    buttonText="osImage.emptyView.buttonText"
                                    onButtonClick={handleCreateOsImage}
                                    icon="cd"
                                />
                            }
                            data-cy={OS_IMAGES.TABLE}
                            columns={columns}
                            renderRowBody={renderRow}
                            data={data}
                            selection={selection}
                            onSelectionChange={setSelection}
                            onExpandedRowsChange={handleExpandedRowsChange}
                            expandedRows={expandedRows}
                            reorderable={reorderable}
                            onReorderEnd={handleDragEnd}
                        />
                    </Loader>
                </InfiniteScroll>
            </StyledTable>
            <Dialog
                heading={(
                    <Translate
                        content={`osImage.form.${item.id > 0 ? 'titleEdit' : 'titleAdd'}`}
                        params={{ group: item.name }}
                    />)}
                closeHandler={handleClose}
                isOpen={openedDialog === OPENED_DIALOG.OS_IMAGE}
                size="xs"
            >
                <Loader isLoading={isLoadingOsImage} center={false}>
                    <OsImageForm onCreated={handleClose} />
                </Loader>
            </Dialog>
            <Dialog
                heading={(
                    <Translate
                        content={`osImageVersion.${osImageVersion.id > 0 ? 'titleEdit' : 'titleAdd'}`}
                        params={{ version: osImageVersion.version }}
                    />
                )}
                closeHandler={handleClose}
                isOpen={openedDialog === OPENED_DIALOG.OS_IMAGE_VERSION}
                size="xs"
            >
                <Loader isLoading={isLoadingOsImageVersion} center={false}>
                    {osImageVersionForm}
                </Loader>
            </Dialog>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    list: state.osImage.list.data.sort((a, b) => a.position - b.position),
    item: state.osImage.item,
    hasMore: !!state.osImage.list.links.next,
    reorderable: true,
    osImageVersion: state.osImageVersion.item,
    loadingFlags: {
        isLoadingList: !state.osImage.list.data.length && state.app.loadingFlags.has(LOADING_FLAGS.OS_IMAGE_LIST),
        isLoadingOsImage: state.app.loadingFlags.has(LOADING_FLAGS.OS_IMAGE_ITEM),
        isLoadingOsImageVersion: state.app.loadingFlags.has(LOADING_FLAGS.OS_IMAGE_VERSION_ITEM),
        isSavingItem: state.app.loadingFlags.has(LOADING_FLAGS.SAVE_OS_IMAGE_ITEM),
        isDeletingItem: state.app.loadingFlags.has(LOADING_FLAGS.REMOVE_OS_IMAGE_ITEM),
    },
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    osImageActions: bindActionCreators(osImageActions, dispatch),
    osImageVersionActions: bindActionCreators(osImageVersionActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(OsImage);
