// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import {
    ActionType,
    getType,
} from 'typesafe-actions';
import * as twoFactorAuthActions from 'common/modules/auth/twoFactorAuth/actions';
import {
    setShouldGenerateTwoFactorAuthRecoveryCodes,
    setTwoFactorAuthRecoveryCodes,
    setTwoFactorAuthSecret,
    unsetTwoFactorAuthRecoveryCodes,
    unsetTwoFactorAuthSecret,
} from 'common/modules/auth/twoFactorAuth/actions';

interface ITwoFactorAuth {
    secret: string;
    qr: string;
    recovery_codes: string[];
    should_generate_recovery_codes: boolean;
}

export type TwoFactorAuthAction = ActionType<typeof twoFactorAuthActions>;
export type TwoFactorAuthState = Readonly<ITwoFactorAuth>;

export const initialTwoFactorAuthState: ITwoFactorAuth = {
    secret: '',
    qr: '',
    recovery_codes: [],
    should_generate_recovery_codes: false,
};

export default (state: TwoFactorAuthState = initialTwoFactorAuthState, action: TwoFactorAuthAction) => {
    switch (action.type) {
    case getType(setTwoFactorAuthSecret):
        return {
            ...state,
            ...action.payload,
        };
    case getType(unsetTwoFactorAuthSecret):
        return {
            ...state,
            secret: initialTwoFactorAuthState.secret,
            qr: initialTwoFactorAuthState.qr,
        };
    case getType(setTwoFactorAuthRecoveryCodes):
        return {
            ...state,
            recovery_codes: action.payload,
            recovery_code_count: action.payload.length,
        };
    case getType(unsetTwoFactorAuthRecoveryCodes):
        return {
            ...state,
            recovery_codes: initialTwoFactorAuthState.recovery_codes,
        };
    case getType(setShouldGenerateTwoFactorAuthRecoveryCodes):
        return {
            ...state,
            should_generate_recovery_codes: action.payload,
        };
    default:
        return state;
    }
};