// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import * as defaultTemplateActions from 'admin/defaultTemplate/actions';
import { connect } from 'react-redux';
import { RootState } from 'admin/core/store';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { PageHeader } from 'admin/common/components/PageHeader/PageHeader';
import { Translate } from '@plesk/ui-library';
import { Loader } from 'common/components';
import { EmptyView } from 'common/components/EmptyView/EmptyView';
import { Dialog } from 'common/components/Dialog/Dialog';
import {
    ICONS,
    SIZE,
} from 'common/constants';
import {
    getActionColumnProps,
    reloadListData,
} from 'common/helpers/list';
import { useIsFirstLoading } from 'common/hooks/useIsFirstLoading';
import List from 'common/components/List/List';
import DefaultTemplateForm from 'admin/defaultTemplate/containers/DefaultTemplateForm';
import { DefaultTemplateActions } from 'admin/defaultTemplate/components/DefaultTemplateActions';

export type DefaultTemplatesProps =
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export enum DefaultTemplateTableColumns {
    ID = 'colId',
    NAME = 'colName',
    ACTIONS = 'colActions',
}

const columns = [{
    width: '1%',
    key: DefaultTemplateTableColumns.ID,
    title: <Translate content="defaultTemplate.list.id" />,
}, {
    width: '15%',
    key: DefaultTemplateTableColumns.NAME,
    title: <Translate content="defaultTemplate.list.name" />,
}, getActionColumnProps(),
];

export const DefaultTemplates: React.FC<DefaultTemplatesProps> = ({
    item,
    list,
    isLoadingList,
    isLoadingItem,
    canManage,
    defaultTemplateActions: {
        getDefaultTemplates,
        getDefaultTemplate,
        removeDefaultTemplate,
    },
}) => {
    const [isDialogOpen, setDialogOpen] = React.useState(false);

    React.useEffect(() => {
        getDefaultTemplates();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadPaginated = (page: number) => getDefaultTemplates({ page });

    const isFirstLoading = useIsFirstLoading(isLoadingList);

    const handleRemove = (id: number) => async () => {
        await removeDefaultTemplate(id);
        reloadListData(list, loadPaginated);
    };

    const handleEdit = (id: number) => () => {
        getDefaultTemplate(id);
        setDialogOpen(true);
    };

    const data = list.data.map((defaultTemplate) => ({
        [DefaultTemplateTableColumns.ID]: defaultTemplate.id,
        [DefaultTemplateTableColumns.NAME]: defaultTemplate.name,
        [DefaultTemplateTableColumns.ACTIONS]: canManage ? (
            <DefaultTemplateActions
                item={defaultTemplate}
                handleEdit={handleEdit(defaultTemplate.id)}
                handleRemove={handleRemove(defaultTemplate.id)}
            />
        ) : undefined,
        key: defaultTemplate.id.toString(),
    }));

    return (
        <>
            <PageHeader
                isButtonShown={list.data.length > 0 && canManage}
                title={<Translate content="defaultTemplate.title"/>}
                buttonText="defaultTemplate.addButton"
                buttonIcon={ICONS.PLUS}
                onButtonClick={() => setDialogOpen(true)}
            />
            <Loader isLoading={isFirstLoading}>
                <List
                    emptyView={
                        <EmptyView
                            title="defaultTemplate.emptyView.title"
                            description="defaultTemplate.emptyView.description"
                            buttonText="defaultTemplate.emptyView.buttonText"
                            onButtonClick={() => setDialogOpen(true)}
                            icon={ICONS.PLUS}
                        />
                    }
                    columns={columns}
                    data={data}
                    loadItems={loadPaginated}
                    meta={list.meta}
                    isLoading={isLoadingList}
                    isFirstLoading={isFirstLoading}
                />
            </Loader>
            <Dialog
                heading={<Translate content={item.id
                    ? 'defaultTemplate.dialog.title.edit'
                    : 'defaultTemplate.dialog.title.create'
                } />}
                closeHandler={() => setDialogOpen(false)}
                isOpen={isDialogOpen}
                size={SIZE.XS}
            >
                <Loader isLoading={isLoadingItem} center={false}>
                    <DefaultTemplateForm onSubmit={() => setDialogOpen(false)}/>
                </Loader>
            </Dialog>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    item: state.defaultTemplate.item,
    list: state.defaultTemplate.list,
    isLoadingList: state.app.loadingFlags.has(LOADING_FLAGS.DEFAULT_TEMPLATE_LIST),
    isLoadingItem: state.app.loadingFlags.has(LOADING_FLAGS.DEFAULT_TEMPLATE_ITEM),
    canManage: true, // hasPermission(state, PERMISSION_LIST.MANAGE_ITEMS),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    defaultTemplateActions: bindActionCreators(defaultTemplateActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(DefaultTemplates);
