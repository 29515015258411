// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'admin/core/store';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import {
    ipRule,
    validate,
    requiredRule,
} from 'common/validator';
import {
    Form,
    FormFieldText,
    Section,
    setIn,
    Translate,
} from '@plesk/ui-library';
import {
    INTENT_TYPE,
    SIZE,
} from 'common/constants';
import { Button } from 'admin/common/components/Button/Button';
import { nestStringProperties } from 'common/modules/app/formErrors/selectors';
import { IpBlockType } from 'common/api/resources/IpBlock';
import * as ipBlockActions from 'admin/ipBlock/actions';
import { IReserveIpRequest } from 'common/api/resources/Ip';

interface IReserveIpFromProps {
    id: number;
    type: IpBlockType;
    onSubmit: () => void;
}

export type ReserveIpFormProps =
    IReserveIpFromProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const ReserveIpForm: React.FC<ReserveIpFormProps> = ({
    id,
    type,
    onSubmit,
    isIpReserving,
    formErrors,
    ipBlockActions: {
        reserveIpInBlock,
    },
    formErrorsActions: {
        setFormErrors,
        clearFormErrors,
    },
}) => {
    const [submitValues, setSubmitValues] = React.useState({
        ip: '',
        comment: '',
    });

    React.useEffect(() => () => {
        clearFormErrors();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const handleSubmit = async (values: IReserveIpRequest) => {
        const version = type === IpBlockType.IPv4 ? 4 : 6;
        const rules = {
            ip: [
                requiredRule(<Translate content="validate.fieldRequired" />),
                ipRule(<Translate content="validate.badIpAddress" />, version),
            ],
        };

        const errors = validate<IReserveIpRequest>(values, rules);

        if (Object.keys(errors).length) {
            setFormErrors(errors);
            return;
        }

        await reserveIpInBlock(id, values);
        onSubmit();
    };

    const handleFieldChange = (field: string, value: string) => setSubmitValues(setIn(submitValues, field, value));

    return (
        <>
            <Form
                id="reserveIpForm"
                footerClassName="hidden"
                onSubmit={handleSubmit}
                onFieldChange={handleFieldChange}
                values={submitValues}
                errors={formErrors}
                hideRequiredLegend={true}
                submitButton={false}
                cancelButton={false}
                applyButton={false}
                vertical={true}
            >
                <Section>
                    <FormFieldText
                        name="ip"
                        size={SIZE.FILL}
                        label={<Translate content="ipBlock.reserveForm.ip" />}
                        required={true}
                    />
                    <FormFieldText
                        name="comment"
                        size={SIZE.FILL}
                        label={<Translate content="ipBlock.reserveForm.comment" />}
                    />
                </Section>
            </Form>
            <Button
                type="submit"
                form="reserveIpForm"
                fill={true}
                intent={INTENT_TYPE.PRIMARY}
                size={SIZE.LG}
                isLoading={isIpReserving}
            >
                <Translate content="ipBlock.reserveForm.saveBtn" />
            </Button>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    formErrors: nestStringProperties(state),
    isIpReserving: state.app.loadingFlags.has(LOADING_FLAGS.ADD_IP_TO_IPBLOCK),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    ipBlockActions: bindActionCreators(ipBlockActions, dispatch),
    formErrorsActions: bindActionCreators(formErrorsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ReserveIpForm);
