// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

export { firstName } from 'common/helpers/random/firstName';

export const alphaNumeric = (length: number) => {
    /* cspell:disable-next-line */
    const chars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';

    return Array(length)
        .fill(chars)
        .map(x => x[Math.floor(Math.random() * x.length)])
        .join('');
};