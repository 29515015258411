// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import {
    api,
    IApiResponse,
} from 'common/api/resources/Response';
import { CancelTokenSource } from 'axios';
import {
    IActivateSolusVMLicenseRequest,
    ISolusVMLicenseResponse,
    ISolusVMLicenseComputeResources,
    IAssignComputeResourcesRequest,
} from 'common/api/resources/License/solusVM/model';

export const license = {
    activate: (data: IActivateSolusVMLicenseRequest) => api.post<IApiResponse<ISolusVMLicenseResponse>>('solus-license/activate', data),
    refresh: () => api.post<IApiResponse<ISolusVMLicenseResponse>>('solus-license/refresh'),
    reset: () => api.post<IApiResponse<ISolusVMLicenseResponse>>('solus-license/reset'),
    item: (cancelToken?: CancelTokenSource) => api.get<IApiResponse<ISolusVMLicenseResponse>>('solus-license', {
        cancelToken: cancelToken?.token,
    }),
    computeResources: () => api.get<IApiResponse<ISolusVMLicenseComputeResources>>('solus-license/compute_resources'),
    assignComputeResources: (data: IAssignComputeResourcesRequest) =>
        api.post<IApiResponse<ISolusVMLicenseComputeResources>>('solus-license/compute_resources', data),
};
