// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';
import { COLORS } from 'common/theme';

export const StyledSegmentedControl = styled.div`
    .pul-button {
        margin-right: 1px!important;
        margin-left: 0!important;
        font-weight: 600;
        font-size: 12px;
        line-height: 16px;
        min-width: 38px;

        background: #ececec;
        box-shadow: 0 1px 1px rgba(0, 0, 0, 0.28);
        border: 0;

        &:focus {
            color: #222;
            background: #ccc;
        }

        &:active {
            color: #222;
            background: #ececec;
        }
    }

    .pul-button--selected {
        color: #222;
        background: #ccc;
        box-shadow: inset 0 2px 1px rgba(0, 0, 0, 0.15);
    }

    .pul-segmented-control .pul-button {
        padding-right: 6px;
        padding-left: 6px;
    }

    button {
        &:first-child {
            border-radius: 2px 0 0 2px!important;
        }

        &:last-child {
            border-radius: 0 2px 2px 0!important;
        }

        &:hover {
            color: #222;
            background: ${COLORS.GREY_40};
            box-shadow: 0 2px 2px rgba(0, 0, 0, 0.2);
        }
    }
`;
