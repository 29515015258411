// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { RootState } from 'admin/core/store';
import { UPDATE_STATUS } from 'common/api/resources/Update';

export enum CLUSTER_UPDATE_STATUS {
    UPDATING,
    FAILED,
    COMPLETE,
    NONE,
}

export const selectClusterUpdateStatus = (state: RootState) => {
    const { total, done, upgrading } = state.update.compute_resources;
    const { status } = state.update;

    const isDone = !upgrading || total - done === 0;

    if (state.update.task === null) {
        return CLUSTER_UPDATE_STATUS.COMPLETE;
    }

    if ((status === UPDATE_STATUS.UPDATING || status === UPDATE_STATUS.UP_TO_DATE) && upgrading) {
        return CLUSTER_UPDATE_STATUS.UPDATING;
    }

    if (status === UPDATE_STATUS.UP_TO_DATE && isDone) {
        return total - done > 0 ? CLUSTER_UPDATE_STATUS.FAILED : CLUSTER_UPDATE_STATUS.COMPLETE;
    }

    return CLUSTER_UPDATE_STATUS.NONE;
};
