// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';

export const Container = styled.div`
  color: ${props => props.theme.primary_color};
  text-align: center;
  
  span {
    vertical-align: middle;
  }
  
  img {
    max-height: 100px;
  }
  
  svg {
    height: 40px;
    fill: ${props => props.theme.primary_color};
  }
`;

export const Title = styled.span`
    font-size: 48px;
    line-height: 48px;
`;
