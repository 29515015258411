// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { createCustomAction } from 'typesafe-actions';
import * as types from 'admin/eventHandler/constants/types';
import { IPaginateApiResponse } from 'common/api/resources/Response';
import {
    IEventHandlerListRequest,
    IEventHandlerPatchRequest,
    IEventHandlerRequest,
    IEventHandlerResponse,
} from 'common/api/resources/EventHandler/model';
import { Dispatch } from 'redux';
import {
    setIsLoading,
    unsetIsLoading,
} from 'common/modules/app/loadingFlags/actions';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { eventHandlers } from 'common/api/resources/EventHandler';
import { bakeForegroundToast } from 'common/modules/app/toaster/actions';
import { INTENT_TYPE } from 'common/constants';

export const setEventHandlerList = createCustomAction(
    types.SET_EVENT_HANDLER_LIST,
    (data: IPaginateApiResponse<IEventHandlerResponse[]>) => ({ payload: data })
);
export const setEventHandlerItem = createCustomAction(
    types.SET_EVENT_HANDLER_ITEM,
    (data: IEventHandlerResponse) => ({ payload: data })
);

export const unsetEventHandlerItem = createCustomAction(types.UNSET_EVENT_HANDLER_ITEM);

export const addEventHandlerItem = createCustomAction(
    types.ADD_EVENT_HANDLER_ITEM,
    (data: IEventHandlerResponse) => ({ payload: data })
);
export const updateEventHandlerItem = createCustomAction(
    types.UPDATE_EVENT_HANDLER_ITEM,
    (data: IEventHandlerResponse) => ({ payload: data })
);

export const setEventHandlerItemIsDeleting = createCustomAction(
    types.SET_EVENT_HANDLER_IS_DELETING,
    (id: number, isDeleting: boolean) => ({ payload: { id, is_deleting: isDeleting } })
);

export const setEventHandlerItemIsLoading = createCustomAction(
    types.SET_EVENT_HANDLER_IS_LOADING,
    (id: number, isLoading: boolean) => ({ payload: { id, is_loading: isLoading } })
);

export const removeEventHandlerItem = createCustomAction(
    types.REMOVE_EVENT_HANDLER_ITEM,
    (id: number) => ({ payload: id })
);

export const getEventHandlers = (params?: IEventHandlerListRequest) => async (dispatch: Dispatch) =>  {
    dispatch(setIsLoading(LOADING_FLAGS.EVENT_HANDLER_LIST));
    try {
        const result = await eventHandlers.list(params);
        dispatch(setEventHandlerList(result.data));
        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.EVENT_HANDLER_LIST));
    }
};

export const getEventHandler = (id: number) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.EVENT_HANDLER_ITEM));
    try {
        const result = await eventHandlers.item(id);
        dispatch(setEventHandlerItem(result.data.data));
        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.EVENT_HANDLER_ITEM));
    }
};

export const createEventHandler = (data: IEventHandlerRequest) => async (dispatch: Dispatch) =>  {
    dispatch(setIsLoading(LOADING_FLAGS.EVENT_HANDLER_ITEM_SAVE));
    try {
        const result = await eventHandlers.create(data);
        dispatch(addEventHandlerItem(result.data.data));
        bakeForegroundToast(INTENT_TYPE.SUCCESS, 'eventHandler.toasts.saved')(dispatch);
        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.EVENT_HANDLER_ITEM_SAVE));
    }
};

export const updateEventHandler = (id: number, data: IEventHandlerRequest) => async (dispatch: Dispatch) =>  {
    dispatch(setIsLoading(LOADING_FLAGS.EVENT_HANDLER_ITEM_SAVE));
    try {
        const result = await eventHandlers.update(id, data);
        dispatch(updateEventHandlerItem(result.data.data));
        bakeForegroundToast(INTENT_TYPE.SUCCESS, 'eventHandler.toasts.saved')(dispatch);
        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.EVENT_HANDLER_ITEM_SAVE));
    }
};

export const patchEventHandler = (id: number, data: IEventHandlerPatchRequest) => async (dispatch: Dispatch) =>  {
    dispatch(setEventHandlerItemIsLoading(id, true));
    try {
        const result = await eventHandlers.patch(id, data);
        dispatch(updateEventHandlerItem(result.data.data));
        bakeForegroundToast(INTENT_TYPE.SUCCESS, 'eventHandler.toasts.saved')(dispatch);
        return result;
    } finally {
        dispatch(setEventHandlerItemIsLoading(id, false));
    }
};

export const removeEventHandler = (id: number) => async (dispatch: Dispatch) => {
    dispatch(setEventHandlerItemIsLoading(id, true));
    try {
        const result = await eventHandlers.remove(id);
        dispatch(removeEventHandlerItem(id));
        bakeForegroundToast(INTENT_TYPE.SUCCESS, 'eventHandler.toasts.removed')(dispatch);
        return result;
    } finally {
        dispatch(setEventHandlerItemIsLoading(id, false));
    }
};