// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import React from 'react';
import {
    Translate,
    Icon,
} from '@plesk/ui-library';
import {
    StatusText,
    StatusIconContainer,
    StatusSubtext,
} from 'admin/update/containers/VersionState/Styles';
import { INTENT_TYPE } from 'common/constants';
import { formatUpdateDate } from 'common/date';

export interface IVersionUpdateCompleteProps {
    finishedAt: string | null;
}

export const VersionUpdateComplete: React.FC<IVersionUpdateCompleteProps> = ({
    finishedAt,
}) => (
    <>
        <StatusIconContainer>
            <Icon
                name="check-mark-circle-filled"
                intent={INTENT_TYPE.SUCCESS}
            />
        </StatusIconContainer>
        <div>
            <StatusText>
                <Translate content="update.status.upToDate" />
            </StatusText>
            {finishedAt && (
                <StatusSubtext>
                    <Translate content="update.finishedAt" />
                    &nbsp;
                    {finishedAt && formatUpdateDate(finishedAt)}
                </StatusSubtext>
            )}
        </div>
    </>
);

export default VersionUpdateComplete;
