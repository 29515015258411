// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import {
    ActionType,
    createCustomAction,
} from 'typesafe-actions';
import * as types from 'common/modules/app/notificationAlert/constants';

export interface INotificationAlertActions {
    setNotificationAlert(data: boolean): ActionType<typeof setNotificationAlert>;
}

export const setNotificationAlert = createCustomAction(
    types.SET_NOTIFICATION_ALERT,
    (data: boolean) => ({ payload: data })
);
