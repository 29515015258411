// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    FormFieldText,
    useTranslate,
} from '@plesk/ui-library';
import { SIZE } from 'common/constants';
import FilterForm from 'admin/common/components/FilterForm/FilterForm';
import { locations } from 'common/api/resources/Location';
import { users } from 'common/api/resources/User';
import { UserFilter } from 'admin/common/components/Filters/UserFilter/UserFilter';
import { LocationFilter } from 'admin/common/components/Filters/LocationFilter/LocationFilter';
import { useObjectSelectFilter } from 'common/hooks/useFilter';
import useObjectPropertyState from 'common/hooks/useObjectPropertyState';
import { ISelectRequiredOption } from 'common/components';
import { ValueType } from 'react-select';

export interface IVpcNetworkFilters {
    id?: string;
    search?: number;
    location_id?: number;
    user_id?: number;
}

export interface IVpcNetworkFiltersProps {
    filters: IVpcNetworkFilters;
    setFilters: React.Dispatch<React.SetStateAction<IVpcNetworkFilters>>;
}

export const VpcNetworkFilters: React.FC<IVpcNetworkFiltersProps> = ({
    filters,
    setFilters,
}) => {
    const [, setSearch] = useObjectPropertyState('search', [filters, setFilters]);

    const {
        isLoading: isLocationLoading,
        selectedItem: selectedLocation,
        selectItem: setSelectedLocation,
    } = useObjectSelectFilter(
        'location_id',
        [filters, setFilters],
        async (id) => {
            const { data: { data: { id: locationId, name } } } = await locations.item(id);
            return { value: locationId.toString(), label: name };
        }
    );

    const {
        isLoading: isUserLoading,
        selectedItem: selectedUser,
        selectItem: setSelectedUser,
    } = useObjectSelectFilter(
        'user_id',
        [filters, setFilters],
        async (id) => {
            const { data: { data: { id: userId, email } } } = await users.item(id);
            return { value: userId.toString(), label: email };
        }
    );

    const translate = useTranslate();

    return (
        <FilterForm values={filters}>
            <FormFieldText
                name="search"
                onChange={setSearch}
                size={SIZE.FILL}
                placeholder={translate('filters.search') as string}
            />
            <LocationFilter
                onChange={setSelectedLocation}
                value={selectedLocation}
                isLoading={isLocationLoading}
            />
            {/* TODO: SIO-5787 change the component signature, as it is incorrect (see ActivityLogs.tsx:86) */}
            <UserFilter
                onChange={setSelectedUser as (option: ValueType<ISelectRequiredOption>) => void}
                value={selectedUser as ValueType<ISelectRequiredOption>}
                isLoading={isUserLoading}
            />
        </FilterForm>
    );
};

export default VpcNetworkFilters;