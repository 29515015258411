// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import styled,
{ css } from 'styled-components';
import { Link } from 'react-router-dom';

interface INavMenuProps {
    isOpen: boolean;
}

interface IMenuItemProps {
    $isActive: boolean;
}

export const StyledClientMenu = styled.nav`
    top: 0;
    width: 108px;
    background: ${props => props.theme.primary_color};
    position: fixed;
    height: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    flex-direction: column;
    color: ${props => props.theme.secondary_color};

    @media (min-width: 1600px) {
      width: 120px;
    }

    @media (max-width: 560px) {
        position: sticky;
        z-index: 1;
        top: 0;
        height: 48px;
        width: 100%;
        justify-content: space-between;
        display: flex;
        flex-direction: row;
        align-items: start;
    }
`;

export const NavContainer = styled.div`
    width: 100%;
    height: inherit;

    @media (max-width: 560px) {
        position: relative;
        width: 130px;
        flex: none;
        margin-right: 12px;
    }
`;

export const NavMenu = styled.ul<INavMenuProps>`
    text-align: center;
    list-style: none;
    padding-left: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex: 2;
    height: inherit;

    li:first-child {
      margin-top: 20px;
      @media (max-width: 560px) {
        margin-top: 0;
      }
    }

    li:last-child {
      margin-top: auto;
      margin-bottom: unset;
    }

    @media (max-width: 560px) {
        height: fit-content;
        text-align: left;
        display: ${props => props.isOpen ? 'block' : 'none'};
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
        border-radius: 2px;
        background: #fff;
    }
`;

const menuStyles = css<IMenuItemProps>`
    height: 80px;
    margin-bottom: 8px;
    padding: 10px;
    cursor: pointer;
    transition: all ease-out 120ms;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: ${props => props.$isActive ? 'rgba(255,255,255,0.1)' : 'none'};
    color: rgb(255, 255, 255) !important;

    &:hover {
        background-color: rgba(255,255,255,0.1);
        text-decoration: none;
    }

    span.icon {
        display: block;
        font-size: 24px;
        transition: all ease 450ms;
        margin: 0 auto;
        padding-bottom: 10px;
    }

    @media (max-width: 560px) {
        display: flex;
        align-items: center;
        flex-direction: row;
        padding: 6px 5px 6px 10px;
        color: #222222;
        font-size: 14px;
        line-height: 16px;
        order: 2;
        height: 36px;
        justify-content: flex-start;
        margin-bottom: 0;

        svg {
            width: 16px;
            height: 16px;
            padding: 0;
            margin: 0;
            fill: #737373;
        }

        span.icon {
            padding: 0;
            margin: 0 10px 0 0;
            height: 20px;
            width: 16px;
        }

        &:hover {
            color: #737373;
        }
    }
`;

export const MenuItem = styled.div<IMenuItemProps>`
    ${menuStyles}
`;

export const MenuItemLink = styled(Link)<IMenuItemProps>`
    ${menuStyles}
`;

export const MobileMenu = styled.span`
    display: none;

    @media (max-width: 560px) {
        display: flex;
        height: 36px;
        padding: 6px 5px 6px 10px;
        align-items: center;
        flex-direction: row;
        font-size: 14px;
        line-height: 16px;
        justify-content: flex-start;
        cursor: pointer;
        color: #ffffff;

        span {
            padding: 0;
            margin: 0 10px 0 0;
        }
    }
`;
