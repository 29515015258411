// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { Text } from '@plesk/ui-library';
import {
    Title,
    Usage,
} from 'common/components/ServerTabs/NetworkingTab/components/TrafficCard/Styles';
import { INTENT_TYPE } from 'common/constants';
import * as React from 'react';
import { IPlanLimit } from 'common/api/resources/Plan';
import { DataUnit } from 'common/helpers/units';

export interface ITrafficProgressProps {
    limit: IPlanLimit<DataUnit>;
    title: React.ReactNode;
    component: string;
    value: number;
    isExceeded: boolean;
}

export const TrafficProgress: React.FC<ITrafficProgressProps> = ({
    component,
    limit,
    title,
    value,
    isExceeded,
}) => (
    <Title>
        <Text className="title" component={component}>
            {title}
        </Text>
        <Usage>
            <Text
                intent={isExceeded ? INTENT_TYPE.WARNING : undefined}
                component={component}
            >
                {value} {limit.unit}
            </Text>
            {limit.is_enabled && (
                <Text intent={INTENT_TYPE.MUTED} component={component}>
                    &nbsp;/ {limit.limit} {limit.unit}
                </Text>
            )}
        </Usage>
    </Title>
);

export default TrafficProgress;