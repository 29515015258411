// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

export const POSITION_INCREMENT = 65535;

export interface IPosition {
    position: number;
}

export const getNewPosition = (
    source: number,
    dest: number,
    item: IPosition[]
) => {
    let prev = 0;
    let next = 0;

    if (dest > 0 && dest < item.length - 1) {
        if (dest > source) {
            prev = item[dest].position;
            next = item[dest + 1].position;
        } else {
            prev = item[dest - 1].position;
            next = item[dest].position;
        }
    }

    if (dest === 0) {
        next = item[0].position;
    }

    if (dest === item.length - 1) {
        prev = item[dest].position;
    }

    if (next === 0) {
        return prev + POSITION_INCREMENT;
    }

    return (next - prev) / 2 + prev;
};
