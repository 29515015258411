// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';

interface IIconWrapperProps {
    color?: string;
}

export const IconWrapper = styled.span<IIconWrapperProps>`
    margin-right: 6px;
    gap: 6px;
    display: flex;
    align-items: center;
    color: ${props => props.color ? props.color : 'currentColor'};
`;
