// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import SettingsTab from 'common/components/ServerTabs/SettingsTab/SettingsTab';
import { connect } from 'react-redux';
import { RootState } from 'admin/core/store';

const mapStateToProps = (state: RootState) => ({
    server: state.computeResourceVm.item,
    isAdminSettings: true,
});

export default connect(mapStateToProps)(SettingsTab);
