// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import { ICommonState } from 'common/store';
import { PageSubHeader } from 'client/common/components';
import {
    ICONS,
    SIZE,
} from 'common/constants';
import {
    Grid,
    GridCol,
} from '@plesk/ui-library';
import InfiniteScroll from 'react-infinite-scroller';
import { Dispatch } from 'redux';
import { getVpcNetworksNextPage } from 'common/modules/vpcNetwork/actions';
import { IVpcNetworkResponse } from 'common/api/resources/VpcNetwork';
import { VpcNetworkCard } from 'common/modules/computeResourceVm/components/VpcNetworkCard';

export interface IVpcNetworkListProps {
    disabledIds?: number[];
    selectedVpcNetworkIds: number[];
    onVpcNetworkToggled: (vpcNetworkId: number) => void;
}

export type VpcNetworkListProps =
    IVpcNetworkListProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const VpcNetworkList: React.FC<VpcNetworkListProps> = ({
    disabledIds,
    selectedVpcNetworkIds,
    onVpcNetworkToggled,
    vpcNetworks,
    hasNextPage,
    loadNextPage,
}) => (
    <>
        <PageSubHeader title="servers.create.vpcNetworks"/>
        <InfiniteScroll
            loadMore={loadNextPage}
            hasMore={hasNextPage}
        >
            <Grid
                style={{ marginBottom: '-24px' }}
                gap={SIZE.LG}
                xs={1}
                sm={2}
                md={3}
                lg={4}
                xl={5}
            >
                {vpcNetworks.map((vpcNetwork: IVpcNetworkResponse) => (
                    <GridCol key={vpcNetwork.id}>
                        <VpcNetworkCard
                            disabled={disabledIds?.includes(vpcNetwork.id)}
                            vpcNetwork={vpcNetwork}
                            icon={ICONS.IP_ADDRESSES}
                            isSelected={selectedVpcNetworkIds.includes(vpcNetwork.id)}
                            onClick={() => onVpcNetworkToggled(vpcNetwork.id)}
                        />
                    </GridCol>
                ))}
            </Grid>
        </InfiniteScroll>
    </>
);

const mapStateToProps = (state: ICommonState) => ({
    hasNextPage: !!state.vpcNetwork.list.links.next,
    vpcNetworks: state.vpcNetwork.list.data,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    loadNextPage: () => dispatch(getVpcNetworksNextPage()),
});

export default connect(mapStateToProps, mapDispatchToProps)(VpcNetworkList);