// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import React from 'react';
import { INotificationResponse } from 'common/api/resources/Notification';
import {
    Icon,
    Translate,
} from '@plesk/ui-library';
import NotificationItem from 'admin/notification/containers/Items/NotificationItem';
import moment from 'moment';
import { INTENT_TYPE } from 'common/constants';

export interface ITrafficExceededProps {
    item: INotificationResponse;
    header: string;
    description: string;
}

export const TrafficExceeded: React.FC<ITrafficExceededProps> = ({
    item,
    header,
    description,
}) => (
    <NotificationItem
        item={item}
        icon={<Icon name="triangle-exclamation-mark" size="16" intent={INTENT_TYPE.WARNING} />}
        header={<Translate content={header} />}
        description={(
            <Translate
                content={description}
                params={{
                    name: <b>{item.data.name}</b>,
                    date: moment(item.data.date).format('D-M-Y'),
                    time: moment(item.data.date).format('HH:mm:ss'),
                }}
            />
        )}
    />
);

export default TrafficExceeded;
