// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import {
    IClusterImportComputeResourceResource,
    IClusterImportResponse,
} from 'common/api/resources/ClusterImport';
import {
    IPaginateApiResponse,
    paginateInitialState,
} from 'common/api/resources/Response';
import {
    ActionType,
    getType,
} from 'typesafe-actions';
import * as clusterImportActions from 'admin/clusterImport/actions';
import { combineReducers } from 'redux';
import {
    addToPaginated,
    updateInPaginated,
} from 'common/reducers/list';

interface IClusterImportReducer {
    item: IClusterImportResponse;
    list: IPaginateApiResponse<IClusterImportResponse[]>;
    computeResources: IPaginateApiResponse<IClusterImportComputeResourceResource[]>;
}

export type ClusterImportActions = ActionType<typeof clusterImportActions>;
export type ClusterImportState = Readonly<IClusterImportReducer>;

export const initialClusterImportState: IClusterImportResponse = {
    id: 0,
    name: '',
    status: '',
    fail_reason: '',
    is_ready: false,
    configuration: {
        host: '',
        port: 8080,
    },
    errors_on_import: [],
};

export const initialClusterImportComputeResourceState: IClusterImportComputeResourceResource = {
    id: 0,
    name: '',
    type: '',
    source_id: 0,
    server_count: 0,
    is_importable: false,
    not_importable_reasons: [],
    is_imported: false,
};

export default combineReducers<ClusterImportState, ClusterImportActions>({
    list: (state = paginateInitialState, action) => {
        switch (action.type) {
        case getType(clusterImportActions.setClusterImports):
            return action.payload;
        case getType(clusterImportActions.addClusterImportItem):
            return addToPaginated(state, action.payload);
        case getType(clusterImportActions.updateClusterImportItem):
            return updateInPaginated(state, action.payload);
        case getType(clusterImportActions.setClusterImportItemIsDeleting):
            return updateInPaginated(state, action.payload);
        case getType(clusterImportActions.removeClusterImportItem):
            return {
                ...state,
                data: state.data.filter(item => item.id !== action.payload),
            };
        case getType(clusterImportActions.setClusterImportStatus):
            return updateInPaginated(state, action.payload);
        default:
            return state;
        }
    },
    item: (state = initialClusterImportState, action) => {
        switch (action.type) {
        case getType(clusterImportActions.setClusterImport):
            return action.payload;
        case getType(clusterImportActions.unsetClusterImport):
            return initialClusterImportState;
        default:
            return state;
        }
    },
    computeResources: (state = paginateInitialState, action) => {
        switch (action.type) {
        case getType(clusterImportActions.setClusterImportComputeResources):
            return action.payload;
        default:
            return state;
        }
    },
});
