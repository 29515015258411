// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';
import { COLORS } from 'admin/core/theme';

export const VersionCardContent = styled.div`
    display: flex;
    padding: 18px 20px 6px;
    margin-bottom: 20px;

    background: ${COLORS.WHITE};
    box-shadow: 0 1px 3px rgba(60, 64, 67, 0.16), 0 1px 1px rgba(60, 64, 67, 0.08);
    border-radius: 2px;
`;

export const ChangeLog = styled.div`
    margin-top: 16px;
`;

export const VersionContent = styled.div`
    flex: 1;
    word-break: 'break-word';
`;
