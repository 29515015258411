// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as osImageActions from 'common/modules/osImage/actions';
import * as osImageVersionActions from 'common/modules/osImage/actionsVersions';
import { combineReducers } from 'redux';
import {
    ActionType,
    getType,
} from 'typesafe-actions';
import {
    IPaginateApiResponse,
    paginateInitialState,
} from 'common/api/resources/Response';
import { addToPaginated } from 'common/reducers/list';
import { IOsImageResponse } from 'common/api/resources/OsImage';

interface IOsImageReducer {
    list: IPaginateApiResponse<IOsImageResponse[]>;
    item: IOsImageResponse;
}

export type OsImageAction = ActionType<typeof osImageActions | typeof osImageVersionActions>;
export type OsImageState = Readonly<IOsImageReducer>;

export const initialOsImageState: IOsImageResponse = {
    id: 0,
    position: 0,
    name: '',
    icon: null,
    versions: [],
    is_default: false,
    is_loading: false,
    is_visible: true,
    is_deleting: false,
};

export default combineReducers<OsImageState, OsImageAction>({
    list: (state = paginateInitialState, action: OsImageAction) => {
        switch (action.type) {
        case getType(osImageActions.appendOsImages):
            return {
                links: action.payload.links,
                data: [...state.data, ...action.payload.data],
                meta: action.payload.meta,
            };
        case getType(osImageActions.addOsImageItem):
            return addToPaginated(state, action.payload);
        case getType(osImageActions.setOsImageList):
            return action.payload;
        case getType(osImageActions.removeOsImageItem):
            return {
                ...state,
                data: state.data.filter(item => item.id !== action.payload),
            };
        case getType(osImageActions.removeOsImageItems):
            const osImages = state.data.reduce((prev: IOsImageResponse[], current: IOsImageResponse) => {
                if (action.payload.includes(current.id)) {
                    return prev;
                }

                return [...prev, current];
            }, []);
            return {
                ...state,
                data: osImages,
            };
        case getType(osImageActions.updateOsImageItem):
        case getType(osImageActions.updateOsImageItemPosition):
            return {
                ...state,
                data: state.data.map(item => {
                    if (item.id === action.payload.id) {
                        return { ...item, ...action.payload };
                    }

                    return item;
                }),
            };
        case getType(osImageActions.clearDefault):
            return {
                ...state,
                data: state.data.map(item => ({ ...item, is_default: false })),
            };
        case getType(osImageActions.setOsImageItemIsLoading):
            return {
                ...state, data: [...state.data.map(item => {
                    if (item.id === action.payload) {
                        return { ...item, is_loading: true };
                    }

                    return item;
                })],
            };
        case getType(osImageActions.setOsImageItemIsDeleting):
            return {
                ...state, data: [...state.data.map(item => {
                    if (item.id === action.payload.id) {
                        return { ...item, is_deleting: action.payload.isDeleting };
                    }

                    return item;
                })],
            };
        case getType(osImageActions.unsetOsImageItemIsLoading):
            return {
                ...state, data: [...state.data.map(item => {
                    if (item.id === action.payload) {
                        return { ...item, is_loading: false };
                    }

                    return item;
                })],
            };
        case getType(osImageActions.addOsImageVersionItem):
            return {
                ...state,
                data: state.data.map(item => {
                    if (item.id === action.payload.id) {
                        item.versions = [action.payload.data, ...item.versions];
                        return item;
                    }

                    return item;
                }),
            };
        case getType(osImageActions.updateOsImageVersionItem):
            return {
                ...state,
                data: state.data.map(item => {
                    if (item.id === action.payload.id) {
                        item.versions = item.versions.map((version) => {
                            if (version.id === action.payload.data.id) {
                                return { ...version, ...action.payload.data };
                            }

                            return version;
                        });

                        return item;
                    }

                    return item;
                }),
            };
        case getType(osImageActions.removeOsImageVersionItem):
            return {
                ...state,
                data: state.data.map(item => {
                    if (item.id === action.payload.osImageId) {
                        item.versions = item.versions.filter(version => version.id !== action.payload.id);
                    }

                    return item;
                }),
            };
        case getType(osImageActions.updateOsImageVersionItemPosition):
            return {
                ...state,
                data: state.data.map(item => {
                    if (item.id === action.payload.os_image_id) {
                        item.versions = item.versions.map((version) => {
                            if (version.id === action.payload.id) {
                                return { ...version, ...action.payload };
                            }

                            return version;
                        });
                    }

                    return item;
                }),
            };
        case getType(osImageActions.setOsImageVersionItemIsLoading):
            return {
                ...state,
                data: state.data.map(item => {
                    if (item.id === action.payload.osImageId) {
                        item.versions = item.versions.map((version) => {
                            if (version.id === action.payload.id) {
                                return { ...version, is_loading: true };
                            }

                            return version;
                        });
                    }

                    return item;
                }),
            };
        case getType(osImageActions.unsetOsImageVersionItemIsLoading):
            return {
                ...state,
                data: state.data.map(item => {
                    if (item.id === action.payload.osImageId) {
                        item.versions = item.versions.map((version) => {
                            if (version.id === action.payload.id) {
                                return { ...version, is_loading: false };
                            }

                            return version;
                        });
                    }

                    return item;
                }),
            };
        default:
            return state;
        }
    },
    item: (state = initialOsImageState, action) => {
        switch (action.type) {
        case getType(osImageActions.setOsImageItem):
            return action.payload;
        case getType(osImageActions.unsetOsImageItem):
            return { ...initialOsImageState };
        default:
            return state;
        }
    },
});
