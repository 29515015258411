// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { SIZE } from 'common/constants';
import { InlineFormField } from 'common/components/Limit/Styles';
import { FormFieldNumber } from 'common/components/Form/FormFieldNumber/FormFieldNumber';
import { SegmentedControl } from 'common/components/SegmentedControl/SegmentedControl';
import {
    FormField,
    FormFieldSelect,
    Translate,
} from '@plesk/ui-library';
import { IPlanLimit } from 'common/api/resources/Plan';
import {
    ErrorForIsEnabled,
    ErrorForLimit,
} from 'common/components/plan/components/Styles';
import { Units } from 'common/helpers/units';

export interface ILimitProps<I = Units> {
    limit: IPlanLimit<I>;
    units: Record<string, string>;
    limitName: string;
    limitOnly?: boolean;
    limitOnlyTooltip?: React.ReactNode;
    label?: React.ReactNode;
    fullDescription?: React.ReactNode;
    disabled?: boolean;
    max?: number;
}

export const PlanLimit: React.FC<ILimitProps> = ({
    limit,
    units,
    limitName,
    limitOnly,
    limitOnlyTooltip,
    disabled,
    max,
    ...rest
}) => {
    const LIMIT_BUTTONS = [
        {
            title: <Translate content="limitForm.unlimited" />,
            value: false,
            disabled: disabled || limitOnly,
            tooltip: limitOnly ? limitOnlyTooltip : undefined,
        },
        { title: <Translate content="limitForm.limit" />, value: true, disabled },
    ];

    return (
        <FormField {...rest}>
            <InlineFormField>
                <ErrorForIsEnabled>
                    <FormField name={`limits[${limitName}][is_enabled]`}>
                        {({ setValue }) => (
                            <SegmentedControl
                                buttons={LIMIT_BUTTONS}
                                selected={limit.is_enabled}
                                onChange={setValue}
                            />
                        )}
                    </FormField>
                </ErrorForIsEnabled>
                <ErrorForLimit>
                    <FormFieldNumber
                        label={null}
                        name={`limits[${limitName}][limit]`}
                        min={1}
                        max={max}
                        disabled={disabled || !limit.is_enabled}
                    />
                </ErrorForLimit>
                <FormFieldSelect
                    name={`limits[${limitName}][unit]`}
                    size={SIZE.XS}
                    value={limit.limit}
                    disabled={disabled || !limit.is_enabled}
                >
                    {Object.values(units).map((value) => (
                        <option key={value} value={value}>
                            {value}
                        </option>
                    ))}
                </FormFieldSelect>
            </InlineFormField>
        </FormField>
    );
};

export default PlanLimit;
