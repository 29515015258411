// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import React from 'react';
import { Translate } from '@plesk/ui-library';
import {
    convertToDataUnit,
    DataUnit,
} from 'common/helpers/units';

interface IPlanDescriptionProps {
    planName?: string;
    vcpu: number;
    disk: number;
    ram: number;
}

export const PlanDescription: React.FC<IPlanDescriptionProps> = ({
    planName,
    vcpu,
    disk,
    ram,
}) => (
    <>
        {planName && (
            <>{planName}:&nbsp;</>
        )}
        <Translate content="planInfo.cpu" params={{ cpu: vcpu }} />,&nbsp;
        <Translate content="planInfo.memory" params={{
            ram: convertToDataUnit(ram, DataUnit.MiB),
        }} />,&nbsp;
        <Translate content="planInfo.disk" params={{ disk }} />
    </>
);
