// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';

export const Label = styled.div`
    flex: 1;
    span {
      vertical-align: middle;
      padding-right: 5px;
    }
`;

export const IconWrap = styled.span`
    color: ${props => props.theme.primary_color};
`;
