// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { Translate } from '@plesk/ui-library';
import ServerCreateDialog from 'admin/computeResource/page/containers/dialogs/ServerCreateDialog/ServerCreateDialog';
import { Dialog } from 'common/components/Dialog/Dialog';
import { SERVER } from 'admin/computeResource/constants/tests';
import ComputeResourceVmTable
    from 'admin/computeResourceVm/containers/ComputeResourceVmTable/ComputeResourceVmTable';
import { EmptyView } from 'common/components/EmptyView/EmptyView';

export interface IComputeResourceVmTabProps {
    computeResourceId: number;
    isOpened: boolean;
    onOpen: () => void;
    onClose: () => void;
}

const ComputeResourceVmTab: React.FC<IComputeResourceVmTabProps> = ({
    computeResourceId,
    isOpened,
    onOpen,
    onClose,
}) => (
    <>
        <ComputeResourceVmTable
            computeResourceId={computeResourceId}
            excludedColumns={['colComputeResource']}
            withFilters={false}
            emptyView={<EmptyView
                title="computeResource.servers.emptyView.title"
                description="computeResource.servers.emptyView.description"
                icon="screen"
                buttonText="computeResource.servers.emptyView.buttonText"
                onButtonClick={onOpen}
            />}
        />
        <Dialog
            heading={
                <Translate content="computeResource.servers.form.title" />
            }
            closeHandler={onClose}
            isOpen={isOpened}
            size="xs"
            data-cy={SERVER.DIALOG}
        >
            <ServerCreateDialog onClose={onClose}/>
        </Dialog>
    </>
);

export default ComputeResourceVmTab;
