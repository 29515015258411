// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

/* eslint @typescript-eslint/no-explicit-any: "off" -- "any" is required for define type ListData */
import { Loader } from 'common/components/Loader/Loader';
import { StyledTable } from 'common/components/styles/StyledTable';
import {
    Pagination,
    List as UIList,
    ListProps,
} from '@plesk/ui-library';
import { IMeta } from 'common/api/resources/Response';
import { EmptyView } from 'common/components/EmptyView/EmptyView';
import { ItemPerPageOption } from 'common/api/resources/Request/request';

type ListData = Record<string, any>;

export interface ICustomListProps<T extends ListData> extends ListProps<T> {
    isLoading?: boolean;
    isFirstLoading?: boolean;
    borderless?: boolean;
    meta?: IMeta;
    loadItems?: (page: number) => void;
    onItemsPerPageChange?: (option: ItemPerPageOption) => void;
    data: T[];
}

export default function List<T extends ListData>({
    isLoading,
    isFirstLoading,
    borderless,
    meta,
    loadItems,
    emptyView,
    filtered,
    onItemsPerPageChange,
    data,
    ...props
}: ICustomListProps<T>) {
    if (filtered) {
        emptyView = (
            <EmptyView
                title="list.emptyViewOfFilters.title"
                description="list.emptyViewOfFilters.description"
                icon="filter"
            />
        );
    }

    return (
        <StyledTable borderless={borderless}>
            {isFirstLoading && isLoading ? (
                <Loader isLoading={isLoading}/>
            ) : (
                <UIList
                    {...props}
                    data={data}
                    emptyView={emptyView}
                    filtered={filtered}
                    loading={isLoading}
                    totalRows={meta?.total}
                    pagination={(
                        // We add fragment to avoid default UI library behavior where paginator is wrapped in popover
                        <>
                            {meta && loadItems && (
                                <Pagination
                                    itemsPerPage={meta.per_page}
                                    itemsPerPageOptions={[50, 'all']}
                                    onItemsPerPageChange={onItemsPerPageChange}
                                    current={meta.current_page}
                                    total={meta.last_page}
                                    onSelect={loadItems}
                                    loading={isLoading}
                                />
                            )}
                        </>
                    )}
                />
            )}
        </StyledTable>
    );
}