// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    HeaderContainer,
    HeaderContent,
    HeaderTitle,
} from 'client/common/styles/Header';
import {
    Action,
    List,
    Popover,
    Translate,
} from '@plesk/ui-library';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { RootState } from 'client/core/store';
import { withRouter } from 'react-router';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { connect } from 'react-redux';
import * as vpcNetworkActions from 'common/modules/vpcNetwork/actions';
import { Loader } from 'common/components';
import { ICONS } from 'common/constants';
import { HeaderButton } from 'client/common/components/HeaderButton/HeaderButton';
import { isUserLockedOrSuspended } from 'common/modules/auth/selectors/user';
import { StyledList } from 'client/common/styles/List';
import {
    getActionColumnProps,
    reloadListData,
} from 'common/helpers/list';
import { EmptyView } from 'common/components/EmptyView/EmptyView';
import { Link } from 'react-router-dom';
import { pathTo } from 'common/helpers/core';
import { IpListTypeTranslations } from 'common/api/resources/IpBlock';
import { useIsFirstLoading } from 'common/hooks/useIsFirstLoading';
import VpcNetworkForm, {
    vpcNetworkFormDefaultFields,
    VpcNetworkFormFields,
} from 'common/modules/vpcNetwork/containers/VpcNetworkForm';
import { dataCySelector } from 'common/tests/selectors';
import { TABLE_ACTIONS } from 'common/modules/vpcNetwork/constants/tests';
import { StyledActions } from 'common/components/Actions/Styles';
import ButtonWithConfirmation from 'common/components/ButtonWithConfirmation';
import VpcNetworkEdit from 'client/vpcNetwork/components/VpcNetworkEdit';

enum VpcNetworkTableColumns {
    NAME = 'colName',
    LIST_TYPE = 'colListType',
    LOCATION = 'colLocation',
    ACTIONS = 'colActions',
}

const columns = [
    {
        width: '15%',
        key: VpcNetworkTableColumns.NAME,
        title: <Translate content="vpcNetwork.list.name" />,
    },
    {
        key: VpcNetworkTableColumns.LIST_TYPE,
        title: <Translate content="vpcNetwork.list.listType" />,
    },
    {
        key: VpcNetworkTableColumns.LOCATION,
        title: <Translate content="vpcNetwork.list.location" />,
    },
    getActionColumnProps(),
];

export type VpcNetworksProps =
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const VpcNetworks: React.FC<VpcNetworksProps> = ({
    list,
    isLoadingList,
    isUserLockedOrSuspended: userLockedOrSuspended,
    vpcNetworkActions: {
        getVpcNetworks,
        setVpcNetworkItem,
        unsetVpcNetworkItem,
        removeVpcNetwork,
    },
}) => {
    const [isPopoverOpened, setPopoverOpened] = React.useState(false);

    React.useEffect(() => {
        getVpcNetworks();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const loadPaginated = (page: number) => getVpcNetworks({ page });
    const isFirstLoading = useIsFirstLoading(isLoadingList);

    const handleCreate = () => {
        unsetVpcNetworkItem();
        setPopoverOpened(!isPopoverOpened);
    };

    const handleRemove = (id: number) => async () => {
        await removeVpcNetwork(id);
        reloadListData(list, loadPaginated);
    };

    const data = list.data.map((vpcNetwork) => ({
        [VpcNetworkTableColumns.NAME]: (
            <Action
                component={Link}
                to={pathTo(`vpc_networks/${vpcNetwork.id}/ips`)}
            >
                {vpcNetwork.name}
            </Action>
        ),
        [VpcNetworkTableColumns.LIST_TYPE]: <Translate content={IpListTypeTranslations[vpcNetwork.list_type]}/>,
        [VpcNetworkTableColumns.LOCATION]: vpcNetwork.location.name,
        [VpcNetworkTableColumns.ACTIONS]: (
            <StyledActions>
                <VpcNetworkEdit
                    item={vpcNetwork}
                    setVpcNetworkItem={setVpcNetworkItem}
                />
                <ButtonWithConfirmation
                    disabled={userLockedOrSuspended || !vpcNetwork.can_delete}
                    isLoading={vpcNetwork.is_deleting}
                    translations={{
                        title: (
                            <Translate content="vpcNetwork.removePopover.title" />
                        ),
                        button: (
                            <Translate content="vpcNetwork.removePopover.button" />
                        ),
                        tooltip: (
                            <Translate content="vpcNetwork.removePopover.tooltip" />
                        ),
                    }}
                    handleConfirm={handleRemove(vpcNetwork.id)}
                    data-cy={dataCySelector(vpcNetwork.id, TABLE_ACTIONS.REMOVE)}
                    icon={ICONS.RECYCLE}
                />
            </StyledActions>
        ),
        key: vpcNetwork.id.toString(),
    }));

    return (
        <>
            <HeaderContainer>
                <HeaderContent>
                    <HeaderTitle>
                        <h1><Translate content="vpcNetwork.title" /></h1>
                    </HeaderTitle>
                </HeaderContent>
                <Popover
                    onClose={() => setPopoverOpened(!isPopoverOpened)}
                    visible={isPopoverOpened}
                    canCloseOnOutsideClick={false}
                    target={(
                        <HeaderButton
                            isMobile={true}
                            onClick={handleCreate}
                            icon={ICONS.PLUS}
                            tooltip={userLockedOrSuspended}
                            disabled={userLockedOrSuspended}
                        >
                            <Translate content="vpcNetwork.addBtn" />
                        </HeaderButton>
                    )}
                >
                    <VpcNetworkForm
                        fields={[
                            ...vpcNetworkFormDefaultFields,
                            VpcNetworkFormFields.LOCATION_ID,
                        ]}
                        onSubmit={() => setPopoverOpened(false)}
                    />
                </Popover>
            </HeaderContainer>
            <Loader isLoading={isFirstLoading}>
                <StyledList>
                    <List
                        emptyView={
                            <EmptyView
                                title="vpcNetwork.emptyView.title"
                                icon={ICONS.IP_ADDRESSES}
                            />
                        }
                        columns={columns}
                        data={data}
                    />
                </StyledList>
            </Loader>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    list: state.vpcNetwork.list,
    isLoadingList: state.app.loadingFlags.has(LOADING_FLAGS.VPC_NETWORK_LIST),
    isUserLockedOrSuspended: isUserLockedOrSuspended(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    vpcNetworkActions: bindActionCreators(vpcNetworkActions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VpcNetworks));