// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { Icon } from '@plesk/ui-library';
import { CutTitle } from 'common/components';
import {
    ICONS,
    INTENT_TYPE,
} from 'common/constants';
import {
    CardItem,
    ContentName,
    DescriptionText,
    ItemContent,
    LogoContainer,
    ItemParams,
} from 'common/modules/computeResourceVm/components/Styles';
import { IOfferResponse } from 'common/api/resources/Offer';
import { IProjectTokenPricingResponse } from 'common/api/resources/Project';
import { IPlanResponse } from 'common/api/resources/Plan';
import { OfferPrice } from 'common/modules/computeResourceVm/components/OfferPrice';

export type IPrimaryDiskCardProps = {
    plan?: IPlanResponse;
    offer: IOfferResponse;
    tokenPricing?: IProjectTokenPricingResponse;
    icon: ICONS;
    isSelected: boolean;
    onItemClicked?: () => void;
    onRemoveClicked?: () => void;
    disabled?: boolean;
    showInternalTooltips?: boolean;
} & React.HTMLProps<HTMLDivElement>;

export const PrimaryDiskCard: React.FC<IPrimaryDiskCardProps> = ({
    plan,
    tokenPricing,
    offer,
    icon,
    isSelected,
    onItemClicked,
    disabled,
    showInternalTooltips= true,
}) => {
    const renderIcon = () => {
        if (isSelected) {
            return <Icon name={icon} />;
        }

        return (
            <Icon
                intent={INTENT_TYPE.INACTIVE}
                name={icon}
            />
        );
    };

    const handleClick = () => {
        if (!disabled && onItemClicked) {
            onItemClicked();
        }
    };

    return (
        <CardItem isSelected={isSelected} onClick={handleClick} isDisabled={disabled}>
            <div>
                <LogoContainer>
                    {renderIcon()}
                </LogoContainer>
            </div>
            <ItemContent>
                <ContentName>
                    <CutTitle title={offer.name} withTooltip={showInternalTooltips} />
                </ContentName>
                {offer.description && (
                    <DescriptionText>
                        <CutTitle title={offer.description} withTooltip={showInternalTooltips} />
                    </DescriptionText>
                )}
                {tokenPricing && (
                    <ItemParams>
                        <OfferPrice
                            plan={plan}
                            tokenPricing={tokenPricing}
                            offer={offer}
                            sizeGib={plan?.params.disk}
                        />
                    </ItemParams>
                )}
            </ItemContent>
        </CardItem>
    );
};
