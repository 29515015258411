// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as iconActions from 'admin/icon/actions';
import { combineReducers } from 'redux';
import {
    ActionType,
    getType,
} from 'typesafe-actions';
import {
    IPaginateApiResponse,
    paginateInitialState,
} from 'common/api/resources/Response';
import {
    addToPaginated,
    deleteFromPaginated,
    updateInPaginated,
} from 'common/reducers/list';
import {
    IconType,
    IIconResponse,
} from 'common/api/resources/Icon';

interface IIcon {
    list: IPaginateApiResponse<IIconResponse[]>;
    item: IIconResponse;
}

export type IconAction = ActionType<typeof iconActions>;
export type IconState = IIcon;

export const initialIconState: IIconResponse = {
    id: 0,
    name: '',
    url: '',
    type: IconType.OS,
    is_deleting: false,
};

export default combineReducers<IconState, IconAction>({
    list: (state = paginateInitialState, action: IconAction) => {
        switch (action.type) {
        case getType(iconActions.addIconItem):
            return addToPaginated(state, action.payload);
        case getType(iconActions.setIconList):
            return action.payload;
        case getType(iconActions.removeIconItem):
            return deleteFromPaginated(state, action.payload);
        case getType(iconActions.updateIconItem):
            return updateInPaginated(state, action.payload);
        case getType(iconActions.setIconItemIsDeleting):
            return updateInPaginated(state, action.payload);
        default:
            return state;
        }
    },
    item: (state = initialIconState, action) => {
        switch (action.type) {
        case getType(iconActions.setIconItem):
            return action.payload;
        case getType(iconActions.unsetIconItem):
            return { ...initialIconState };
        default:
            return state;
        }
    },
});
