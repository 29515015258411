// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as types from 'admin/task/constants/types';
import { IPaginateApiResponse } from 'common/api/resources/Response';
import { createCustomAction } from 'typesafe-actions';
import { Dispatch } from 'redux';
import {
    setIsLoading,
    unsetIsLoading,
} from 'common/modules/app/loadingFlags/actions';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { HTTP_CODES } from 'common/api/constants';
import { bakeForegroundToast } from 'common/modules/app/toaster/actions';
import { INTENT_TYPE } from 'common/constants';
import { IAppState } from 'admin/core/store';
import { loadOnScroll } from 'common/actions/actionsWrapper';
import {
    ITaskListRequest,
    ITaskResponse,
    tasks,
} from 'common/api/resources/Task';
import { CancelTokenSource } from 'axios';

export const setTasks = createCustomAction(
    types.SET_TASKS,
    (data: IPaginateApiResponse<ITaskResponse[]>) => ({ payload: data })
);
export const appendTasks = createCustomAction(
    types.APPEND_TASKS,
    (data: IPaginateApiResponse<ITaskResponse[]>) => ({ payload: data })
);
export const appendTask = createCustomAction(
    types.APPEND_TASK,
    (data: ITaskResponse) => ({ payload: data })
);
export const updateTask = createCustomAction(
    types.UPDATE_TASK,
    (data: Partial<ITaskResponse>) => ({ payload: data })
);
export const setTask = createCustomAction(
    types.SET_TASK,
    (data: ITaskResponse) => ({ payload: data })
);

export const retry = (id: number) => async(dispatch: Dispatch) => {
    await tasks.retry(id);

    bakeForegroundToast(INTENT_TYPE.SUCCESS, 'task.retrySuccess')(dispatch);
};

export const cancel = (id: number) => async(dispatch: Dispatch) => {
    await tasks.cancel(id);

    bakeForegroundToast(INTENT_TYPE.SUCCESS, 'task.cancelSuccess')(dispatch);
};

export const getTaskList = (request?: ITaskListRequest, cancelToken?: CancelTokenSource) => async(dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.COMPUTE_RESOURCE_TASK_LIST));

    try {
        const result = await tasks.list(request, cancelToken);

        if (result.status === HTTP_CODES.OK) {
            dispatch(setTasks(result.data));
        }

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.COMPUTE_RESOURCE_TASK_LIST));
    }
};

export const loadOnScrollTasks = () => async(dispatch: Dispatch, getState: () => IAppState) => {
    const state = getState();
    const nextPage = state.task.list.links.next;
    const isLoading = state.app.loadingFlags.has(LOADING_FLAGS.COMPUTE_RESOURCE_TASK_LIST_INFINITY_SCROLL);

    return await loadOnScroll({
        nextPage,
        isLoading,
        dispatch,
        action: appendTasks,
        loadingFlag: LOADING_FLAGS.COMPUTE_RESOURCE_TASK_LIST_INFINITY_SCROLL,
    });
};
