// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    Icon,
    InlineItem,
} from 'common/components/ServerTabs/RescueTab/Styles';
import { ICONS } from 'common/constants';
import { Translate } from '@plesk/ui-library';
import CopyText from 'common/containers/CopyText/CopyText';

export interface IIsoImageDescriptionProps {
    url: string;
    useTls: boolean;
    checksum: string;
    showUrlAndChecksum: boolean;
    showTls: boolean;
    width: string;
}

export const IsoImageDescription: React.FC<IIsoImageDescriptionProps> = ({
    url,
    useTls,
    checksum,
    showUrlAndChecksum,
    showTls,
    width,
}) => {
    const tlsIcon = useTls ? (
        <Icon size={18} name={ICONS.SHIELD} />
    ) : (
        <Icon size={18} name={ICONS.TRIANGLE_EXCLAMATION_MARK_FILLED} />
    );

    return (
        <>
            {showUrlAndChecksum && (
                <>
                    <InlineItem>
                        <CopyText
                            width={width}
                        >
                            {showTls && tlsIcon}{url}
                        </CopyText>
                    </InlineItem>
                    {checksum &&
                        <InlineItem>
                            <CopyText
                                width={width}
                                tooltip={<Translate content="isoImage.description.checksum" />}
                            >
                                {showTls && <Icon size={18} name={ICONS.SHIELD_CHAIN} />}{checksum}
                            </CopyText>
                        </InlineItem>
                    }
                </>
            )}
            {!showUrlAndChecksum && showTls && (
                <InlineItem>
                    <div>
                        {tlsIcon}
                    </div>
                    <div>
                        {useTls ? (
                            <Translate content="isoImage.description.tls" />
                        ) : (
                            <Translate content="isoImage.description.withoutTls" />
                        )}
                    </div>
                </InlineItem>
            )}
        </>
    );
};