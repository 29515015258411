// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import Echo,
{ Options } from 'laravel-echo';
import * as io from 'socket.io-client';
import { isTesting } from 'common/helpers/core';
import { EchoMock } from 'common/tests/echo';

export const initEchoConnection = (authorization?: string) => {
    if (isTesting()) {
        return new EchoMock();
    }

    if (!process.env.REACT_APP_ECHO_HOST) {
        throw Error('Echo host is not set');
    }

    if (!process.env.REACT_APP_ECHO_PORT) {
        throw Error('Echo port is not set');
    }

    const config: Options = {
        broadcaster: 'socket.io',
        client: io,
        host: `${process.env.REACT_APP_ECHO_HOST}:${process.env.REACT_APP_ECHO_PORT}`,
    };

    if (authorization) {
        config.auth = {
            headers: {
                Authorization: authorization,
            },
        };
    }

    return new Echo(config);
};
