// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

export const SET_LOCATION_LIST = 'location/SET_LOCATION_LIST';
export const APPEND_LOCATIONS = 'location/APPEND_LOCATIONS';
export const ADD_LOCATION_ITEM = 'location/ADD_LOCATION_ITEM';
export const SET_LOCATION_ITEM = 'location/SET_LOCATION_ITEM';
export const UNSET_LOCATION_ITEM = 'location/UNSET_LOCATION_ITEM';
export const REMOVE_LOCATION_ITEM = 'location/REMOVE_LOCATION_ITEM';
export const UPDATE_LOCATION_ITEM = 'location/UPDATE_LOCATION_ITEM';
export const UPDATE_LOCATION_ITEM_POSITION = 'location/UPDATE_LOCATION_ITEM_POSITION';
export const CLEAR_DEFAULT = 'location/CLEAR_DEFAULT';
export const SET_LOCATION_ITEM_IS_LOADING = 'location/SET_LOCATION_ITEM_IS_LOADING';
export const UNSET_LOCATION_ITEM_IS_LOADING = 'location/UNSET_LOCATION_ITEM_IS_LOADING';
export const SET_LOCATION_ITEM_IS_DELETING = 'location/SET_LOCATION_ITEM_IS_DELETING';
