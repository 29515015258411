// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import {
    api,
    IApiResponse,
} from 'common/api/resources/Response';
import { IPermissionResponse } from 'common/api/resources/Permission/model';

export const permissions = {
    list: () => api.get<IApiResponse<IPermissionResponse[]>>('permissions'),
};
