// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { UserStatus } from 'common/api/resources/User';
import { Translate } from '@plesk/ui-library';
import { STATUS_INVITED } from 'common/modules/project/constants';

export const statusTooltipMapping = {
    [UserStatus.LOCKED]: <Translate content="projects.statusUserTooltip.locked"/>,
    [UserStatus.SUSPENDED]: <Translate content="projects.statusUserTooltip.suspended"/>,
};

export const statusMapping = {
    [UserStatus.ACTIVE]: <Translate content="projects.statusUser.active"/>,
    [UserStatus.LOCKED]: <Translate content="projects.statusUser.locked"/>,
    [UserStatus.SUSPENDED]: <Translate content="projects.statusUser.suspended"/>,
    [STATUS_INVITED]: <Translate content="projects.statusUser.invited"/>,
};
