// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'client/core/store';
import {
    Redirect,
    RouteComponentProps,
    withRouter,
} from 'react-router';
import LoginForm from 'common/modules/auth/containers/LoginForm/LoginForm';
import RegisterForm from 'client/auth/containers/RegisterForm/RegisterForm';
import {
    Tab,
    TabList,
    TabPanel,
    Tabs,
} from 'react-tabs';
import { Translate } from '@plesk/ui-library';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { Loader } from 'common/components';
import LogoBox from 'common/modules/auth/components/LogoBox/LogoBox';
import { dataCySelector } from 'common/tests/selectors';
import { AuthPageHeader } from 'common/components/styles/AuthPageHeader';
import AuthLanguageDropdown from 'common/modules/auth/containers/AuthLanguageDropdown/AuthLanguageDropdown';
import {
    pathTo,
    pathToHome,
} from 'common/helpers/core';
import { AuthContainer } from 'common/modules/auth/Styles';

interface ILoginClientProps extends RouteComponentProps {
    isAuthByLink: boolean;
}

export type AuthLoginProps =
    ILoginClientProps &
    ReturnType<typeof mapStateToProps>;

export const AuthLogin: React.FC<AuthLoginProps> = ({
    auth,
    isLoggedIn,
    isAuthByLink,
    isRegistrationEnabled,
}) => {
    const [selectedTab, setSelectedTab] = React.useState(0);

    if (auth.isAuth) {
        return (<Redirect to={pathToHome()} />);
    }

    if (auth.user.is_two_factor_auth_enabled) {
        return (<Redirect to={pathTo('login/2fa')} />);
    }

    const handleSelect = (index: number) => setSelectedTab(index);

    return (
        <Loader isLoading={isAuthByLink}>
            <AuthContainer>
                <AuthPageHeader>
                    <LogoBox />
                    <AuthLanguageDropdown />
                </AuthPageHeader>
                <Tabs selectedIndex={selectedTab} onSelect={handleSelect}>
                    <TabList>
                        <Tab>
                            <Translate content="auth.signIn" />
                        </Tab>
                        {isRegistrationEnabled && (
                            <Tab data-cy={dataCySelector(1, 'register')} disabled={isLoggedIn}>
                                <Translate content="auth.register" />
                            </Tab>
                        )}
                    </TabList>
                    <TabPanel>
                        <LoginForm />
                    </TabPanel>
                    <TabPanel>
                        <RegisterForm />
                    </TabPanel>
                </Tabs>
            </AuthContainer>
        </Loader>
    );
};

const mapStateToProps = (state: RootState) => ({
    auth: state.auth,
    isLoggedIn: state.app.loadingFlags.has(LOADING_FLAGS.LOGIN),
    isRegistrationEnabled: state.settings.features.allow_registration,
});

export default withRouter(connect(mapStateToProps)(AuthLogin));
