// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import styled,
{ css } from 'styled-components';
import { Icon } from '@plesk/ui-library';
import { COLORS } from 'common/theme';
import { Link } from 'react-router-dom';

interface IItemIconProps {
    background: string;
}

interface IToolbarActionProps {
    $isDisabled?: boolean;
    $marginLeft?: string;
}

export const ItemInfoDescription = styled.span`
    color: #979797;
    font-size: 12px;
    line-height: 16px;
    margin-bottom: 8px;
`;

export const ItemIcon = styled.span<IItemIconProps>`
    width: 32px;
    height: 32px;
    background: #${props => props.background};
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    span {
        font-size: 24px;
        line-height: 32px;
        color: #fff;
        font-weight: 600;
    }
`;

const toolbarActionStyles = css<IToolbarActionProps>`
    text-decoration: none !important;
    height: 20px;
    padding: 5px 6px;
    opacity: ${props => !props.$isDisabled ? 1 : 0.6};
    margin-left: ${props => props.$marginLeft ? props.$marginLeft : 0};
    font-weight: 600;
    
    ${props => props.$isDisabled && css`
        cursor: not-allowed;
        pointer-events: none;
    `}

    a:hover {
        text-decoration: none;
    }

    &:hover {
        background-color: ${props => !props.$isDisabled ? 'rgba(233, 233, 233, 0.5)' : 'none'};
        border-radius: 2px;
        padding: 5px 6px;
    }

    span:first-child {
        margin-right: 4px;
        vertical-align: -3px;
        fill: #737373;
    }
`;

export const ToolbarAction = styled.a<IToolbarActionProps>`
    ${toolbarActionStyles}
`;

export const ToolbarActionLink = styled(Link)<IToolbarActionProps>`
    ${toolbarActionStyles}
`;

export const ProjectOwner = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 50%;
    align-items: center;
    padding-right: 20px;
    color: ${props => props.theme.primary_color};
`;

export const ProjectDelete = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 50%;
    align-items: center;
    padding-left: 20px;
`;

export const NameEditInput = styled.input`
    border-radius: 2px;
    border: none;
    height: 20px;
    color: #222;
    width: 100%;
    padding: 6px 5px;
    font-size: 20px;
    line-height: 26px;
    cursor: text;
    outline: none;
    margin-bottom: 8px;

    &:focus {
        border: none;
        background: linear-gradient(0deg, rgba(40, 170, 222, 0.06), rgba(40, 170, 222, 0.06)), #FFFFFF;
    }

    &::placeholder {
        color: #979797;
    }

    &:disabled {
        border: none;
        background: none;
        color: ${COLORS.GREY_60};
    }
`;

export const DescriptionEditInput = styled.input`
    border-radius: 2px;
    border: none;
    height: 20px;
    width: 100%;
    color: #737373;
    padding: 6px 5px;
    font-size: 12px;
    line-height: 16px;
    cursor: text;
    outline: none;

    &:focus {
        background: linear-gradient(0deg, rgba(40, 170, 222, 0.06), rgba(40, 170, 222, 0.06)), #FFFFFF;
        color: #979797;
        border: none;
    }

    &::placeholder {
        color: #979797;
    }
`;

export const PrimaryIcon = styled(Icon)`
    svg {
        fill: ${props => props.theme.primary_color};
    }
`;
