// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    Label,
    StyledCheckBox,
} from 'common/components/CheckBox/styles';

export const CheckBox: React.FC<React.HTMLProps<HTMLInputElement>> = ({
    children,
    id,
    name,
    onChange,
    style,
    checked,
    ...rest
}) => {
    id = id ? id : 'checkbox';

    return (
        <StyledCheckBox style={style}>
            <input
                type="checkbox"
                id={id}
                name={name}
                onChange={onChange}
                checked={checked}
                {...rest}
            />
            <Label className="checkmark" htmlFor={id}>
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <rect filter="url(#filter0_i)" id="background" width="20" height="20" rx="2" fill="white"/>
                    <rect id="border" x="0.5" y="0.5" width="19" height="19" rx="1.5" stroke="#B5B5B5"/>
                    <path filter="url(#filter0_d)" transform="translate(2,3)" id="checkmark" fillRule="evenodd" clipRule="evenodd" d="M13.5704 1.1786C14.024 1.49362 14.1364 2.11673 13.8214 2.57036L7.57137 11.5704C7.40097 11.8157 7.1298 11.9721 6.83207 11.9966C6.53434 12.0211 6.24125 11.9112 6.03301 11.697L2.28301 7.8399C1.89802 7.44392 1.90694 6.81081 2.30292 6.42583C2.69891 6.04084 3.33201 6.04976 3.717 6.44575L6.62093 9.43265L12.1786 1.42957C12.4937 0.975941 13.1168 0.863576 13.5704 1.1786Z" fill="white"/>
                    <defs>
                        <filter id="filter0_d" x="0" y="-0.00012207" width="16.0001" height="15.0001" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"/>
                            <feOffset dy="1"/>
                            <feGaussianBlur stdDeviation="1"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.3 0"/>
                            <feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow" result="shape"/>
                        </filter>
                        <filter id="filter0_i" x="0" y="0" width="20" height="21" filterUnits="userSpaceOnUse" colorInterpolationFilters="sRGB">
                            <feFlood floodOpacity="0" result="BackgroundImageFix"/>
                            <feBlend mode="normal" in="SourceGraphic" in2="BackgroundImageFix" result="shape"/>
                            <feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
                            <feOffset dy="3"/>
                            <feGaussianBlur stdDeviation="0.5"/>
                            <feComposite in2="hardAlpha" operator="arithmetic" k2="-1" k3="1"/>
                            <feColorMatrix type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.07 0"/>
                            <feBlend mode="normal" in2="shape" result="effect1_innerShadow"/>
                        </filter>
                    </defs>
                </svg>
            </Label>
            {children}
        </StyledCheckBox>
    );
};
