// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    Tooltip as UiTooltip,
    TooltipProps as UiTooltipProps,
} from '@plesk/ui-library';

export type TooltipProps = UiTooltipProps & { shown?: boolean };

export const Tooltip: React.FC<TooltipProps> = ({
    shown = true,
    children,
    ...props
}) => {
    if (shown) {
        return (
            <UiTooltip {...props}>
                {children}
            </UiTooltip>
        );
    }

    return children;
};
