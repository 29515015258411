// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { Text } from '@plesk/ui-library';
import {
    Title,
    Usage,
} from 'common/components/ServerTabs/components/LimitCard/Styles';
import { INTENT_TYPE } from 'common/constants';
import * as React from 'react';
import { Unit } from 'common/helpers/units';
import { IServerLimitResponse } from 'common/api/resources/ComputeResourceVm';

export interface ILimitProgressProps {
    limit: IServerLimitResponse;
    title: React.ReactNode;
    component: string;
}

export const LimitProgress: React.FC<ILimitProgressProps> = ({
    component,
    limit,
    title,
}) => {
    const format = (value: string|number, showUnit: boolean = true): string => {
        value = String(value);

        if (limit.limit.unit === Unit.UNITS || !showUnit) {
            return value;
        }

        return value + ' ' + limit.limit.unit;
    };

    return (
        <Title>
            <Text className="title" component={component}>
                {title}
            </Text>
            <Usage>
                <Text
                    intent={limit.is_exceeded ? INTENT_TYPE.WARNING : undefined}
                    component={component}
                >
                    {format(limit.used, !limit.limit.is_enabled)}
                </Text>
                {limit.limit.is_enabled && (
                    <Text intent={INTENT_TYPE.MUTED} component={component}>
                        &nbsp;/ {format(limit.limit.limit)}
                    </Text>
                )}
            </Usage>
        </Title>
    );
};

export default LimitProgress;
