// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

export const CLUSTER_IMPORT = {
    RUN: 'cluster-import-run',
    PRE_CHECK: 'cluster-import-pre-check',
    REFRESH: 'cluster-import-refresh',
    EDIT: 'cluster-import-edit',
    DELETE: 'cluster-import-delete',
    MODAL_LINK: 'cluster-import-model-link',
    ERROR: 'cluster-import-error',
};
