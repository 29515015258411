// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as storageTagActions from 'admin/storageTag/actions';
import { combineReducers } from 'redux';
import {
    ActionType,
    getType,
} from 'typesafe-actions';
import {
    IPaginateApiResponse,
    paginateInitialState,
} from 'common/api/resources/Response';
import {
    addToPaginated,
    deleteFromPaginated,
    updateInPaginated,
} from 'common/reducers/list';
import { IStorageTagResponse } from 'common/api/resources/StorageTag';

interface IStorageTag {
    list: IPaginateApiResponse<IStorageTagResponse[]>;
    item: IStorageTagResponse;
}

export type StorageTagAction = ActionType<typeof storageTagActions>;
export type StorageTagState = IStorageTag;

export const initialStorageTagState: IStorageTagResponse = {
    id: 0,
    name: '',
    description: '',
    position: 0,
    tier: 0,
    is_deleting: false,
};

export default combineReducers<StorageTagState, StorageTagAction>({
    list: (state = paginateInitialState, action: StorageTagAction) => {
        switch (action.type) {
        case getType(storageTagActions.addStorageTagItem):
            return addToPaginated(state, action.payload);
        case getType(storageTagActions.setStorageTagList):
            return action.payload;
        case getType(storageTagActions.removeStorageTagItem):
            return deleteFromPaginated(state, action.payload);
        case getType(storageTagActions.updateStorageTagItem):
            return updateInPaginated(state, action.payload);
        case getType(storageTagActions.setStorageTagItemIsDeleting):
            return updateInPaginated(state, action.payload);
        default:
            return state;
        }
    },
    item: (state = initialStorageTagState, action) => {
        switch (action.type) {
        case getType(storageTagActions.setStorageTagItem):
            return action.payload;
        case getType(storageTagActions.unsetStorageTagItem):
            return { ...initialStorageTagState };
        default:
            return state;
        }
    },
});
