// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';
import { Columns as UILibColumns } from '@plesk/ui-library';

export const Label = styled.div`
  display: flex; 
  align-items: center;
  justify-content: space-between;
`;

export const Columns = styled(UILibColumns)`
  align-items: center;
  height: 40px;
`;
