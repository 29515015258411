// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    Button,
    Translate,
} from '@plesk/ui-library';
import {
    IClusterImportResponse,
    CLUSTER_IMPORT_STATUS,
    ClusterImportStatusIntentMap,
} from 'common/api/resources/ClusterImport';
import { dataCySelector } from 'common/tests/selectors';
import { CLUSTER_IMPORT } from 'admin/clusterImport/constants/tests';
import { Status } from 'common/components/styles/Status';
import { ICONS } from 'common/constants';

export interface IClusterImportStatusProps {
    item: IClusterImportResponse;
    onOpen: () => void;
}

const CLUSTER_IMPORT_STATUS_MAP = {
    [CLUSTER_IMPORT_STATUS.PREPARING]: <Translate content="clusterImport.status.preparing"/>,
    [CLUSTER_IMPORT_STATUS.PRE_CHECKING]: <Translate content="clusterImport.status.pre_checking"/>,
    [CLUSTER_IMPORT_STATUS.PREPARED]: <Translate content="clusterImport.status.prepared"/>,
    [CLUSTER_IMPORT_STATUS.IMPORTING]: <Translate content="clusterImport.status.importing"/>,
    [CLUSTER_IMPORT_STATUS.REFRESHING]: <Translate content="clusterImport.status.refreshing"/>,
    [CLUSTER_IMPORT_STATUS.FAILED_PRE_CHECK]: <Translate content="clusterImport.status.failed_pre_check"/>,
    [CLUSTER_IMPORT_STATUS.FAILED_PREPARE]: <Translate content="clusterImport.status.failed_prepare"/>,
    [CLUSTER_IMPORT_STATUS.FAILED_IMPORT]: <Translate content="clusterImport.status.failed_import"/>,
    [CLUSTER_IMPORT_STATUS.FAILED_REFRESH]: <Translate content="clusterImport.status.failed_refresh"/>,
};

const STATUSES_WITH_BUTTON = [
    CLUSTER_IMPORT_STATUS.FAILED_PREPARE,
    CLUSTER_IMPORT_STATUS.FAILED_PRE_CHECK,
    CLUSTER_IMPORT_STATUS.FAILED_IMPORT,
    CLUSTER_IMPORT_STATUS.FAILED_REFRESH,
];

const RUNNING_STATUSES = [
    CLUSTER_IMPORT_STATUS.PREPARING,
    CLUSTER_IMPORT_STATUS.PRE_CHECKING,
    CLUSTER_IMPORT_STATUS.IMPORTING,
    CLUSTER_IMPORT_STATUS.REFRESHING,
];

export const ClusterImportStatus: React.FC<IClusterImportStatusProps> = ({
    item,
    onOpen,
}) => {
    if (RUNNING_STATUSES.includes(item.status as CLUSTER_IMPORT_STATUS)) {
        return (
            <Status progress={true}>
                {CLUSTER_IMPORT_STATUS_MAP[item.status]}
            </Status>
        );
    }

    const buttonEl = (
        <Button
            data-cy={dataCySelector(item.id, CLUSTER_IMPORT.MODAL_LINK)}
            onClick={onOpen}
            ghost={true}
            icon={ICONS.BOX_DIAGONAL_TOP_IN}
        />
    );

    const showButton = STATUSES_WITH_BUTTON.includes(item.status as CLUSTER_IMPORT_STATUS)
        && item.fail_reason.length > 0;

    return (
        <>
            <Status
                intent={ClusterImportStatusIntentMap[item.status]}
                compact={true}
            >
                {CLUSTER_IMPORT_STATUS_MAP[item.status]}
            </Status>
            &nbsp;
            {showButton && buttonEl}
        </>
    );
};
