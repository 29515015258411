// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { createCustomAction } from 'typesafe-actions';
import { Dispatch } from 'redux';
import * as types from 'common/modules/language/constants/types';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import {
    IApiResponse,
    IPaginateApiResponse,
} from 'common/api/resources/Response';
import { HTTP_CODES } from 'common/api/constants';
import {
    setIsLoading,
    unsetIsLoading,
} from 'common/modules/app/loadingFlags/actions';
import {
    ILanguageRequest,
    ILanguageResponse,
    languages,
} from 'common/api/resources/Language';
import { AxiosResponse } from 'axios';
import { IAppState } from 'admin/core/store';
import { loadOnScroll } from 'common/actions/actionsWrapper';

export const setLanguageList = createCustomAction(
    types.SET_LANGUAGE_LIST,
    (data: IPaginateApiResponse<ILanguageResponse[]>) => ({ payload: data })
);
export const appendLanguages = createCustomAction(
    types.APPEND_LANGUAGES,
    (data: IPaginateApiResponse<ILanguageResponse[]>) => ({ payload: data })
);
export const clearDefault = createCustomAction(types.CLEAR_DEFAULT);
export const updateLanguageItem = createCustomAction(
    types.UPDATE_LANGUAGE_ITEM,
    (data: ILanguageResponse) => ({ payload: data })
);

export const getLanguages = () => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.LANGUAGE_LIST));

    try {
        const result = await languages.list();

        if (result.status === HTTP_CODES.OK) {
            dispatch(setLanguageList(result.data));
        }

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.LANGUAGE_LIST));
    }
};

export const setLanguageVisible = (id: number, flag: boolean) =>
    patch(id, { is_visible: flag }, (result, dispatch) => {
        dispatch(updateLanguageItem(result.data.data));
    });

export const setLanguageDefault = (id: number, flag: boolean) =>
    patch(id, { is_default: flag }, (result, dispatch) => {
        dispatch(clearDefault());
        dispatch(updateLanguageItem(result.data.data));
    });

const patch = (id: number, request: ILanguageRequest, onSuccess: (result: AxiosResponse<IApiResponse<ILanguageResponse>>, dispatch: Dispatch) => void) => async(dispatch: Dispatch) => {
    const result = await languages.patch(id, request);
    onSuccess(result, dispatch);
    return result;
};

export const loadLanguagesOnScroll = ()  => async(dispatch: Dispatch, getState: () => IAppState) => {
    const state = getState();
    const nextPage = state.language.list.links.next;
    const isLoading = state.app.loadingFlags.has(LOADING_FLAGS.LANGUAGE_LIST_INFINITY_SCROLL);

    return await loadOnScroll({
        nextPage,
        isLoading,
        dispatch,
        action: appendLanguages,
        loadingFlag: LOADING_FLAGS.LANGUAGE_LIST_INFINITY_SCROLL,
    });
};
