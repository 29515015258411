// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'admin/core/store';
import * as computeResourceVmActions from 'common/modules/computeResourceVm/actions';
import PageHeader from 'common/components/PageHeader/PageHeader';
import { VmStatus } from 'common/components/VmStatus';
import ComputeResourceVmActions from 'admin/computeResourceVm/containers/ComputeResourceVmActions/ComputeResourceVmActions';
import ComputeResourceVmDescription from 'admin/computeResourceVm/containers/ComputeResourceVmDescription';
import { Loader } from 'common/components';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import VmRescueTab from 'admin/computeResourceVm/containers/Tabs/VmRescueTab';
import { ADMIN_ROUTE_PREFIX } from 'admin/core/constants';
import VmBackupsTab from 'admin/computeResourceVm/containers/Tabs/VmBackupsTab';
import {
    ICONS,
    INTENT_TYPE,
    SIZE,
} from 'common/constants';
import { WithErrorPages } from 'common/components/WithPageNotFound/WithErrorPages';
import ButtonWithConfirmation from 'common/components/ButtonWithConfirmation';
import { COLORS } from 'admin/core/theme';
import VmReinstallTab from 'admin/computeResourceVm/containers/Tabs/VmReinstallTab';
import VmResizeTab from 'admin/computeResourceVm/containers/Tabs/VmResizeTab';
import VmTasksTab from 'admin/computeResourceVm/containers/Tabs/VmTasksTab';
import VmSnapshotsTab from 'admin/computeResourceVm/containers/Tabs/VmSnapshotsTab';
import VmNetworkingTab from 'admin/computeResourceVm/containers/Tabs/VmNetworkingTab';
import SnapshotForm from 'admin/computeResourceVm/containers/Tabs/SnapshotForm';
import { HeaderButton } from 'client/common/components/HeaderButton/HeaderButton';
import VmGraphsTab from 'admin/computeResourceVm/containers/Tabs/VmGraphsTab';
import { ACTIONS } from 'common/modules/backup/constants/tests';
import TabStatus from 'common/components/ServerTabs/components/TabStatus/TabStatus';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import {
    RouteComponentProps,
    withRouter,
} from 'react-router';
import {
    Tab,
    TabList,
    TabPanel,
    Tabs,
} from 'react-tabs';
import { VM_TABS } from 'admin/computeResourceVm/constants';
import {
    BootMode,
    ComputeResourceVmStatus,
    isBackupEnabled,
} from 'common/api/resources/ComputeResourceVm';
import {
    Action,
    Icon,
    Label,
    Popover,
    Tooltip,
    Translate,
} from '@plesk/ui-library';
import {
    ActionContainer,
    MainTitleWithActionSubContainer,
    PageName,
    PageSection,
} from 'common/components/PageHeader/Styles';
import { areNetworkLimitsExceeded } from 'common/api/resources/Plan';
import { hasPermission } from 'common/modules/permission/selectors';
import { PERMISSION_LIST } from 'common/modules/permission/constants';
import ServerEditDialog from 'admin/common/containers/ServerEditDialog/ServerEditDialog';
import { Dialog } from 'common/components/Dialog/Dialog';
import VmSettingsTab from 'admin/computeResourceVm/containers/Tabs/VmSettingsTab';
import { initialComputeResourceVmState } from 'common/modules/computeResourceVm/reducers';
import VmDisksTab from 'admin/computeResourceVm/containers/Tabs/VmDisksTab';
import { VirtualizationType } from 'common/api/resources/ComputeResource';
import VmActivityLogsTab from 'admin/computeResourceVm/containers/Tabs/VmActivityLogsTab';
import { pathTo } from 'common/helpers/core';
import { Link } from 'react-router-dom';
import VmTagsTab from 'admin/computeResourceVm/containers/Tabs/VmTagsTab';

export type ComputeResourceVmProps =
    RouteComponentProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const ComputeResourceVm: React.FC<ComputeResourceVmProps> = ({
    id,
    item,
    hash,
    areSnapshotsAvailable,
    areLimitsExceeded,
    computeResourceVmActions: {
        getComputeResourceVm,
        createBackup,
        deleteComputeResourceVm,
    },
    canResizeComputeResourceVm,
    canManageBackups,
    canViewBootRescueTab,
    canGetTags,
    isLoadingVm,
    isBackupCreating,
    history,
}) => {
    const tabs = Object.values(VM_TABS).filter(tab =>
        (areSnapshotsAvailable || tab !== VM_TABS.SNAPSHOT_TAB)
        && (item.is_backup_available || tab !== VM_TABS.BACKUPS_TAB)
        && (canResizeComputeResourceVm || tab !== VM_TABS.RESIZE_TAB)
        && (item.virtualization_type === VirtualizationType.KVM || tab !== VM_TABS.DISKS_TAB)
        && (canGetTags || tab !== VM_TABS.TAGS_TAB)
    );

    if ((item.id !== initialComputeResourceVmState.id) && (!hash || tabs.every(tab => tab !== hash))) {
        history.replace(tabs[0]);
    }

    const onMount = async () => await getComputeResourceVm(id);

    const handleTabSelect = (index: number) => history.replace(tabs[index]);

    const handleDelete = async (vmId: number) => {
        try {
            await deleteComputeResourceVm(vmId);
            history.push(`${ADMIN_ROUTE_PREFIX}/servers`);
        } catch (e) {
            throw e;
        }
    };

    enum POPOVERS {
        SNAPSHOT = 'snapshot'
    }
    const [isPopoverOpened, setPopoverOpened] = React.useState({
        [POPOVERS.SNAPSHOT]: false,
    });
    const [isEditDialogOpened, setEditDialogOpened] = React.useState(false);
    const isLoading = isLoadingVm && !isEditDialogOpened;

    const handleOpen = (name: string) => () => setPopoverOpened({ ...isPopoverOpened, [name]: true });
    const handleClose = (name: string) => () => setPopoverOpened({ ...isPopoverOpened, [name]: false });

    const handleToggleEditDialog = () => setEditDialogOpened(isOpen => !isOpen);

    const handleCreateBackup = () => {
        createBackup(id);
    };

    const renderHeaderButton = () => {
        switch (hash) {
        case VM_TABS.BACKUPS_TAB:
            const createTooltip = item.boot_mode === BootMode.RESCUE ? (
                <Translate content="computeResourceVm.backups.createBtn.disabledInRescueModeTooltip" />
            ) : (
                <Translate content="computeResourceVm.backups.createBtn.tooltip" />
            );

            const isBackupAvailable = canManageBackups || isBackupEnabled(item);

            return (
                <ButtonWithConfirmation
                    icon="backup"
                    isLoading={isBackupCreating}
                    handleConfirm={handleCreateBackup}
                    confirmationButtonText={<Translate content="computeResourceVm.backups.createBtn.title"/>}
                    confirmationButtonGhost={false}
                    buttonIntent={INTENT_TYPE.PRIMARY}
                    buttonColor={COLORS.PRIMARY}
                    withStyledButton={true}
                    buttonSize={SIZE.LG}
                    disabled={item.is_processing || !isBackupAvailable || item.boot_mode === BootMode.RESCUE}
                    translations={{
                        title: (
                            <Translate content="computeResourceVm.backups.createBtn.title" />
                        ),
                        text: (
                            <Translate content="computeResourceVm.backups.createBtn.text" />
                        ),
                        button: (
                            <Translate content="computeResourceVm.backups.createBtn.button" />
                        ),
                        tooltip: createTooltip,
                    }}
                    data-cy={ACTIONS.CREATE_BUTTON}
                />
            );
        case VM_TABS.SNAPSHOT_TAB:
            return (
                <Popover
                    onClose={handleClose(POPOVERS.SNAPSHOT)}
                    visible={isPopoverOpened[POPOVERS.SNAPSHOT]}
                    canCloseOnOutsideClick={false}
                    target={
                        <HeaderButton
                            isLoading={item.status === ComputeResourceVmStatus.SNAPSHOT_CREATING || item.isLoading}
                            isMobile={true}
                            onClick={handleOpen(POPOVERS.SNAPSHOT)}
                            disabled={item.is_processing}
                            icon={ICONS.CAMERA}
                        >
                            <Translate content="snapshots.createSnapshot" />
                        </HeaderButton>
                    }
                    placement="bottom-left"
                >
                    <SnapshotForm onCreated={handleClose(POPOVERS.SNAPSHOT)} serverId={id}/>
                </Popover>
            );
        }
        return null;
    };

    return (
        <WithErrorPages onMount={onMount} deps={[id]}>
            <Loader isLoading={isLoading}>
                <PageHeader
                    title={
                        <MainTitleWithActionSubContainer>
                            <PageSection>
                                <Action
                                    component={Link}
                                    to={pathTo('servers')}
                                >
                                    <Translate content="computeResourceVm.header" />
                                </Action>
                                <Icon name="chevron-right" />
                            </PageSection>
                            <Tooltip
                                title={
                                    <span style={{ whiteSpace: 'nowrap' }}>
                                        {item.name}
                                    </span>
                                }
                            >
                                <PageName>
                                    <span>
                                        {item.name}
                                    </span>
                                </PageName>
                            </Tooltip>
                            <ActionContainer style={{ marginRight: '16px' }}>
                                <VmStatus
                                    status={item.status}
                                    isProcessing={item.is_processing}
                                    progress={item.progress}
                                />
                            </ActionContainer>
                            {item.is_suspended && (
                                <ActionContainer>
                                    <Label intent={INTENT_TYPE.WARNING}>
                                        <Translate content="servers.status.suspended" />
                                    </Label>
                                </ActionContainer>
                            )}
                        </MainTitleWithActionSubContainer>
                    }
                    subtitle={<ComputeResourceVmDescription />}
                    actions={<ComputeResourceVmActions item={item} iconSize="24" onUpdate={handleToggleEditDialog} onDelete={handleDelete} />}
                    actionButton={renderHeaderButton()}
                />
                <Tabs selectedIndex={tabs.findIndex(tab => tab === hash)} onSelect={handleTabSelect}>
                    <TabList>
                        <Tab>
                            <Translate content="computeResourceVm.settingsTab.title" />
                        </Tab>
                        {canViewBootRescueTab && (
                            <Tab>
                                <Translate content="computeResourceVm.rescueTab.title" />
                            </Tab>
                        )}
                        {item.is_backup_available && (
                            <Tab>
                                <Translate content="computeResourceVm.backupsTab.title" />
                            </Tab>
                        )}
                        {canResizeComputeResourceVm && (
                            <Tab>
                                <Translate content="computeResourceVm.resizeTab.title" />
                            </Tab>
                        )}
                        <Tab>
                            <Translate content="computeResourceVm.reinstallTab.title" />
                        </Tab>
                        <Tab>
                            <Translate content="computeResourceVm.tasksTab.title" />
                        </Tab>
                        {areSnapshotsAvailable && (
                            <Tab>
                                <Translate content="computeResourceVm.snapshotsTab.title" />
                            </Tab>
                        )}
                        <Tab>
                            <Translate content="computeResourceVm.graphTab.title" />
                        </Tab>
                        <Tab>
                            {areLimitsExceeded && (
                                <TabStatus intent={INTENT_TYPE.WARNING} />
                            )}
                            <Translate content="computeResourceVm.networkingTab.title" />
                        </Tab>
                        {item.virtualization_type === VirtualizationType.KVM && (
                            <Tab>
                                <Translate content="computeResourceVm.disksTab.title" />
                            </Tab>
                        )}
                        {canGetTags && (
                            <Tab>
                                <Translate content="computeResourceVm.tagsTab.title" />
                            </Tab>
                        )}
                        <Tab>
                            <Translate content="computeResourceVm.activityLogsTab.title" />
                        </Tab>
                    </TabList>
                    <TabPanel>
                        <VmSettingsTab />
                    </TabPanel>
                    {canViewBootRescueTab && (
                        <TabPanel>
                            <VmRescueTab />
                        </TabPanel>
                    )}
                    {item.is_backup_available && (
                        <TabPanel>
                            <VmBackupsTab />
                        </TabPanel>
                    )}
                    {canResizeComputeResourceVm && (
                        <TabPanel>
                            <VmResizeTab />
                        </TabPanel>
                    )}
                    <TabPanel>
                        <VmReinstallTab />
                    </TabPanel>
                    <TabPanel>
                        <VmTasksTab id={id} />
                    </TabPanel>
                    {areSnapshotsAvailable && (
                        <TabPanel>
                            <VmSnapshotsTab serverId={id} />
                        </TabPanel>
                    )}
                    <TabPanel>
                        <VmGraphsTab />
                    </TabPanel>
                    <TabPanel>
                        <VmNetworkingTab />
                    </TabPanel>
                    {item.virtualization_type === VirtualizationType.KVM && (
                        <TabPanel>
                            <VmDisksTab />
                        </TabPanel>
                    )}
                    {canGetTags && (
                        <TabPanel>
                            <VmTagsTab />
                        </TabPanel>
                    )}
                    <TabPanel>
                        <VmActivityLogsTab uuid={item.uuid}/>
                    </TabPanel>
                </Tabs>
            </Loader>
            <Dialog
                heading={<Translate content="computeResource.servers.form.titleEdit" />}
                closeHandler={handleToggleEditDialog}
                isOpen={isEditDialogOpened}
                size={SIZE.XS}
            >
                <ServerEditDialog computeResourceVmId={id} onClose={handleToggleEditDialog} />
            </Dialog>
        </WithErrorPages>
    );
};

const mapStateToProps = (state: RootState, ownProps: RouteComponentProps<{ id: string }>) => {
    const { item } = state.computeResourceVm;

    return {
        id: parseInt(ownProps.match.params.id, 10),
        hash: ownProps.location.hash,
        item,
        isLoadingVm: state.app.loadingFlags.has(LOADING_FLAGS.COMPUTE_RESOURCE_VM),
        areSnapshotsAvailable: item.plan.is_snapshot_available && item.plan.is_snapshots_enabled,
        areLimitsExceeded: areNetworkLimitsExceeded(item),
        isBackupCreating: state.app.loadingFlags.has(LOADING_FLAGS.COMPUTE_RESOURCE_VM_BACKUP_CREATE) ||
            item.status === ComputeResourceVmStatus.BACKUP_CREATING,
        canResizeComputeResourceVm: hasPermission(state, PERMISSION_LIST.MANAGE_SERVERS, PERMISSION_LIST.RESIZE_SERVERS),
        canManageBackups: hasPermission(state, PERMISSION_LIST.MANAGE_BACKUPS),
        canViewBootRescueTab: hasPermission(
            state,
            PERMISSION_LIST.MANAGE_SERVERS,
            PERMISSION_LIST.RESET_ROOT_PASSWORD,
            PERMISSION_LIST.CHANGE_SERVERS_BOOT_MODE
        ),
        canGetTags: hasPermission(state, PERMISSION_LIST.GET_TAGS, PERMISSION_LIST.MANAGE_TAGS),
    };
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    computeResourceVmActions: bindActionCreators(computeResourceVmActions, dispatch),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ComputeResourceVm));
