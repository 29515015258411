// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { IExternalIntegrationResponse } from 'common/api/resources/ExternalIntegration';
import { IIconResponse } from 'common/api/resources/Icon';
import { IShortPlanResponse } from 'common/api/resources/Plan';

// Should be synced with `backend/api/v1/Application/Enums/ApplicationLoginLinkType.php`
export enum ApplicationLoginLinkType {
    NONE = 'none',
    URL = 'url',
    JS_CODE = 'js-code',
    INFO = 'info',
}

export interface IApplicationLoginLink {
    type: ApplicationLoginLinkType;
    content: string;
}

export interface IApplicationResponse {
    id: number;
    name: string;
    url: string;
    icon: IIconResponse | null;
    cloud_init_version: string;
    external_integration: IExternalIntegrationResponse | null;
    user_data_template: string;
    is_default: boolean;
    is_loading: boolean;
    is_deleting: boolean;
    json_schema: string | null;
    login_link: IApplicationLoginLink;
    is_visible: boolean;
    is_buildin: boolean;
    position: number;
    available_plans?: IShortPlanResponse[];
}

export interface IShortApplicationResponse {
    id: number;
    name: string;
}

export interface IApplicationCreateRequest {
    name: string;
    icon_id: number | null;
    url: string;
    cloud_init_version: string;
    user_data_template: string;
    json_schema: string | null;
    login_link: IApplicationLoginLink;
    external_integration_id: number | null;
    is_visible: boolean;
    available_plans: number[];
}

export interface IApplicationPatchRequest {
    is_default?: boolean;
    is_visible?: boolean;
    position?: number;
}
