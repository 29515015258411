// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { ICONS } from 'common/constants';
import { PERMISSION_LIST } from 'common/modules/permission/constants';
import { FEATURE } from 'common/api/resources/Settings';
import { FEATURES } from 'common/modules/app/features/constants';

export interface IMenuItem {
    path: string;
    icon: ICONS;
    value: string;
    permission?: Array<typeof PERMISSION_LIST[keyof typeof PERMISSION_LIST]>;
    settingFeature?: FEATURE;
    feature?: FEATURES;
}

export const menuItems: IMenuItem[] = [
    {
        path: '/projects',
        icon: ICONS.PROJECTS,
        value: 'menu.projects',
    },
    {
        path: '/account',
        icon: ICONS.USER,
        value: 'menu.account',
    },
    {
        path: '/vpc_networks',
        icon: ICONS.IP_ADDRESSES,
        value: 'menu.vpcNetworks',
        permission: [PERMISSION_LIST.MANAGE_ALL_VPC_NETWORKS, PERMISSION_LIST.MANAGE_OWNED_VPC_NETWORKS],
    },
    {
        path: '/iso_images',
        icon: ICONS.CD,
        value: 'menu.isoImages',
        permission: [PERMISSION_LIST.MANAGE_ALL_ISO_IMAGES, PERMISSION_LIST.MANAGE_OWNED_ISO_IMAGES],
    },
    {
        path: '/documentation',
        icon: ICONS.GLOBE,
        value: 'menu.documentation',
        settingFeature: FEATURE.HIDE_API_DOCUMENTATION_LINK,
    },
];
