// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { UserStatus } from 'common/api/resources/User';
import { Translate } from '@plesk/ui-library';

export const statusMappings = {
    [UserStatus.ACTIVE]: <Translate content="user.status.active"/>,
    [UserStatus.LOCKED]: <Translate content="user.status.locked"/>,
    [UserStatus.SUSPENDED]: <Translate content="user.status.suspended"/>,
};
