// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';

export const PopoverFormContent = styled.div`
    min-width: 300px;

    .pul-input {
        width: 100%;
        margin-top: 4px;
    }

    .pul-input__input {
        width: inherit;
        height: 40px;
    }

    .pul-button {
        width: 100%;
        margin-top: 15px;
    }

    .pul-section:last-child {
        margin-bottom: unset;
    }
`;
