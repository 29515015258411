// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import React from 'react';
import {
    InstallState,
    StatusIconContainer,
    StatusText,
} from 'admin/update/containers/VersionState/Styles';
import { UPDATE_STATUS } from 'common/api/resources/Update';
import {
    Translate,
    Action,
} from '@plesk/ui-library';
import { connect } from 'react-redux';
import { RootState } from 'admin/core/store';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import {
    Dispatch,
    bindActionCreators,
} from 'redux';
import * as updateActions from 'admin/update/actions';
import VersionReadyForUpdate from 'admin/update/containers/VersionState/VersionReadyForUpdate';
import VersionUpdating from 'admin/update/containers/VersionState/VersionUpdating';
import {
    selectClusterUpdateStatus,
    CLUSTER_UPDATE_STATUS,
} from 'admin/update/selectors';
import VersionUpdateCompleteWithErrors from 'admin/update/containers/VersionState/VersionUpdateCompleteWithErrors';
import * as taskActions from 'admin/task/actions';
import VersionUpdateComplete from 'admin/update/containers/VersionState/VersionUpdateComplete';
import VersionUpdateFailed from 'admin/update/containers/VersionState/VersionUpdateFailed';

export type VersionStateProps =
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const VersionState: React.FC<VersionStateProps> = ({
    data,
    isUpdateRequested,
    clusterUpdateStatus,
    failedCount,
    updateActions: {
        initUpdate,
    },
    taskActions: {
        retry,
    },
}) => {
    const handleRetry = (id: number) => () => retry(id);

    if (!data.task) {
        return <></>;
    }

    return (
        <InstallState>
            <>
                {data.status === UPDATE_STATUS.UP_TO_DATE && clusterUpdateStatus === CLUSTER_UPDATE_STATUS.COMPLETE && (
                    <VersionUpdateComplete finishedAt={data.task ? data.task.finished_at : null} />
                )}
                {clusterUpdateStatus === CLUSTER_UPDATE_STATUS.UPDATING && (
                    <VersionUpdating
                        isMasterUpdated={data.status === UPDATE_STATUS.UP_TO_DATE}
                        startedAt={data.task.started_at}
                        computeResources={data.compute_resources}
                    />
                )}
                {clusterUpdateStatus === CLUSTER_UPDATE_STATUS.FAILED && (
                    <VersionUpdateCompleteWithErrors
                        failedCount={failedCount}
                        finishedAt={data.task.finished_at}
                    />
                )}
                {data.status === UPDATE_STATUS.READY_FOR_UPDATE && (
                    <VersionReadyForUpdate
                        scheduledDate={data.scheduled_date}
                        isLoading={isUpdateRequested}
                        onInitUpdate={initUpdate}
                    />
                )}
                {data.status === UPDATE_STATUS.DOWNLOADING_UPDATE && (
                    <>
                        <StatusIconContainer>
                            <Action progress={true} />
                        </StatusIconContainer>
                        <div>
                            <StatusText>
                                <Translate content="update.status.downloadingUpdate" />
                            </StatusText>
                        </div>
                    </>
                )}
                {data.status === UPDATE_STATUS.UPDATE_DOWNLOAD_FAILED && (
                    <VersionUpdateFailed
                        task={data.task}
                        title={<Translate content="update.status.updateDownloadFailed" />}
                        retryUpdate={handleRetry(data.task.id)}
                    />
                )}
                {data.status === UPDATE_STATUS.UPDATE_FAILED && (
                    <VersionUpdateFailed
                        task={data.task}
                        title={<Translate content="update.status.updateFailed" />}
                        retryUpdate={handleRetry(data.task.id)}
                    />
                )}
            </>
        </InstallState>
    );
};

const mapStateToProps = (state: RootState) => ({
    data: state.update,
    clusterUpdateStatus: selectClusterUpdateStatus(state),
    failedCount: state.update.compute_resources.total - state.update.compute_resources.done,
    isUpdateRequested: state.app.loadingFlags.has(LOADING_FLAGS.UPDATE_INSTALLATION),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    updateActions: bindActionCreators(updateActions, dispatch),
    taskActions: bindActionCreators(taskActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(VersionState);
