// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import {
    api,
    IApiResponse,
    IPaginateApiResponse,
} from 'common/api/resources/Response';
import {
    ILanguageRequest,
    ILanguageResponse,
} from 'common/api/resources/Language/model';
import { QueryBuilder } from 'common/api/resources/Request/QueryBuilder';
import { IPaginatedWithSearch } from 'common/api/resources/Request/request';

export const languages = {
    list: (params?: IPaginatedWithSearch) => api.get<IPaginateApiResponse<ILanguageResponse[]>>('languages', {
        params: new QueryBuilder(params).build(),
    }),
    patch: (id: number, data: ILanguageRequest) => api.patch<IApiResponse<ILanguageResponse>>(`languages/${id}`, data),
};
