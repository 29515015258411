// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { COMPUTE_RESOURCE_STATUS } from 'admin/computeResource/constants';
import { PROGRESS_STATUS } from 'common/constants';
import { ILocationResponse } from 'common/api/resources/Location';
import { IUserResponse } from 'common/api/resources/User';
import { ISnapshotResponse } from 'common/api/resources/Snapshot';
import { IIpBlockResponse } from 'common/api/resources/IpBlock';
import {
    DiskCacheMode,
    IBackupSettings,
} from 'common/api/resources/ComputeResourceVm';
import { IShortStorageResponse } from 'common/api/resources/Storage';
import { IConcurrentBackups } from 'common/api/resources/BackupNode';

export enum VirtualizationType {
    KVM = 'kvm',
    VZ = 'vz',
}

export const VIRTUALIZATION_TYPE_TRANSLATION_MAP: Record<VirtualizationType, string> = {
    [VirtualizationType.KVM]: 'KVM',
    [VirtualizationType.VZ]: 'VZ',
};

export enum NetworkType {
    BRIDGED = 'bridged',
    ROUTED = 'routed',
    VZ_ROUTED = 'vz_routed'
}

// Should be synced with LicenseType in backend/api/v1/ComputeResource/Enums/LicenseType.php
export enum LicenseType {
    STANDARD = 'standard',
    MINI = 'mini',
    MICRO = 'micro',
}

export interface IComputeResourceSettingNetworkBridges {
    name: string;
    type: string;
}

export interface IComputeResourceSettingNetwork {
    type: NetworkType;
    bridges: IComputeResourceSettingNetworkBridges[];
    ip_for_vpc_network?: string;
}

export interface IComputeResourceCapabilities {
    kvm: boolean;
    vz: boolean;
    is_management_node: boolean;
    is_mem_balloon_free_page_reporting_supported: boolean;
    is_virtio_discard_supported: boolean;
}

interface IComputeResourceSettingsLimit {
    unlimited: boolean;
    total: number;
    used?: number;
}

interface IComputeResourceSettingsLimits {
    vm: IComputeResourceSettingsLimit;
    hdd: IComputeResourceSettingsLimit;
    ram: IComputeResourceSettingsLimit;
    vcpu: IComputeResourceSettingsLimit;
}

export interface IComputeResourceSettingsResponse {
    cache_path: string;
    backup_tmp_path: string;
    iso_path: string;
    vnc_proxy_port: number;
    balance_strategy: string;
    limits: IComputeResourceSettingsLimits;
    network: IComputeResourceSettingNetwork;
    virtualization_types: VirtualizationType[];
    vs_disk_cache_mode: DiskCacheMode | null;
    concurrent_backups: IConcurrentBackups;
}

export interface IMetricsNetwork {
    ipv6_available: boolean;
}

export interface IComputeResourceMetricsResponse {
    network: IMetricsNetwork;
}

export interface IShortComputeResourceResponse {
    id: number;
    name: string;
    host: string;
    capabilities: IComputeResourceCapabilities;
}

export interface IComputeResourceResponse {
    id: number;
    host: string;
    agent_port: number;
    vms_count: number;
    name: string;
    version: string;
    status: COMPUTE_RESOURCE_STATUS;
    settings: IComputeResourceSettingsResponse;
    metrics: IComputeResourceMetricsResponse;
    locations: ILocationResponse[];
    capabilities: IComputeResourceCapabilities;
    installSteps?: IComputeResourceInstallStepResponse[];
    is_deleting: boolean;
    ip_blocks?: IIpBlockResponse[];
    domain?: string;
    is_configuring_network?: boolean;
    is_locked: boolean;
    storages: IShortStorageResponse[];
}

export interface IComputeResourceCreateRequest {
    name: string;
    host: string;
    type: string;
    login: string;
    port: string;
    agent_port: string;
    password?: string;
    key?: string;
    locations?: number[];
    ip_blocks?: number[];
    license_type?: LicenseType;
}

export interface IComputeResourcePatchRequest {
    name?: string;
    locations?: number[];
    ip_blocks?: number[];
    is_locked?: boolean;
}

export interface IComputeResourceDeleteRequest {
    force?: boolean;
}

export interface IComputeResourceInstallStepResponse {
    id: number;
    compute_resource_id: number;
    title: string;
    status: PROGRESS_STATUS;
    status_text: string;
    progress: string;
}

export interface IUpdateSnapshot {
    snapshot: ISnapshotResponse;
}

export interface IUpdateUser {
    user: IUserResponse;
}

export interface IUpdateComputeResource {
    computeResource: IComputeResourceResponse;
}

export interface IUpdateComputeResourceInstallStep {
    installStep: IComputeResourceInstallStepResponse;
}

export interface INetworkResponse {
    id: string;
    name: string;
    type: string;
    addr_conf_type: string;
    ip_version: number;
    ip: string;
    mask: string;
    mask_size: number;
}

export interface IMetricResponse {
    total: number;
    used: number;
    name: string;
    compute_resource_id: number;
}

export interface IUsageResponse {
    server_count: number;
    cpu: number;
    ram: number;
    disk: number;
}

export interface IPhysicalVolumeResponse {
    vg_name: string;
    vg_size: string;
    vg_free: string;
}

export interface ILogicalVolumeResponse {
    convert_lv: string;
    copy_percent: string;
    data_percent: string;
    lv_attr: string;
    lv_layout: string;
    lv_name: string;
    lv_size: string;
    metadata_percent: string;
    mirror_log: string;
    move_pv: string;
    origin: string;
    pool_lv: string;
    vg_name: string;
}

export interface ISetUpNetworkRequest {
    id: string;
    type: NetworkType;
}

// todo just remove this methods and the interface https://webpros.atlassian.net/browse/SIO-3017
// @ts-ignore
/* eslint-disable */
interface IOldStatus {
    name: string;
}

export const getStatus = (status: COMPUTE_RESOURCE_STATUS | IOldStatus): COMPUTE_RESOURCE_STATUS => {
    // @ts-ignore
    if (status?.name) {
        // @ts-ignore
        return status.name;
    }
    // @ts-ignore
    return status;
};
/* eslint-enable */

export interface IVmCreateRequest {
    name: string;
    description: string;
    plan_id: number;
    os_image_version_id?: number;
    application_id?: number;
    application_data?: Record<string, unknown>;
    password: string;
    ssh_keys: number[];
    user_id: number;
    project_id: number;
    user_data?: string;
    compute_resource_id?: number;
    backup_settings?: IBackupSettings;
    fqdns?: string[];
}
