// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { ValueType } from 'react-select';
import { ISelectRequiredOption } from 'common/components';
import * as React from 'react';
import AsyncSelectInput from 'common/components/Select/AsyncSelectInput';
import { FILTERS } from 'admin/common/constants/tests';
import { FixedFormField } from 'admin/common/components/Filters/Styles';
import {
    ITagOption,
    tag,
} from 'common/api/resources/Tag';
import { Translate } from '@plesk/ui-library';
import { createOptionsLoader } from 'common/components/Select/helpers';

export interface ITagsFilterProps {
    onChange?: (option: Array<ValueType<ISelectRequiredOption>>) => void;
    margin_left?: string;
    value?: Array<ValueType<ISelectRequiredOption>>;
    isLoading?: boolean;
}

export const TagsFilter: React.FC<ITagsFilterProps & React.ComponentProps<typeof FixedFormField>> = ({
    onChange,
    margin_left,
    value,
    isLoading,
    ...props
}) => {
    const loadOptions = createOptionsLoader(
        tag.options,
        (item: ITagOption) => ({
            label: item.tag_full_name,
            value: item.tag_full_name,
        })
    );

    return (
        <FixedFormField margin_left={margin_left} data-cy={FILTERS.TAG} {...props}>
            <AsyncSelectInput
                inputId="tags-filter"
                isClearable={true}
                placeholder={<Translate content="filters.tags"/>}
                loadOptions={loadOptions}
                onChange={onChange}
                debounceTimeout={1000}
                additional={{ page: 1 }}
                value={value}
                isLoading={isLoading}
                menuPosition="absolute"
                menuPortalTarget={document.body}
                isMulti
            />
        </FixedFormField>
    );
};
