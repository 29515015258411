// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { LoaderBarContainer } from 'common/components/LoaderBar/Styles';

export interface ILoaderBarProps {
    isLoading: boolean;
}

export const LoaderBar: React.FC<ILoaderBarProps> = ({ isLoading }) => {
    if (!isLoading) {
        return <></>;
    }

    return (
        <LoaderBarContainer className="loader">
            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" width="16" height="16">
                <circle fill="#28AADE" stroke="none" cx="2" cy="8" r="2">
                    <animateTransform
                        attributeName="transform"
                        dur="1s"
                        type="translate"
                        values="0 3 ; 0 -3; 0 3"
                        repeatCount="indefinite"
                        begin="0.1" />
                </circle>
                <circle fill="#28AADE" stroke="none" cx="8" cy="8" r="2">
                    <animateTransform
                        attributeName="transform"
                        dur="1s"
                        type="translate"
                        values="0 2 ; 0 -2; 0 2"
                        repeatCount="indefinite"
                        begin="0.2" />
                </circle>
                <circle fill="#28AADE" stroke="none" cx="14" cy="8" r="2">
                    <animateTransform
                        attributeName="transform"
                        dur="1s"
                        type="translate"
                        values="0 1 ; 0 -1; 0 1"
                        repeatCount="indefinite"
                        begin="0.3" />
                </circle>
            </svg>
        </LoaderBarContainer>
    );
};
