// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { IPermissionResponse } from 'common/api/resources/Permission';

export const ROLE_CLIENT = 'CLIENT';

export interface IRoleRequest {
    name: string;
    permissions: string[];
}

export interface IRoleResponse{
    id: number;
    name: string;
    is_default: boolean;
    permissions: IPermissionResponse[];
    users_count?: number;
    is_deleting: boolean;
}
