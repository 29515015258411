// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import {
    api,
    IApiResponse,
} from 'common/api/resources/Response';
import { IStorageTypeResponse } from 'common/api/resources/StorageType/model';

export const storageTypes = {
    list: () => api.get<IApiResponse<IStorageTypeResponse[]>>('storage_types'),
};
