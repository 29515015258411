// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { createCustomAction } from 'typesafe-actions';
import * as types from 'common/modules/app/responseError/constants';
import { IResponseError } from 'common/modules/app/responseError/reducer';

export const setResponseError = createCustomAction(
    types.SET_RESPONSE_ERROR,
    (data: IResponseError) => ({ payload: data })
);
export const clearResponseError = createCustomAction(
    types.CLEAR_RESPONSE_ERROR,
    () => ({})
);
