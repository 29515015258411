// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';

export const ManagementNodeBackupCardContainer  = styled.div`
    margin-top: 30px;
    margin-bottom: 30px;
    margin-right: 30px;
    display: flex;
    
    & > div {
        flex-basis: 350px;
        margin-right: 20px;
        height: 146px;
    }
`;