// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import * as settingsActions from 'common/modules/settings/actions';
import SettingsWrapper from 'admin/settings/containers/Settings/SettingsWrapper';
import { ISettingsResponse } from 'common/api/resources/Settings';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import {
    FormFieldCheckbox,
    FormFieldText,
    Section,
    Translate,
} from '@plesk/ui-library';
import { FormFieldNumber } from 'common/components/Form/FormFieldNumber/FormFieldNumber';
import { SubInputs } from 'admin/common/styles/Styles';
import { RootState } from 'admin/core/store';
import { SIZE } from 'common/constants';
import { Title } from 'common/components';

export type GeneralSettingsProps =
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const GeneralSettings: React.FC<GeneralSettingsProps> = ({
    settings,
    settingsActions: { saveSettings },
}) => {
    const handleSubmit = (values: ISettingsResponse) => {
        saveSettings({
            hostname: values.hostname,
            frontend_hostname: values.frontend_hostname,
            send_statistic: values.send_statistic,
            auth: values.auth,
            http: {
                ...values.http,
                trusted_proxies: values.http.trusted_proxies.filter(Boolean),
            },
        });
    };

    return (
        <SettingsWrapper title="settings.title" onSubmit={handleSubmit}>
            <FormFieldText
                name="hostname"
                size="xl"
                label={<Translate content="settings.hostname" />}
                description={<Translate content="settings.hostnameDescription" />}
                required={true}
            />
            <FormFieldCheckbox
                name="send_statistic"
                label={<Translate content="settings.sendStatistic"/>}
            />
            <Section title={<Title content="settings.http.header" />}>
                <FormFieldText
                    name="http[frontend_hostname]"
                    size={SIZE.XL}
                    label={<Translate content="settings.frontendHostname" />}
                    description={<Translate content="settings.frontendHostnameDescription" />}
                />
                <FormFieldText
                    name="http[trusted_proxies]"
                    multi={true}
                    size={SIZE.XL}
                    label={<Translate content="settings.http.trustedProxies" />}
                    description={<Translate content="settings.http.trustedProxiesDescription" />}
                />
                <FormFieldText
                    name="http[true_ip_header]"
                    size={SIZE.XL}
                    label={<Translate content="settings.http.trueIpHeader" />}
                    description={<Translate content="settings.http.trueIpHeaderDescription" />}
                />
            </Section>
            <Section title={<Title content="settings.auth.header" />}>
                <FormFieldCheckbox
                    name="auth[is_session_timeout_enabled]"
                    label={<Translate content="settings.auth.isSessionTimeoutEnabled" />}
                />
                <SubInputs>
                    <FormFieldNumber
                        name="auth[session_timeout]"
                        label={<Translate content="settings.auth.sessionTimeout" />}
                        disabled={!settings.auth?.is_session_timeout_enabled}
                        min={0}
                        size={SIZE.MD}
                    />
                </SubInputs>
                <FormFieldCheckbox
                    name="auth[single_session_only]"
                    label={<Translate content="settings.auth.singleSessionOnly" />}
                />
                <FormFieldCheckbox
                    name="auth[allow_different_ips]"
                    label={<Translate content="settings.auth.allowDifferentIps" />}
                />
            </Section>
        </SettingsWrapper>
    );
};

const mapStateToProps = (state: RootState) => ({
    settings: state.settings,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    settingsActions: bindActionCreators(settingsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(GeneralSettings);
