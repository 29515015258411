// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import Select,
{
    OptionTypeBase,
    Props,
} from 'react-select';
import { selectInputStyles } from 'common/components/Select/styles';

export interface ISelectInputProps<T extends OptionTypeBase> extends Props<T> {}

const SelectInput = <T extends OptionTypeBase,>(props: ISelectInputProps<T>) => {
    const actualProps = {
        ...props,
        components: {
            ...props.components,
            // Hide small bar between input and indicator
            IndicatorSeparator: () => null,
        },
        styles: selectInputStyles,
    };

    return <Select<T> {...actualProps}/>;
};

export default SelectInput;
