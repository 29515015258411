// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';

export const OsImageRowContainer = styled.div`
    display: flex;
    width: 100%;
    > div {
      width: 100%;
    }
`;
