// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    CodeEditor,
    FormField,
    FormFieldCheckbox,
    FormFieldSelect,
    FormFieldText,
    Section,
    Translate,
} from '@plesk/ui-library';
import SettingsWrapper from 'admin/settings/containers/Settings/SettingsWrapper';
import { StyledSelect } from 'common/components/styles/Select';
import { StrategySelector } from 'admin/settings/containers/Settings/Styles';
import {
    ComputeResourcesBalanceStrategy,
    ISettingsResponse,
} from 'common/api/resources/Settings';
import * as settingsActions from 'common/modules/settings/actions';
import { connect } from 'react-redux';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { SIZE } from 'common/constants';
import { FormFieldNumber } from 'common/components/Form/FormFieldNumber/FormFieldNumber';
import { RootState } from 'admin/core/store';
import {
    DiskCacheMode,
    DiskDriver,
} from 'common/api/resources/ComputeResourceVm';
import { StyledCodeEditor } from 'admin/common/styles/CodeEditor';
import { Button } from 'admin/common/components/Button/Button';
import { initialState } from 'common/modules/settings/reducer';
import {
    Tab,
    TabList,
    TabPanel,
    Tabs,
} from 'react-tabs';
import { nestStringProperties } from 'common/modules/app/formErrors/selectors';

export type ComputeResourceSettingsProps =
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps> & {
    errors: {
        compute_resource?: {
            libvirt_xml_cpu?: string[];
            libvirt_xml_os_mode_aarch64?: string[];
            libvirt_xml_os_mode_x86_64_bios?: string[];
            libvirt_xml_os_mode_x86_64_efi?: string[];
            libvirt_xml_io_threads?: string[];
        };
    };
};

const balanceStrategyOptions = Object.values(ComputeResourcesBalanceStrategy).map((value) => (
    <option key={value} value={value}>
        {value}
    </option>
));

const diskCacheModeOptions = Object.values(DiskCacheMode).map((value) => (
    <option key={value} value={value}>
        {value}
    </option>
));

const diskDriverOptions =  Object.values(DiskDriver).map((value) => (
    <option key={value} value={value}>
        {value}
    </option>
));

enum LIBVIRT_XML_TABS {
    CPU,
    OS_MODE_AARCH_64,
    OS_MODE_X86_64_BIOS,
    OS_MODE_X86_64_EFI,
    IO_THREADS,
}

export const ComputeResourceSettings: React.FC<ComputeResourceSettingsProps> = ({
    settings,
    errors,
    settingsActions: { saveSettings },
}) => {
    const [libvirtXml, setLibvirtXml] = React.useState({
        cpu: settings.compute_resource.libvirt_xml_cpu,
        os_mode_aarch64: settings.compute_resource.libvirt_xml_os_mode_aarch64,
        os_mode_x86_64_bios: settings.compute_resource.libvirt_xml_os_mode_x86_64_bios,
        os_mode_x86_64_efi: settings.compute_resource.libvirt_xml_os_mode_x86_64_efi,
        io_threads: settings.compute_resource.libvirt_xml_io_threads,
    });
    const [selectedLibvirtXmlTab, setSelectedLibvirtXmTab] = React.useState(LIBVIRT_XML_TABS.CPU);
    const handleSelectLibvirtXmlTab = (index: number) => setSelectedLibvirtXmTab(index);

    React.useEffect(() => {
        setLibvirtXml({
            cpu: settings.compute_resource.libvirt_xml_cpu,
            os_mode_aarch64: settings.compute_resource.libvirt_xml_os_mode_aarch64,
            os_mode_x86_64_bios: settings.compute_resource.libvirt_xml_os_mode_x86_64_bios,
            os_mode_x86_64_efi: settings.compute_resource.libvirt_xml_os_mode_x86_64_efi,
            io_threads: settings.compute_resource.libvirt_xml_io_threads ?? '',
        });
    }, [settings.compute_resource]);

    React.useEffect(() => {
        if (errors.compute_resource?.libvirt_xml_cpu && selectedLibvirtXmlTab !== LIBVIRT_XML_TABS.CPU) {
            setSelectedLibvirtXmTab(LIBVIRT_XML_TABS.CPU);
        }

        if (errors.compute_resource?.libvirt_xml_os_mode_aarch64
            && selectedLibvirtXmlTab !== LIBVIRT_XML_TABS.OS_MODE_AARCH_64
        ) {
            setSelectedLibvirtXmTab(LIBVIRT_XML_TABS.OS_MODE_AARCH_64);
        }

        if (errors.compute_resource?.libvirt_xml_os_mode_x86_64_bios
            && selectedLibvirtXmlTab !== LIBVIRT_XML_TABS.OS_MODE_X86_64_BIOS
        ) {
            setSelectedLibvirtXmTab(LIBVIRT_XML_TABS.OS_MODE_X86_64_BIOS);
        }

        if (errors.compute_resource?.libvirt_xml_os_mode_x86_64_efi
            && selectedLibvirtXmlTab !== LIBVIRT_XML_TABS.OS_MODE_X86_64_EFI
        ) {
            setSelectedLibvirtXmTab(LIBVIRT_XML_TABS.OS_MODE_X86_64_EFI);
        }

        if (errors.compute_resource?.libvirt_xml_io_threads
            && selectedLibvirtXmlTab !== LIBVIRT_XML_TABS.IO_THREADS) {
            setSelectedLibvirtXmTab(LIBVIRT_XML_TABS.IO_THREADS);
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        errors.compute_resource?.libvirt_xml_cpu,
        errors.compute_resource?.libvirt_xml_os_mode_aarch64,
        errors.compute_resource?.libvirt_xml_os_mode_x86_64_bios,
        errors.compute_resource?.libvirt_xml_os_mode_x86_64_efi,
        errors.compute_resource?.libvirt_xml_io_threads,
    ]);

    const handleSubmit = (values: ISettingsResponse) => {
        saveSettings({
            compute_resource: {
                ...values.compute_resource,
                libvirt_xml_cpu: libvirtXml.cpu,
                libvirt_xml_os_mode_aarch64: libvirtXml.os_mode_aarch64,
                libvirt_xml_os_mode_x86_64_bios: libvirtXml.os_mode_x86_64_bios,
                libvirt_xml_os_mode_x86_64_efi: libvirtXml.os_mode_x86_64_efi,
                libvirt_xml_io_threads: libvirtXml.io_threads,
            },
            non_existent_vms_remover: values.non_existent_vms_remover,
        });
    };

    return (
        <SettingsWrapper
            title="settings.titles.computeResource"
            onSubmit={handleSubmit}
        >
            <Section>
                <StyledSelect>
                    <StrategySelector
                        size={SIZE.XL}
                        name="compute_resource[balance_strategy]"
                        description={<Translate content="settings.computeResource.vmCreateStrategyDescription" />}
                        required={true}
                        label={<Translate content="settings.computeResource.vmCreateStrategy" />}
                    >
                        {balanceStrategyOptions}
                    </StrategySelector>
                </StyledSelect>
                <FormFieldText
                    required={true}
                    name="compute_resource[rescue_iso_url]"
                    size={SIZE.XL}
                    label={
                        <Translate content="settings.computeResource.rescueImageUrl" />
                    }
                    description={
                        <Translate content="settings.computeResource.rescueImageUrlDescription" />
                    }
                />
                <FormFieldCheckbox
                    name="non_existent_vms_remover[enabled]"
                    label={
                        <Translate content="settings.nonExistentVmsRemover.enabled" />
                    }
                />
                <FormFieldNumber
                    label={
                        <Translate content="settings.nonExistentVmsRemover.interval" />
                    }
                    name="non_existent_vms_remover[interval]"
                    min={1}
                    max={60}
                    disabled={!settings.non_existent_vms_remover.enabled}
                />
            </Section>
            <Section title={<Translate content="settings.computeResource.kvm.title" />}>
                <FormFieldSelect
                    size={SIZE.XL}
                    name="compute_resource[vs_disk_cache_mode]"
                    description={<Translate content="settings.computeResource.kvm.diskCacheModeDescription" />}
                    required={true}
                    label={<Translate content="settings.computeResource.kvm.diskCacheMode" />}
                >
                    {diskCacheModeOptions}
                </FormFieldSelect>

                <FormFieldSelect
                    size={SIZE.XL}
                    name="compute_resource[vs_disk_driver]"
                    description={<Translate content="settings.computeResource.kvm.diskDriverDescription" />}
                    required={true}
                    label={<Translate content="settings.computeResource.kvm.diskDriver" />}
                >
                    {diskDriverOptions}
                </FormFieldSelect>

                <Section>
                    <FormField
                        label={<Translate content="settings.computeResource.kvm.libvirtXmlConfiguration" />}
                        required={true}
                    />
                    <Tabs selectedIndex={selectedLibvirtXmlTab} onSelect={handleSelectLibvirtXmlTab}>
                        <div>
                            <TabList style={{ marginBottom: '0' }}>
                                <Tab>
                                    <Translate content="settings.computeResource.kvm.libvirtXmlConfigurationCpu"/>
                                </Tab>
                                <Tab>
                                    <Translate content="settings.computeResource.kvm.libvirtXmlConfigurationOsModeAarch64"/>
                                </Tab>
                                <Tab>
                                    <Translate content="settings.computeResource.kvm.libvirtXmlConfigurationOsModeX8684BIOS"/>
                                </Tab>
                                <Tab>
                                    <Translate content="settings.computeResource.kvm.libvirtXmlConfigurationOsModeX8684EFI"/>
                                </Tab>
                                <Tab>
                                    <Translate content="settings.computeResource.kvm.libvirtXmlConfigurationIoThreads"/>
                                </Tab>
                            </TabList>
                            <div></div>
                        </div>
                        <TabPanel>
                            <FormField
                                name="compute_resource[libvirt_xml_cpu]"
                                size={SIZE.XL}
                            >
                                <StyledCodeEditor height={400} width={560}>
                                    <CodeEditor
                                        mode="xml"
                                        onChange={(value: string) => setLibvirtXml({
                                            ...libvirtXml,
                                            cpu: value,
                                        })}
                                    >
                                        {libvirtXml.cpu}
                                    </CodeEditor>
                                </StyledCodeEditor>
                            </FormField>
                            <Button
                                ghost={false}
                                onClick={() => setLibvirtXml({
                                    ...libvirtXml,
                                    cpu: initialState.compute_resource.libvirt_xml_cpu,
                                })}
                            >
                                <Translate content="settings.computeResource.kvm.setDefaultLibvirtXml" />
                            </Button>
                        </TabPanel>
                        <TabPanel>
                            <FormField
                                name="compute_resource[libvirt_xml_os_mode_aarch64]"
                                size={SIZE.XL}
                            >
                                <StyledCodeEditor height={400} width={560}>
                                    <CodeEditor
                                        onChange={(value: string) => setLibvirtXml({
                                            ...libvirtXml,
                                            os_mode_aarch64: value,
                                        })}
                                    >
                                        {libvirtXml.os_mode_aarch64}
                                    </CodeEditor>
                                </StyledCodeEditor>
                            </FormField>
                            <Button
                                ghost={false}
                                onClick={() => setLibvirtXml({
                                    ...libvirtXml,
                                    os_mode_aarch64: initialState.compute_resource.libvirt_xml_os_mode_aarch64,
                                })}
                            >
                                <Translate content="settings.computeResource.kvm.setDefaultLibvirtXml" />
                            </Button>
                        </TabPanel>
                        <TabPanel>
                            <FormField
                                name="compute_resource[libvirt_xml_os_mode_x86_64_bios]"
                                size={SIZE.XL}
                            >
                                <StyledCodeEditor height={400} width={560}>
                                    <CodeEditor
                                        mode="xml"
                                        onChange={(value: string) => setLibvirtXml({
                                            ...libvirtXml,
                                            os_mode_x86_64_bios: value,
                                        })}
                                    >
                                        {libvirtXml.os_mode_x86_64_bios}
                                    </CodeEditor>
                                </StyledCodeEditor>
                            </FormField>
                            <Button
                                ghost={false}
                                onClick={() => setLibvirtXml({
                                    ...libvirtXml,
                                    os_mode_x86_64_bios: initialState.compute_resource.libvirt_xml_os_mode_x86_64_bios,
                                })}
                            >
                                <Translate content="settings.computeResource.kvm.setDefaultLibvirtXml" />
                            </Button>
                        </TabPanel>
                        <TabPanel>
                            <FormField
                                name="compute_resource[libvirt_xml_os_mode_x86_64_efi]"
                                size={SIZE.XL}
                            >
                                <StyledCodeEditor height={400} width={560}>
                                    <CodeEditor
                                        mode="xml"
                                        onChange={(value: string) => setLibvirtXml({
                                            ...libvirtXml,
                                            os_mode_x86_64_efi: value,
                                        })}
                                    >
                                        {libvirtXml.os_mode_x86_64_efi}
                                    </CodeEditor>
                                </StyledCodeEditor>
                            </FormField>
                            <Button
                                ghost={false}
                                onClick={() => setLibvirtXml({
                                    ...libvirtXml,
                                    os_mode_x86_64_efi: initialState.compute_resource.libvirt_xml_os_mode_x86_64_efi,
                                })}
                            >
                                <Translate content="settings.computeResource.kvm.setDefaultLibvirtXml" />
                            </Button>
                        </TabPanel>
                        <TabPanel>
                            <FormField
                                name="compute_resource[libvirt_xml_io_threads]"
                                size={SIZE.XL}
                            >
                                <StyledCodeEditor height={400} width={560}>
                                    <CodeEditor
                                        mode="xml"
                                        onChange={(value: string) => setLibvirtXml({
                                            ...libvirtXml,
                                            io_threads: value,
                                        })}
                                    >
                                        {libvirtXml.io_threads}
                                    </CodeEditor>
                                </StyledCodeEditor>
                            </FormField>
                        </TabPanel>
                    </Tabs>
                </Section>
                <FormFieldCheckbox
                    name="compute_resource[is_storage_tier_list_enabled]"
                    label={<Translate content="settings.computeResource.storageTierList"/>}
                />
            </Section>
        </SettingsWrapper>
    );
};

const mapStateToProps = (state: RootState) => ({
    settings: state.settings,
    errors: nestStringProperties(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    settingsActions: bindActionCreators(settingsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ComputeResourceSettings);
