// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { VZNetfilterStatus } from 'common/api/resources/ComputeResourceVm';
import { Translate } from '@plesk/ui-library';
import SelectWithConfirmation, { ISelectWithConfirmationProps } from 'common/components/SelectWithConfirmation/SelectWithConfirmation';
import { ISelectOption } from 'common/components/SelectInput';
import { ValueType } from 'react-select';

export interface IVZNetfilterStatusProps extends Omit<
    ISelectWithConfirmationProps,
    'onChange' | 'dialog' | 'isLoading' | 'isDisabled'
> {
    disabled?: boolean;
    loading?: boolean;
    value: VZNetfilterStatus;
    onChange: (value: VZNetfilterStatus) => void;
}

const VZNetfilterStatusControl: React.FC<IVZNetfilterStatusProps> = ({
    disabled = false,
    loading = false,
    value,
    onChange,
    ...props
}) => {
    const handleChange = (option: ValueType<ISelectOption>) => {
        onChange((option as ISelectOption).value as VZNetfilterStatus);
    };

    const options: ISelectOption[] = Object.values(VZNetfilterStatus).map(status => ({
        value: status,
        label: status.charAt(0).toUpperCase() + status.slice(1),
    }));
    const getValue = (status: VZNetfilterStatus) =>
        options.find((option) => option.value === status);

    return (
        <SelectWithConfirmation
            minHeight="40px"
            width="350px"
            {...props}
            onChange={handleChange}
            value={getValue(value)}
            options={options}
            isDisabled={disabled}
            isLoading={loading}
            dialog={{
                title: <Translate content="servers.controls.vzNetfilterStatus.confirmation.title" />,
                text: <Translate content="servers.controls.vzNetfilterStatus.confirmation.text" />,
                button: <Translate content="servers.controls.vzNetfilterStatus.confirmation.button" />,
            }}
        />
    );
};

export default VZNetfilterStatusControl;
