// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'admin/core/store';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import {
    requiredRule,
    validate,
} from 'common/validator';
import * as osImageActions from 'common/modules/osImage/actions';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import {
    Form,
    FormField,
    FormFieldText,
    Section,
    setIn,
    Translate,
} from '@plesk/ui-library';
import { INTENT_TYPE } from 'common/constants';
import { Button } from 'admin/common/components/Button/Button';
import { IOsImageCreateRequest } from 'common/api/resources/OsImage';
import { FormFieldSwitch } from 'common/components/Form/FormFieldSwitch/FormFieldSwitch';
import { nestStringProperties } from 'common/modules/app/formErrors/selectors';
import { IconType } from 'common/api/resources/Icon';
import { IconSelector } from 'admin/icon/components/IconSelector/IconSelector';

interface IOsImageFormProps {
    onCreated: () => void;
}

export type OsImageFormProps =
    IOsImageFormProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const OsImageForm: React.FC<OsImageFormProps> = ({
    item,
    isItemSaving,
    formErrors,
    formErrorsActions: {
        setFormErrors,
        clearFormErrors,
    },
    osImageActions: {
        unsetOsImageItem,
        createOsImage,
        updateOsImage,
    },
    onCreated,
}) => {
    const [submitValues, setSubmitValues] = React.useState({ ...item });

    React.useEffect(() => () => {
        clearFormErrors();
        unsetOsImageItem();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmit = async (values: IOsImageCreateRequest) => {
        const errors = validate<IOsImageCreateRequest>(values, {
            name: requiredRule(<Translate content="validate.fieldRequired" />),
        });

        if (Object.keys(errors).length) {
            setFormErrors(errors);
            return;
        }

        try {
            item.id
                ? await updateOsImage(item.id, values)
                : await createOsImage(values);
            onCreated();
        } catch (e) {
            throw e;
        }
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleChangeIcon = (value: any) => {
        setSubmitValues(state => ({
            ...state,
            icon_id: value,
        }));
    };

    const onFieldChange = (key: string, value: string) => setSubmitValues(setIn(submitValues, key, value));

    return (
        <>
            <Form
                id="osImageForm"
                onFieldChange={onFieldChange}
                onSubmit={handleSubmit}
                values={submitValues}
                footerClassName="hidden"
                errors={formErrors}
                hideRequiredLegend={true}
                submitButton={false}
                cancelButton={false}
                applyButton={false}
                vertical={true}
            >
                <Section>
                    <FormFieldText
                        name="name"
                        size="fill"
                        label={<Translate content="osImage.form.name" />}
                        required={true}
                    />
                    <FormField name="icon_id" label={<Translate content="osImage.form.icon" />}>
                        {({ getId }) => (
                            <IconSelector
                                inputId={getId()}
                                type={IconType.OS}
                                onChange={handleChangeIcon}
                                selected={submitValues.icon}
                                menuPosition="fixed"
                            />
                        )}
                    </FormField>
                    <FormFieldSwitch name="is_visible" label={<Translate content="osImage.form.visibility"/>} />
                </Section>
            </Form>
            <Button
                type="submit"
                form="osImageForm"
                fill={true}
                intent={INTENT_TYPE.PRIMARY}
                size="lg"
                isLoading={isItemSaving}
            >
                <Translate content="osImage.form.saveBtn" />
            </Button>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    item: state.osImage.item,
    isItemSaving: state.app.loadingFlags.has(LOADING_FLAGS.SAVE_OS_IMAGE_ITEM),
    formErrors: nestStringProperties(state),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    osImageActions: bindActionCreators(osImageActions, dispatch),
    formErrorsActions: bindActionCreators(formErrorsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(OsImageForm);
