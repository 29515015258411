// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { IPaginateApiResponse } from 'common/api/resources/Response';
import { AxiosPromise } from 'axios';
import {
    IAdditionalOptions,
    ILoadOptions,
    ISelectOption,
} from 'common/components/SelectInput';
import {
    Dispatch,
    SetStateAction,
} from 'react';
import { IPaginatedWithSearch } from 'common/api/resources/Request/request';

/**
 * Helper factory for creating options loader functions for async select input.
 *
 * @param api
 * @param mapper
 * @param allowed
 * @param filters
 */
export const createOptionsLoader = <Item, Filters = unknown>(
    api: (params?: IPaginatedWithSearch) => AxiosPromise<IPaginateApiResponse<Item[]>>,
    mapper: (item: Item) => ISelectOption,
    allowed: boolean = true,
    filters?: Filters
): ILoadOptions =>
        async (search, _, { page } : IAdditionalOptions) => {
            if (!allowed) {
                return {
                    options: [],
                    hasMore: false,
                };
            }

            const {
                data: { data, links },
            } = await api({
                page,
                filters: {
                    ...filters,
                    search,
                },
            });

            return {
                options: data.map(mapper),
                hasMore: !!links.next,
                additional: {
                    page: page + 1,
                },
            };
        };

/**
 * Wrapper for options loader with filter.
 *
 * @param loader
 * @param filter
 */
export const filteredOptionsLoader = (loader: ILoadOptions, filter: (item: ISelectOption) => boolean): ILoadOptions =>
    async (search, selectOpt, additionalOpt) => {
        const options = await loader(search, selectOpt, additionalOpt);
        return {
            ...options,
            options: options.options.filter(filter),
        };
    };
/**
 * Helper factory for AsyncSelectInput and SelectInput `onChange` event in form.
 * Works in React.FC only.
 *
 * Prerequisites:
 *  - Selection state should has ISelectOption[] type.
 *
 * @param submitValuesSetter Setter for form values state.
 * @param selectionSetter    Setter for selection options state.
 * @param property           Property in form values state which should be updated.
 */
export const createChangeHandler = <S>(
    submitValuesSetter: Dispatch<SetStateAction<S>>,
    selectionSetter: Dispatch<SetStateAction<ISelectOption[]>>,
    property: keyof S
) => (options: ISelectOption[]) => {
        submitValuesSetter((prevState: S) => ({
            ...prevState,
            [property]: options !== null ? options.map((option) => option.value) : [],
        }));
        selectionSetter(options !== null ? [...options] : []);
    };
