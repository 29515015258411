// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

// This code is based on code of game when players should write a JS program to win.
// This game is presented on HolyJS at 2018 in Moscow so I think this code is tested
// enough for our purpose.
//
// Main features of this code:
// * Untrusted code is running inside separate thread (Worker);
// * We can control which functions and object is available for untrusted code;
// * Ability to limit untrusted code execution time.
//
// https://github.com/lekzd/script_battle_game/blob/4f7dfbf72f341ff4344ad628e9a32a1e9a093633/src/common/codeSandbox/CodeSandbox.ts
// This code is written by guy who wrote Heroes Of Might and Magic 3 in JS!

/**
 * Run specified untrusted code in sandbox.
 *
 * Untrusted code should call `openWindow()` or `end()` at the end in order to inform
 * that it ends or it will be killed by timeout. Code will be terminated after calling
 * one of this function.
 *
 * `openWindow()` will open new browser window with provided url.
 * `end()` will stop execution normally.
 *
 * @param code
 * @param timeout How long code may executed in seconds.
 * @param onEnd This callback will be triggered when untrusted code is ends by calling
 *              `openWindow()` or `end()` or after timeout.
 */
const run = (code: string, timeout: number, onEnd: () => void) => {
    const worker = new Worker(URL.createObjectURL(new Blob([getWorkerCode(code)], { type: 'text/javascript' })));
    timeout = timeout * 1000;

    // Make sure that code will not execute for too long.
    const timer = setTimeout(() => {
        worker.terminate();
        // This is required 'cause users may wants to understand that is happen with
        // they code.
        //
        // eslint-disable-next-line no-console
        console.error(`Application JS login link code don't finished after ${timeout} milliseconds! Make sure that you call \`end()\` or \`openWindow()\` at the end.`);
        onEnd();
    }, timeout);

    worker.addEventListener('message', (e) => {
        clearTimeout(timer);
        if (e.data) {
            window.open(e.data, '_blank');
        }
        onEnd();
    });
};

// List of JS symbols which is allowed for untrusted code.
const allowedApis = [
    'console',
    'Math',
    'parseInt',
    'parseFloat',
    'Object',
    'JSON',
    'encodeURI',
    'encodeURIComponent',
    'decodeURI',
    'decodeURIComponent',
    // `atob` and `btoa` cannot be passed to proxy and should be invoked on real
    // `window` object.

    // `fetch()` is passed by specific code 'cause this function is requires specific
    // context and if we copy it into Object it will start to throw error: "Illegal
    // invocation." on each call.
    // `openWindow()` and `end()` also added by specific code.
];

// Remember: this code is executed in browser "as is", so check caniuse.com for
// availability of some features and don't use comments inside.
const getWorkerCode = (code: string): string => `
    const apis = {${allowedApis.join(',')}};

    const nativeFetch = this.fetch;
    apis.fetch = function (a, b) {
        return nativeFetch(a, b)
    }

    const nativePostMessage = this.postMessage;
    apis.openWindow = function (location) {
        if (!location) {
            console.error('openWindow(): provide location');
            return;
        }

        if (!isURL(location)) {
            console.error('openWindow(): provide valid URL');
            return;
        }

        nativePostMessage(location);
    }
    apis.end = function () {
        nativePostMessage('');
    }

    const nativeBtoa = this.btoa;
    apis.btoa = function (str) {
        return nativeBtoa(str);
    }

    const nativeAtob = this.atob;
    apis.atob = function (str) {
        return nativeAtob(str);
    }

    const sandboxProxy = new Proxy(Object.assign(apis), {
        has: function () {
           return true;
        },
        get: function (target, key) {
            if (key === Symbol.unscopables) {
                return undefined;
            }
            return target[key];
        },
    });

    Object.keys(this).forEach(key => {
        delete this[key];
    });

    this.Function = function() { return null };

    with (sandboxProxy) {
        (function() {
            try {
                ${code};
            } catch (e) {
                console.error(e);
                nativePostMessage('');
            }
        }).call(WorkerGlobalScope)
    }

    function isURL(str) {
        var pattern = new RegExp('^(https?:\\/\\/)','i');
        return !!pattern.test(str);
    }
`;

export default {
    run,
};
