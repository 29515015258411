// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import React from 'react';
import {
    Translate,
    Label,
} from '@plesk/ui-library';
import {
    Subtitle,
    TitleContent,
} from 'admin/update/components/VersionTitle/Styles';
import moment from 'moment';
import { INTENT_TYPE } from 'common/constants';

export interface IVersionTitleProps {
    version: string;
    date: string;
    isCurrentVersion: boolean;
}

export const VersionTitle: React.FC<IVersionTitleProps> = ({
    version,
    date,
    isCurrentVersion,
}) => {
    const releasedAt = date ? moment(date).format('D MMMM YYYY') : '';

    return (
        <>
            <TitleContent>
                <h3>
                    <Translate content="update.version" />
                    &nbsp;{version}
                </h3>
                {isCurrentVersion && (
                    <Label intent={INTENT_TYPE.INACTIVE}>
                        <Translate content="update.current" />
                    </Label>
                )}
            </TitleContent>
            <Subtitle>
                {releasedAt}
            </Subtitle>
        </>
    );
};
