// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import React from 'react';
import {
    CHANGE_TYPE,
    IChange,
} from 'common/api/resources/Update';
import {
    Label,
    Translate,
} from '@plesk/ui-library';
import { INTENT_TYPE } from 'common/constants';
import { ChangeContainer } from 'admin/update/components/Change/Styles';

export interface IChangeProps {
    item: IChange;
}

const Change: React.FC<IChangeProps> = ({
    item,
}) => (
    <ChangeContainer>
        {item.type === CHANGE_TYPE.FEATURE && (
            <Label intent={INTENT_TYPE.INFO}>
                <Translate content="update.changeType.feature" />
            </Label>
        )}
        {item.type === CHANGE_TYPE.IMPROVEMENT && (
            <Label intent={INTENT_TYPE.SUCCESS}>
                <Translate content="update.changeType.improvement" />
            </Label>
        )}
        {item.type === CHANGE_TYPE.ATTENTION && (
            <Label intent={INTENT_TYPE.DANGER}>
                <Translate content="update.changeType.attention" />
            </Label>
        )}
        {item.type === CHANGE_TYPE.BUGFIX && (
            <Label intent={INTENT_TYPE.WARNING}>
                <Translate content="update.changeType.bugfix" />
            </Label>
        )}
        <div>{item.text}</div>
    </ChangeContainer>
);

export default Change;
