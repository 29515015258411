// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';
import { COLORS } from 'admin/core/theme';

export const Row = styled.div`
  line-height: 24px;
  font-size: 14px;
  margin: 6px 0;
`;

export const StatusWrapper = styled.div`
  .pul-action {
    margin-left: 15px;
    vertical-align: middle;
    font-size: 14px;
    line-height: 20px;
    font-weight: normal;
  }
  
  .pul-status {
      color: ${COLORS.GREY_80};
  }
`;

export const Table = styled.table`
    td {
      &:first-child {
        font-size: 14px;
        line-height: 20px;
        color: ${COLORS.GREY_80};
      }
    
       padding-bottom: 12px;
       padding-right: 16px;
    }
    
    tr:first-child td {
       padding-bottom: 8px;
    }
`;
