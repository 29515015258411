// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';
import { COLORS } from 'common/theme';

export const Container = styled.div`
    margin-bottom: 85px;
`;

export const Paragraph = styled.div`
  margin-bottom: 20px;
`;

export const CheckBoxWrapper = styled.div`
    margin-top: 20px;
    
    .pul-checkbox {
      width: 100%;
    }

    .pul-checkbox__label {
      display: flex;
      align-items: center;
    }
  
    padding: 15px;
    border: 1px solid ${COLORS.GREY_40};
    border-radius: 4px;
    cursor: pointer;
`;

export const CheckBoxText = styled.div`
    padding-left: 10px;
`;
