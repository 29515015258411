// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { RootState } from 'client/core/store';
import { connect } from 'react-redux';
import {
    AdditionalPrice,
    PriceInfo,
    PriceItem,
    PriceTaxes,
    TermsAndConditions,
    TotalPrice,
} from 'client/common/components/PlanPrice/Styles';
import {
    Link,
    Translate,
} from '@plesk/ui-library';
import {
    formatPricePerHour,
    formatPricePerMonth,
} from 'common/helpers/token_pricing';
import { IAdditionalDiskRequest } from 'common/api/resources/ComputeResourceVm';
import { offerToPricePerMonth } from 'common/helpers/ProjectTokenCalculator';

export interface IPlanPriceProps {
    planId?: number;
    isBackupEnabled: boolean;
    additionalIps?: number;
    primaryDiskOfferId?: number;
    additionalDisks?: IAdditionalDiskRequest[];
}

export type PlanPriceProps =
    IPlanPriceProps &
    ReturnType<typeof mapStateToProps>;

export const PlanPrice: React.FC<PlanPriceProps> = ({
    planId,
    plans,
    project,
    offers,
    termsAndConditionsUrl,
    isBackupEnabled,
    additionalIps,
    primaryDiskOfferId,
    additionalDisks,
}) => {
    const plan = plans.data.find(item => item.id === planId);
    const primaryDiskOffer = offers.find(item => item.id === primaryDiskOfferId);
    const additionalDiskOffers = additionalDisks?.map(item => ({
        ...item,
        offer: offers.find(offer => offer.id === item.offer_id),
    }));

    const primaryDiskTokensPerMonth = primaryDiskOffer ? offerToPricePerMonth(primaryDiskOffer, plan, plan?.params.disk) : undefined;
    const additionalDisksTokensPerMonth = additionalDiskOffers
        ? additionalDiskOffers.reduce((acc, item) => {
            const diskPrice = offerToPricePerMonth(item.offer!, plan, item.size) ?? 0;
            return acc + diskPrice;
        }, 0)
        : undefined;

    const planPrice = React.useMemo(() => {
        if (project.token_pricing && plan) {
            const serverWithStoragePrice = plan.tokens_per_month + (additionalDisksTokensPerMonth ?? 0);

            return {
                per_month: formatPricePerMonth(plan.tokens_per_month, project.token_pricing),
                per_hour: formatPricePerHour(plan.tokens_per_hour, project.token_pricing),
                primary_disk_per_month: formatPricePerMonth(
                    (primaryDiskTokensPerMonth ?? 0),
                    project.token_pricing
                ),
                additional_disks_per_month: formatPricePerMonth(
                    (additionalDisksTokensPerMonth ?? 0),
                    project.token_pricing
                ),
                backup_per_month: formatPricePerMonth(
                    serverWithStoragePrice * (plan.backup_price / 100),
                    project.token_pricing
                ),
                additional_ips_per_month: formatPricePerMonth(
                    plan.ip_tokens_per_month * (additionalIps ?? 1),
                    project.token_pricing
                ),
            };
        }

        return undefined;
    }, [plan, project.token_pricing, additionalIps, primaryDiskTokensPerMonth, additionalDisksTokensPerMonth]);

    const totalPricePerMonth = React.useMemo(() => {
        if (!project.token_pricing || !plan) {
            return undefined;
        }

        const serverWithStoragePrice = plan.tokens_per_month + (additionalDisksTokensPerMonth ?? 0);

        let totalPrice = serverWithStoragePrice;

        if (primaryDiskTokensPerMonth) {
            totalPrice += primaryDiskTokensPerMonth;
        }

        if (isBackupEnabled) {
            totalPrice += serverWithStoragePrice * plan.backup_price / 100;
        }

        if (additionalIps) {
            totalPrice += plan.ip_tokens_per_month * additionalIps;
        }

        return formatPricePerMonth(totalPrice, project.token_pricing);
    }, [plan, isBackupEnabled, project.token_pricing, additionalIps, primaryDiskTokensPerMonth, additionalDisksTokensPerMonth]);

    if (!plan || !planPrice || !project.token_pricing) {
        return (<></>);
    }

    const taxesText = project.token_pricing.taxes_inclusive ? 'plan.price.taxesInclusive' : 'plan.price.taxesExclusive';
    const hasAdditionalIps = additionalIps !== undefined && additionalIps > 0;

    const additionalPrice = (
        <>
            | <PriceItem>
                <span>
                    {planPrice.per_month}
                </span>
                <Translate content="plan.price.server"/>
            </PriceItem>
            {primaryDiskOffer && <>
                + <PriceItem>
                    <span>
                        {planPrice.primary_disk_per_month}
                    </span>
                    <Translate content="plan.price.primaryDisk"/>
                </PriceItem>
            </>}
            {((additionalDisks ?? []).length > 0) && <>
                + <PriceItem>
                    <span>
                        {planPrice.additional_disks_per_month}
                    </span>
                    <Translate content="plan.price.additionalDisks"/>
                </PriceItem>
            </>}
            {isBackupEnabled && <>
                + <PriceItem>
                    <span>
                        {planPrice.backup_per_month}
                    </span>
                    <Translate content="plan.price.backup"/>
                </PriceItem>
            </>}
            {hasAdditionalIps && plan.is_additional_ips_available && <>
                + <PriceItem>
                    <span>
                        {planPrice.additional_ips_per_month}
                    </span>
                    <Translate content="plan.price.additionalIps"/>
                </PriceItem>
            </>}
        </>
    );

    return (
        <PriceInfo>
            <TotalPrice>
                <Translate content="plan.price.total" params={{
                    price: totalPricePerMonth,
                }}/><AdditionalPrice>{additionalPrice}</AdditionalPrice>
            </TotalPrice>
            {project.token_pricing.taxes.length > 0 && <PriceTaxes>
                <Translate content={taxesText} params={{
                    taxes: project.token_pricing.taxes.map(tax => tax.rate + '% ' + tax.label).join(', '),
                }}/>
            </PriceTaxes>}
            {termsAndConditionsUrl && <TermsAndConditions>
                <Translate content="plan.price.termsAndConditions.text" params={{
                    link: <Link href={termsAndConditionsUrl} target="blank">
                        <Translate content="plan.price.termsAndConditions.link"/>
                    </Link>,
                }}/>
            </TermsAndConditions>}
        </PriceInfo>
    );
};

const mapStateToProps = (state: RootState) => ({
    plans: state.plan.list,
    project: state.project.projects.item,
    offers: state.offer.list.data,
    termsAndConditionsUrl: state.settings.theme.terms_and_conditions_url,
});

export default connect(mapStateToProps)(PlanPrice);