// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as sshKeyActions from 'client/account/actions/sshKeys';
import { combineReducers } from 'redux';
import {
    ActionType,
    getType,
} from 'typesafe-actions';
import { ISshKeyResponse } from 'common/api/resources/SshKey';

interface ISshKeys {
    list: ISshKeyResponse[];
}

export type SshKeyAction = ActionType<typeof sshKeyActions>;
export type SshKeyState = Readonly<ISshKeys>;

export const initialSshKeyState: ISshKeyResponse = {
    id: 0,
    name: 'test',
    body: '',
    isLoading: false,
    is_deleting: false,
};

export default combineReducers<SshKeyState, SshKeyAction>({
    list: (state = [], action: SshKeyAction) => {
        switch (action.type) {
        case getType(sshKeyActions.appendSshKeyItem):
            return [...state, action.payload];
        case getType(sshKeyActions.removeSshKeyItem):
            return [...state.filter(item => item.id !== action.payload)];
        case getType(sshKeyActions.setSshKeys):
            return action.payload;
        case getType(sshKeyActions.setItemIsLoading):
            return [...state.map(item => {
                if (item.id === action.payload) {
                    return { ...item, isLoading: true };
                }

                return item;
            })];
        case getType(sshKeyActions.unsetItemIsLoading):
            return [...state.map(item => {
                if (item.id === action.payload) {
                    return { ...item, isLoading: false };
                }

                return item;
            })];
        default:
            return state;
        }
    },
});
