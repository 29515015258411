// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { initEchoConnection } from 'common/services/EchoService';
import { STORE_PERSIST_KEY } from 'common/redux/constants';
import {
    SOCKET_CHANNELS,
    SOCKET_EVENTS,
} from 'common/constants';
import {
    IMeta,
    meta,
} from 'common/api/resources/Meta';

const VERSION_ITEM = 'version';
class VersionUpdatedError extends Error {}

export const versionCheck = (version: string) => {
    if (localStorage.getItem(VERSION_ITEM) !== version) {
        localStorage.setItem(VERSION_ITEM, version);
        localStorage.removeItem(`persist:${STORE_PERSIST_KEY}`);
        // @ts-ignore We understand that this isn't a part of specification.
        // eslint-disable-next-line no-restricted-globals
        location.reload(true);
        throw new VersionUpdatedError('reloading...');
    }
};

export const beforeAppLoading = async () => {
    try {
        const metaResult = await meta.get();

        if ('application-version' in metaResult.headers) {
            versionCheck(metaResult.headers['application-version']);
        }

        const channel = initEchoConnection().channel(SOCKET_CHANNELS.APP);
        channel.listen(SOCKET_EVENTS.VERSION_UPDATED, (data: IMeta) => versionCheck(data.version));
    } catch (e) {
        if (e instanceof VersionUpdatedError) {
            throw e;
        }

        // eslint-disable-next-line no-console
        console.error(`Failed to initialize application: ${e}`);
    }
};
