// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';

export const DocumentationContainer = styled.div`
  pre {
      background: unset;
      color: unset;
      font-size: unset;
  }
`;
