// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';
import { COLORS } from 'common/theme';
import { Status as UIStatus } from '@plesk/ui-library';

export const Status = styled(UIStatus)`
  span:last-child {
    margin-left: ${props => props.progress === true ? '3px' : 0};
    font-size: 14px;
    color: ${COLORS.GREY_80};
  }
`;
