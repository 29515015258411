// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';

export const AuthLayoutContainer = styled.div`
    background: ${props => props.theme.primary_color};
    background-blend-mode: overlay, normal;
    justify-content: center;
    align-items: center;
    height: 100%;
    margin: 0 auto;
    display: flex;

    @media (max-width: 560px) {
        display: flex;
    }
`;
