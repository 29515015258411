// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { IShortUserResponse } from 'common/api/resources/User';
import { IIpBlockResponse } from 'common/api/resources/IpBlock';
import { IReverseDnsResponse } from 'common/api/resources/ReverseDns';

export enum IpIssuedFor {
    VM = 'vm',
    USER = 'user',
    GATEWAY = 'gateway',
    SERVERS_MIGRATION = 'servers_migration',
}

export interface IIpServerResponse {
    id: number;
    name: string;
}

export interface IIpResponse {
    id: number;
    ip: string;
    ip_block?: IIpBlockResponse;
    user: IShortUserResponse | null;
    server: IIpServerResponse | null;
    is_loading: boolean;
    comment: string;
    issued_for: IpIssuedFor;
    is_reverse_dns_enabled: boolean;
    reverse_dns: IReverseDnsResponse[];
    is_primary: boolean;
    is_reserved: boolean;
}

export interface IReserveIpRequest {
    ip: string;
    comment?: string;
}

export interface IAddIpRequest {
    ip: string;
    comment?: string;
}

export interface IAddIpsRequest {
    ips: string[];
}

export interface IPatchIpRequest {
    is_reserved: boolean;
}

export const primaryIps = (ips: IIpResponse[]): IIpResponse[] =>
    ips.filter(ip => ip.issued_for === IpIssuedFor.VM && ip.is_primary);
