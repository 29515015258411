// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { api } from 'common/api/resources/Response';
import { IPatchIpRequest } from 'common/api/resources/Ip/model';

export const ips = {
    remove: (id: number) => api.delete(`ips/${id}`),
    patch: (id: number, data: IPatchIpRequest) => api.patch(`ips/${id}`, data),
    removeBatch: (ids: number[]) => api.delete('ips', { data: { ids } }),
};
