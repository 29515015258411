// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { IPlanResponse } from 'common/api/resources/Plan';
import { IOsImageResponse } from 'common/api/resources/OsImage';
import { ILocationResponse } from 'common/api/resources/Location';
import { IApplicationResponse } from 'common/api/resources/Application';
import { ComputeResourceVmCustomPlan } from 'common/api/resources/ComputeResourceVm';
import { VirtualizationType } from 'common/api/resources/ComputeResource';
import { IOfferResponse } from 'common/api/resources/Offer';
import { IVpcNetworkResponse } from 'common/api/resources/VpcNetwork';
import { IOsImageVersionResponse } from 'common/api/resources/OsImageVersion';

export default class ManagerOfDisabledEntities {
    plans: IPlanResponse[];
    planId?: number;
    selectedPlan?: IPlanResponse;

    osImages: IOsImageResponse[];
    osImageVersionId?: number;
    osImageVersion?: IOsImageVersionResponse;

    applications: IApplicationResponse[];
    applicationId?: number;

    locations?: ILocationResponse[];
    location?: ILocationResponse;
    locationId?: number;

    offers?: IOfferResponse[];
    selectedOfferIds?: number[];
    selectedOffers?: IOfferResponse[];

    vpcNetworks?: IVpcNetworkResponse[];
    selectedVpcNetworkIds?: number[];
    selectedVpcNetworks?: IVpcNetworkResponse[];

    constructor(
        plans: IPlanResponse[],
        osImages: IOsImageResponse[],
        applications: IApplicationResponse[],
        planId?: number,
        osImageVersionId?: number,
        applicationId?: number,
        locations?: ILocationResponse[],
        locationId?: number,
        offers?: IOfferResponse[],
        selectedOfferIds?: number[],
        vpcNetworks?: IVpcNetworkResponse[],
        selectedVpcNetworkIds?: number[]
    ) {
        this.plans = plans;
        this.planId = planId;
        this.osImages = osImages;
        this.osImageVersionId = osImageVersionId;
        this.applications = applications;
        this.applicationId = applicationId;
        this.locations = locations;
        this.locationId = locationId;
        this.location = locations?.find(location => location.id === this.locationId && location.available_plans !== undefined);
        this.offers = offers;
        this.selectedOfferIds = selectedOfferIds;
        this.selectedOffers = offers?.filter(offer => this.selectedOfferIds?.includes(offer.id));

        this.vpcNetworks = vpcNetworks;
        this.selectedVpcNetworkIds = selectedVpcNetworkIds;
        this.selectedVpcNetworks = vpcNetworks?.filter(vpcNetwork => this.selectedVpcNetworkIds?.includes(vpcNetwork.id));
    }

    getDisabledPlanIds = () => this.plans.filter(plan => {
        // Check if plan is disabled by OS image
        // If plans `available_os_image_versions` does not contain selected `osImageVersionId`, disable it
        if (this.osImageVersionId && !plan.available_os_image_versions?.some(item => item.id === this.osImageVersionId)) {
            return true;
        }

        // Check if plan is disabled by application
        // If plans `available_applications` does not contain selected `applicationId`, disable it
        if (this.applicationId && !plan.available_applications?.some(item => item.id === this.applicationId)) {
            return true;
        }

        // Check if plan is disabled by location
        // If plans `available_locations` does not contain selected `locationId`, disable it
        if (this.locationId && !plan.available_locations?.some(item => item.id === this.locationId)) {
            return true;
        }

        // Check if plan is disabled by offers
        // if any of selected offers `available_plans` does not contain this plan, disable it
        const disabledByOffers = this.selectedOffers && this.selectedOffers.length > 0
            && this.selectedOffers?.every(offer => !offer.available_plans?.some(item => item.id === plan.id));

        return disabledByOffers;
    }).map(item => item.id);

    getDisabledOsImageVersionIds = (customPlan?: ComputeResourceVmCustomPlan) =>
        this.osImages.map(item => item.versions).flat(1).filter(osImageVersion => {
            // Check if OS image version is disabled by custom plan
            // Any OS image version is available with custom plan if it has the same virtualization type
            if (customPlan !== undefined) {
                return osImageVersion.virtualization_type !== customPlan.virtualization_type;
            }

            // Check if OS image version is disabled by plan
            // If OS image version `available_plans` does not contain selected `planId`, disable it
            if (this.planId && !osImageVersion.available_plans?.some(item => item.id === this.planId)) {
                return true;
            }

            if (this.location) {
                for (const availablePlan of this.location?.available_plans!) {
                    if (osImageVersion.available_plans?.some(item => item.id === availablePlan.id)) {
                        return false;
                    }
                }

                return true;
            }

            return false;
        }).map(item => item.id);

    getDisabledApplicationIds = (customPlan?: ComputeResourceVmCustomPlan) => this.applications.filter(application => {
        // Check if application is disabled by custom plan
        // Any application is available with custom plan if it has the same virtualization type
        if (customPlan !== undefined) {
            return customPlan.virtualization_type === VirtualizationType.VZ;
        }

        // Check if application is disabled by plan
        // If application `available_plans` does not contain selected `planId`, disable it
        if (this.planId && !application.available_plans?.some(item => item.id === this.planId)) {
            return true;
        }

        if (this.location) {
            for (const availablePlan of this.location?.available_plans!) {
                if (application.available_plans?.some(item => item.id === availablePlan.id)) {
                    return false;
                }
            }

            return true;
        }

        return false;
    }).map(item => item.id);

    getDisabledLocationIds = () => this.locations !== undefined ? this.locations.filter(location => {
        // Check if location is disabled by plan
        // If location `available_plans` does not contain selected `planId`, disable it
        if (this.planId && !location.available_plans?.some(item => item.id === this.planId)) {
            return true;
        }

        // Check if location is disabled by offers
        // If any of selected offers does not have this location, disable it
        const disabledByOffers = this.selectedOffers && this.selectedOffers.length > 0
            && this.selectedOffers?.every(offer => !offer.available_locations?.some(item => item.id === location.id));
        if (disabledByOffers) {
            return true;
        }

        // Check if location is disabled by VPC networks
        // If selected VPC networks are available only in other locations, disable it
        const disabledByVpcNetworks = this.selectedVpcNetworks && this.selectedVpcNetworks.length > 0
            && this.selectedVpcNetworks?.every(vpcNetwork => vpcNetwork.location.id !== location.id);
        if (disabledByVpcNetworks) {
            return true;
        }

        if (location.available_plans !== undefined && (this.osImageVersionId || this.applicationId)) {
            for (const availablePlan of location.available_plans) {
                if (this.osImageVersionId && this.plans.find(plan => plan.id === availablePlan.id
                    && plan?.available_os_image_versions?.some(item => item.id === this.osImageVersionId)
                )) {
                    return false;
                }

                if (this.applicationId && this.plans.find(plan => plan.id === availablePlan.id
                    && plan?.available_applications?.some(item => item.id === this.applicationId)
                )) {
                    return false;
                }
            }

            return true;
        }

        return false;
    }).map(item => item.id) : [];

    getDisabledOfferIds = () => this.offers !== undefined ? this.offers.filter(offer => {
        // Check if offer is disabled by plan
        // If offer `available_plans` does not contain selected `planId`, disable it
        if (this.planId && !offer.available_plans?.some(item => item.id === this.planId)) {
            return true;
        }

        // Check if offer is disabled by location
        // If offer `available_locations` does not contain selected `locationId`, disable it
        const disabledByLocation = this.locationId && !offer.available_locations?.some(item => item.id === this.locationId);

        return disabledByLocation;
    }).map(item => item.id) : [];

    getDisabledVpcNetworkIds = () => this.vpcNetworks !== undefined ? this.vpcNetworks.filter(vpcNetwork => {
        if (!this.locationId) {
            return false;
        }

        return vpcNetwork.location.id !== this.locationId;
    }).map(item => item.id) : [];
}