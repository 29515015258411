// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import {
    api,
    IApiResponse,
    IPaginateApiResponse,
} from 'common/api/resources/Response';
import {
    IPreCheckResponse,
    IServerMigrationRequest,
    IServersMigrationResponse,
} from 'common/api/resources/ServersMigration/model';
import { CancelTokenSource } from 'axios';
import { QueryBuilder } from 'common/api/resources/Request/QueryBuilder';
import { IPaginatedWithSearch } from 'common/api/resources/Request/request';

export const serversMigrations = {
    list: (params?: IPaginatedWithSearch, cancelToken?: CancelTokenSource) => api.get<IPaginateApiResponse<IServersMigrationResponse[]>>('servers_migrations', {
        params: new QueryBuilder(params).build(),
        cancelToken: cancelToken?.token,
    }),
    create: (data: IServerMigrationRequest) => api.post<IApiResponse<IServersMigrationResponse>>('servers_migrations', data),
    remove: (id: number) => api.delete(`servers_migrations/${id}`),
    removeBatch: (ids: number[]) => api.delete('servers_migrations', { data: { ids } }),
    preCheck: (ids: number[]) => api.get<IApiResponse<IPreCheckResponse>>('servers_migrations/pre_check', { params: { ids } }),
};
