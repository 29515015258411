// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

type InsecureAction = () => Promise<unknown>|void;

const defaultInsecureAction: InsecureAction = ()=> {};
let insecureAction: InsecureAction = defaultInsecureAction;

export const setAndCallInsecureAction = async (action: InsecureAction) => {
    insecureAction = action;
    await callInsecureAction();
};

export const callInsecureAction = async () => {
    await insecureAction();
    clearInsecureAction();
};

export const clearInsecureAction = () => {
    insecureAction = defaultInsecureAction;
};
