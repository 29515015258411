// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import {
    IOfferResponse,
    IShortOfferResponse,
    OfferPriceMode,
} from 'common/api/resources/Offer';
import { IPlanResponse } from 'common/api/resources/Plan';

export const offerToPricePerMonth = (offer: IOfferResponse|IShortOfferResponse, plan?: IPlanResponse, sizeGib?: number): number | undefined => {
    switch (offer.price_mode) {
    case OfferPriceMode.PER_UNIT:
        return offer.per_month;
    case OfferPriceMode.PERCENT:
        return plan
            ? plan.tokens_per_month * offer.per_month / 100
            : undefined;
    case OfferPriceMode.PER_GIB:
        return sizeGib
            ? offer.per_month * sizeGib
            : undefined;
    default:
        return 0;
    }
};

export const offerToPricePerHour = (offer: IOfferResponse|IShortOfferResponse, plan?: IPlanResponse, sizeGib?: number): number | undefined => {
    switch (offer.price_mode) {
    case OfferPriceMode.PER_UNIT:
        return offer.per_hour;
    case OfferPriceMode.PER_GIB:
        return sizeGib
            ? offer.per_hour * sizeGib
            : undefined;
    case OfferPriceMode.PERCENT:
        return plan
            ? plan.tokens_per_hour * offer.per_hour / 100
            : undefined;
    default:
        return 0;
    }
};