// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { FormFieldNumber } from 'common/components/Form/FormFieldNumber/FormFieldNumber';
import { SIZE } from 'common/constants';
import {
    Column,
    Columns,
    FormFieldSelect,
    Translate,
} from '@plesk/ui-library';
import { VirtualizationType } from 'common/api/resources/ComputeResource';
import { DataUnit } from 'common/helpers/units';
import { FIELDS } from 'common/components/plan/PlanForm';

export interface IParamsColumnsProps {
    virtualizationType: VirtualizationType;
    minRam: number;
    ramUnit: string;
    setRamUnit: (unit: string) => void;
    minDisk?: number;
    fields?: string[];
}

export const ParamsColumns: React.FC<IParamsColumnsProps> = ({
    virtualizationType,
    minRam,
    ramUnit,
    setRamUnit,
    minDisk = 1,
    fields= [
        FIELDS.DISK,
        FIELDS.RAM,
        FIELDS.VCPU,
        FIELDS.VCPU_UNITS,
        FIELDS.VCPU_LIMIT,
        FIELDS.IO_PRIORITY,
        FIELDS.SWAP,
    ],
}) => (
    <>
        <Columns gap={SIZE.MD} vertical={false}>
            {fields.includes(FIELDS.VCPU) && (
                <Column width={219}>
                    <FormFieldNumber
                        id="params[vcpu]"
                        name="params[vcpu]"
                        label={
                            <Translate content="plan.actionDialog.planCpu" />
                        }
                        fullDescription={
                            <Translate content="plan.actionDialog.planCpuDescription" />
                        }
                        vertical
                        min={1}
                        max={64}
                        size={SIZE.FILL}
                    />
                </Column>
            )}
            {fields.includes(FIELDS.DISK) && (
                <Column width={219}>
                    <FormFieldNumber
                        id="params[disk]"
                        name="params[disk]"
                        label={
                            <Translate content="plan.actionDialog.planDisk" />
                        }
                        vertical
                        min={minDisk}
                        size={SIZE.FILL}
                    />
                </Column>
            )}
        </Columns>
        {fields.includes(FIELDS.RAM) && (
            <Columns gap={SIZE.MD} vertical={false}>
                <Column width={219}>
                    <FormFieldNumber
                        id="params[ram]"
                        name="params[ram]"
                        label={
                            <Translate content="plan.actionDialog.planRam" />
                        }
                        vertical
                        min={minRam}
                        size={SIZE.FILL}
                    />
                </Column>
                <Column width={150}>
                    <FormFieldSelect
                        id="ram_unit"
                        size={SIZE.XS}
                        label={
                            <Translate content="plan.actionDialog.planRamUnit" />
                        }
                        vertical
                        value={ramUnit}
                        onChange={setRamUnit}
                    >
                        {[DataUnit.MiB, DataUnit.GiB].map((value) => (
                            <option key={value} value={value}>
                                {value}
                            </option>
                        ))}
                    </FormFieldSelect>
                </Column>
            </Columns>
        )}
        <Columns gap={SIZE.MD} vertical={false}>
            {fields.includes(FIELDS.VCPU_UNITS) && (
                <Column width={219}>
                    <FormFieldNumber
                        id="params[vcpu_units]"
                        name="params[vcpu_units]"
                        label={
                            <Translate content="plan.actionDialog.planCpuUnits" />
                        }
                        fullDescription={
                            <>
                                <Translate content="plan.actionDialog.planCpuUnitsDescription" />
                                &nbsp;
                                <a
                                    href="https://docs.virtuozzo.com/virtuozzo_hybrid_server_7_users_guide/managing-resources/managing-cpu-resources.html#configuring-cpu-units"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <Translate content="plan.actionDialog.documentation" />
                                </a>
                            </>
                        }
                        vertical
                        min={8}
                        max={500000}
                        disabled={virtualizationType !== VirtualizationType.VZ}
                        size={SIZE.FILL}
                    />
                </Column>
            )}
            {fields.includes(FIELDS.VCPU_LIMIT) && (
                <Column width={219}>
                    <FormFieldNumber
                        id="params[vcpu_limit]"
                        name="params[vcpu_limit]"
                        label={
                            <Translate content="plan.actionDialog.planCpuLimits" />
                        }
                        fullDescription={
                            <>
                                <Translate content="plan.actionDialog.planCpuLimitDescription" />
                                &nbsp;
                                <a
                                    href="https://docs.virtuozzo.com/virtuozzo_hybrid_server_7_users_guide/managing-resources/managing-cpu-resources.html#configuring-cpu-limits-for-virtual-machines-and-containers"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <Translate content="plan.actionDialog.documentation" />
                                </a>
                            </>
                        }
                        vertical
                        min={10}
                        max={6400}
                        disabled={virtualizationType !== VirtualizationType.VZ}
                        size={SIZE.FILL}
                    />
                </Column>
            )}
        </Columns>
        <Columns gap={SIZE.MD} vertical={false}>
            {fields.includes(FIELDS.IO_PRIORITY) && (
                <Column width={219}>
                    <FormFieldNumber
                        id="params[io_priority]"
                        name="params[io_priority]"
                        label={
                            <Translate content="plan.actionDialog.planIOPriority" />
                        }
                        fullDescription={
                            <>
                                <Translate content="plan.actionDialog.planIOPriorityDescription" />
                                &nbsp;
                                <a
                                    href="https://docs.virtuozzo.com/virtuozzo_hybrid_server_7_users_guide/managing-resources/managing-disk-io-parameters.html#configuring-priority-levels-for-virtual-machines-and-containers"
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <Translate content="plan.actionDialog.documentation" />
                                </a>
                            </>
                        }
                        vertical
                        min={0}
                        max={7}
                        disabled={virtualizationType !== VirtualizationType.VZ}
                        size={SIZE.FILL}
                    />
                </Column>
            )}
            {fields.includes(FIELDS.SWAP) && (
                <Column width={219}>
                    <FormFieldNumber
                        id="params[swap]"
                        name="params[swap]"
                        label={
                            <Translate content="plan.actionDialog.planSwap" />
                        }
                        vertical
                        min={0}
                        disabled={virtualizationType !== VirtualizationType.VZ}
                        size={SIZE.FILL}
                    />
                </Column>
            )}
        </Columns>
    </>
);

export default ParamsColumns;
