// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    Action,
    Icon,
    Tooltip,
} from '@plesk/ui-library';
import { COLORS } from 'admin/core/theme';

export interface ISetAsDefaultActionProps {
    isDefault: boolean;
    isLoading: boolean;
    onClick: () => void;
    title: React.ReactNode;
    'data-cy'?: string;
}

export const SetAsDefaultAction: React.FC<ISetAsDefaultActionProps> = ({
    isDefault,
    isLoading,
    title,
    onClick,
    'data-cy': dataCy,
}) => (
    <Tooltip title={title}>
        <Action
            icon={
                <Icon
                    name={isDefault ? 'star-filled' : 'star'}
                    style={{ color: isDefault ? COLORS.PRIMARY : COLORS.GREY_80 }}
                />
            }
            progress={isLoading}
            className="action-icon"
            onClick={onClick}
            data-cy={dataCy}
        />
    </Tooltip>
);
