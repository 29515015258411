// Copyright 1999-2024. WebPros International GmbH. All rights reserved.
import {
    LimitName,
    NetworkTrafficLimitTypes,
} from 'common/api/resources/Plan';
import { ItemList } from 'common/components/ServerTabs/NetworkingTab/Styles';
import {
    INTENT_TYPE,
    SIZE,
} from 'common/constants';
import TotalTrafficCard from 'common/components/ServerTabs/NetworkingTab/components/TrafficCard/TotalTrafficCard';
import TrafficCard from 'common/components/ServerTabs/NetworkingTab/components/TrafficCard/TrafficCard';
import { Translate } from '@plesk/ui-library';
import { dataCySelector } from 'common/tests/selectors';
import { TABLE_ACTIONS } from 'common/components/ServerTabs/NetworkingTab/constants/test';
import {
    ComputeResourceVmStatus,
    IVmResponse,
} from 'common/api/resources/ComputeResourceVm';
import * as React from 'react';
import ButtonWithConfirmation from 'common/components/ButtonWithConfirmation';
import { connect } from 'react-redux';
import { RootState } from 'client/core/store';
import { hasPermission } from 'common/modules/permission/selectors';
import { PERMISSION_LIST } from 'common/modules/permission/constants';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import * as computeResourceVmActions from 'common/modules/computeResourceVm/actions';

export interface INetworkUsageProps {
    server: IVmResponse;
}

export type NetworkUsageProps = INetworkUsageProps
    & ReturnType<typeof mapStateToProps>
    & ReturnType<typeof mapDispatchToProps>;

export const NetworkUsage: React.FC<NetworkUsageProps> = ({
    server,
    canResetUsage,
    isResettingUsage,
    resetUsage,
}) => {
    const handleResetUsage = () => resetUsage(server.id);

    return (
        <>
            {server.plan.network_traffic_limit_type === NetworkTrafficLimitTypes.Total && (
                <ItemList
                    xs={1}
                    sm={1}
                    md={1}
                    xl={2}
                    lg={2}
                    gap={SIZE.MD}
                    stretchable={true}
                >
                    <TotalTrafficCard
                        incomingTraffic={server.usage.network.incoming}
                        outgoingTraffic={server.usage.network.outgoing}
                        limit={server.plan.limits[LimitName.NetworkTotalTraffic]}
                    />
                </ItemList>
            )}
            {server.plan.network_traffic_limit_type === NetworkTrafficLimitTypes.Separate && (
                <ItemList
                    xs={1}
                    sm={1}
                    md={2}
                    xl={2}
                    lg={2}
                    gap={SIZE.MD}
                    stretchable={true}
                >
                    <TrafficCard
                        networkTraffic={server.usage.network.outgoing}
                        limit={server.plan.limits[LimitName.NetworkOutgoingTraffic]}
                        title={
                            <Translate content="servers.tabs.networking.traffic.outgoing.title" />
                        }
                        description={
                            <Translate content="servers.tabs.networking.traffic.outgoing.description"/>
                        }
                        exceededDescription={
                            <Translate content="servers.tabs.networking.traffic.outgoing.exceededDescription"/>
                        }
                    />
                    <TrafficCard
                        networkTraffic={server.usage.network.incoming}
                        limit={server.plan.limits[LimitName.NetworkIncomingTraffic]}
                        title={
                            <Translate content="servers.tabs.networking.traffic.incoming.title" />
                        }
                        description={
                            <Translate content="servers.tabs.networking.traffic.incoming.description"/>
                        }
                        exceededDescription={
                            <Translate content="servers.tabs.networking.traffic.incoming.exceededDescription"/>
                        }
                    />
                </ItemList>
            )}
            {canResetUsage && (
                <ButtonWithConfirmation
                    data-cy={dataCySelector(server.id, TABLE_ACTIONS.RESET_TRAFFIC)}
                    disabled={server.is_processing}
                    confirmationButtonGhost={false}
                    translations={{
                        text: (
                            <Translate content="servers.tabs.networking.traffic.resetPopover.text" />
                        ),
                        button: (
                            <Translate content="servers.tabs.networking.traffic.resetPopover.confirmationButton"/>
                        ),
                        title: (
                            <Translate content="servers.tabs.networking.traffic.resetPopover.title" />
                        ),
                    }}
                    handleConfirm={() => handleResetUsage()}
                    isLoading={isResettingUsage || server.status === ComputeResourceVmStatus.USAGE_RESETTING}
                    confirmationButtonText={
                        <Translate content="servers.tabs.networking.traffic.resetPopover.button"/>
                    }
                    buttonIntent={INTENT_TYPE.PRIMARY}
                    buttonSize={SIZE.LG}
                    withStyledButton={true}
                    placement="top-right"
                />
            )}
        </>
    );
};


const mapStateToProps = (state: RootState) => ({
    canResetUsage: hasPermission(state, PERMISSION_LIST.MANAGE_SERVERS),
    isResettingUsage: state.app.loadingFlags.has(LOADING_FLAGS.COMPUTE_RESOURCE_VM_RESET_USAGE),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    resetUsage: bindActionCreators(computeResourceVmActions.resetComputeResourceVmUsage, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(NetworkUsage);