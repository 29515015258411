// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import * as clusterImportActions from 'admin/clusterImport/actions';
import { connect } from 'react-redux';
import { RootState } from 'admin/core/store';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import {
    Column,
    Columns,
    Form,
    FormFieldText,
    Section,
    setIn,
    Translate,
} from '@plesk/ui-library';
import {
    INTENT_TYPE,
    SIZE,
} from 'common/constants';
import { Loader } from 'common/components';
import { getProcessedErrors } from 'common/modules/app/formErrors/selectors';
import {
    ClusterImportCredentialsType,
    IClusterImportRequest,
} from 'common/api/resources/ClusterImport';
import { Button } from 'admin/common/components/Button/Button';
import { FixedMultilineFormText } from 'common/components/styles/FixedMultilineFromText';
import SSHGenerateButton from 'common/components/SSHGenerateButton/SSHGenerateButton';
import {
    numericRule,
    requiredRule,
    validate,
} from 'common/validator';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import { SSHGenerateButtonContainer } from 'common/components/SSHGenerateButton/Styles';

export interface IClusterImportFormProps {
    onSubmit: () => void;
}

export type ClusterImportFormProps = IClusterImportFormProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const ClusterImportForm: React.FC<ClusterImportFormProps> = ({
    onSubmit,
    clusterImport,
    userEmail,
    isLoading,
    formErrors,
    formErrorsActions: {
        setFormErrors,
    },
    clusterImportActions: {
        createClusterImport,
        prepareClusterImport,
    },
}) => {
    const [submitValues, setSubmitValues] = React.useState<IClusterImportRequest>({
        name: clusterImport.name,
        configuration: clusterImport.configuration,
        credentials: {
            type: ClusterImportCredentialsType.KEY,
            data: {
                login: 'root',
                port: 22,
                key: '',
            },
        },
    });

    const handleSubmit = async (data: IClusterImportRequest) => {
        const errors = validate<IClusterImportRequest>(data, {
            name: requiredRule(<Translate content="validate.fieldRequired" />),
            'configuration.host': requiredRule(<Translate content="validate.fieldRequired" />),
            'configuration.port': numericRule(<Translate content="validate.fieldRequired" />),
            'credentials.data.login': requiredRule(<Translate content="validate.fieldRequired" />),
            'credentials.data.port': numericRule(<Translate content="validate.fieldRequired" />),
            'credentials.data.key': requiredRule(<Translate content="validate.fieldRequired" />),
        });

        if (Object.keys(errors).length) {
            setFormErrors(errors);
            return;
        }

        if (!clusterImport.id) {
            await createClusterImport(data);
        } else {
            await prepareClusterImport(clusterImport.id, data);
        }

        onSubmit();
    };

    const handleFieldChange = (field: string, value: string) => setSubmitValues(setIn(submitValues, field, value));

    const handleSSHKeyGenerateSuccess = (_: string, key: string) => {
        setSubmitValues((values) => ({
            ...values,
            key,
        }));
    };

    return (
        <Loader isLoading={isLoading} center={false}>
            <Form
                id="clusterImportForm"
                footerClassName="hidden"
                values={submitValues}
                errors={formErrors}
                submitButton={false}
                cancelButton={false}
                applyButton={false}
                hideRequiredLegend={true}
                vertical={true}
                onSubmit={handleSubmit}
                onFieldChange={handleFieldChange}
            >
                <Section>
                    <FormFieldText
                        name="name"
                        description={<Translate content="clusterImport.form.nameDescription" />}
                        size={SIZE.FILL}
                        inputProps={{
                            maxLength: 63,
                        }}
                        required={true}
                        label={<Translate content="clusterImport.form.name" />}
                    />
                    <Columns gap={SIZE.LG} vertical={false}>
                        <Column fill={true}>
                            <FormFieldText
                                size={SIZE.FILL}
                                name="configuration[host]"
                                description={<Translate content="clusterImport.form.configuration.hostDescription" />}
                                label={<Translate content="clusterImport.form.configuration.host" />}
                                required={true}
                                vertical
                            />
                        </Column>
                        <Column width={85}>
                            <FormFieldText
                                name="configuration[port]"
                                size={SIZE.FILL}
                                label={<Translate content="clusterImport.form.configuration.port" />}
                                required={true}
                                vertical
                            />
                        </Column>
                    </Columns>
                    <Columns gap={SIZE.LG} vertical={false}>
                        <Column fill={true}>
                            <FormFieldText
                                name="credentials[data][login]"
                                size={SIZE.FILL}
                                label={<Translate content="clusterImport.form.credentials.login" />}
                                disabled={false}
                                required={true}
                                vertical
                            />
                        </Column>
                        <Column width={85}>
                            <FormFieldText
                                name="credentials[data][port]"
                                size={SIZE.FILL}
                                label={<Translate content="clusterImport.form.credentials.port" />}
                                required={true}
                                vertical
                            />
                        </Column>
                    </Columns>
                    <FixedMultilineFormText height="200px">
                        <FormFieldText
                            size={SIZE.FILL}
                            name="credentials[data][key]"
                            multiline={true}
                            required={true}
                            label={<Translate content="clusterImport.form.credentials.key" />}
                        />
                    </FixedMultilineFormText>
                    <SSHGenerateButtonContainer>
                        <SSHGenerateButton
                            tooltip={<Translate content="clusterImport.form.sshGenerate.tooltip"/>}
                            email={userEmail}
                            onSuccessfulGenerate={handleSSHKeyGenerateSuccess}
                        >
                            <Translate content="clusterImport.form.sshGenerate.title"/>
                        </SSHGenerateButton>
                    </SSHGenerateButtonContainer>
                </Section>
                <Button
                    type="submit"
                    form="clusterImportForm"
                    fill={true}
                    intent={INTENT_TYPE.PRIMARY}
                    size={SIZE.LG}
                    isLoading={isLoading}
                >
                    {clusterImport.id ? (
                        <Translate content="clusterImport.form.editBtn" />
                    ) : (
                        <Translate content="clusterImport.form.addBtn" />
                    )}
                </Button>
            </Form>
        </Loader>
    );
};

const mapStateToProps = (state: RootState) => ({
    clusterImport: state.clusterImport.item,
    userEmail: state.auth.user.email,
    formErrors: getProcessedErrors(state),
    isLoading: state.app.loadingFlags.has(LOADING_FLAGS.CLUSTER_IMPORT_CREATE),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    clusterImportActions: bindActionCreators(clusterImportActions, dispatch),
    formErrorsActions: bindActionCreators(formErrorsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ClusterImportForm);