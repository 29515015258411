// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    Form,
    FormFieldText,
    Section,
    Translate,
} from '@plesk/ui-library';
import isEmpty from 'validator/lib/isEmpty';
import { ISnapshotRequest } from 'common/api/resources/Snapshot';
import { validate } from 'common/validator';
import { StyledForm } from 'client/common/styles/Form';
import moment from 'moment';
import { PopoverFormContent } from 'client/common/styles/PopoverForm';
import { HeaderButton } from 'client/common/components/HeaderButton/HeaderButton';
import { bindedActionCreators } from 'common/redux/types';
import * as snapshotsServerActions from 'common/modules/snapshot/actions';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import { IFormErrors } from 'common/modules/app/formErrors/reducer';
import { ICONS } from 'common/constants';

export interface ISnapshotFormProps {
    serverId: number;
    onCreated: () => void;
    isSaving: boolean;
    formErrors: IFormErrors;
    snapshotActions: bindedActionCreators<typeof snapshotsServerActions>;
    formErrorsActions: bindedActionCreators<typeof formErrorsActions>;
}

const defaultValues = {
    name: '',
};

export const SnapshotForm: React.FC<ISnapshotFormProps> = ({
    serverId,
    onCreated,
    isSaving,
    formErrors,
    snapshotActions: { createSnapshot },
    formErrorsActions: { setFormErrors, clearFormErrors },
}) => {
    const [submitValues, setSubmitValues] = React.useState({ ...defaultValues });

    React.useEffect(() => {
        setSubmitValues({ name: moment().format('DD-MM-YYYY HH:mm:ss') });

        return () => {
            clearFormErrors();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleSubmit = async (values: ISnapshotRequest) => {
        const validationErrors = validate<ISnapshotRequest>(values, {
            name: {
                validator: isEmpty,
                message: <Translate content="validate.fieldRequired" />,
                comparison: true,
            },
        });

        if (Object.keys(validationErrors).length) {
            setFormErrors(validationErrors);
            return;
        }

        try {
            await createSnapshot(serverId, values);
            onCreated();
        } catch (e) {
            throw e;
        }
    };

    return (
        <PopoverFormContent>
            <Section title={<Translate content="snapshots.createSnapshot" />}>
                <StyledForm>
                    <Form
                        id="snapshotForm"
                        values={submitValues}
                        errors={formErrors}
                        onSubmit={handleSubmit}
                        hideRequiredLegend={true}
                        submitButton={false}
                        cancelButton={false}
                        applyButton={false}
                        vertical={true}
                    >
                        <FormFieldText
                            name="name"
                            placeholder="Name"
                            errors={formErrors}
                            autoFocus={true}
                            size="fill"
                            label={null}
                        />
                        <HeaderButton
                            icon={ICONS.CAMERA}
                            type="submit"
                            isLoading={isSaving}
                            form="snapshotForm"
                        >
                            <Translate content="snapshots.create" />
                        </HeaderButton>
                    </Form>
                </StyledForm>
            </Section>
        </PopoverFormContent>
    );
};
