// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import { RootState } from 'admin/core/store';
import {
    copyToClipboard,
    Form,
    FormField,
    FormFieldText,
    Icon,
    Paragraph,
    Popover,
    Section,
    setIn,
    Translate,
} from '@plesk/ui-library';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import * as apiTokenActions from 'admin/apiToken/actions';
import * as formErrorsActions from 'common/modules/app/formErrors/actions';
import { IApiTokenCreateRequest } from 'common/api/resources/ApiToken';
import {
    intMinRule,
    requiredRule,
    validate,
} from 'common/validator';
import CopyText from 'common/containers/CopyText/CopyText';
import AsyncSelectInput from 'common/components/Select/AsyncSelectInput';
import { ISelectOption } from 'common/components';
import {
    IUserResponse,
    users,
} from 'common/api/resources/User';
import { Button } from 'admin/common/components/Button/Button';
import {
    ICONS,
    INTENT_TYPE,
    SIZE,
} from 'common/constants';
import { createOptionsLoader } from 'common/components/Select/helpers';
import { EntityTagInputs } from 'admin/tag/components/EntityTagInputs';
import { ItemsLine } from 'common/components/styles/ItemsLine';
import { hasPermission } from 'common/modules/permission/selectors';
import { PERMISSION_LIST } from 'common/modules/permission/constants';
import * as tagActions from 'admin/tag/actions';
import { EntityType } from 'common/api/resources/Tag';

interface IApiTokenCreateFormProps {
    onSubmit: () => void;
}

type ApiTokenFormRequest = IApiTokenCreateRequest & {
    tag_value_ids: Array<number|undefined>;
};

const userToSelectOptions = (user: IUserResponse) => ({
    label: user.email,
    value: user.id.toString(),
});

export type ApiTokenCreateFormProps =
    IApiTokenCreateFormProps &
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

export const ApiTokenCreateForm: React.FC<ApiTokenCreateFormProps> = ({
    isSaving,
    formErrors,
    user,
    canManageTags,
    apiTokenActions: {
        createApiToken,
    },
    tagActions: {
        updateEntityTags,
    },
    formErrorsActions: {
        setFormErrors,
        clearFormErrors,
    },
    onSubmit,
}) => {
    const [submitValues, setSubmitValues] = React.useState<ApiTokenFormRequest>({
        user_id: user.id,
        name: '',
        tag_value_ids: [],
    });
    const [selectedUser, setSelectedUser] = React.useState<ISelectOption>(userToSelectOptions(user));

    const [token, setToken] = React.useState<string>();

    const handleSubmit = async (values: ApiTokenFormRequest) => {
        const errors = validate<ApiTokenFormRequest>(values, {
            name: requiredRule(<Translate content="validate.fieldRequired" />),
            user_id: intMinRule(<Translate
                content="validate.fieldRequired"
                params={{ min: 0 }}
            />, 0),
            ...Object.fromEntries(Object.entries(values.tag_value_ids).map(([idx]) =>
                [`tag_value_ids.${idx}`, requiredRule(<Translate content="validate.fieldRequired" />)])),
        });

        if (Object.keys(errors).length) {
            setFormErrors(errors);
            return;
        }

        const response = await createApiToken({
            name: values.name,
            // @ts-ignore values.user_id may actually be string.
            user_id: parseInt(values.user_id, 10),
        });

        if (values.tag_value_ids.length > 0) {
            await updateEntityTags({
                id: response.token.id,
                type: EntityType.TOKEN,
                tag_value_ids: values.tag_value_ids as number[],
            });
        }
        setToken(response.access_token);
        onSubmit();
    };

    const loadUserOptions = createOptionsLoader<IUserResponse>(
        users.list,
        userToSelectOptions
    );

    const handleCopyAndClose = () => {
        if (token) {
            copyToClipboard(token);
            clearFormErrors();
            onSubmit();
        }
    };

    const handleFieldChange = (field: string, value: string) => {
        setSubmitValues(setIn(submitValues, field, value));
    };

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const handleUserChange = (option: any) => {
        setSubmitValues((state) => ({
            ...state,
            user_id: option ? option.value : null,
        }));
        setSelectedUser(option);
    };

    const handleTagValueIdsChange = (tagValueIds: Array<number|undefined>) => {
        clearFormErrors();
        setSubmitValues({
            ...submitValues,
            tag_value_ids: tagValueIds,
        });
    };

    React.useEffect(() => () => {
        clearFormErrors();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    if (token) {
        return (
            <>
                <Section>
                    <Paragraph>
                        <Translate content="apiToken.tokenAddedText"/>
                    </Paragraph>
                    <CopyText width="380px">{token}</CopyText>
                </Section>
                <Button
                    onClick={handleCopyAndClose}
                    fill={true}
                    intent={INTENT_TYPE.PRIMARY}
                    size={SIZE.LG}
                >
                    <Translate content="apiToken.copyAndClose"/>
                </Button>
            </>
        );
    }

    return (
        <Form
            id="apiTokenForm"
            values={submitValues}
            errors={formErrors}
            onSubmit={handleSubmit}
            onFieldChange={handleFieldChange}
            footerClassName="hidden"
            hideRequiredLegend={true}
            submitButton={false}
            cancelButton={false}
            applyButton={false}
            vertical={true}
        >
            <Section>
                <FormFieldText
                    name="name"
                    autoFocus={true}
                    size={SIZE.FILL}
                    label={<Translate content="apiToken.form.name"/>}
                    required={true}
                />
                <FormField
                    name="user_id"
                    label={<Translate content="apiToken.form.user"/>}
                >
                    {({ getId }) => (
                        <AsyncSelectInput
                            inputId={getId()}
                            value={selectedUser}
                            placeholder={<Translate content="apiToken.form.user"/>}
                            loadOptions={loadUserOptions}
                            onChange={handleUserChange}
                            debounceTimeout={1000}
                            additional={{ page: 1 }}
                            menuPosition="fixed"
                            required={true}
                            isClearable={true}
                        />
                    )}
                </FormField>
                {canManageTags && (
                    <FormField
                        label={
                            <ItemsLine>
                                <Translate content="apiToken.form.tags" /><br/>
                                <Popover
                                    target={(
                                        <Icon name={ICONS.INFO_CIRCLE} size="12" />
                                    )}>
                                    <Translate content="apiToken.form.tagsInfo" />
                                </Popover>
                            </ItemsLine>
                        }
                    >
                        <EntityTagInputs
                            tags={[]}
                            onChange={handleTagValueIdsChange}
                            emptyViewText={<Translate content="apiToken.form.tagsEmptyViewText" />}
                        />
                    </FormField>
                )}
            </Section>
            <Button
                type="submit"
                isLoading={isSaving}
                form="apiTokenForm"
                fill={true}
                intent={INTENT_TYPE.PRIMARY}
                size={SIZE.LG}
            >
                <Translate content="apiToken.form.generateBtn"/>
            </Button>
        </Form>
    );
};

const mapStateToProps = (state: RootState) => ({
    user: state.auth.user,
    formErrors: state.app.formErrors,
    isSaving: state.app.loadingFlags.has(LOADING_FLAGS.SAVE_AUTH_API_TOKEN) ||
        state.app.loadingFlags.has(LOADING_FLAGS.TAGGABLE_ENTITY_SAVE),
    canManageTags: hasPermission(state, PERMISSION_LIST.MANAGE_TAGS),
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    apiTokenActions: bindActionCreators(apiTokenActions, dispatch),
    tagActions: bindActionCreators(tagActions, dispatch),
    formErrorsActions: bindActionCreators(formErrorsActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ApiTokenCreateForm);
