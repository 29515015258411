// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import {
    api,
    IApiResponse,
    IPaginateApiResponse,
} from 'common/api/resources/Response';
import { QueryBuilder } from 'common/api/resources/Request/QueryBuilder';
import {
    IOfferListRequest,
    IOfferPatchRequest,
    IOfferRequest,
    IOfferResponse,
} from 'common/api/resources/Offer/model';

export const offer = {
    list: (params?: IOfferListRequest) => api.get<IPaginateApiResponse<IOfferResponse[]>>('offers', {
        params: new QueryBuilder(params).build(),
    }),
    item: (id: number) => api.get<IApiResponse<IOfferResponse>>(`offers/${id}`),
    remove: (id: number) => api.delete(`offers/${id}`),
    create: (data: IOfferRequest) => api.post<IApiResponse<IOfferResponse>>('offers', data),
    update: (id: number, data: IOfferRequest) => api.put<IApiResponse<IOfferResponse>>(`offers/${id}`, data),
    patch: (id: number, data: IOfferPatchRequest) => api.patch<IApiResponse<IOfferResponse>>(`offers/${id}`, data),
};
