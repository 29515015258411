// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';

export const Usage = styled.div`
    padding-top: 6px;

    h3 {
     display: inline-block;
    }
`;
