// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import styled from 'styled-components';
import {
    FormFieldText,
    FormFieldPassword,
    Button,
} from '@plesk/ui-library';
import { COLORS } from 'admin/core/theme';
import { INTENT_TYPE } from 'common/constants';
import { FormFieldNumber } from 'common/components/Form/FormFieldNumber/FormFieldNumber';

export const InputWithRefreshButton = styled.div`
    display: flex;
    align-items: center;

    .pul-input__input {
        padding-right: 40px;
    }

    .pul-form-field__error {
        max-width: 350px;
    }

    .pul-button {
        margin-bottom: 8px;
        margin-left: -32px;
        margin-top: 10px;
        z-index: 1;
        align-self: flex-start;
    }
`;

export const InputText = styled(FormFieldText)`
    .pul-input__input {
        min-height: 40px;
    }
`;

const inputStyles = `
    padding-top: 30px;
    
    .pul-input__input {
        min-height: 40px;
    }
    
    .pul-section-item__title {
        margin-bottom: 16px;
    }
        
    label > span {
        color: ${COLORS.GREY_100} !important;
        font-size: 20px !important;
        font-weight: bold;
        line-height: 26px;
    }
`;

export const InputNumber = styled(FormFieldNumber)`${inputStyles}`;
export const InputPassword = styled(FormFieldPassword)`${inputStyles}`;
export const InputPrimaryIp = styled(FormFieldText)`${inputStyles}`;

export const SubmitButton = styled(Button)`
    ${props => props.intent === INTENT_TYPE.PRIMARY ? `
    background-color: ${COLORS.PRIMARY}!important;

    &:hover {
        background-color: ${COLORS.PRIMARY_HOVER}!important;
    }
    ` : ''}
`;

export const Footer = styled.div`
    display: flex;
    align-items: center;
    box-shadow: 0px -1px 3px rgba(60, 64, 67, 0.16), 0px -1px 1px rgba(60, 64, 67, 0.08);
    border-radius: 2px;
    height: 40px;
    bottom: 0;
    right: 0;
    background: ${COLORS.WHITE};

    margin: 40px -40px -40px -40px;
    padding: 20px;
`;

export const InlineFields = styled.div`
    display: flex;
    flex-wrap: wrap;

    > div {
        &:first-child {
            margin-right: 20px;
        }
    }
`;

export const ErrorMessage = styled.span`
  font-size: 12px;
  padding: 0 15px;
  color: ${COLORS.RED};
`;
