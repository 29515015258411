// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { ICONS } from 'common/constants';
import {
    LocationPlaceholderContainer,
    LocationPlaceholderText,
} from 'admin/location/components/LocationPlaceholder/Styles';
import {
    Translate,
    Icon,
} from '@plesk/ui-library';

const LocationPlaceholder: React.FC = () => (
    <LocationPlaceholderContainer>
        <Icon name={ICONS.GLOBE} />
        <LocationPlaceholderText>
            <Translate content="location.default" />
        </LocationPlaceholderText>
    </LocationPlaceholderContainer>
);

export default LocationPlaceholder;
