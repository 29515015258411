// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { IPaginatedWithSearch } from 'common/api/resources/Request/request';
import { CancelTokenSource } from 'axios';
import { Dispatch } from 'redux';
import {
    setIsLoading,
    unsetIsLoading,
} from 'common/modules/app/loadingFlags/actions';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import { HTTP_CODES } from 'common/api/constants';
import {
    CLUSTER_IMPORT_STATUS,
    clusterImports,
    IClusterImportComputeResourceResource,
    IClusterImportRequest,
    IClusterImportResponse,
} from 'common/api/resources/ClusterImport';
import { createCustomAction } from 'typesafe-actions';
import * as types from 'admin/clusterImport/constants/types';
import { IPaginateApiResponse } from 'common/api/resources/Response';
import {
    clearFormErrors,
    setFormErrors,
} from 'common/modules/app/formErrors/actions';
import { transformErrors } from 'common/validator';
import { remove } from 'common/actions/actionsWrapper';

export const setClusterImports = createCustomAction(
    types.SET_CLUSTER_IMPORT_LIST,
    (data: IPaginateApiResponse<IClusterImportResponse[]>) => ({ payload: data })
);
export const setClusterImport = createCustomAction(
    types.SET_CLUSTER_IMPORT_ITEM,
    (data: IClusterImportResponse) => ({ payload: data })
);

export const unsetClusterImport = createCustomAction(types.UNSET_CLUSTER_IMPORT_ITEM);

export const addClusterImportItem = createCustomAction(
    types.ADD_CLUSTER_IMPORT_ITEM,
    (data: IClusterImportResponse) => ({ payload: data })
);
export const updateClusterImportItem = createCustomAction(
    types.UPDATE_CLUSTER_IMPORT_ITEM,
    (id: number, data: IClusterImportResponse) => ({ payload: data })
);

export const setClusterImportItemIsDeleting = createCustomAction(
    types.SET_CLUSTER_IMPORT_IS_DELETING,
    (id: number, isDeleting: boolean) => ({ payload: { id, is_deleting: isDeleting } })
);

export const setClusterImportStatus = createCustomAction(
    types.SET_CLUSTER_IMPORT_STATUS,
    (id: number, status: CLUSTER_IMPORT_STATUS) => ({ payload: { id, status } })
);

export const removeClusterImportItem = createCustomAction(
    types.REMOVE_CLUSTER_IMPORT_ITEM,
    (id: number) => ({ payload: id })
);

export const setClusterImportComputeResources = createCustomAction(
    types.SET_CLUSTER_IMPORT_COMPUTE_RESOURCES,
    (data: IPaginateApiResponse<IClusterImportComputeResourceResource[]>) => ({ payload: data })
);

export const getClusterImport = (id: number) => async(dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.CLUSTER_IMPORT_ITEM));

    try {
        const result = await clusterImports.item(id);
        if (result.status === HTTP_CODES.OK) {
            dispatch(setClusterImport(result.data.data));
        }

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.CLUSTER_IMPORT_ITEM));
    }
};

export const getClusterImports = (request?: IPaginatedWithSearch, cancelToken?: CancelTokenSource) => async(dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.CLUSTER_IMPORT_LIST));

    try {
        const result = await clusterImports.list(request, cancelToken);
        if (result.status === HTTP_CODES.OK) {
            dispatch(setClusterImports(result.data));
        }

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.CLUSTER_IMPORT_LIST));
    }
};

export const createClusterImport = (data: IClusterImportRequest) => async(dispatch: Dispatch) =>  {
    dispatch(setIsLoading(LOADING_FLAGS.CLUSTER_IMPORT_CREATE));
    try {
        const result = await clusterImports.create(data);

        dispatch(clearFormErrors());
        dispatch(addClusterImportItem(result.data.data));

        return result;
    } catch (e) {
        if (e.response.status === HTTP_CODES.VALIDATION_ERROR) {
            dispatch(setFormErrors(transformErrors(e.response.data.errors)));
        } else if (e.response.status === HTTP_CODES.BAD_REQUEST) {
            dispatch(setFormErrors({
                notAccessible: e.response.data.errors,
            }));
        }

        throw e;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.CLUSTER_IMPORT_CREATE));
    }
};

export const prepareClusterImport = (id: number, data: IClusterImportRequest) => async(dispatch: Dispatch) => {
    dispatch(unsetIsLoading(LOADING_FLAGS.CLUSTER_IMPORT_CREATE));
    try {
        const result = await clusterImports.prepare(id, data);

        dispatch(clearFormErrors());
        dispatch(updateClusterImportItem(id, result.data.data));
        dispatch(setClusterImport(result.data.data));

        return result;
    } catch (e) {
        if (e.response.status === HTTP_CODES.VALIDATION_ERROR) {
            dispatch(setFormErrors(transformErrors(e.response.data.errors)));
        } else if (e.response.status === HTTP_CODES.BAD_REQUEST) {
            dispatch(setFormErrors({
                notAccessible: e.response.data.errors,
            }));
        }

        throw e;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.CLUSTER_IMPORT_CREATE));
    }
};

export const deleteClusterImport = (id: number) => async (dispatch: Dispatch) => await remove(id, {
    dispatch,
    apiCall: clusterImports.remove,
    setLoadingAction: setClusterImportItemIsDeleting,
    action: removeClusterImportItem,
    loadingFlag: LOADING_FLAGS.CLUSTER_IMPORT_REMOVE,
    translations: {
        success: 'clusterImport.toasters.removed',
    },
});

export const getClusterImportComputeResources = (id: number, request?: IPaginatedWithSearch, cancelToken?: CancelTokenSource) => async(dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.CLUSTER_IMPORT_COMPUTE_RESOURCES));
    try {
        const result = await clusterImports.getComputeResources(id, request, cancelToken);

        if (result.status === HTTP_CODES.OK) {
            dispatch(setClusterImportComputeResources(result.data));
        }

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.CLUSTER_IMPORT_COMPUTE_RESOURCES));
    }
};

export const preCheckClusterImport = (clusterImport: IClusterImportResponse) => async (dispatch: Dispatch) => {
    dispatch(setClusterImportStatus(clusterImport.id, CLUSTER_IMPORT_STATUS.PRE_CHECKING));

    try {
        await clusterImports.preCheck(clusterImport.id);
    } catch (e) {
        dispatch(setClusterImportStatus(clusterImport.id, clusterImport.status as CLUSTER_IMPORT_STATUS));
    }
};

export const refreshClusterImport = (clusterImport: IClusterImportResponse) => async(dispatch: Dispatch) => {
    dispatch(setClusterImportStatus(clusterImport.id, CLUSTER_IMPORT_STATUS.REFRESHING));

    try {
        await clusterImports.refresh(clusterImport.id);
    } catch (e) {
        dispatch(setClusterImportStatus(clusterImport.id, clusterImport.status as CLUSTER_IMPORT_STATUS));
    }
};

export const runClusterImport = (clusterImport: IClusterImportResponse, ids: number[]) => async(dispatch: Dispatch) => {
    dispatch(setClusterImportStatus(clusterImport.id, CLUSTER_IMPORT_STATUS.IMPORTING));

    try {
        await clusterImports.run(clusterImport.id, ids);
    } finally {
        dispatch(setClusterImportStatus(clusterImport.id, clusterImport.status as CLUSTER_IMPORT_STATUS));
    }
};
