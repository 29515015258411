// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import React from 'react';
import { INotificationResponse } from 'common/api/resources/Notification';
import {
    NotificationItemContainer,
    NotificationItemContent,
    NotificationItemHeader,
    NotificationItemDescription,
    NotificationItemToolbar,
    IconContainer,
} from 'admin/notification/containers/Items/Styles';
import {
    Translate,
    ToolbarExpander,
} from '@plesk/ui-library';
import { Button } from 'common/components/styles/Button';
import * as notificationActions from 'admin/notification/actions';
import { connect } from 'react-redux';
import {
    Dispatch,
    bindActionCreators,
} from 'redux';

interface INotificationItemProps {
    item: INotificationResponse;
    icon: JSX.Element;
    header: JSX.Element;
    description: JSX.Element;
}

export type NotificationItemProps =
    INotificationItemProps &
    ReturnType<typeof mapDispatchToProps>;

export const NotificationItem: React.FC<NotificationItemProps> = ({
    item,
    icon,
    header,
    description,
    notificationActions: {
        removeNotification,
    },
}) => {
    const handleClick = () => removeNotification(item.id);

    return (
        <NotificationItemContainer>
            <IconContainer>
                {icon}
            </IconContainer>
            <NotificationItemContent>
                <NotificationItemHeader>
                    {header}
                </NotificationItemHeader>
                <NotificationItemDescription>
                    {description}
                </NotificationItemDescription>
                <NotificationItemToolbar>
                    <ToolbarExpander />
                    <Button ghost={true} onClick={handleClick}>
                        <Translate content="notification.remove" />
                    </Button>
                </NotificationItemToolbar>
            </NotificationItemContent>
        </NotificationItemContainer>
    );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    notificationActions: bindActionCreators(notificationActions, dispatch),
});

export default connect(null, mapDispatchToProps)(NotificationItem);
