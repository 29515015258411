// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { INTENT_TYPE } from 'common/constants';
import { bakeForegroundToast } from 'common/modules/app/toaster/actions';
import { createCustomAction } from 'typesafe-actions';
import { Dispatch } from 'redux';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import {
    create,
    get,
    loadOnScroll,
    paginateList,
    remove,
    removeBatch,
    update,
} from 'common/actions/actionsWrapper';
import { IPaginateApiResponse } from 'common/api/resources/Response';
import * as types from 'common/modules/osImage/constants/types';
import { IAppState } from 'admin/core/store';
import {
    setIsLoading,
    unsetIsLoading,
} from 'common/modules/app/loadingFlags/actions';
import { clearFormErrors } from 'common/modules/app/formErrors/actions';
import {
    IOsImageCreateRequest,
    IOsImageListRequest,
    IOsImageResponse,
    IOsImageUpdateRequest,
    osImages,
} from 'common/api/resources/OsImage';
import {
    IOsImageVersionRequest,
    IOsImageVersionResponse,
} from 'common/api/resources/OsImageVersion';

export const setOsImageList = createCustomAction(
    types.SET_OS_IMAGE_LIST,
    (data: IPaginateApiResponse<IOsImageResponse[]>) => ({ payload: data })
);
export const setOsImageItem = createCustomAction(
    types.SET_OS_IMAGE_ITEM,
    (data: IOsImageResponse) => ({ payload: data })
);
export const unsetOsImageItem = createCustomAction(types.UNSET_OS_IMAGE_ITEM);
export const addOsImageItem = createCustomAction(
    types.ADD_OS_IMAGE_ITEM,
    (data: IOsImageResponse) => ({ payload: data })
);
export const updateOsImageItem = createCustomAction(
    types.UPDATE_OS_IMAGE_ITEM,
    (data: IOsImageResponse) => ({ payload: data })
);
export const updateOsImageItemPosition = createCustomAction(
    types.UPDATE_OS_IMAGE_ITEM_POSITION,
    (id: number, position: number) => ({ payload: { id, position } })
);
export const removeOsImageItem = createCustomAction(
    types.REMOVE_OS_IMAGE_ITEM,
    (id: number) => ({ payload: id })
);
export const removeOsImageItems = createCustomAction(
    types.REMOVE_OS_IMAGE_ITEMS,
    (ids: number[]) => ({ payload: ids })
);
export const appendOsImages = createCustomAction(
    types.APPEND_OS_IMAGES,
    (data: IPaginateApiResponse<IOsImageResponse[]>) => ({ payload: data })
);
export const clearDefault = createCustomAction(types.CLEAR_DEFAULT);
export const setOsImageItemIsLoading = createCustomAction(
    types.SET_OS_IMAGE_ITEM_IS_LOADING,
    (index: number) => ({ payload: index })
);
export const unsetOsImageItemIsLoading = createCustomAction(
    types.UNSET_OS_IMAGE_ITEM_IS_LOADING,
    (index: number) => ({ payload: index })
);
export const setOsImageItemIsDeleting = createCustomAction(
    types.SET_OS_IMAGE_ITEM_IS_DELETING,
    (id: number, isDeleting: boolean) => ({ payload: { id, isDeleting } })
);
export const addOsImageVersionItem = createCustomAction(
    types.ADD_OS_IMAGE_VERSION,
    (id: number, data: IOsImageVersionResponse) => ({ payload: { id, data } })
);
export const updateOsImageVersionItem = createCustomAction(
    types.UPDATE_OS_IMAGE_VERSION_ITEM,
    (id: number, data: IOsImageVersionResponse) => ({ payload: { id, data } })
);
export const updateOsImageVersionItemPosition = createCustomAction(
    types.UPDATE_OS_IMAGE_VERSION_ITEM_POSITION,
    (data: IOsImageVersionResponse) => ({ payload: data })
);
export const setOsImageVersionItemIsLoading = createCustomAction(
    types.SET_OS_IMAGE_VERSION_ITEM_IS_LOADING,
    (osImageId: number, id: number) => ({ payload: { osImageId, id } })
);
export const unsetOsImageVersionItemIsLoading = createCustomAction(
    types.UNSET_OS_IMAGE_VERSION_ITEM_IS_LOADING,
    (osImageId: number, id: number) => ({ payload: { osImageId, id } })
);
export const removeOsImageVersionItem = createCustomAction(
    types.REMOVE_OS_IMAGE_VERSION_ITEM,
    (osImageId: number, id: number) => ({ payload: { osImageId, id } })
);

export const getOsImages = (params?: IOsImageListRequest) => async (dispatch: Dispatch) => await paginateList({
    dispatch,
    loadingFlag: LOADING_FLAGS.OS_IMAGE_LIST,
    action: setOsImageList,
    apiCall: () => osImages.list(params),
});

export const createOsImage = (data: IOsImageCreateRequest) => async (dispatch: Dispatch) => await create({
    data,
    dispatch,
    loadingFlag: LOADING_FLAGS.SAVE_OS_IMAGE_ITEM,
    action: addOsImageItem,
    apiCall: osImages.create,
    translations: {
        success: 'osImage.toasts.osImageSaved',
    },
});

export const createOsImageVersion = (id: number, data: IOsImageVersionRequest) => async(dispatch: Dispatch) =>  {
    dispatch(setIsLoading(LOADING_FLAGS.SAVE_OS_IMAGE_VERSION_ITEM));
    try {
        const result = await osImages.versions.create(id, data);

        bakeForegroundToast(INTENT_TYPE.SUCCESS, 'osImageVersion.itemCreateSuccess')(dispatch);
        dispatch(clearFormErrors());
        dispatch(addOsImageVersionItem(id, result.data.data));

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.SAVE_OS_IMAGE_VERSION_ITEM));
    }
};

export const getOsImage = (id: number) => async (dispatch: Dispatch) => await get(id, {
    dispatch,
    apiCall: osImages.item,
    action: setOsImageItem,
    loadingFlag: LOADING_FLAGS.OS_IMAGE_ITEM,
});

export const updateOsImage = (id: number, data: IOsImageCreateRequest) => async (dispatch: Dispatch) => await update(id, {
    data,
    dispatch,
    apiCall: osImages.update,
    action: updateOsImageItem,
    loadingFlag: LOADING_FLAGS.SAVE_OS_IMAGE_ITEM,
    translations: {
        success: 'osImage.toasts.osImageSaved',
    },
});

export const updateDefaultOsImage = (id: number, data: IOsImageUpdateRequest) => async(dispatch: Dispatch) => {
    dispatch(setOsImageItemIsLoading(id));

    try {
        const result = await osImages.patch(id, data);

        dispatch(clearDefault());
        dispatch(updateOsImageItem(result.data.data));

        return result;
    } finally {
        dispatch(unsetOsImageItemIsLoading(id));
    }
};

export const updateOsImageVisibility = (id: number, data: IOsImageUpdateRequest) => async(dispatch: Dispatch) => {
    dispatch(setOsImageItemIsLoading(id));

    try {
        const result = await osImages.patch(id, data);

        dispatch(updateOsImageItem(result.data.data));

        return result;
    } finally {
        dispatch(unsetOsImageItemIsLoading(id));
    }
};

export const removeOsImage = (id: number) => async (dispatch: Dispatch) => await remove(id, {
    dispatch,
    apiCall: osImages.remove,
    setLoadingAction: setOsImageItemIsDeleting,
    action: removeOsImageItem,
    loadingFlag: LOADING_FLAGS.REMOVE_OS_IMAGE_ITEM,
    translations: {
        success: 'osImage.toasts.osImageDeleted',
    },
});

export const removeOsImages = (ids: number[]) => async (dispatch: Dispatch) => await removeBatch(ids, {
    dispatch,
    apiCall: osImages.removeBatch,
    action: removeOsImageItems,
    loadingFlag: LOADING_FLAGS.REMOVE_OS_IMAGE_ITEM,
    translations: {
        success: 'osImage.toasts.osImageBatchDeleted',
    },
});

export const loadOsImagesOnScroll = ()  => async(dispatch: Dispatch, getState: () => IAppState) => {
    const state = getState();
    const nextPage = state.osImage.list.links.next;
    const isLoading = state.app.loadingFlags.has(LOADING_FLAGS.OS_IMAGE_LIST);

    return await loadOnScroll({
        nextPage,
        isLoading,
        dispatch,
        action: appendOsImages,
        loadingFlag: LOADING_FLAGS.OS_IMAGE_LIST,
    });
};

export const updateOsImagePosition = (source_id: number, data: IOsImageUpdateRequest) => async (dispatch: Dispatch) => {
    dispatch(setIsLoading(LOADING_FLAGS.SAVE_OS_IMAGE_ITEM));

    if (data.position) {
        dispatch(updateOsImageItemPosition(source_id, data.position));
    }

    try {
        const result = await osImages.patch(source_id, data);

        bakeForegroundToast(INTENT_TYPE.SUCCESS, 'osImage.toasts.osImageSaved')(dispatch);
        dispatch(updateOsImageItem(result.data.data));

        return result;
    } finally {
        dispatch(unsetIsLoading(LOADING_FLAGS.SAVE_OS_IMAGE_ITEM));
    }
};
