// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { RootState } from 'admin/core/store';
import { connect } from 'react-redux';

export type SurveyProps =
    ReturnType<typeof mapStateToProps>;

export const Survey: React.FC<SurveyProps> = ({
    user,
    cluster,
}) => {
    const createdDate = new Date(user.created_at);
    const now = new Date();
    const daysSinceCreated = Math.round((now.getTime() - createdDate.getTime()) / (1000 * 3600 * 24));
    const userId = cluster + '-' + user.id;

    (function (d: Document, s: string, id: string) {
        const rjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) {
            return;
        }
        let js = d.createElement(s);
        js.id = id;
        // @ts-ignore
        js.src = 'https://cdn.retently.com/public/components/embed/sdk.min.js';
        rjs.parentNode!.insertBefore(js, rjs);
    }(document, 'script', 'retently-jssdk'));

    return (
        <>
            <div
                id="retently-survey-embed"
                data-href="https://app.retently.com/api/remote/tracking/606c61a4c8c2d36d01246bc4"
                data-rel="dialog"
                data-email={userId + '@solusvm2.user'}
                data-prop-user-id={userId}
                data-prop-days-since-creation={daysSinceCreated}
                data-tags="SolusVM2"
                data-campaignId="65bb783989c99818aad5dcb6"
            ></div>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    user: state.auth.user,
    cluster: state.app.meta.cluster,
});

export default connect(mapStateToProps)(Survey);
