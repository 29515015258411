// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import * as taskActions from 'admin/task/actions';
import { PageHeader } from 'admin/common/components/PageHeader/PageHeader';
import {
    Filters,
    IFilterOptions,
    ISelectOption,
} from 'admin/task/components/Filters';
import { ValueType } from 'react-select';
import queryString from 'query-string';
import TaskTable from 'admin/task/containers/TaskTable';
import { TASK_ACTION } from 'common/api/resources/Task';
import { computeResources } from 'common/api/resources/ComputeResource';
import { Translate } from '@plesk/ui-library';
import { useRequestCancellationEffect } from 'common/hooks/useRequestCancellationEffect';

interface IQueryParams {
    compute_resource_id?: number;
    action?: TASK_ACTION;
    version?: string;
}

export type TasksProps =
    RouteComponentProps &
    ReturnType<typeof mapDispatchToProps>;

export const Tasks: React.FC<TasksProps> = ({
    location,
    taskActions: {
        getTaskList,
    },
}) => {
    const query: IQueryParams = queryString.parse(location.search);

    const [filters, setFilters] = React.useState<IFilterOptions>({
        action: query.action,
        version: query.version,
        compute_resource_id: query.compute_resource_id,
    });

    const [selectedComputeResource, setSelectedComputeResource] = React.useState<ISelectOption>();

    const isFirstLoading = useRequestCancellationEffect(
        token => getTaskList({ filters }, token),
        [filters]
    );

    React.useEffect(() => {
        const fetchComputeResource = async () => {
            if (query.compute_resource_id) {
                const computeResource = await computeResources.item(query.compute_resource_id);

                setSelectedComputeResource({
                    value: computeResource.data.data.id.toString(),
                    label: computeResource.data.data.host,
                });
            }
        };

        fetchComputeResource();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleStatusFilterChange = async (option: ValueType<ISelectOption>) => {
        setFilters(values => ({
            ...values,
            status: option ? (option as ISelectOption).value : '',
        }));
    };

    const handleActionFilterChange = async (option: ValueType<ISelectOption>) => {
        setFilters(values => ({
            ...values,
            action: option ? (option as ISelectOption).value : '',
        }));
    };

    const handleComputeResourceFilterChange = async (option: ValueType<ISelectOption>) => {
        const computeResourceOption = option as ISelectOption;

        setFilters(values => ({
            ...values,
            compute_resource_id: computeResourceOption ? parseFloat(computeResourceOption.value!) : undefined,
        }));
    };

    return (
        <>
            <PageHeader title={<Translate content="task.title" />} isButtonShown={false} />
            <TaskTable
                isFirstLoading={isFirstLoading}
                filters={filters}
                toolbar={(
                    <Filters
                        data={filters}
                        selectedComputeResource={selectedComputeResource}
                        onActionFilterChange={handleActionFilterChange}
                        onStatusFilterChange={handleStatusFilterChange}
                        onComputeResourceFilterChange={handleComputeResourceFilterChange}
                    />
                )}
            />
        </>
    );
};

const mapDispatchToProps = (dispatch: Dispatch) => ({
    taskActions: bindActionCreators(taskActions, dispatch),
});

export default connect(null, mapDispatchToProps)(Tasks);
