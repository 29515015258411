// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { connect } from 'react-redux';
import {
    Icon,
    Item,
    Translate,
} from '@plesk/ui-library';
import { RootState } from 'admin/core/store';
import TaskTable from 'admin/task/containers/TaskTable';
import { ItemWithTableContainer } from 'admin/dashboard/containers/items/Styles';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';

export type TaskItemProps =
    ReturnType<typeof mapStateToProps>;

export const TaskItem: React.FC<TaskItemProps> = ({
    isLoadingTaskList,
}) => (
    <ItemWithTableContainer>
        <Item
            icon={<Icon name="check-list" intent="info" />}
            view="card"
            title={<Translate content="dashboard.cards.tasks" />}
        >
            <TaskTable isFirstLoading={isLoadingTaskList} borderless={true}/>
        </Item>
    </ItemWithTableContainer>
);

const mapStateToProps = (state: RootState) => ({
    isLoadingTaskList: state.app.loadingFlags.has(LOADING_FLAGS.COMPUTE_RESOURCE_TASK_LIST_INFINITY_SCROLL),
});

export default connect(mapStateToProps)(TaskItem);
