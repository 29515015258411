// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import LocationForm from 'admin/location/containers/LocationForm';
import { Loader } from 'common/components';
import { connect } from 'react-redux';
import {
    bindActionCreators,
    Dispatch,
} from 'redux';
import { RootState } from 'admin/core/store';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import * as locationActions from 'common/modules/location/actions';
import { PageHeader } from 'admin/common/components/PageHeader/PageHeader';
import InfiniteScroll from 'common/components/InfinityScroll/InfinityScroll';
import { StyledTable } from 'common/components/styles/StyledTable';
import { StyledActions } from 'common/components/Actions/Styles';
import { ILocationResponse } from 'common/api/resources/Location';
import {
    Action,
    Icon,
    List,
    Switch,
    Tooltip,
    Translate,
} from '@plesk/ui-library';
import { LOCATIONS } from 'common/modules/location/constants/tests';
import { EmptyView } from 'common/components/EmptyView/EmptyView';
import ButtonWithConfirmation from 'common/components/ButtonWithConfirmation';
import { Dialog } from 'common/components/Dialog/Dialog';
import { dataCySelector } from 'common/tests/selectors';
import LocationCell from 'common/components/IconCell/LocationCell';
import { SetAsDefaultAction } from 'admin/common/components/SetAsDefaultAction';
import { IListReorderedItem } from 'common/reducers/list';
import { getNewPosition } from 'common/helpers/position';
import { getActionColumnProps } from 'common/helpers/list';

export type LocationProps =
    ReturnType<typeof mapStateToProps> &
    ReturnType<typeof mapDispatchToProps>;

const columns = [{
    key: 'colId',
    width: '1%',
    title: <Translate content="location.list.id" />,
}, {
    key: 'colName',
    title: <Translate content="location.list.name" />,
    cellProps: {
        className: 'cell-bold',
    },
}, {
    key: 'colDescription',
    title: <Translate content="location.list.description" />,
}, {
    width: '1%',
    key: 'colVisibility',
    title: <Translate content="location.list.visibility" />,
    cellProps: {
        style: { textAlign: 'center' },
    },
}, getActionColumnProps(),
];

export const Location: React.FC<LocationProps> = ({
    locationActions: {
        loadLocationsOnScroll,
        getLocations,
        getLocation,
        removeLocation,
        updateDefaultLocation,
        updateLocationVisibility,
        updateLocationPosition,
    },
    list,
    item,
    hasMore,
    loadingFlags: {
        isLoadingList,
        isLoadingItem,
        isSavingItem,
    },
    reorderable,
}) => {
    const [isOpen, setIsOpen] = React.useState(false);
    const [blockDefault, setBlockDefault] = React.useState(false);
    const [blockVisibility, setBlockVisibility] = React.useState(false);

    React.useEffect(() => {
        getLocations();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleCreate = () => setIsOpen(true);
    const handleClose = () => setIsOpen(false);
    const handleEdit = (id: number) => async() => {
        getLocation(id);
        setIsOpen(true);
    };
    const handleRemove = (id: number) => async() => await removeLocation(id);
    const handleSetDefault = (location: ILocationResponse) => async() => {
        if (blockDefault) {
            return;
        }

        try {
            setBlockDefault(true);
            await updateDefaultLocation(location.id, { is_default: !location.is_default });
        } finally {
            setBlockDefault(false);
        }
    };
    const handleToggleVisibility = (location: ILocationResponse) => async() => {
        if (blockVisibility && location.is_loading) {
            return;
        }

        try {
            setBlockVisibility(true);
            await updateLocationVisibility(location.id, { is_visible: !location.is_visible });
        } finally {
            setBlockVisibility(false);
        }
    };

    const handleDragEnd = async (reorderData: IListReorderedItem) => {
        if (list[reorderData.oldIndex] !== undefined) {
            const newPos = getNewPosition(reorderData.oldIndex, reorderData.newIndex, list);

            await updateLocationPosition(list[reorderData.oldIndex].id, { position: newPos });
        }
    };

    const data = React.useMemo(() =>  list.map((location) => {
        const actionsEl = (
            <StyledActions>
                <SetAsDefaultAction
                    title={<Translate content="location.tooltip.setAsDefault"/>}
                    isDefault={location.is_default}
                    isLoading={location.is_loading && blockDefault}
                    onClick={handleSetDefault(location)}
                    data-cy={dataCySelector(location.id, LOCATIONS.DEFAULT)}
                />
                <Tooltip title={<Translate content="location.tooltip.edit"/>}>
                    <Action
                        icon={<Icon name="pencil" />}
                        className="action-icon"
                        onClick={handleEdit(location.id)}
                    />
                </Tooltip>
                <ButtonWithConfirmation
                    isLoading={location.is_deleting}
                    translations={{
                        button: (
                            <Translate content="location.buttonWithConfirmation.button" />
                        ),
                        title: (
                            <Translate content="location.buttonWithConfirmation.title" />
                        ),
                        tooltip: (
                            <Translate content="location.tooltip.remove" />
                        ),
                    }}
                    handleConfirm={handleRemove(location.id)}
                    icon="recycle"
                />
            </StyledActions>
        );

        return {
            colId: location.id,
            colName: <LocationCell location={location}/>,
            colDescription: location.description,
            colVisibility: <Switch
                checked={location.is_visible}
                onChange={handleToggleVisibility(location)}
                loading={location.is_loading && blockVisibility}
                data-cy={dataCySelector(location.id, LOCATIONS.VISIBILITY)}
            />,
            colActions: actionsEl,
            key: location.id.toString(),
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }), [list, isSavingItem, blockDefault, blockVisibility]);

    return (
        <>
            <PageHeader
                title={<Translate content="location.title"/>}
                buttonText="location.addBtn"
                buttonIcon="location"
                onButtonClick={handleCreate}
                isButtonShown={data.length > 0}
            />
            <StyledTable>
                <InfiniteScroll
                    loadMore={loadLocationsOnScroll}
                    hasMore={hasMore}
                >
                    <Loader isLoading={isLoadingList}>
                        <List
                            emptyView={
                                <EmptyView
                                    title="location.emptyView.title"
                                    description="location.emptyView.description"
                                    buttonText="location.emptyView.buttonText"
                                    onButtonClick={handleCreate}
                                    icon="location"
                                />
                            }
                            data-cy={LOCATIONS.TABLE}
                            columns={columns}
                            data={data}
                            reorderable={reorderable}
                            onReorderEnd={handleDragEnd}
                        />
                    </Loader>
                </InfiniteScroll>
            </StyledTable>
            <Dialog
                heading={<Translate content={item.id ? 'location.form.titleEdit' : 'location.form.titleAdd'} />}
                closeHandler={handleClose}
                isOpen={isOpen}
                size="xs"
            >
                <Loader isLoading={isLoadingItem} center={false}>
                    <LocationForm closeDialog={handleClose} />
                </Loader>
            </Dialog>
        </>
    );
};

const mapStateToProps = (state: RootState) => ({
    list: state.location.list.data.sort((a, b) => a.position - b.position),
    item: state.location.item,
    hasMore: !!state.location.list.links.next,
    errors: state.app.formErrors,
    loadingFlags: {
        isLoadingList: !state.location.list.data.length && state.app.loadingFlags.has(LOADING_FLAGS.LOCATION_LIST),
        isLoadingItem: state.app.loadingFlags.has(LOADING_FLAGS.LOCATION_ITEM),
        isSavingItem: state.app.loadingFlags.has(LOADING_FLAGS.SAVE_LOCATION_ITEM),
    },
    reorderable: true,
});

const mapDispatchToProps = (dispatch: Dispatch) => ({
    locationActions: bindActionCreators(locationActions, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(Location);
