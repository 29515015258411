// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import { createCustomAction } from 'typesafe-actions';
import { Dispatch } from 'redux';
import { LOADING_FLAGS } from 'common/modules/app/loadingFlags/constants';
import {
    create,
    get,
    paginateList,
    remove,
    update,
} from 'common/actions/actionsWrapper';
import { IPaginateApiResponse } from 'common/api/resources/Response';
import * as types from 'admin/storageTag/constants/types';
import {
    IStorageTagListRequest,
    IStorageTagPatchRequest,
    IStorageTagRequest,
    IStorageTagResponse,
} from 'common/api/resources/StorageTag/model';
import { storageTag } from 'common/api/resources/StorageTag/api';

export const setStorageTagList = createCustomAction(
    types.SET_STORAGE_TAG_LIST,
    (data: IPaginateApiResponse<IStorageTagResponse[]>) => ({ payload: data })
);
export const setStorageTagItem = createCustomAction(
    types.SET_STORAGE_TAG_ITEM,
    (data: IStorageTagResponse) => ({ payload: data })
);
export const unsetStorageTagItem = createCustomAction(types.UNSET_STORAGE_TAG_ITEM);
export const addStorageTagItem = createCustomAction(
    types.ADD_STORAGE_TAG_ITEM,
    (data: IStorageTagResponse) => ({ payload: data })
);
export const updateStorageTagItem = createCustomAction(
    types.UPDATE_STORAGE_TAG_ITEM,
    (data: IStorageTagResponse) => ({ payload: data })
);
export const removeStorageTagItem = createCustomAction(
    types.REMOVE_STORAGE_TAG_ITEM,
    (id: number) => ({ payload: id })
);
export const setStorageTagItemIsDeleting = createCustomAction(
    types.SET_STORAGE_TAG_ITEM_IS_DELETING,
    (id: number, isDeleting: boolean) => ({ payload: { id, is_deleting: isDeleting } })
);

export const getStorageTags = (request?: IStorageTagListRequest) => async (dispatch: Dispatch) => {
    const apiCall = () => storageTag.list(request);

    return await paginateList({
        dispatch,
        loadingFlag: LOADING_FLAGS.STORAGE_TAG_LIST,
        action: setStorageTagList,
        apiCall,
    });
};

export const createStorageTag = (data: IStorageTagRequest) => async (dispatch: Dispatch) => await create({
    data,
    dispatch,
    loadingFlag: LOADING_FLAGS.STORAGE_TAG_ITEM_SAVE,
    action: addStorageTagItem,
    apiCall: storageTag.create,
    translations: {
        success: 'storageTag.toasts.saved',
    },
});

export const getStorageTag = (id: number) => async (dispatch: Dispatch) => await get(id, {
    dispatch,
    apiCall: storageTag.item,
    action: setStorageTagItem,
    loadingFlag: LOADING_FLAGS.STORAGE_TAG_ITEM,
});

export const updateStorageTag = (id: number, data: IStorageTagRequest) => async (dispatch: Dispatch) => await update(id, {
    data,
    dispatch,
    apiCall: storageTag.update,
    action: updateStorageTagItem,
    loadingFlag: LOADING_FLAGS.STORAGE_TAG_ITEM_SAVE,
    translations: {
        success: 'storageTag.toasts.saved',
    },
});

export const updateStorageTagPosition = (id: number, data: IStorageTagPatchRequest) => async (dispatch: Dispatch) => await update(id, {
    data,
    dispatch,
    apiCall: storageTag.patch,
    action: updateStorageTagItem,
    loadingFlag: LOADING_FLAGS.STORAGE_TAG_ITEM_SAVE,
    translations: {
        success: 'storageTag.toasts.saved',
    },
});

export const removeStorageTag = (id: number) => async (dispatch: Dispatch) => await remove(id, {
    dispatch,
    apiCall: storageTag.remove,
    setLoadingAction: setStorageTagItemIsDeleting,
    action: removeStorageTagItem,
    loadingFlag: LOADING_FLAGS.STORAGE_TAG_ITEM_REMOVE,
    translations: {
        success: 'storageTag.toasts.deleted',
    },
});
