// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import { ISelectOption } from 'common/components';
import { createOptionsLoader } from 'common/components/Select/helpers';
import {
    FormField,
    Translate,
} from '@plesk/ui-library';
import AsyncSelectInput from 'common/components/Select/AsyncSelectInput';
import { INTENT_TYPE } from 'common/constants';
import { Button } from 'admin/common/components/Button/Button';
import { IPaginateApiResponse } from 'common/api/resources/Response';
import { IPaginatedWithSearch } from 'common/api/resources/Request/request';
import { AxiosPromise } from 'axios';
import { LabelWithButton } from 'admin/common/components/SelectWithDataLoader/Styles';

export interface ISelectWithDataLoader<Item> {
    name: string;
    label: string;
    buttonLabel?: string;
    loadItems: (params?: IPaginatedWithSearch) => AxiosPromise<IPaginateApiResponse<Item[]>>;
    mapper: (item: Item) => ISelectOption;
    onChange: (ids: number[]) => void;
    values?: ISelectOption[];
    resetItems?: boolean;
    disabled?: boolean;
    // this is as-is from the original component
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    cacheUniqs?: readonly any[];
}

export function SelectWithDataLoader<Item>(props: ISelectWithDataLoader<Item>) {
    const {
        name,
        label,
        buttonLabel,
        loadItems,
        mapper,
        onChange,
        values = [],
        resetItems,
        disabled,
        cacheUniqs,
    } = props;

    const [selectedItems, setSelectedItems] = React.useState<ISelectOption[]>(values);
    const [isLoading, setIsLoading] = React.useState(false);

    React.useEffect(() => {
        setSelectedItems(values);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [resetItems]);

    const loadOptions = createOptionsLoader(loadItems, mapper);

    const handleChange = (options: ISelectOption[]) => {
        onChange(options !== null ? options.map(option => option.value as number) : []);
        setSelectedItems(options !== null ? [...options] : []);
    };

    const loadAllItems = async () => {
        let list: ISelectOption[] = [];
        let hasMore = false;
        let i = 0;

        setIsLoading(true);

        do {
            try {
                const result = await loadItems({ page: ++i });
                list = [...list, ...result.data.data.map(mapper)];
                hasMore = !!result.data.links.next && result.data.meta.current_page === i;
            } catch {
                hasMore = false;
            }
        } while (hasMore);

        setSelectedItems(list.map(item => {
            if (item.options !== undefined) {
                return item.options.map(option => ({
                    ...option,
                    label: item.label + ' ' + option.label,
                }));
            }

            return item;
        }).flat(1));
        onChange(list.map(item => {
            if (item.options !== undefined) {
                return item.options.map(option => option.value as number);
            }

            return item.value as number;
        }).flat(1));

        setIsLoading(false);
    };

    return (
        <FormField
            name={name}
            label={buttonLabel
                ? (
                    <LabelWithButton>
                        <Translate content={label} />
                        <Button
                            ghost={true}
                            intent={INTENT_TYPE.PRIMARY}
                            state={isLoading ? 'loading' : undefined}
                            onClick={loadAllItems}
                            disabled={disabled}
                        >
                            <Translate content={buttonLabel} />
                        </Button>
                    </LabelWithButton>
                )
                : <Translate content={label} />
            }
        >
            {({ getId }) => (
                <AsyncSelectInput
                    inputId={getId()}
                    value={selectedItems}
                    isMulti={true}
                    loadOptions={loadOptions}
                    onChange={handleChange}
                    debounceTimeout={1000}
                    additional={{ page: 1 }}
                    isDisabled={disabled || isLoading}
                    menuPosition="fixed"
                    cacheUniqs={cacheUniqs}
                />
            )}
        </FormField>
    );
}
