// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import React from 'react';
import { IVersion } from 'common/api/resources/Update';
import {
    VersionCardContent,
    ChangeLog,
    VersionContent,
} from 'admin/update/containers/VersionCard/Styles';
import { VersionTitle } from 'admin/update/components/VersionTitle/VersionTitle';
import { Translate } from '@plesk/ui-library';
import Change from 'admin/update/components/Change/Change';
import VersionState from 'admin/update/containers/VersionState/VersionState';

export interface IVersionCardProps {
    item: IVersion;
    isCurrentVersion: boolean;
    isInstallable: boolean;
}

export const VersionCard: React.FC<IVersionCardProps> = ({
    item,
    isCurrentVersion,
    isInstallable,
}) => {
    const changes = item.changelog.map((change, index) => <Change key={index} item={change} />);

    return (
        <VersionCardContent>
            <VersionContent>
                <VersionTitle
                    version={item.version}
                    date={item.date}
                    isCurrentVersion={isCurrentVersion}
                />
                {item.date && (
                    <ChangeLog>
                        <h4 style={{ marginBottom: '4px' }}>
                            <Translate content="update.changeLog" />
                        </h4>
                        {changes}
                    </ChangeLog>
                )}
            </VersionContent>
            {isInstallable && <VersionState />}
        </VersionCardContent>
    );
};

export default VersionCard;
