// Copyright 1999-2024. WebPros International GmbH. All rights reserved.

import * as React from 'react';
import {
    Translate,
    Text,
} from '@plesk/ui-library';

export interface ITitleProps {
    content: string;
}

export const Title: React.FC<ITitleProps> = (props) => {
    const { content } = props;

    return (
        <Text intent="muted">
            <Translate content={content} />
        </Text>
    );
};
